import { createAction } from 'typesafe-actions'
import {
  GET_YEEGE_GAME_HISTORY_REQUEST,
  GET_YEEGE_GAME_HISTORY_SUCCESS,
  GET_YEEGE_GAME_HISTORY_FAILURE,
  GET_YEEGE_GAME_HISTORY_CANCEL,
  CLEAR_YEEGE_GAME_HISTORY,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getYeegeGameHistoryAction = createAction(
  GET_YEEGE_GAME_HISTORY_REQUEST,
  resolve => (request: IYeegeGameHistoryRequest) => resolve(request))

const getYeegeGameHistorySuccessAction = createAction(
  GET_YEEGE_GAME_HISTORY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IYeegeGameHistory[]>>) => resolve(data))

const getYeegeGameHistoryFailureAction = createAction(
  GET_YEEGE_GAME_HISTORY_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getYeegeGameHistoryCancelAction = createAction(GET_YEEGE_GAME_HISTORY_CANCEL)

const clearYeegeGameHistory = createAction(CLEAR_YEEGE_GAME_HISTORY)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getYeegeGameHistoryAction,
  getYeegeGameHistorySuccessAction,
  getYeegeGameHistoryFailureAction,
  getYeegeGameHistoryCancelAction,
  clearYeegeGameHistory,
}