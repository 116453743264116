import { createAction } from 'typesafe-actions'
import { CLICK_REF, CLICK_REF_CLEAR } from './constants'

const clickRef = createAction(
  CLICK_REF,
  resolve => (data: IClickRef) =>
    resolve(data)
)

const clearClickRef = createAction(CLICK_REF_CLEAR,)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  clickRef,
  clearClickRef
}