import { createAction } from 'typesafe-actions'
import {
    UPDATE_PMPG_ERA_REQUEST,
    UPDATE_PMPG_ERA_SUCCESS,
    LISTEN_PMPG_ERA_SOCKET,
} from './constants'


const listenPmpgEraSocket = createAction(LISTEN_PMPG_ERA_SOCKET)

const updatePmpgEraAction = createAction(
    UPDATE_PMPG_ERA_REQUEST,
    resolve => (data: IPmpgEra) => resolve(data))

const updatePmpgEraSuccessAction = createAction(
    UPDATE_PMPG_ERA_SUCCESS,
    resolve => (data: IPmpgEra) => resolve(data))


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    listenPmpgEraSocket,
    updatePmpgEraAction,
    updatePmpgEraSuccessAction,
}