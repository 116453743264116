import React, { FC } from 'react'
import './banner.style.scss'
import AwesomeSlider from 'react-awesome-slider'
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss'
import withAutoplay from 'react-awesome-slider/dist/autoplay';

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IBanner = {
  ImageIcon: [''],
  size: 'large',
}

const Banner: FC<IBanner & DefaultProps> = (props) => {
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  return (
    <div className={`banner-container image-${props.size}`}>
      <AutoplaySlider
        // play={true}
        cancelOnInteraction={false}
        // interval={3000}
        animation="foldOutAnimation"
        cssModule={[CoreStyles, AwesomeSliderStyles]}
      >
        {
          props.ImageIcon.map((src, index) => (
            <div key={`${window.location.pathname}${src}${index}`} data-src={src} />
          ))
        }
      </AutoplaySlider>
    </div>
  )
}

Banner.defaultProps = defaultProps

export default Banner