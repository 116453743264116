import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { fetchGetBetHistory } from './services'
import { RootAction } from 'typings/reduxs/Actions'
import actions from './actions'

const getBetHistoryListEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>{
return action$.pipe(
  filter(isActionOf(actions.getBetHistoryAction)),
  exhaustMap(action =>
    from(fetchGetBetHistory(action.payload))
  .pipe(
    exhaustMap(response =>
      of(actions.getBetHistorySuccessAction(response, action.payload.isFirst || false))),
    catchError(error =>
      of(actions.getBetHistoryFailureAction(error))),
    takeUntil(action$.pipe(filter(isActionOf(actions.getBetHistoryCancelAction))))
  )
  )
)}
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  getBetHistoryListEpic,
]
