import { get } from 'lodash'
import project from 'constants/project'
import { initialState as initialCreditInfoListState } from './list/constants'
import { initialState as initialICreditInfoBetLotteryState } from './lottery/constants'
import { initialState as initialBetLotteryBenefitState } from './report/constants'
import { initialState as initialCodeLottoGameState } from '../user/codeCreditReport/constants'
const creditInfoList = (state: RootReducers): ICreditInfoListState =>
    get(state, `${project.name}.credit.list`, initialCreditInfoListState)

const creditMeDetail = (state: RootReducers): ReducerState<ICreditDetail[]> =>
    get(state, `${project.name}.credit.detail`, initialCreditInfoListState)

const creditInfoBetLottery = (state: RootReducers): ICreditInfoBetLotteryState =>
    get(state, `${project.name}.credit.lottery`, initialICreditInfoBetLotteryState)

const creditInfoBetGame = (state: RootReducers): ICreditInfoBetLotteryState =>
    get(state, `${project.name}.credit.game`, initialICreditInfoBetLotteryState)

const getLotterBenefit = (state: RootReducers): ReducerState<IResponseBetLotteryBenefit> =>
    get(state, `${project.name}.credit.lotteryBenefit`, initialBetLotteryBenefitState)

const getGameBenefit = (state: RootReducers): ReducerState<IResponseBetLotteryBenefit> =>
    get(state, `${project.name}.credit.gameBenefit`, initialBetLotteryBenefitState)

const getCodeLotterGame = (state: RootReducers): ICodeLotterGameState =>
    get(state, `${project.name}.user.codeCreditReport`, initialCodeLottoGameState)
// eslint-disable-next-line import/no-anonymous-default-export    
export default {
    creditInfoList,
    creditMeDetail,
    creditInfoBetLottery,
    getLotterBenefit,
    getGameBenefit,
    creditInfoBetGame,
    getCodeLotterGame
}