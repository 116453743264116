
import project from 'constants/project'

export const UPDATE_PMPG_ERA_REQUEST = 'UPDATE_PMPG_ERA_REQUEST'
export const UPDATE_PMPG_ERA_SUCCESS = 'UPDATE_PMPG_ERA_SUCCESS'
export const LISTEN_PMPG_ERA_SOCKET = 'LISTEN_PMPG_ERA_SOCKET'

export const initialState: ReducerState<IPmpgEra> = {
  isFetching: false,
  code: 0,
  data: {
    isTinGive: false,
    isBronzeGive: false,
    isSilverGive: false,
    isGoldGive: false,
    highlightEra: {
      average: 0,
      total: 0,
      type: '',
    },
  },
  error: '',
}


export const endpoint = {
  getPmpgEra: `${project.environment[project.environmentName].api}/pmpg/era`,
}