import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, exhaustMap, filter, map, takeUntil } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { RootAction } from "typings/reduxs/Actions";
import actions from "./actions";
import { fetchGetNotificationUser } from "./services";

const getBetNumberRateEpic: Epic<RootAction, RootAction, RootReducers> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(actions.getNotificationUserAction)),
    exhaustMap((action) =>
      from(fetchGetNotificationUser(action.payload)).pipe(
        map(actions.getNotificationUserSuccessAction),
        catchError((error) =>
          of(actions.getNotificationUserFailureAction(error))
        ),
        takeUntil(
          action$.pipe(
            filter(isActionOf(actions.getNotificationUserCancelAction))
          )
        )
      )
    )
  );
// eslint-disable-next-line import/no-anonymous-default-export
export default [getBetNumberRateEpic];
