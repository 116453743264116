import { createAction } from 'typesafe-actions'
import {
  GET_BET_LIMIT_REQUEST,
  GET_BET_LIMIT_SUCCESS,
  GET_BET_LIMIT_FAILURE,
  GET_BET_LIMIT_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getBetLimitAction = createAction(GET_BET_LIMIT_REQUEST)

const getBetLimitSuccessAction = createAction(
  GET_BET_LIMIT_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IBetLimit>>) => resolve(data))

const getBetLimitFailureAction = createAction(
  GET_BET_LIMIT_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getBetLimitCancelAction = createAction(GET_BET_LIMIT_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBetLimitAction,
  getBetLimitSuccessAction,
  getBetLimitFailureAction,
  getBetLimitCancelAction,
}