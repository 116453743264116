
import project from 'constants/project'

export const GET_AWARD_LIST_PMPG_REQUEST = 'GET_AWARD_LIST_PMPG_REQUEST'
export const GET_AWARD_LIST_PMPG_SUCCESS = 'GET_AWARD_LIST_PMPG_SUCCESS'
export const GET_AWARD_LIST_PMPG_FAILURE = 'GET_AWARD_LIST_PMPG_FAILURE'
export const GET_AWARD_LIST_PMPG_CANCEL= 'GET_AWARD_LIST_PMPG_CANCEL'

export const initialState: ReducerState<IPmpgAwardList[]> = {
  isFetching: false,
  code: 0,
  data: [
    {
      no: 0,
      result: 0,
      username: '',
      approve_limit: 0,
    },
  ],
  error: '',
}


export const endpoint = {
  getAwardListPmpg: `${project.environment[project.environmentName].api}/pmpg/award-list`,
}