import project from "constants/project";

export const POST_NOTIFICATION_USER_READ_REQUEST =
  "POST_NOTIFICATION_USER_READ_REQUEST";
export const POST_NOTIFICATION_USER_READ_SUCCESS =
  "POST_NOTIFICATION_USER_READ_SUCCESS";
export const POST_NOTIFICATION_USER_READ_FAILURE =
  "POST_NOTIFICATION_USER_READ_FAILURE";
export const POST_NOTIFICATION_USER_READ_CANCEL =
  "POST_NOTIFICATION_USER_READ_CANCEL";

export const initialState: ReducerState<{}> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
};

export const endpoint = {
  postNotificationUserRead: `${
    project.environment[project.environmentName].api
  }/v1/notification/user/read`,
};
