const thai = {
  name: 'thai',
  startYear: 1,
  yearLength: 365,
  epoch: 1523097,
  century: 25,
  weekStartDayIndex: 1,
  getMonthLengths(isLeap: any) {
    return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  },
  isLeap(year: any) {
    const yearAD = year - 543
    return (yearAD % 4 === 0 && yearAD % 100 !== 0) || yearAD % 400 === 0;
  },
  getLeaps(currentYear: any) {
    if (currentYear === 0) return;

    let year = currentYear > 0 ? 1 : -1;

    const leaps:any = [],
      // tslint:disable-next-line:prefer-const
      condition = () =>
        currentYear > 0 ? year <= currentYear : currentYear <= year,
      // tslint:disable-next-line:prefer-const
      increase = () => (currentYear > 0 ? year++ : year--);

    while (condition()) {
      if (this.isLeap(year)) leaps.push(year);

      increase();
    }

    return leaps;
  },
  getDayOfYear({ year, month, day }: any) {
    const monthLengths:any = this.getMonthLengths(this.isLeap(year));

    for (let i = 0; i < month.index; i++) {
      day += monthLengths[i];
    }

    return day;
  },
  getAllDays(date: any) {
    const { year } = date;

    return (
      this.yearLength * (year - 1) +
      this.leapsLength(year) +
      this.getDayOfYear(date)
    );
  },
  leapsLength(year: any) {
    return (
      // tslint:disable-next-line:no-bitwise
      (((year - 1) / 4) | 0) +
         // tslint:disable-next-line:no-bitwise
      (-((year - 1) / 100) | 0) +
         // tslint:disable-next-line:no-bitwise
      (((year - 1) / 400) | 0)
    );
  },
  guessYear(days: any, currentYear: any) {
    // tslint:disable-next-line:no-bitwise
    const year:any = ~~(days / 365.24);

    return year + (currentYear > 0 ? 1 : -1);
  },
};

export default thai;