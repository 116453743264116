import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const getBetLotteryPullDetailReducer = (
  state: IBetLotteryPullDetailState = initialState,
  action: RootAction
): IBetLotteryPullDetailState => {
  switch (action.type) {
    case getType(actions.getLotteryPullDetailAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getLotteryPullDetailSuccessAction):
      const data = (action.payload.isFirst)
      ? action.payload.data.data.dataList
      : [...(state.data || []), ...(action.payload.data.data.dataList || [] || null)]
      return {
        ...state,
        isFetching: false,
        data: data,
        code: action.payload.status,
        pagination: action.payload.data.data,
      }

    case getType(actions.getLotteryPullDetailFailureAction):
      return {
        ...state,
        isFetching: false,
        error: action.payload.response?.data.devMessage,
        code: action.payload.response?.status,
      }
    case getType(actions.clearLotteryPullDetailAction):
      return initialState
    default:
      return state
  }
}

export default getBetLotteryPullDetailReducer