import React, { FC } from "react";
import "./lottoBetItem.style.scss";
import { number } from "utils";
import { Dictionary, groupBy, isEmpty, map } from "lodash";
import { CheckBox } from "components/CheckBox";
import { BetLotteryDetailItem } from "components/BetLotteryDetailItem";
import { LOTTO_BET_TYPES, LOTTO_GAME_TYPE_NAME } from "constants/variables";
const constants = {
  bet: "เดิมพัน",
  fund: "ทุน",
  lottoOn: "โพยเมื่อ",
  active: "ใช้งาน",
  numbers: "จำนวน",
  backText: "ย้อนกลับ",
  detail: "รายละเอียด",
  selectAll: "เลือกทั้งหมด",
  select: "เลือก",
  historyOld: "รายละเอียดและปรับแต่งโพย",
  note: "ระบบสะสมเพียง 10 โพย ล่าสุดเท่านั้น",
  historyBet: "ประวัติการแทง",
  lotter: "แทงหวย",
  confirm: "ยืนยันดึงโพย",
  announce: "โพยดังกล่าวไม่มีข้อมูล",
  accept: "เข้าใจ",
  description: "กรุณาเลือกเลขจากโพยดังกล่าวเพื่อใช้งานระบบดึงโพยจากประวัติ",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ILottoBetItem = {
  totalNumbers: 0,
  totalValue: 0,
  time: "",
  containerClassName: "",
  result: "",
  isGame: false,
  onClick: undefined,
  expandedItem: [],
  isLottoOldFavoriteConfirmModal: false,
};

const LottoBetItem: FC<ILottoBetItem & DefaultProps> = (props) => {
  const {
    totalNumbers,
    totalValue,
    onClick,
    time,
    containerClassName,
    expandedItem,
    betLotteryPullDetail,
    selectAll,
    keys,
    onCheckBoxSelect,
    onSelectAll,
    onCheckBox,
    isLottoOldFavoriteConfirmModal,
  } = props;

  const renderOldFavoriteLottoList = () => {
    const betLotteryPullDetailGroupList: Dictionary<
      IResBetLotteryPullDetail[]
    > = groupBy<IResBetLotteryPullDetail>(betLotteryPullDetail, "type");
    return map(betLotteryPullDetailGroupList, (_, key, index) => {
      const betLotteryItem = betLotteryPullDetailGroupList[key].map(
        (betLotteryPullDetail, betLotteryPullDetailIndex) => {
          return (
            <BetLotteryDetailItem
              betLotteryPullDetail={betLotteryPullDetail}
              key={`credit-info-items-${betLotteryPullDetailIndex}`}
              selectAll={
                selectAll && keys![LOTTO_BET_TYPES[key as TLottoGameType]]
                  ? false
                  : false
              }
              onClick={() => onCheckBox!(betLotteryPullDetail)}
            />
          );
        }
      );
      return (<>
        <div className="row mt-1" key={`${key}-${index}`}>
          {/* <div className="col"> */}
            {/* <div className="row "> */}
              {/* <div className="d-flex flex-row py-0 w-100"> */}
                <div className="col-4">
                  <div className="primary-text font-m15r">
                    {LOTTO_GAME_TYPE_NAME[key as TLottoGameType]}
                  </div>
                </div>
                <div className="col-4"></div>
                <div className="col-4">
                  <CheckBox
                    selectAll={
                      selectAll && keys![LOTTO_BET_TYPES[key as TLottoGameType]]
                        ? false
                        : false
                    }
                    isSelect={keys![LOTTO_BET_TYPES[key as TLottoGameType]]}
                    id={`Select${key}-${index}`}
                    onClick={() =>
                      onCheckBoxSelect!(betLotteryPullDetailGroupList[key])
                    }
                    labelClassName="label-secondary-gold-bg"
                  />
                </div>
              {/* </div> */}
            {/* </div> */}
          {/* </div> */}
        </div>
        {betLotteryItem}
      </>);
    });
  };

  const disabledCursor = typeof onClick === "undefined" ? "" : "cursor";
  const historyBetItemList = renderOldFavoriteLottoList();
  

  return (
    <div className="container-lottoBet-item tertiary-bg-blue m1-b">
      <div
        className={`${containerClassName} lottoBet-item tertiary-bg-blue ${isLottoOldFavoriteConfirmModal && 'lottoBet-item-lotto-old-favorite-confirm-modal'}`}
      >
        <div className={`col history-bet-item-container tertiary-bg-blue`}>
          {/* <div className="row no-gutters">
            <div className="col">
              <div className="row"> */}
                <div className="col d-flex flex-row justify-content-between px-3">
                  <div className="d-flex flex-column text-center box-border justify-content-center align-items">
                    <h6 className="title-itemlist-responsive font-m15r">
                      {constants.numbers}
                    </h6>
                    <h6 className="number-responsive font-m15r m1-t">{totalNumbers}</h6>
                  </div>
                  <div className="d-flex flex-column text-center box-border justify-content-center align-items">
                    <h6 className="title-itemlist-responsive font-m15r">
                      {constants.fund}
                    </h6>
                    <h6 className="number-responsive font-m15r m1-t">
                      {number.addComma(totalValue)}
                    </h6>
                  </div>
                  <div className="d-flex flex-column text-center box-border line-border justify-content-center">
                    <h6 className="title-itemlist-responsive font-m15r">
                      {constants.lottoOn}
                    </h6>
                    <h6 className="number-responsive font-m15r m1-t">{`${time}`}</h6>
                  </div>
                  <div
                    className={`d-flex align-items-center justify-content-center`}
                  >
                    <div className={`primary-blue-bg active-button ${disabledCursor} box-border my-2 px-3 py-1`} onClick={onClick}>
                      <h6 className={`active-bet font-m15r secondary-text-gold`}>{constants.active}</h6>
                    </div>
                  </div>{" "}
                </div>
              {/* </div>
            </div>
          </div> */}
        </div>
      </div>
      {!isEmpty(expandedItem) && (
        <div
          className={`${containerClassName} lottoBet-item tertiary-bg-blue p-lg-2 p1`}
        >
          <div
            className={`col d-flex flex-row history-bet-item-container tertiary-bg-blue px-0`}
          >
            <div className="w-100 d-flex flex-row align-items-center m1-b">
              <h4 className="col flex primary-text font-m20r">
                {constants.detail}
              </h4>
              <div className="col d-flex flex-row align-items-center justify-content-end">
                <h4 className="m1-r primary-text font-m15r mr-2">
                  {constants.selectAll}
                </h4>
                <div>
                  <CheckBox
                    id="selectAll"
                    selectAll={selectAll}
                    onClick={() => onSelectAll!()}
                    labelClassName="label-secondary-gold-bg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col">{historyBetItemList}</div>
        </div>
      )}
    </div>
  );
};

LottoBetItem.defaultProps = defaultProps;

export default LottoBetItem;
