import React, { Component } from 'react'
import { Animate, HashMap } from 'react-move'

declare interface IAnimatedProgressProviderProps {
  valueStart: number
  valueEnd: number
  duration: number
  easingFunction: any
  repeat: boolean
  children(childrenProps: HashMap):any
}

const defaultProps: IAnimatedProgressProviderProps = {
  valueStart: 0,
  valueEnd: 0,
  duration: 0,
  easingFunction: null,
  repeat: false,
  children() { return <></> },
}


class AnimatedProgressProvider extends Component<IAnimatedProgressProviderProps, IAnimatedProgressProvider> {

  static defaultProps = defaultProps


  state: IAnimatedProgressProvider = {
    isAnimated: false,
    interval: 0,
  };

  componentDidMount() {
    if (this.props.repeat) {
      // eslint-disable-next-line
      this.state.interval = window.setInterval(() => {
        this.setState({
          isAnimated: !this.state.isAnimated,
        });
      }, this.props.duration * 1000);
    } else {
      this.setState({
        isAnimated: !this.state.isAnimated,
      });
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.state.interval);
  }

  render() {
    return (
      <Animate
        start={() => ({
          value: this.props.valueStart,
        })}
        update={() => ({
          value: [
            this.state.isAnimated ? this.props.valueEnd : this.props.valueStart,
          ],
          timing: {
            duration: this.props.duration * 1000,
            ease: this.props.easingFunction,
          },
        })}
      >
        {(value) => this.props.children(value)}
      </Animate>
    );
  }
}

export default AnimatedProgressProvider;
