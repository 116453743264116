import { get } from 'lodash'
import prject from 'constants/project'
import { initialState as initialWithdrawalState } from './withdrawal/constants'
import { initialState as initialClickState } from './click/constants'

const withdrawal = (state: RootReducers): ReducerState<IAffilateWithdrawal> =>
    get(state, `${prject.name}.affilate.withdrawal`, initialWithdrawalState)

const click = (state: RootReducers): ReducerState<IAffilateClick> =>
    get(state, `${prject.name}.affilate.click`, initialClickState)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    withdrawal,
    click,
}