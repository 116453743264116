const initialValue: IRegister = {
  phoneNumber: '',
  otp: '',
  username: '',
  password: '',
  confirmPassword: '',
  affilateRef: '',
  bankType: '',
  bankNumber: '',
  ownerName: '',
}

export default initialValue