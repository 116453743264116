import React, { Component, ComponentClass, createRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoaderSpinner from "assets/images/icon/LoaderSpiner.png"
import { get, noop } from 'lodash'
import './loadMore.style.scss'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILoadMoreProps = {
  isLoad:false,
  handleClickLoadMore:()=>noop()
}

const LoadMore = class extends Component< ILoadMoreProps & any & DefaultProps > {

  static defaultProps = defaultProps

  listInnerRef = createRef();

  state: ILoadMoreState = {
    isReLoader: false,
    isShowLoadMore: false,
    pointLastScroll: 0,
  }

  componentDidUpdate(prevProps: Readonly<ILoadMoreProps & any & Readonly<ILoadMoreProps>>): void {
    if (this.props.dataLength!==prevProps.dataLength || (prevProps.hasMore!==this.props.hasMore && !this.props.hasMore)) {
      this.setState({isShowLoadMore:false})
    }
  }
  
  renderLoader=()=>{
    return <div id="click-me" className={`p2-y px-3 clickMe-container ${(this.state.isShowLoadMore && this.props.dataLength!==0) && "show" }`} >
      { this.props.isLoad ? 
      <div className='click-me loading'>
        <img alt={'loader-spinner'} src={LoaderSpinner} className="loader-spinner" />
      </div> :
      ((this.state.isShowLoadMore && this.props.dataLength!==0) ? <div className='click-me text-click'>
        <label onClick={()=>{ this.props.handleClickLoadMore() }}>ดูข้อมูลเพิ่ม</label>
      </div> : <></>)
      }
    </div>
  }
  render() {
    return (
      <div className={`loadmore-component`}>
        <InfiniteScroll
        {...this.props}
        ref={this.listInnerRef}
        dataLength={this.state.isReLoader?999999: this.props.dataLength}
        onScroll={()=>{
          const scroll = this.listInnerRef.current
          const lastScrollTop = get(scroll,'lastScrollTop',0)
          const getLoader = get(document.getElementById('loader'),"clientHeight",0);

          // loader แสดง getLoader===1
          if ( getLoader > 0 ) {
            this.setState({isShowLoadMore:true, pointLastScroll:lastScrollTop},()=>{
              this.setState({isReLoader:true},()=>{setTimeout(()=>{this.setState({isReLoader:false})},0)})
            })
          }
          
          if (this.state.pointLastScroll <= lastScrollTop && getLoader > 0) {
            this.setState({isShowLoadMore:true})
          }else{
            this.setState({isShowLoadMore:false})
          }

          if (this.state.pointLastScroll <= lastScrollTop) {
            this.setState({pointLastScroll:lastScrollTop})
          }
        }}
        loader={(<div id="loader" style={{width:"0px",height:"1px"}}></div>)}
        >
          {this.props.children}{this.renderLoader()}
        </InfiniteScroll>
      </div>
    )
  }
} as ComponentClass<ILoadMoreProps & any>

export default LoadMore