import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { fetchBanNumber } from './services'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'

const getNumberBanListEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.getNumberBanAction)),
    exhaustMap(data => {
      const { slugname } = data.payload
      const getGame = fetchBanNumber(slugname)
      return from(getGame)
        .pipe(
          map(actions.getNumberBanSuccessAction),
          catchError(error => of(actions.getNumberBanFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getNumberBanCancelAction))))
        )
    }
    )
  )
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  getNumberBanListEpic,
]
