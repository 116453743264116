import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import gameAction from 'reduxs/game/actions'
import loaderAction from 'reduxs/loader/actions'
import GameContainer from './Games.container'
import gameSelector from 'reduxs/game/selectors'
import displayConfigSelector from 'reduxs/config/selectors'

const mapStateToProps = (state: RootReducers): IGameProps => {
  const gameListSelected = gameSelector.gameList(state)
  const connectedGameSelected = gameSelector.gameConnect(state)
  const displayConfig = displayConfigSelector.displayConfig(state)

  return {
    getGameListIsFetching: gameListSelected.isFetching!,
    getGameListError: gameListSelected.error!,
    getGameListCode: gameListSelected.code!,
    gameList: gameListSelected.data!,
    connectedGameIsFetching: connectedGameSelected.isFetching!,
    connectedGameError: connectedGameSelected.error!,
    connectedGameCode: connectedGameSelected.code!,
    connectedGame: connectedGameSelected.data!,
    displayConfig: displayConfig.data,
  }
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IGameActionProps => bindActionCreators({
  loader: loaderAction.loadingAction,
  getGameList: gameAction.getGameListAction,
  clearGameList: gameAction.clearGameListAction,
  connectGame: gameAction.connectGameAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(GameContainer)