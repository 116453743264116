import summaryActions from './summary/actions'
import memberActions from './member/actions'
import withdrawalActions from './withdrawal/actions'
import clickActions from './click/actions'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...summaryActions,
  ...memberActions,
  ...withdrawalActions,
  ...clickActions,
}