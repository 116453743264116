import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import userAction from 'reduxs/user/actions'
import creditAction from 'reduxs/credit/actions'
import CreditInfoContainer from './CreditInfo.container'
import creditInfoSelector from 'reduxs/credit/selector'

const mapStateToProps = (state: RootReducers): ICreditInfoProps => {
  const creditInfo = creditInfoSelector.creditInfoList(state)
  const creditMeDatail = creditInfoSelector.creditMeDetail(state)
  return ({
    wallet: state.ludens.user.wallet,
    getCreditInfoListCode: creditInfo.code!,
    getCreditInfoListError: creditInfo.error!,
    getCreditInfoListIsFetching: creditInfo.isFetching!,
    creditInfo: creditInfo.data!,
    creditInfoPagination: creditInfo.pagination!,
    postCreditMeDetailCode: creditMeDatail.code!,
    postCreditMeDetailError: creditMeDatail.error!,
    postCreditMeDetailIsFetching: creditMeDatail.isFetching!,
    creditMetail: creditMeDatail.data!,
    storeCreditMetail: creditMeDatail.storeData!,
    getMeUser: state.ludens.user.me.data!,
})
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ICreditInfoActionProps => bindActionCreators({
  loader: loaderAction.loadingAction,
  getUser: userAction.getMeAction,
  meDetailRequest: creditAction.postCreditMeDeTailRequestAction,
  getCreditInfoList: creditAction.getCreditInfoListAction,
  clearCreditInfoList: creditAction.clearCreditInfoList,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(CreditInfoContainer)