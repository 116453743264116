import React, { FC } from 'react'
import { noop } from 'lodash'
import { ResponsiveIcon, Button } from 'components'
import InfoIcon from 'assets/images/modal/info.png'
import { Modal } from '../../'
import './info.style.scss'
import colors from 'constants/colors'
const constants = {
  title: 'ข่าวด่วน',
  description: 'กรุณารอการตรวจสอบสักครู่',
  actionText: 'เข้าใจแล้ว',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IInfoModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() { noop() },
}

const InfoModal: FC<IInfoModal & DefaultProps> = (props) => {

  return (
    <div className="info-modal-container border-rounded">
      <ResponsiveIcon
        alt="info-icon"
        className="info-checked-icon"
        icon={InfoIcon}
      />
      <p className="body-1 title">{props.title}</p>
      {/* <h2 className="secondary-green-text p1-t">{props.title}</h2> */}
      <p className="body-1">{props.description}</p>
      <div className="m3-y">
        <Button
          id="info-ok-button"
          backgroundColor={colors.TERTIARY_GREEN}
          backgroundHoverColor={colors.TERTIARY_GREEN_HOVER}
          type="rectangle"
          onClick={props.action}
          text={props.actionText!}
        />
      </div>
    </div>
  )
}

InfoModal.defaultProps = defaultProps

const successModal = {
  show: (props: IInfoModal, size: string = 'sm') => {
    return Modal.event('MODAL_OVER', {
      state: 'show',
      extraProps: { size: size },
      component: (<InfoModal {...props} />),
    })
  },
  hide: () => Modal.event('MODAL_OVER', { state: 'hide' }),
}

export default successModal