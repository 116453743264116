import React, { FC } from 'react'
import { map } from 'lodash'
import { LottoResultCard } from 'components'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILottoProps = {
  data: [],
}

const constants = {
  yeege: 'หวยยี่กี',
  share: 'หวยหุ้น',
}

const LottoList: FC<ILottoProps & DefaultProps> = (props) => {

  const globalLottos = props.data.filter((lotto) => (!lotto.code.includes('YEGEE') && !lotto.code.includes('SHARE')))
  const shareLottos = props.data.filter((lotto) => lotto.code.includes('SHARE'))
  const yeegeLottos = props.data.filter((lotto) => lotto.code.includes('YEGEE'))

  const renderLottoComponent = (lottos: ILotto[]) => map(lottos, (lotto, index) => (
    <div key={`lotto-card-${lotto.code}-${index}`} className="col-12 col-md-6 col-lg-4 m3-b">
      <LottoResultCard lotto={lotto} />
    </div>
  ))

  const globalLotto = () => renderLottoComponent(globalLottos)
  const shareLotto = () => renderLottoComponent(shareLottos)
  const yeegeLotto = () => renderLottoComponent(yeegeLottos)

  return (
    <div className="p4-t">
      <div className="row">
        {globalLotto()}
      </div>
      {
        shareLottos.length > 0
          ? (
            <>
              <div className="row m4-t">
                <div className="col">
                  <h2>{constants.share}</h2>
                </div>
              </div>
              <div className="row m3-t">
                {shareLotto()}
              </div>
            </>
          )
          : <></>
      }
      {
        yeegeLottos.length > 0
          ? (
            <>
              <div className="row m4-t">
                <div className="col">
                  <h2>{constants.yeege}</h2>
                </div>
              </div>
              <div className="row m3-t">
                {yeegeLotto()}
              </div>
            </>
          )
          : <></>
      }

    </div >
  )
}

LottoList.defaultProps = defaultProps

export default LottoList