import { createAction } from 'typesafe-actions'
import {
  GET_OLD_FAVORITE_LOTTO_LIST_REQUEST,
  GET_OLD_FAVORITE_LOTTO_LIST_SUCCESS,
  GET_OLD_FAVORITE_LOTTO_LIST_CANCEL,
  GET_OLD_FAVORITE_LOTTO_LIST_FAILURE,
  CLEAR_OLD_FAVORITE_LOTTO_LIST,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getOldFavoriteLottoListAction = createAction(
  GET_OLD_FAVORITE_LOTTO_LIST_REQUEST,
  resolve => (query: APIPaginationQuery, isFirst?: boolean) => resolve({ ...query, isFirst }))

const getOldFavoriteLottoListSuccessAction = createAction(
  GET_OLD_FAVORITE_LOTTO_LIST_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<APIPagination<IOldFavoriteSet>>>,isFirst:boolean) =>
  resolve({...data, isFirst}))

const getOldFavoriteLottoListFailureAction = createAction(
  GET_OLD_FAVORITE_LOTTO_LIST_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getOldFavoriteLottoListCancelAction = createAction(GET_OLD_FAVORITE_LOTTO_LIST_CANCEL)

const clearOldFavoriteLottoListAction = createAction(CLEAR_OLD_FAVORITE_LOTTO_LIST)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getOldFavoriteLottoListAction,
  getOldFavoriteLottoListSuccessAction,
  getOldFavoriteLottoListFailureAction,
  getOldFavoriteLottoListCancelAction,
  clearOldFavoriteLottoListAction,
}
