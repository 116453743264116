import { get } from 'lodash'
import prject from 'constants/project'
import { initialState as gameListIntialState } from './list/constants'
import { initialState as gameConnectInitialState } from './connect/constants'

const gameList = (state: RootReducers): ReducerState<IGame[]> =>
    get(state, `${prject.name}.game.list`, gameListIntialState)

const gameConnect = (state: RootReducers): ReducerState<string> =>
    get(state, `${prject.name}.game.connect`, gameConnectInitialState)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    gameList,
    gameConnect,
}