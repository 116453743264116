import { createAction } from 'typesafe-actions'
import {
  POST_CREDIT_ME_DETAIL_REQUEST,
  POST_CREDIT_ME_DETAIL_SUCCESS,
  POST_CREDIT_ME_DETAIL_FAILURE,
  POST_CREDIT_ME_DETAIL_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const postCreditMeDeTailRequestAction = createAction(
  POST_CREDIT_ME_DETAIL_REQUEST,
  resolve => (data: ICreditMeDetailReq) => resolve(data))

const postCreditMeDetailRequestSuccessAction = createAction(
  POST_CREDIT_ME_DETAIL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<any>>) => resolve(data))

const postCreditMeDetailFailureAction = createAction(
  POST_CREDIT_ME_DETAIL_FAILURE,
  resolve => (error: AxiosError<APIResponse<any>>) => resolve(error))

const postCreditMeDetailCancelAction = createAction(POST_CREDIT_ME_DETAIL_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postCreditMeDeTailRequestAction,
  postCreditMeDetailRequestSuccessAction,
  postCreditMeDetailFailureAction,
  postCreditMeDetailCancelAction,
}