import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { fetchAwardListPmpg } from './services'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'



const getAwardListPmpgEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.getAwardListPmpgAction)),
    exhaustMap(_ =>
      from(fetchAwardListPmpg())
        .pipe(
          map(actions.getAwardListPmpgSuccessAction),
          catchError(error => of(actions.getAwardListPmpgFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getAwardListPmpgCancelAction))))
        ),
    )
  )

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  getAwardListPmpgEpic,
]
