import project from 'constants/project'

export const CONNECT_NOTIFICATION_USER_COUNT_SOCKET = 'CONNECT_NOTIFICATION_USER_COUNT_SOCKET'
export const CONNECTED_NOTIFICATION_USER_COUNT_SOCKET = 'CONNECTED_NOTIFICATION_USER_COUNT_SOCKET'
export const CONNECT_NOTIFICATION_USER_COUNT_SOCKET_ERROR = 'CONNECT_NOTIFICATION_USER_COUNT_SOCKET_ERROR'
export const DISCONNECT_NOTIFICATION_USER_COUNT_SOCKET = 'DISCONNECT_NOTIFICATION_USER_COUNT_SOCKET'
export const DISCONNECTED_NOTIFICATION_USER_COUNT_SOCKET = 'DISCONNECTED_NOTIFICATION_USER_COUNT_SOCKET'
export const LOG_NOTIFICATION_USER_COUNT_SOCKET = 'LOG_NOTIFICATION_USER_COUNT_SOCKET'

export const initialState: ICoreSocketState = {
  connected: false,
}

export const endpoint = {
  connectNotificationUserCountSocket: `${project.environment[project.environmentName].notificationUserCountSocket}`,
}