import JOKER_PRESENTER from './gamecamp_joker@2x.png'
import PG_PRESENTER from './gamecamp_pg-slot@2x.png'
import DREAMTECH_PRESENTER from './gamecamp_dreamtech@draf.png'


const presenterSlotGame: { name: TGameCompany, image: string }[] = [
    { name: 'JOKER', image: JOKER_PRESENTER },
    { name: 'PG', image: PG_PRESENTER },
    { name: 'DREAMTECH', image: DREAMTECH_PRESENTER },
]

export default presenterSlotGame