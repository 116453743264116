import React, { FunctionComponent, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LogoIcon from "assets/images/logo/logothailandbet02.png";
import CreditIcon from "assets/images/main/icons/credit.png";
import LottoIcon from "assets/images/main/icons/lottery.png";
import CasinoIcon from "assets/images/main/icons/casino-online.png";
import SlotIcon from "assets/images/main/icons/casinogame.png";
import LottoResultIcon from "assets/images/main/icons/lotto.png";
import FinanceIcon from "assets/images/main/icons/finance.png";
import BGIcon from "assets/images/main/BG.png";
import GoldIcon from "assets/images/pmpg/chest.svg";
import DownloadIcon from "assets/images/main/appdownload/download.png";
import BronzeIcon from "assets/images/pmpg/bronze.svg";
import SilverIcon from "assets/images/pmpg/diamond.svg";
import TinIcon from "assets/images/pmpg/tin.svg";
import Coin from "assets/images/pmpg/chinese-coin.svg";
import { number } from "utils";
import "./index.style.scss";
import routes from "constants/routes";
import project from "constants/project";
import { LudensContext } from "configs/context";
import { get, isEmpty, isNull } from "lodash";

import { AnnounceNewLottoModal, AnnounceLottoModal } from "components";

const mapIconCurrectBonus: { [key in IEraType]: string } = {
  GOLD: GoldIcon,
  SILVER: SilverIcon,
  BRONZE: BronzeIcon,
  TIN: TinIcon,
  "": Coin,
};

interface IIndexProps {
  user: IUser;
  wallet: IWallet;
  webConfig: IWebConfig;
  highlightEra?: IPmpgEra;
  clickRef: IClickRef;
}

interface IIndexActionProps {
  getUser(): void;
  getSummaryPmpg(): void;
  clearSummaryPmpg(): void;
  loader(state: boolean): void;
}

const PMPGComponent: FunctionComponent<{ era?: IPmpgEra }> = ({ era }) => {
  return (
    <div className="d-flex flex-row align-items-center pmpg-wrapper">
      <img
        className="icon-bonus pr-1"
        src={mapIconCurrectBonus[era!.highlightEra!.type!]}
        alt="icon-bonus"
      />
      <img
        className="icon-bonus pr-1"
        src={mapIconCurrectBonus[era!.highlightEra!.type!]}
        alt="icon-bonus"
      />
      <div className="d-inline-block text-truncate jackpot-title">
        &nbsp; แจ็คพอตแตกสูงสุด
        <span className="body-1 pl-1">
          {number.castToMoney(get(era, "highlightEra.total", 0))}
        </span>
      </div>
      <img
        className="icon-bonus pl-1"
        src={mapIconCurrectBonus[era!.highlightEra!.type!]}
        alt="icon-bonus"
      />
      <img
        className="icon-bonus pl-1"
        src={mapIconCurrectBonus[era!.highlightEra!.type!]}
        alt="icon-bonus"
      />
    </div>
  );
};

const Index: FunctionComponent<IIndexProps & IIndexActionProps> = ({
  user,
  wallet,
  webConfig,
  highlightEra,
  clickRef,
  loader,
  getUser,
  getSummaryPmpg,
  clearSummaryPmpg,
}) => {
  const context = useContext(LudensContext);
  const history = useHistory();
  const onNavigateByClickRef = () => {
    history.push(clickRef.path!);
  };

  useEffect(() => {
    if (isNull(localStorage.getItem("isShowNewLotto"))) {
      AnnounceNewLottoModal.show({
        action: () => {
          AnnounceNewLottoModal.hide();
          localStorage.setItem("isShowNewLotto", JSON.stringify(true));
        },
      });
    }
    if (history.action === "REPLACE") {
      AnnounceLottoModal.show({
        action: () => {
          AnnounceLottoModal.hide();
          if (isNull(localStorage.getItem("isShowNewLotto"))) {
            AnnounceNewLottoModal.show({
              action: () => {
                AnnounceNewLottoModal.hide();
                localStorage.setItem("isShowNewLotto", JSON.stringify(true));
              },
            });
          }
        },
        title: "ประกาศ",
        description:
          "เรียนท่านสมาชิกทุกท่าน ระบบได้ทำการเปลี่ยนการปรับอัตราจ่ายหวยใหม่(ทุกหวย) โดยจะถูกแบ่งออกเป็น 3 ระดับเท่านั้น ดังนี้",
        actionText: "เข้าใจ",
        actionCancle: AnnounceLottoModal.hide,
        textHowTo: "อัตราจ่าย หวยใหม่",
      });
    }
    context.navbar.setShowNavbar(false);
    getUser();
    getSummaryPmpg();
    loader(false);
    if (!isEmpty(clickRef.path) || clickRef.path !== undefined) {
      onNavigateByClickRef();
    }
    return () => {
      context.navbar.setShowNavbar(true);
      clearSummaryPmpg();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="index-wrapper">
      <div className="container">
        <img src={BGIcon} className="bg-images-fixed" alt="bg-images-fixed" />
        {/* <div className='row'>
                    <div className='col-12'>
                        <div className='d-flex flex-row justify-content-end' onClick={() => context.drawer.setDrawerOpen(!context.drawer.isDrawerOpen)}>
                            <FontAwesomeIcon
                                icon={faBars}
                                className="burger-container" />
                        </div>
                    </div>
                </div> */}
        <div className="row mt-2">
          <div className="col-12">
            <PMPGComponent era={highlightEra} />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-12">
            <div className="termofuse-link">
              <span
                onClick={() => history.push(routes.qaType.exactPath("privacy"))}
              >
                ข้อตกลงการใช้งาน
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12">
            <div className="wallet-info-wrapper">
              <div className="d-flex flex-row">
                <div className="detail-container">
                  <div className="username-label">
                    {get(user, "username", "")}
                  </div>
                  <div className="mt-2">
                    <div className="balance-label">เงินคงเหลือ</div>
                    <h3 className="money-amount">
                      {number.castToMoney(Number(get(wallet, "money", 0)))}
                    </h3>
                  </div>
                </div>
                <div className="icon-wrapper">
                  <img src={LogoIcon} className="logo-icon" alt="tlb-logo" />
                  {/* <button className='transaction-menu' onClick={() => history.push(routes.creditInfoReport.path)}>ข้อมูลการเงิน</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-6">
            <button
              className="transaction-button"
              onClick={() => history.push(routes.deposit.path)}
            >
              ฝากเงิน
            </button>
          </div>
          <div className="col-6">
            <button
              className="transaction-button"
              onClick={() => history.push(routes.withdraw.path)}
            >
              ถอนเงิน
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <div
              className="menu-highlight-container"
              onClick={() => history.push(routes.lotto.path)}
            >
              <img
                src={LottoIcon}
                alt="menu-icon-item"
                className="menu-icon-item"
              />
              แทงหวย
            </div>
          </div>
          <div className="col-12">
            <div
              className="menu-highlight-container menu-credit-info"
              onClick={() => history.push(routes.creditInfoReport.path)}
            >
              <img
                src={CreditIcon}
                alt="menu-icon-item"
                className="menu-icon-item"
              />
              ข้อมูลเครดิต
            </div>
          </div>
          <div className="col-3 pr-2">
            <div
              className="menu-container"
              onClick={() => history.push(routes.casino.path)}
            >
              <img
                src={CasinoIcon}
                alt="menu-icon-item"
                className="menu-icon-item"
              />
              คาสิโน
            </div>
          </div>
          <div className="col-3 px-2">
            <div
              className="menu-container"
              onClick={() => history.push(routes.slot.path)}
            >
              <img
                src={SlotIcon}
                alt="menu-icon-item"
                className="menu-icon-item"
              />
              สล็อต
            </div>
          </div>
          <div className="col-3 px-2">
            <div
              className="menu-container"
              onClick={() => history.push(routes.lottoResult.path)}
            >
              <img
                src={LottoResultIcon}
                alt="menu-icon-item"
                className="menu-icon-item"
              />
              ผลหวย
            </div>
          </div>
          <div className="col-3 pl-2">
            <div
              className="menu-container"
              onClick={() => history.push(routes.lottoFavoriteManagement.path)}
            >
              <img
                src={FinanceIcon}
                alt="menu-icon-item"
                className="menu-icon-item"
              />
              สร้างเลขชุด
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <div
              className="menu-sub-hightlight"
              onClick={() => history.push(routes.transaction.path)}
            >
              รายการฝาก-ถอน
            </div>
          </div>
          <div className="col-6">
            <div
              className="menu-sub-hightlight d-flex flex-row"
              onClick={() => history.push(routes.affiliate.path)}
            >
              แนะนำเพื่อน<span className="parui-text">พารวย!</span>
            </div>
          </div>
          <div className="col-12">
            <div
              className="menu-sub-hightlight line"
              onClick={() =>
                window.open(
                  `https://line.me/R/ti/p/${get(webConfig, "contactLine", "")}`,
                  "_blank"
                )
              }
            >
              ติดต่อ LINE @thailandbet
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className="menu-sub-hightlight download-app d-flex flex-row"
              onClick={() => {
                const newWindow = window.open(
                  project.environmentName !== "production"
                    ? "/downloadst.html"
                    : "/download.html",
                  "_blank",
                  "noopener,noreferrer"
                );
                if (newWindow) newWindow.opener = null;
                return;
              }}
            >
              <img
                src={DownloadIcon}
                className="download-icon mr-2"
                alt="download-icon"
              />
              ดาวน์โหลดแอป
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
