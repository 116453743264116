import React, { Component, ChangeEvent } from 'react'
import {
  Button,
  InputNumber,
} from 'components'
import { get, groupBy, keys, noop, reduce, sum, filter, split, map, isEmpty } from 'lodash'
import { number, transformer } from 'utils'
import { LOTTO_GAME_TYPE_NAME } from 'constants/variables'
import './summary.style.scss'

import 'simplebar/dist/simplebar.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight ,faTimes } from '@fortawesome/free-solid-svg-icons'
import Scrollbar from 'react-scrollbars-custom'

const constants = {
  lottoListTitle: 'รายการแทง',
  price: 'ราคาแทง',
  historyMakedList: 'ดึงโพย',
  makeLotto: 'แทงหวย',
  perBath: 'บาทละ',
  win: 'เมื่อชนะ ได้ ',
  defaultValue: 'เดิมพันราคาเท่ากัน',
  totalToPay: 'เดิมพันทั้งหมด',
  totalBenefit: 'เมื่อชนะทั้งหมด',
  clean: 'ล้าง',
  reMarkBet: (remark: string) => `เดิมพันสูงสุดครั้งละ ฿${remark} ต่อเลข`,
  bth:"บาท"
}

const defaultProps: ILottoPaymentSummaryProps = {
  betRates: [],
  lottoList: [],
  lottoSlug: undefined,
  onClickBet() { noop() },
  onBetListChanged() { noop() },
  onNavigateToFavorite() { noop() },
  onChangeOpenListLotto() { noop() },
  betLimit: {betLimit: 0},
  openListLotto:false
}

const DEFAULT_GAME_VALUE = '1'
const LAO_SULT_FIXED_GAME_VALUE = '120'
const windowHeight = window.innerHeight
class SummaryLottoComponent extends Component<ILottoPaymentSummaryProps, ILottoPaymentSummaryState> {

  static defaultProps = defaultProps

  state: ILottoPaymentSummaryState = {
    betList: [],
    defaultValue: (this.props.lottoSlug === 'LOTTER_LAO_SUITE') ? LAO_SULT_FIXED_GAME_VALUE : DEFAULT_GAME_VALUE,
    listExpandCollapse:[],
    openListLotto:this.props.openListLotto
  }

  componentDidMount() {
    this.setState({ betList: this.props.lottoList })
  }

  componentDidUpdate(prevProps: ILottoPaymentSummaryProps) {

    if (this.props.openListLotto !== prevProps.openListLotto) {
      setTimeout(() => {
        this.setState({openListLotto:this.props.openListLotto})
      }, 0);
    }
    
    if (prevProps.lottoList !== this.props.lottoList || this.props.lottoList !== this.state.betList) {
      this.setState({ betList: this.props.lottoList },()=>{
        const groupingLottoListObject: { [name in TLottoGameType]?: (ILottoNumber & { seq?: number })[] }
          = groupBy<(ILottoNumber & { seq?: number })>( this.state.betList.map((bet, betIndex) => ({ ...bet, seq: betIndex })),'type')
        let listExpand:IListExpand[] = Object.keys(groupingLottoListObject).map((item)=>{
          let find = this.state.listExpandCollapse.find((itemExpan)=>itemExpan.type===item)
          return{type:item,isExpand:get(find,"isExpand",false)}
        })
        this.setState({listExpandCollapse:listExpand})
      })
    }

  }

  handleOnClickBet = () => {
    this.props.onClickBet(this.state.betList)
  }

  handleOnRemove = (seq: number) => {
    const newBetList: ILottoNumber[] = filter<ILottoNumber>(this.state.betList, (_, index) => index !== seq)
    this.setState({ betList: newBetList }, () => {
      this.props.onBetListChanged!(newBetList)
    })
  }

  handleOnRemoveSet = (seq: TLottoGameType) => {
    const newBetList: ILottoNumber[] = filter<ILottoNumber>(this.state.betList, (item, index) => item.type !== seq)
    this.setState({ betList: newBetList }, () => {
      this.props.onBetListChanged!(newBetList)
    })
  }

  handleOnChangeValue = (value: string, seq: number) => {
    const betArray: ILottoNumber[] = filter<ILottoNumber>(this.state.betList, (_, index) => index === seq)
    const betObject: ILottoNumber = get(betArray, '0', {})
    const newObject: ILottoNumber = { ...betObject, value }
    const newBetList: ILottoNumber[] = this.state.betList
    newBetList[seq] = newObject
    this.setState({ betList: newBetList })
  }

  handleOnBlurValue = (value: string, seq: number) => {
    const betArray: ILottoNumber[] = filter<ILottoNumber>(this.state.betList, (_, index) => index === seq)
    const betObject: ILottoNumber = get(betArray, '0', {})
    const newBetList: ILottoNumber[] = this.state.betList
    newBetList[seq] = betObject
    const finalBetNumberList = newBetList.filter(bet => !isEmpty(bet.value) && Number(bet.value) > 0)
    this.setState({ betList: finalBetNumberList }, () => {
      this.props.onBetListChanged!(finalBetNumberList)
    })
  }

  handleOnDefaultValueBlur = () => {
    const newBetList = map(this.props.lottoList, item => ({ ...item, value: this.state.defaultValue }))
    const finalBetNumberList = newBetList.filter(bet => !isEmpty(bet.value) && Number(bet.value) > 0)
    this.setState({ betList: finalBetNumberList }, () => {
      this.props.onBetListChanged!(finalBetNumberList)
    })
  }

  handleOnGotoFavoriteSelect = () => {
    this.props.onNavigateToFavorite!()
  }

  handleOnCleanLit = () => {
    this.setState({ betList: [] }, () => {
      this.props.onBetListChanged!([])
    })
  }

  calculateBenefitValue = (betValueString: string = '0', rate: string = '0') => {
    if (this.props.lottoSlug === 'LOTTER_LAO_SUITE') {
      return number.castToMoneyWithoutPrefix(Number(rate))
    }
    const betValue = Number(number.castToInteger(betValueString)) || 0
    const calculatedBenefit = Number(rate) * betValue
    return number.castToMoneyWithoutPrefix(calculatedBenefit)
  }

  calculateTotalValue = () => {
    const totally: number = reduce(this.state.betList, (prev, curr) => {
      const betValue = Number(number.castToInteger(curr.value || '0'))
      return sum([prev, betValue])
    }, 0)
    return number.castToMoneyWithoutPrefix(totally)
  }

  calculateTotalBenefit = () => {
    const totally: number = reduce(this.state.betList, (prev, curr) => {
      const splitedType = split(curr.slug!, '_')
      const lotterType = split(curr.slug!, '_', (get(splitedType, 'length', 0) - 1)).reduce((prevType, currType) => `${prevType}_${currType}`)
      const betType = `${lotterType}_${curr.type}`
      const betRateValue = (isEmpty(curr.rate) || Number(curr.rate) <= 0)
        ? get(this.props.betRates.filter((rate) => rate.type === betType), '0.rate', '0')
        : curr.rate
      const betValue = number.castToInteger(curr.value || '0')
      const total = (this.props.lottoSlug === 'LOTTER_LAO_SUITE')
        ? Number(betRateValue)
        : Number(betRateValue) * Number(number.castToInteger(betValue))
      return sum([prev, total])
    }, 0)
    return number.castToMoneyWithoutPrefix(totally)
  }

  renderLottoList = () => {
    const groupingLottoListObject: { [name in TLottoGameType]?: (ILottoNumber & { seq?: number })[] }
      = groupBy<(ILottoNumber & { seq?: number })>(
        this.state.betList.map((bet, betIndex) => ({ ...bet, seq: betIndex })),
        'type')
    const GroupingLottoListComponent = keys(groupingLottoListObject).map((lottos, lottosIndex) => {
      const LottoListComponent = groupingLottoListObject[lottos as TLottoGameType]?.map((lotto, lottoIndex) => {
        const splitedType = split(lotto.slug!, '_')
        const lotterType = split(lotto.slug!, '_', (get(splitedType, 'length', 0) - 1)).reduce((prev, curr) => `${prev}_${curr}`)
        const betType = `${lotterType}_${lotto.type}`
        const betRateValue = (isEmpty(lotto.rate) || Number(lotto.rate) <= 0)
          ? get(this.props.betRates.filter((rate) => rate.type === betType), '0.rate', '0')
          : lotto.rate
        return (
          <div className="row lotto-row" key={`lotto-${lotto.type}-${lottoIndex}`}>
            <div className="col-12 lotto-wrapper">
              <div className='font-m20r primary-yellow-text min-number'>{lotto.number}</div>
              <InputNumber
                textAlign="right"
                hiddenErrorBlock
                thousandSeparator
                allowNegative={false}
                decimalScale={0}
                isAllowed={(values)=>(
                  (values.formattedValue === '' ||
                  values.floatValue! <=this.props.betLimit.betLimit) && values.value[0]!=='0')
                }
                name={`values-${lotto.seq}`}
                disabled={this.props.lottoSlug === 'LOTTER_LAO_SUITE'}
                onBlur={(event: ChangeEvent<HTMLInputElement>) =>
                  this.handleOnBlurValue(event.target.value, lotto.seq!)}
                onValueChange={({ value }) =>
                  this.handleOnChangeValue(value, lotto.seq!)}
                value={lotto.value}
                borderStyle={"input-bet flex-grow-1 mx-3"}
              />
              { this.props.lottoSlug === 'LOTTER_LAO_SUITE'
                  ? <></>
                  : (
                    <div className="pr-2">
                      <h6 className="font-m15r d-flex justify-content-end">
                        <span className='font-m15r secondary-text-gold text-right mr-1'>{constants.perBath}</span>
                        <span className='font-m15r primary-yellow-text fig-width d-flex justify-content-between'>
                          <div></div><span>x</span>
                          <span>{`${number.castToMoneyWithoutPrefix(Number(betRateValue))}`}</span>
                        </span>
                      </h6>
                      <h6 className="font-m15r d-flex justify-content-between">
                        <span className="font-m15r mr-1">{constants.win}</span>
                        <span className='font-m15r primary-yellow-text fig-width'>{this.calculateBenefitValue(lotto.value || '0', betRateValue || '0')}</span>
                      </h6>
                    </div>
                  )
              }
              <FontAwesomeIcon icon={faTimes}
                onClick={() => this.handleOnRemove(lotto.seq!)}
                className="font-m20r delete-lotto-button-icon"
              />
            </div>
          </div>
        )
      })
      let isExpandObject = this.state.listExpandCollapse.find(element => element.type === lottos)

      return (
        <div className="row list-type-bet" key={`lotto-type-${lottosIndex}`}>
          <div
            className='col-12 d-flex flex-row align-items-center my-2'
            onClick={()=>{
              let data = this.state.listExpandCollapse.find(element => element.type === lottos);
              let new_array = this.state.listExpandCollapse.map(element => element.type === lottos ? Object.assign({}, element, {isExpand : !data?.isExpand}) : element);
              this.setState({listExpandCollapse:new_array})
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} className={`mr-3 secondary-text-gold icon-arrow-right-item ${get(isExpandObject,"isExpand",false)&&"open"}`} />
            <div className='d-flex flex-row align-items-center'>
              <h4 className='font-m25r mt-0'>{LOTTO_GAME_TYPE_NAME[lottos as TLottoGameType]}</h4>
            </div>
              <h4 className={`font-m25r d-flex justify-content-end ml-auto`}>
                <span className='primary-yellow-text mr-1'>{`${get(LottoListComponent,"length",'')}`}</span>{` รายการ`}
              </h4>
            <FontAwesomeIcon icon={faTimes}
              onClick={(e)=>{
                e.stopPropagation(); 
                this.handleOnRemoveSet(lottos as TLottoGameType)
              }}
              className="font-m25r delete-lotto-button-icon-header"
            />
          </div>
          <div className={`col-12 expand-collapse ${get(isExpandObject,"isExpand",false)?'open':'close'}`}>
            {LottoListComponent}
          </div>
        </div>
      )
    })

    return ( <>{GroupingLottoListComponent}</> )
  }

  renderSummaryTotal = () => {
    return (
      <>
        <div className="row">
          <div className="col m-auto">
            <h5 className='font-m15r'>{constants.totalToPay}</h5>
          </div>
          <div className="col m-auto text-right">
            <h5 className='font-m15r'>{this.calculateTotalValue()}{` ${constants.bth}`}</h5>
          </div>
        </div>
        <div className="row">
          <div className="col m-auto">
            <h5 className='font-m15r'>{constants.totalBenefit}</h5>
          </div>
          <div className="col m-auto text-right">
            <h5 className='font-m15r'>{this.calculateTotalBenefit()}{` ${constants.bth}`}</h5>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <Button
              type="rectangle"
              size='small'
              id="maked-lotto-submit-button"
              onClick={this.handleOnClickBet}
              text=''
              disabled={this.state.betList.length <= 0}
            ><div className='font-m23r secondary-text-gold thin-text'>{constants.makeLotto}</div></Button>
          </div>
        </div>
      </>
    )
  }
  
  sizeDevice =()=> transformer.checkSizeDevice(windowHeight)

  render() {
    const LottoListComponent = this.renderLottoList
    const SummaryTotalComponent = this.renderSummaryTotal 

    var heightNavbar = get(document.getElementById('navbar-container'),"clientHeight",0);
    var heightTabbar = get(document.getElementById('tabbar-container'),"clientHeight",0);
    var heightJackpot = get(document.getElementById('badge-jackpot'),"clientHeight",0);
    var heightTop = heightNavbar+heightJackpot
    var tabbarNavbar = heightTabbar+heightTop
    var heighFullMain = windowHeight - (tabbarNavbar+10)
    var heightSetion1 = get(document.getElementById('setion-1'),"clientHeight",0);
    
    return (
      <>
        <div
          style={{
            maxHeight:`${heighFullMain-heightSetion1}px`,
            // minHeight:`100%`,
            height:this.state.openListLotto?`${heighFullMain-heightSetion1}px`:`${this.sizeDevice()==="xxs"?63 : this.sizeDevice()==="xs"?75 : this.sizeDevice()==="s"?85 : this.sizeDevice()==="m"?98:111}px`
          }}
          className={`d-flex flex-column summary-maked-list-container ${this.state.openListLotto&&'open'} ${this.props.lottoSlug} ${this.sizeDevice()}`}
        >
            <div className="d-flex justify-content-between clickOpen" onClick={()=>{this.props.onChangeOpenListLotto!(!this.state.openListLotto)}}>
              <h5 className='font-m20r'>{`รายการที่เลือกทั้งหมด ${this.props.lottoList.length} รายการ`}</h5>
              <FontAwesomeIcon icon={faChevronRight} className={`ml-1 secondary-text-gold icon-arrow-right ${this.state.openListLotto&&"open"}`} />
            </div>
            {/* <div className='font-m30r' style={{position:"absolute",top:"10"}}>
                {screenHeight}
            </div> */}
          
          {this.state.openListLotto&&<div className={`open-list-lotto ${this.state.openListLotto&&'open'}`}>
            <Scrollbar
                id="lotto-row"
                removeTracksWhenNotUsed 
                disableTracksWidthCompensation 
            >
              <LottoListComponent />
            </Scrollbar>
          </div>}

          <div className="mt-auto">
              <SummaryTotalComponent />
          </div>
        </div>
      </>
    )
  }
}

export default SummaryLottoComponent