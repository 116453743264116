import project from 'constants/project'

export const GET_COUNT_REQUEST = 'GET_COUNT_REQUEST'
export const GET_COUNT_SUCCESS = 'GET_COUNT_SUCCESS'
export const GET_COUNT_FAILURE = 'GET_COUNT_FAILURE'
export const GET_COUNT_CANCEL = 'GET_COUNT_CANCEL'

export const initialState: IUserCountState = {
    isFetching: false,
    code: 0,
    data: {userCount: 0},
    devMessage: '',
}

export const endpoint = {
    getCount: `${project.environment[project.environmentName].api}/user/count`,
}