import { FC, useRef, useEffect } from 'react';
import React from 'react';
import './datepicker.style.scss';
import DatePicker, {
  CalendarProps,
  DateObject,
  DatePickerProps,
} from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/colors/yellow.css';
import CalendarIcon from 'assets/images/icon/calendar.svg'
import { THAI_TH } from './thai_th'
import thai from './thai'
import { isEmpty } from 'lodash';
type DefaultProps = Readonly<typeof defaultProps>;

declare interface IDatePickerNew {
  errorMessage?: string;
  format?: string;
  error?: boolean;
  maxDate?: string | number | Date | DateObject | undefined;
  width?: string;
  flag?: boolean;
  hasErrorMessage?: boolean;
  icon?: boolean;
  titleDay?: string;
}

const defaultProps: IDatePickerNew = {
  errorMessage: '',
  format: 'DD MMM YYYY',
  hasErrorMessage: true,
  icon:false,
  titleDay:''
};

const IDatePicker: FC<
  IDatePickerNew & DefaultProps & CalendarProps & DatePickerProps
> = (props) => {
  const ref: any = useRef();
  const { format, error, maxDate, width, value, disabled, icon, titleDay } =
    props;

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (e.target.innerHTML.length !== undefined && e.target.innerText !== undefined) {
        if (ref.current) {
          if (ref.current.textContent.split('DateClose').length === 1 && e.target.innerHTML.length !== 0) {
            // tslint:disable-next-line:max-line-length
            if ((e.target.innerHTML.length <= 2 && !isNaN(Number(e.target.innerHTML))) || e.target.innerText.length <= 2) {

              if (!e.target.innerHTML.includes('arrow')) {
                ref.current.closeCalendar();
              }
            }
          }
        }

      }
    }
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  const propLocale = () => {
      return { locale: THAI_TH, calendar: thai }
  }

  return (<>
    {icon&&<img
      className={`icon-date default-filter`}
      alt={'date-icon'}
      src={CalendarIcon}
      onClick={()=>{
        ref.current.openCalendar()
      }}
    />}
    <div className="date-picker-container">
      {!isEmpty(titleDay)&&<h5 className="title-day subtitle-2">{titleDay}</h5>}
         {/* //@ts-ignore */}
      <DatePicker
        fixMainPosition={true}
        calendarPosition="bottom-center"
        ref={ref}
        style={{ width: `${width}%` }}
        {...props}
        className={`date-picker-input yellow`}
        inputClass={`rmdp-input ${error && 'error'} ${disabled && 'disabled'}`}
        format={format}
        maxDate={maxDate}
        value={value}
        // calendar={thai}
        {...propLocale()}
      />
    </div>
  </>);
};

IDatePicker.defaultProps = defaultProps;

export default IDatePicker;
