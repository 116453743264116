import { DECIMAL_DITGITS, COMMA } from 'constants/regex'
import { padStart } from 'lodash'

const castToInteger = (numberString: string = '') => {
  const result = numberString.replace(COMMA, '').replace(DECIMAL_DITGITS, '')
  return result
}

const padNumber = (value: string, ditgit: number) => {
  const padString = '0'
  return padStart(value, ditgit, padString)
}

const castToMoney = (value: number, spacing?: boolean) => {
  const locals = 'th-TH'
  const currency = 'THB'
  const money = new Intl.NumberFormat(locals, { style: 'currency', currency }).format(value || 0)
  if (spacing) {
    return money.replace(/^(\D+)/, '$1 ')
  }
  return money
}

const addComma = (value: number) => ` ${new Intl.NumberFormat('th-TH').format(value || 0)} `

const castToMoneyWithoutPrefix = (value: number) => {
  return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

const formatBankNumber = (value: string, type: string) => {
  if ((type === 'GSB' || type === 'BAAC') && value.length === 12) {
    return value.slice(0, 1) + '-' + value.slice(1, 4) + '-' + value.slice(4, 8) + '-' + value.slice(8, 12)
  } else if ((type !== 'GSB' && type !== 'BAAC') && value.length === 10) {
    return value.slice(0, 3) + '-' + value.slice(3, 9) + '-' + value.slice(9, 10)
  } else {
    return value
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  castToInteger,
  castToMoney,
  padNumber,
  castToMoneyWithoutPrefix,
  formatBankNumber,
  addComma
}