import React, { Component, createRef, RefObject } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { get, map, noop, sortBy } from 'lodash'
import { ResponsiveIcon, ALink, Banner } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { interactive } from 'utils'
import { vendorLists } from 'assets/images/e-casino'
import saGameBanner from 'assets/images/banner/banner-16.9-sa.png'
import W88_BANNER from  'assets/images/banner/banner-16.9-w88.png'
import allbetBanner from 'assets/images/banner/banner-16.9-allbet.png'
import aeSexyBanner from 'assets/images/banner/banner-16.9-ae.png'
import dreamBanner from 'assets/images/banner/banner-16.9-dg.png'
import wmBanner from 'assets/images/banner/banner-16.9-wm.png'
import colors from 'constants/colors'
import routes from 'constants/routes'
import 'simplebar/dist/simplebar.min.css'
import './casinoOnline.style.scss'
import { CustomModalComponent } from './components'
import project from 'constants/project'

const constants = {
  comingSoon: 'พบกันเร็วๆนี้',
  defaultCompanyGame: 'AESEXY',
  thbGame: 'คาสิโนออนไลน์',
  back: 'ย้อนกลับ',
  closedGame: ``,
}
const defaultProps: ICasinoOnlineProps & ICasinoOnlineActionProps = {
  connectGame() {
    noop()
  },
  connectedGameIsFetching: false,
  connectedGameError: '',
  connectedGameCode: 0,
  connectedGame: '',
  displayConfig: {},
  getDisplayConfig() {
    noop()
  },
  loader() {
    noop()
  },
  clearClickRef() {
    noop()
  },
  clickRef: {
    code: undefined,
    company: undefined,
    path: undefined,
  },
}

class CasinoOnlineContainer extends Component<
  ICasinoOnlineProps & ICasinoOnlineActionProps & RouteComponentProps,
  ICasinoOnlineActionState
> {
  static defaultProps = defaultProps
  state: ICasinoOnlineActionState = {
    triggerModal: false,
  }
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    this.props.getDisplayConfig()
    setTimeout(() => {
      interactive.rootElement.scrollToTop()
    }, 256)
    if (this.props.clickRef.company !== undefined) {
      /* this.props.loader(true) */
      for (let i = 0; i < this.onSortingCasinoConfig().length; i++) {
        if (
          this.onSortingCasinoConfig()[i].company ===
          this.props.clickRef.company
        ) {
          setTimeout(() => {
            ;['W88'].includes(this.props.clickRef!.company!)
              ? this.setState({ triggerModal: true }, () =>
                  this.onClearClickRef()
                )
              : this.onNavigateByClickRef(this.onSortingCasinoConfig()[i])
          }, 512)
          break
        }
      }
    } else {
      this.props.loader(false)
    }
  }

  componentWillUnmount() {
    document.body.style.removeProperty('overflow-y')
  }
  onNavigateByClickRef = (casino: ICasino) => {
    if (
      this.props.displayConfig![project.environmentName]![
        casino.company.toLowerCase() as TGameCompany
      ]!
    ) {
      window.open(
        interactive.openLink<
          ICasino & {
            gameLocation: TGameLocation
          }
        >({
          ...casino,
          gameLocation: 'EXTERNAL',
        }),
        '_blank'
      )
      this.onClearClickRef()
    }
    return this.onClearClickRef()
  }

  onClearClickRef = () => {
    this.props.loader(false)
    return this.props.clearClickRef()
  }

  onClickBack = () => this.props.history.push('/main')

  onHandleTranformObjectKeys = (obj: any): Object => {
    let tranformed = obj
    for (var key in tranformed) {
      tranformed[key.toUpperCase()] = tranformed[key]
      delete tranformed[key]
    }
    return tranformed
  }

  onSortingCasinoConfig = () => {
    const configs = { ...this.props.displayConfig![project.environmentName]! }
    const displayConfig = this.onHandleTranformObjectKeys(configs)

    let casioVariable = [...vendorLists.vendorListCircle]
    casioVariable.map((dataList, i) => {
      Object.keys(displayConfig).forEach((data) => {
        if (dataList.company === data) {
          casioVariable[i].isMainternance = get(
            displayConfig,
            data as TGameCompany,
            false
          )
        }
        if (
          dataList.company === 'W88' &&
          (get(displayConfig, 'W88CASINO', false) ||
            get(displayConfig, 'W88LOTTERY', false) ||
            get(displayConfig, 'W88P2P', false) ||
            get(displayConfig, 'W88SLOT', false))
        ) {
          casioVariable[i].isMainternance = true
        }
      })
      return casioVariable
    })
    const returnData = sortBy(casioVariable, [(o) => o.isMainternance !== true])
    return returnData
  }

  onSelectPortal = (val?: string) => {
    if (val) {
      if (val === 'SLOT') {
        this.props.history.push(routes.slot.path, { company: 'W88' })
      } else {
        window.open(
          interactive.openLink<
            { code: string; company: TGameCompany } & {
              gameLocation: TGameLocation
            }
          >({
            company: 'W88',
            code: val,
            gameLocation: 'EXTERNAL',
          }),
          '_blank'
        )
        this.setState({ triggerModal: false }, () => {
          document.body.style.removeProperty('overflow-y')
        })
      }
    } else {
      this.setState({ triggerModal: false }, () => {
        document.body.style.removeProperty('overflow-y')
      })
    }
    return
  }

  onRenderCasinoIcon = () => {
    const mapCasinoIcon = map(
      this.onSortingCasinoConfig(),
      (dataList, indexList) => {
        const isMaintenance = get(dataList, 'isMainternance', false)
        return (
          <div
            className={`col-6 col-lg-3 col-md-4 col-sm-6 casino-icon-container m2-y`}
            key={`icon-item-${indexList}`}
          >
            {dataList.company === 'W88' ? (
              <div
                key={`casino-online-${dataList.id}`}
                className={`${!isMaintenance ? 'disabled' : 'can-click'}`}
                tabIndex={0}
                onClick={() => this.setState({ triggerModal: true })}
              >
                <div className="row casino-icon-wrapper">
                  <div className="col-12 text-center">
                    <ResponsiveIcon
                      icon={dataList.image}
                      alt={`casino-online-${dataList.id}-alt`}
                      className="casino-icon m1-b"
                    />
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 text-center">
                        <div className="text-th-name">{dataList.thName}</div>
                      </div>
                      <div className="col-12 text-center">
                        <span className="text-name">{dataList.name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <a
                href={
                  isMaintenance
                    ? interactive.openLink<
                        ICasino & {
                          gameLocation: TGameLocation
                        }
                      >({
                        ...dataList,
                        gameLocation: 'EXTERNAL',
                      })
                    : '/'
                }
                target="_blank"
                rel="noreferrer noopener"
                key={`casino-online-${dataList.id}`}
                className={`${!isMaintenance && 'disabled'}`}
              >
                <div className="row casino-icon-wrapper">
                  <div className="col-12 text-center">
                    <ResponsiveIcon
                      icon={dataList.image}
                      alt={`casino-online-${dataList.id}-alt`}
                      className="casino-icon m1-b"
                    />
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 text-center">
                        <div className="text-th-name">{dataList.thName}</div>
                      </div>
                      <div className="col-12 text-center">
                        <span className="text-name">{dataList.name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            )}
            {isMaintenance ? (
              <></>
            ) : (
              <div className="closed-game-play">
                <h3>{constants.closedGame}</h3>
              </div>
            )}
          </div>
        )
      }
    )
    return <>{mapCasinoIcon}</>
  }

  onRenderBanner = () => (
    <div className="col-12 mb-5">
      <Banner
        ImageIcon={[
          W88_BANNER,
          dreamBanner,
          wmBanner,
          allbetBanner,
          aeSexyBanner,
          saGameBanner,
        ]}
        size={`small`}
      />
    </div>
  )

  render() {
    const RenderBanner = this.onRenderBanner
    const RenderCasinoIcon = this.onRenderCasinoIcon
    return (
      <div
        className="casino-online-container secondary-bg-navy"
        ref={this.pageContainerRef}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <ALink
                id="backto-previus-page"
                color={colors.PRIMARY_RED}
                bold
                onClick={this.onClickBack}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="m1-r" />
                {constants.back}
              </ALink>
            </div>
          </div>
          <div className="row p4-t">
            <div className="col">
              <h2>{constants.thbGame}</h2>
            </div>
          </div>
          <div className="row mt-4">
            <RenderBanner />
          </div>
          <div className="row justify-content-center">
            <RenderCasinoIcon />
          </div>
          <CustomModalComponent
            onChangeAction={this.state.triggerModal}
            onSubmitModal={this.onSelectPortal}
            config={this.props.displayConfig![project.environmentName]!}
          />
        </div>
      </div>
    )
  }
}

export default CasinoOnlineContainer
