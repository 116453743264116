// eslint-disable-next-line import/no-anonymous-default-export
export default {
    PRIMARY_BG: '#222529',
    SECONDARY_BG: '#282b2f',
    TERTIARY_BG: '#2b2e32',
    PRIMARY_TEXT: '#fcfcfc',
    SECONDARY_TEXT: '#8d8d8d',
    PRIMARY_RED: '#dd3d45',
    SECONDARY_RED: '#e46067',
    TERTIARY_RED: '#DD3D45',
    TERTIARY_RED_HOVER: '#F44336',
    PRIMARY_BLUE: '#004AB9',
    SECONDARY_BLUE: '#053BA7',
    // PRIMARY_GREEN: '#6fcf97',
    SECONDARY_GREEN: '#66c6b9',
    TERTIARY_GREEN: '#27A02C',
    TERTIARY_GREEN_HOVER: '#4CAF50',
    PRIMARY_YELLOW: '#ffb751',
    SECONDARY_YELLOW: '#e5a448',
    PRIMARY_BONUS: 'linear-gradient(90deg, #DE651A 0%, #E89F26 100%)',


    SECONDARY_BG_NAVY: '#1F2F4A',
    TERTIARY_BG_NAVY: '#253C63',

    PRIMARY_GREEN: '#4BAE4F',
    PRIMARY_GREEN_HOVER: '#6ebe72',

    SECONDARY_GOLD_TEXT: '#FAEB98',
    PRIMARY_NAVY: '#000A3E',
    SECONDARY_BUTTON_BG: '#253C63',
    TERTIARY_BUTTON_BLUE: '#0047BF',
}