import { createAction } from 'typesafe-actions'
import {
  GET_CREDIT_INFO_BET_LOTTERY_REQUEST,
  GET_CREDIT_INFO_BET_LOTTERY_SUCCESS,
  GET_CREDIT_INFO_BET_LOTTERY_FAILURE,
  GET_CREDIT_INFO_BET_LOTTERY_CANCEL,
  CLEAR_CREDIT_INFO_BET_LOTTERY,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getCreditInfoBetLotteryAction = createAction(
  GET_CREDIT_INFO_BET_LOTTERY_REQUEST,
  resolve => (query: APIPaginationQuery, isFirst?: boolean) => resolve({ ...query, isFirst }))

const getCreditInfoBetLotterySuccessAction = createAction(
  GET_CREDIT_INFO_BET_LOTTERY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<APIPagination<ICreditBetLottery>>>, isFirst: boolean) =>
    resolve({ ...data, isFirst }))

const getCreditInfoBeyLotteryFailureAction = createAction(
  GET_CREDIT_INFO_BET_LOTTERY_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getCreditInfoLotteryCancelAction = createAction(GET_CREDIT_INFO_BET_LOTTERY_CANCEL)

const clearCreditInfoBetLottery = createAction(CLEAR_CREDIT_INFO_BET_LOTTERY)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  clearCreditInfoBetLottery,
  getCreditInfoBetLotteryAction,
  getCreditInfoBetLotterySuccessAction,
  getCreditInfoBeyLotteryFailureAction,
  getCreditInfoLotteryCancelAction,
}