import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const getYeegeGameListReducer = (
    state: ReducerState<IPmpgSummary> = initialState,
    action: RootAction
): ReducerState<any> => {
    switch (action.type) {
        case getType(actions.getSummaryPmpgAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getSummaryPmpgSuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.status,
            }

        case getType(actions.getSummaryPmpgFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.response?.data.code,
            }
        default:
            return state
    }
}

export default getYeegeGameListReducer