import React, { Component, createRef, RefObject } from 'react'
import { Button, ErrorModal } from 'components'
import colors from 'constants/colors'
import routers from 'constants/routes'
import { noop, map, get } from 'lodash'
import { interactive } from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { responseCode } from 'constants/response'
import './lottoFavorite.style.scss'
import Scrollbar from 'react-scrollbars-custom'

const constants = {
  backText: 'ย้อนกลับ',
  favoriteList: 'ดึงเลขชุด',
  favoriteManagement: 'จัดการเลขชุด',
  select: 'เลือก',
}

const defaultProps: ILottoFavoriteProps & ILottoFavoriteActionProps = {
  getLottoFavoriteList() { noop() },
  loader() { noop() },
  getLottoFavoriteListIsFetching: false,
  getLottoFavoriteListCode: 0,
  getLottoFavoriteListError: '',
  lottoFavoriteList: [],
}

class LottoFavoriteContainer extends Component<
  ILottoFavoriteProps & ILottoFavoriteActionProps
  // & RouteComponentProps<any, any, IMakingLottoRouteProps | ILottoFavoriteRouteProps>
  > {

  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    interactive.rootElement.scrollToTop()
    this.props.loader(true)
    this.props.getLottoFavoriteList()
  }

  componentDidUpdate(prevProps: ILottoFavoriteProps) {
    if (prevProps.getLottoFavoriteListIsFetching !== this.props.getLottoFavoriteListIsFetching
      && !this.props.getLottoFavoriteListIsFetching) {
      this.props.loader(false)
      if (this.props.getLottoFavoriteListCode !== responseCode.OK
        && this.props.getLottoFavoriteListCode !== responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: () => ErrorModal.hide,
          description: this.props.getLottoFavoriteListError,
        })
      }
    }
  }

  onClickFavoriteManagement = () => {
    this.props.history.push(routers.lottoFavoriteManagement.path)
  }

  onClickSelectFavorite =(favoriteItems: IFavoriteSet)=>{
    this.props.onAddedNumber!(favoriteItems.list.map((item)=>({
      number: item.number,
      type: item.type,
      value:'1'
    })),'ADD',false)
  }

  renderFavoriteList = () => {
    const FavoriteListComponent = map(this.props.lottoFavoriteList, (favorite, index) => {
      return (
        <div id={index+favorite.id+"list"}  key={`favorite-${index}`} className="favorite-container-new row flex-row align-items-center p1-y p2-x">
          <h4 className="col-5 font-m15r">{favorite.title}</h4>
          <h5 className="col-3 font-m15r">{favorite.list.length}</h5>
          <div className="selector-favorite-wrapper ml-auto col">
            <Button
              size="small"
              id="select-this-favorite"
              textClassName="font-m15r"
              styleContainer="btn-select"
              text={constants.select}
              backgroundColor={colors.SECONDARY_BLUE}
              backgroundHoverColor={colors.PRIMARY_BLUE}
              onClick={() => this.onClickSelectFavorite(favorite)}
            />
          </div>
        </div>
      )
    })
    return (<>{FavoriteListComponent}</>)
  }

  render() {
    const FavoriteComponent = this.renderFavoriteList

    const heightSetion2 = get(document.getElementById('setion-2'),"clientHeight",0);
    const heightBorderRounded = get(document.getElementById('border-rounded'),"clientHeight",0);

    return (
      <div className="lotto-favorite-container-new tertiary-bg-navy h-100 px-2" ref={this.pageContainerRef}>
        {/* <div className="container"> */}
          <div className="row">
            <div className="col">
              <div className="border-rounded">
                <div id='border-rounded' className="d-flex flex-row justify-content-between align-items-center px-3 pt-2">
                  <h3 className='font-m20r'>{constants.favoriteList}</h3>
                  {/* <ALink
                    id="goto-favorite-management"
                    color={colors.SECONDARY_GOLD_TEXT}
                    onClick={this.onClickFavoriteManagement}
                    // className="font-m20r"
                  >
                    {constants.favoriteManagement}
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2 font-m20r" />
                  </ALink> */}
                  <h3
                    id='goto-favorite-management'
                    className='font-m20r goto-favorite-management'
                    onClick={this.onClickFavoriteManagement}
                  >
                    {constants.favoriteManagement}
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2 font-m20r" />
                  </h3>
                </div>
                  
                <div className="p1-y">
                  <div className="row m2-x pb-2 head-tabel">
                    <h6 className="col-5 font-m15r primary-text">ชื่อ</h6>
                    <h6 className="col-5 font-m15r primary-text">จำนวนเลข</h6>
                  </div>
                  {/* <div 
                  className='body-list mx-3' 
                  style={{height:`${(heightSetion2-heightBorderRounded)-65}px`}}
                  // style={{height:`50px`}}
                  >
                    <FavoriteComponent />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
            {/* <div className='body-list mx-2'> */}
              <Scrollbar
                id="favorite"
                style={{height:`${(heightSetion2-heightBorderRounded)-65}px`}}
                removeTracksWhenNotUsed 
                disableTracksWidthCompensation 
              >
                <FavoriteComponent />
              </Scrollbar>
            {/* </div> */}
      </div>
    )
  }
}

export default LottoFavoriteContainer