import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'
import { get } from 'lodash'

const connectGameReducer = (
  state: ReducerState<string> = initialState,
  action: RootAction
): ReducerState<string> => {
  switch (action.type) {
    case getType(actions.connectGameAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.connectGameSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data.url,
        code: action.payload.data.code,
      }
    case getType(actions.connectGameFailureAction):
      const message = get(action, 'payload.response.data.devMessage', '')
      const code = get(action, 'payload.response.data.code', 400)
      return {
        isFetching: false,
        error: message,
        code,
      }
    default:
      return state
  }
}

export default connectGameReducer