import project from 'constants/project'
import { transformer } from 'utils'

export const GET_LOTTERY_PULL_DETAIL_REQUEST = 'GET_LOTTERY_PULL_DETAIL_REQUEST'
export const GET_LOTTERY_PULL_DETAIL_SUCCESS = 'GET_LOTTERY_PULL_DETAIL_SUCCESS'
export const GET_LOTTERY_PULL_DETAIL_FAILURE = 'GET_LOTTERY_PULL_DETAIL_FAILURE'
export const GET_LOTTERY_PULL_DETAIL_CANCEL = 'GET_LOTTERY_PULL_DETAIL_CANCEL'
export const CLEAR_LOTTERY_PULL_DETAIL = 'CLEAR_LOTTERY_PULL_DETAIL'

export const initialState: IBetLotteryPullDetailState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
  pagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
}


export const endpoint = {
  getBetLotteryPullDetail: (query?: APIPaginationQuery) =>`${project.environment[project.environmentName].api}/bet/lottery/pull/detail${transformer.urlSearchParams(query)}`,
}