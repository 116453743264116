import React, { FC, useState, useEffect } from 'react'
import { isNaN } from 'lodash'
import { ResponsiveIcon} from 'components'
import colors from 'constants/colors'
import moment from 'moment-timezone'
import { date, number } from 'utils'
import './lottoActionCard.style.scss'
import logoThb from '../../assets/images/logo/logothailandbet04.png'
import iconClock from '../../assets/images/icon/timer.svg'
import iconBan from '../../assets/images/icon/ban.svg'
import iconCheck from '../../assets/images/icon/checkmark.svg'
const constants = {
  day: 'วัน',
  timeups: 'รอเปิด',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILottoActionCard = {
  id: '',
  title: '',
  slug: null,
  subTitle: '',
  isCountingdown: false,
  start: '',
  expire: '',
  status: 'UNKNOWN',
  openedStatusText: '',
  waitingStatusText: '',
  closedStatusText: '',
  description: '',
  backgroundColor: colors.TERTIARY_BG_NAVY,
  icon: '',
}

const LottoActionCard: FC<ILottoActionCard & DefaultProps> = (props) => {

  const {
    id,
    slug,
    title,
    subTitle,
    isCountingdown,
    start,
    expire,
    status,
    openedStatusText,
    waitingStatusText,
    closedStatusText,
    backgroundColor,
    description,
    icon,
    onClick,
  } = props

  let intervalId: NodeJS.Timeout | null = null

  const [remain, setRemain] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })

  const clearLocalInterval = () => {
    if (intervalId !== null) {
      clearInterval(intervalId)
    }
  }

  const countingdown = () => {
    clearLocalInterval()
    if (isCountingdown) {
      const resultTiming = date.calibratingTime(start).unix() - moment().local().unix()
      const selectedTime = resultTiming < 0 ? expire : start
      const expireTime = slug === 'LOTTER_YEGEE' ? expire : selectedTime
      const expireMoment = date.calibratingTime(expireTime)
      intervalId = setInterval(() => {
        const diiferentSecond = expireMoment.diff(moment().local(), 'seconds')
        const days = Math.floor((diiferentSecond / 86400))
        const hours = Math.floor((diiferentSecond / 3600) % 24)
        const minutes = Math.floor((diiferentSecond / 60) % 60)
        const seconds = Math.floor(diiferentSecond % 60)

        if ((hours <= 0 && minutes <= 0 && seconds <= 0)
          || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
          setRemain({ days: 0, hours: 0, minutes: 0, seconds: 0 })
          clearLocalInterval()
        } else {
          setRemain({ days, hours, minutes, seconds })
        }
      }, 1000);
    }
  }

  useEffect(() => {
    if (isCountingdown) {
      countingdown()
    }
    return clearLocalInterval
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountingdown])

  const resultTimingCheck = date.calibratingTime(start).unix() - moment().local().unix()
  const isAvailable = (slug === 'LOTTER_YEGEE') || resultTimingCheck < 0
  // const BadgeComponent = ({ text }: { text: string }) => {
  //   const resultTiming = date.calibratingTime(start).unix() - moment().local().unix()
  //   const isAvailable = (slug === 'LOTTER_YEGEE') || resultTiming < 0
  //   if (status === 'OPEN') {
  //     if (!isAvailable && isCountingdown) {
  //       return <Badge text={text} backgroundColor={colors.SECONDARY_BLUE} color={colors.PRIMARY_TEXT} />
  //     }
  //     return <Badge text={text} backgroundColor={colors.PRIMARY_GREEN} color={colors.PRIMARY_TEXT} />
  //   } else if (status === 'CLOSE') {
  //     return <Badge text={text} backgroundColor={colors.SECONDARY_RED} color={colors.PRIMARY_TEXT} />
  //   } else if (status === 'WAIT') {
  //     return <Badge text={text} backgroundColor={colors.SECONDARY_BLUE} color={colors.PRIMARY_TEXT} />
  //   } else if (status === 'UNKNOWN') {
  //     return <Badge text={text} backgroundColor={colors.PRIMARY_BG} color={colors.PRIMARY_TEXT} />
  //   }
  //   return <></>
  // }

  const DateText = ({ text }: { text: string }) => {
    if (!isAvailable && isCountingdown) {
      return <div className={`h3r sub-title-label time-label WAIT`}>{text}</div>
    }
    if (status === 'OPEN') {
      return <div className={`h3r sub-title-label time-label ${status}`}>{text}</div>
    }
    else if (status === 'WAIT') {
      return <div className={`h3r sub-title-label time-label ${status}`}>{text}</div>
    }
    else if (status === 'CLOSE') {
      return <div className={`h3r sub-title-label time-label ${status}`}>{text}</div>
    }
    return <></>
  }

  const IconTime = () => {
    if (status === 'OPEN') {
      if (!isAvailable && isCountingdown) {
        return (
          <>
            <img
              alt={'icon-status'}
              src={iconStatus()}
              className={`icon-outline ${'WAIT'}`}
            />
          </>
        )
      }
      return (
        <>
            <img
              alt={'icon-status'}
              src={iconStatus()}
              className={`icon-outline ${status}`}
            />
        </>
      )
    }
    else  {
      return  (
        <>
            <img
              alt={'icon-status'}
              src={iconStatus()}
              className={`icon-outline ${status}`}
            />
        </>
      )
    }
  }


  const handleOnClick = () => {
    if (typeof onClick === 'function') {
      onClick()
    }
  }

  const statusText = (): string => {
    if (isCountingdown) {
      if (remain.days > 0) {
        return `${number.padNumber(String(remain.days), 2)} ${constants.day}`
      } else if (remain.days <= 0 && remain.hours <= 0 && remain.minutes <= 0 && remain.seconds <= 0) {
        return constants.timeups
      } else {
        return `${number.padNumber(String(remain.hours), 2)}:${number.padNumber(String(remain.minutes), 2)}:${number.padNumber(String(remain.seconds), 2)}`
      }
    } else if (status === 'OPEN') {
      return openedStatusText || ''
    } else if (status === 'WAIT') {
      return waitingStatusText || ''
    } else if (status === 'CLOSE') {
      return closedStatusText || ''
    }
    return '-'
  }

  const logoIcon = slug === 'LOTTER_YEGEE' ? logoThb : icon

  const iconStatus = () => {
    if (status === 'OPEN' && slug === 'LOTTER_YEGEE') {
      return iconCheck
    }
    else if (status === 'OPEN') {
      return iconClock
    }
    else if (status === 'CLOSE') {
      return iconBan
    }
    return iconClock
  }


  return (
    <div
      className={`lotto-action-card-container ${typeof onClick === 'function' ? '' : 'disabled'} ${status}`}
      style={{ backgroundColor }}
      onClick={handleOnClick}
      id={id}
    >
      <div className={`sub-background ${status}`} />
      <div className="lotto-action-text-wrapper d-flex flex-row justify-content-between">
        <ResponsiveIcon icon={logoIcon!} alt="flag" className="lotto-action-card-flag m2-r" />
        <div className="d-flex flex-column align-items-end lotto-card">
          <div className={`body-bold-rs title-text ${status}`}>
            {title}
          </div>
          <h6 className={`subtitle-2r sub-title-label ${status}`}>
            {subTitle}
            <span className={`subtitle-2r description ${status}`}>{description}</span>
          </h6>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between m1-t">
        {/* <BadgeComponent text={statusText()} /> */}
        <IconTime />
        <DateText text={statusText()} />
      </div>
    </div>
  )
}

LottoActionCard.defaultProps = defaultProps

export default LottoActionCard