export const THAI_TH = {
    name: 'thai_th',
    months: [
        ['มกราคม', 'ม.ค.'],
        ['กุมภาพันธ์', 'ก.พ.'],
        ['มีนาคม', 'มี.ค.'],
        ['เมษายน', 'เม.ย.'],
        ['พฤษภาคม', 'พ.ค.'],
        ['มิถุนายน', 'มิ.ย.'],
        ['กรกฎาคม', 'ก.ค.'],
        ['สิงหาคม', 'ส.ค.'],
        ['กันยายน', 'ก.ย.'],
        ['ตุลาคม', 'ต.ค.'],
        ['พฤศจิกายน', 'พ.ย.'],
        ['ธันวาคม', 'ธ.ค.'],
    ],
    weekDays: [
        ['วันเสาร์', 'ส'],
        ['วันอาทิตย์', 'อา'],
        ['วันจันทร์', 'จ'],
        ['วันอังคาร', 'อ'],
        ['วันพุธ', 'พ'],
        ['วันพฤหัส', 'พฤ'],
        ['วันศุกร์', 'ศ'],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
        ['ก่อนเที่ยง', 'เอเอ็ม'],
        ['หลังเที่ยง', 'พีเอ็ม'],
    ],
};
