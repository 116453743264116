import React, { FC } from 'react'
import { Modal } from '../..'
import { noop } from 'lodash'
import './announceLotto.style.scss'
import colors from 'constants/colors'
import { ResponsiveIcon, Button } from 'components'
import thailandIcon from 'assets/images/logo/logothailandbet02.png'
// import downloadIcon from 'assets/images/main/appdownload/download.png'

const constants = {
  title: 'ข่าวด่วน',
  actionText: 'เข้าใจแล้ว',
  description: 'กรุณารอการตรวจสอบสักครู่',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IAnnounceModal = {
  href: '',
  textHowTo: '',
  action() { noop() },
  actionHowTo() { noop() },
  title: constants.title,
  actionText: constants.actionText,
  description: constants.description,
}

const AnnounceLottoModal: FC<IInfoModal & DefaultProps> = (props) => {

  return (
    <div className="announce-lotto-modal-container border-rounded">
      <div className="container">
        <div>
          <ResponsiveIcon
            alt="logo-thb"
            className="logo-thb"
            icon={thailandIcon}
          />
        </div>
        <h2 className="h3r title-text">{props.title}</h2>
        <span id={'how-to'} className="h3r how-to my-md-3">
          {props.textHowTo}
        </span>
        <div className="my-2">
          <h3 className="subtitle-1r description-text">{props.description}</h3>
          <h5 className="body-2 secondary-text detail-text">{props.detail}</h5>
        </div>
        <div className="my-1">
          <table className="table table-bordered primary-text">
            <tbody>
              <tr>
                <th scope="row">ระดับ 1</th>
                <td>ราคาเต็ม</td>
                <td>100%</td>
              </tr>
              <tr>
                <th scope="row">ระดับ 2</th>
                <td>ครึ่งราคา</td>
                <td>50%</td>
              </tr>
              <tr>
                <th scope="row">ระดับ 3</th>
                <td>ครึ่งของครึ่งราคา</td>
                <td>25%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="example my-2">
          <h3 className="subtitle-1r description-text">{'ตัวอย่าง'}</h3>
          <h3 className="subtitle-1r description-text">{'การปรับอัตราจ่ายหวยรัฐบาล ในขณะที่ '}</h3>
          <h3 className="subtitle-1r description-text">{'สามตัว ราคาเต็ม 900'}</h3>
          <h3 className="subtitle-1r description-text">{'สองตัว ราคาเต็ม 90'}</h3>
        </div>
        <div className="my-1">
          <table className="table table-bordered primary-text">
            <tbody>
              <tr>
                <th scope="row">ระดับ</th>
                <td style={{ width: '50.47%' }}>เลข</td>
                <td>ราคา</td>
              </tr>
              <tr>
                <th style={{ width: '25.78%' }} scope="row">1</th>
                <td>887, 223</td>
                <td>900</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>221, 334, 567, 831</td>
                <td>450</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>100, 201, 003, 021</td>
                <td>225</td>
              </tr>
            </tbody>
          </table>
          {/* <Button
            id="announce-ok-button"
            type="rectangle"
            size="large"
            href={props.href}
            onClick={props.action}
            text={props.actionText!}
            icon={downloadIcon}
          /> */}
        </div>
        <div className="m2-y">
        <Button
          styleContainer="button-style"
          backgroundColor={colors.PRIMARY_GREEN}
          backgroundHoverColor={colors.PRIMARY_GREEN_HOVER}
          id="announce-ok-button"
          type="rectangle"
          size="large"
          href={props.href}
          onClick={props.action}
          text={props.actionText!}
        />
        </div>
      </div>

    </div>
  )
}

AnnounceLottoModal.defaultProps = defaultProps

const successModal = {
  show: (props: IAnnounceModal) => {
    return Modal.event('MODAL_OVER', {
      state: 'show',
      extraProps: { size: 'xl' },
      component: (<AnnounceLottoModal {...props} />),
    })
  },
  hide: () => Modal.event('MODAL_OVER', { state: 'hide' }),
}

export default successModal