import sumActions from './sum/actions'
import playActions from './play/actions'
import gameActions from './game/actions'
import gameListActions from './gameList/actions'
import playedListActions from './playedList/actions'
import gameHistoryActions from './gameHistory/actions'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...sumActions,
  ...playActions,
  ...gameActions,
  ...gameListActions,
  ...playedListActions,
  ...gameHistoryActions,
}