import { createAction } from 'typesafe-actions'
import {
  GET_CREDIT_INFO_LIST_REQUEST,
  GET_CREDIT_INFO_LIST_SUCCESS,
  GET_CREDIT_INFO_LIST_FAILURE,
  GET_CREDIT_INFO_LIST_CANCEL,
  CLEAR_CREDIT_INFO_LIST,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getCreditInfoListAction = createAction(
  GET_CREDIT_INFO_LIST_REQUEST,
  resolve => (query: APIPaginationQuery, isFirst?: boolean) => resolve({ ...query, isFirst }))

const getCreditInfoListSuccessAction = createAction(
  GET_CREDIT_INFO_LIST_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<APIPagination<ICredit>>>, isFirst: boolean) =>
    resolve({ ...data, isFirst }))

const getCreditInfoListFailureAction = createAction(
  GET_CREDIT_INFO_LIST_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getCreditInfoListCancelAction = createAction(GET_CREDIT_INFO_LIST_CANCEL)

const clearCreditInfoList = createAction(CLEAR_CREDIT_INFO_LIST)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  clearCreditInfoList,
  getCreditInfoListAction,
  getCreditInfoListSuccessAction,
  getCreditInfoListFailureAction,
  getCreditInfoListCancelAction,
}