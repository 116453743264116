import project from 'constants/project'

export const AFFILATE_WITHDRAWAL_REQUEST = 'AFFILATE_WITHDRAWAL_REQUEST'
export const AFFILATE_WITHDRAWAL_SUCCESS = 'AFFILATE_WITHDRAWAL_SUCCESS'
export const AFFILATE_WITHDRAWAL_FAILURE = 'AFFILATE_WITHDRAWAL_FAILURE'
export const AFFILATE_WITHDRAWAL_CANCEL = 'AFFILATE_WITHDRAWAL_CANCEL'

export const initialState: ReducerState<IAffilateWithdrawal> = {
  isFetching: false,
  code: 0,
  data: {},
  error: '',
}

export const endpoint = {
  affilateWithdraw: `${project.environment[project.environmentName].api}/affilate/withdraw`,
}