import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'
import { WORD_SPECIAL } from 'constants/variables'

const getCreditInfoListReducer = (
  state: ICreditInfoBetLotteryState = initialState,
  action: RootAction
): ICreditInfoBetLotteryState => {
  switch (action.type) {
    case getType(actions.getCreditInfoBetGameAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getCreditInfoBetGameSuccessAction):
      let newData = action.payload.data.data.dataList.map((item, index) => ({ ...item, wordSpecial: WORD_SPECIAL[(Date.now() + index) % WORD_SPECIAL.length] }))
      const data = (action.payload.isFirst)
        ? newData
        : [...(state.data || []), ...(newData || [])]
      return {
        isFetching: false,
        data,
        code: action.payload.status,
        pagination: action.payload.data.data,
      }
    case getType(actions.getCreditInfoBetGameFailureAction):
      return {
        isFetching: false,
        error: action.payload.response?.data.devMessage,
        code: action.payload.response?.data.code,
      }
    case getType(actions.clearCreditInfoBetGame):
      return initialState
    default:
      return state
  }
}

export default getCreditInfoListReducer