import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import gameAction from 'reduxs/game/actions'
import loaderAction from 'reduxs/loader/actions'
import CasinoGamePartner from './CasinoGamePartner.container'
import gameSelector from 'reduxs/game/selectors'
import displayConfigSelector from 'reduxs/config/selectors'
import configAction from 'reduxs/config/actions'
import userSelector from 'reduxs/user/selector'
import clickRefAction from 'reduxs/user/clickRef/actions'

const mapStateToProps = (state: RootReducers): ICasinoGameProps => {
  const gameListSelected = gameSelector.gameList(state)
  const connectedGameSelected = gameSelector.gameConnect(state)
  const displayConfig = displayConfigSelector.displayConfig(state)
  const clickRef = userSelector.clickRef(state)
  return {
    getGameListIsFetching: gameListSelected.isFetching!,
    getGameListError: gameListSelected.error!,
    getGameListCode: gameListSelected.code!,
    gameList: gameListSelected.data!,
    connectedGameIsFetching: connectedGameSelected.isFetching!,
    connectedGameError: connectedGameSelected.error!,
    connectedGameCode: connectedGameSelected.code!,
    connectedGame: connectedGameSelected.data!,
    displayConfig: displayConfig.data!,
    displayConfigCode: displayConfig.code!,
    displayConfigError: displayConfig.error!,
    displayConfigIsFetching: displayConfig.isFetching!,
    clickRef,
  }
}

const mapDispatchToProos = (
  dispatch: Dispatch<RootAction>
): ICasinoGameActionProps =>
  bindActionCreators(
    {
      loader: loaderAction.loadingAction,
      getGameList: gameAction.getGameListAction,
      clearGameList: gameAction.clearGameListAction,
      connectGame: gameAction.connectGameAction,
      getDisplayConfig: configAction.getDisplayConfigAction,
      clearClickRef: clickRefAction.clearClickRef,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProos)(CasinoGamePartner)
