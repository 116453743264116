import React, { FC } from "react";
import { noop } from "lodash";
import { Icon } from "@iconify/react";
import "./backButton.style.scss";

type DefaultProps = Readonly<typeof defaultProps>;

const constants = {
  back: "ย้อนกลับ",
};

const defaultProps: IBackButton = {
  id: "",
  text: constants.back,
  onClick() {
    noop();
  },
};

const BackButton: FC<IBackButton & DefaultProps> = (props) => {
  const { id, onClick = () => {}, text } = props;
  return (
    <div
      id={id}
      className="d-flex flex-row back-botton-container"
      onClick={onClick}
    >
      <Icon icon="flowbite:arrow-left-outline" width="24px" height="24px" />
      <span className="back-button-text">{text}</span>
    </div>
  );
};

BackButton.defaultProps = defaultProps;

export default BackButton;
