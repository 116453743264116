import './creditInfo.style.scss'
import moment from 'moment-timezone'
import colors from 'constants/colors'
import { date, interactive } from 'utils'
import { responseCode } from 'constants/response'
import { RouteComponentProps } from 'react-router-dom'
// import InfiniteScroll from 'react-infinite-scroll-component'
import React, { Component, createRef, RefObject } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  map,
  noop,
  keys,
  sortBy,
  isEmpty,
  reverse,
  groupBy,
  Dictionary,
  get,
} from 'lodash'
import {
  ALink,
  ErrorModal,
  CreditCard,
  TransactionItemCollapsible,
  PlaceholderLoading,
  LoadMore,
} from 'components'
import {
  faPlus,
  faMinus,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import swap from 'assets/images/icon/swap.png'
import routes from 'constants/routes'

const constants = {
  titleText: 'ข้อมูลเครดิต',
  remainingLabel: 'เครดิตคงเหลือ',
  deposit: 'ฝาก',
  withdraw: 'ถอน',
  back: ' ย้อนกลับ',
  latedUpdate: 'อัพเดทล่าสุด:',
  ok: 'ตกลง',
  thailand: 'Thailand',
  credit: 'CREDIT',
  bet: 'Bet',
  cardNumber: '**** **** **** ****',
  today: 'วันนี้',
  yesterday: 'เมื่อวาน',
  creditTextNew: 'ข้อมูลเครดิตใหม่'
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ICreditInfoProps & ICreditInfoActionProps = {
  clearCreditInfoList() { noop() },
  getCreditInfoList() { noop() },
  meDetailRequest() { noop() },
  postCreditMeDetailCode: 0,
  postCreditMeDetailError: '',
  postCreditMeDetailIsFetching: false,
  creditMetail: [],
  storeCreditMetail: {},
  getCreditInfoListCode: 0,
  getCreditInfoListError: '',
  getCreditInfoListIsFetching: false,
  creditInfoPagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  creditInfo: [],
  wallet: {},
  loader() { noop() },
  getUser() { noop() },
  getMeUser: {},
}

class TransactionListContainer extends
  Component<ICreditInfoProps & ICreditInfoActionProps & DefaultProps & RouteComponentProps, ICreditInfoState> {

  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  state: ICreditInfoState = {
    hasMore: true,
  }

  componentDidMount() {
    interactive.rootElement.scrollToTop()
    this.props.getUser()
    this.onGetCreditinfo(true)
    this.props.loader(true)
  }

  componentDidUpdate(prevProps: ICreditInfoProps) {
    if (prevProps.getCreditInfoListIsFetching !== this.props.getCreditInfoListIsFetching
      && !this.props.getCreditInfoListIsFetching) {
      this.props.loader(false)
      if (this.props.getCreditInfoListCode === responseCode.OK) {
        if (this.props.creditInfoPagination.page >= this.props.creditInfoPagination.total) {
          this.setState({ hasMore: false })
        }
      } else if (this.props.getCreditInfoListCode !== responseCode.OK
        && this.props.getCreditInfoListCode !== responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.getCreditInfoListError,
          actionText: constants.ok,
        })
      }
    }

    if (prevProps.postCreditMeDetailIsFetching !== this.props.postCreditMeDetailIsFetching && !this.props.postCreditMeDetailIsFetching) {
        this.props.loader(false)
      if (this.props.postCreditMeDetailCode !== responseCode.OK
        && this.props.postCreditMeDetailCode !== responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.postCreditMeDetailError,
          actionText: constants.ok,
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.clearCreditInfoList()
  }

  onPressBack = () => {
    this.props.history.push(routes.home.path)
  }

  onPressDeposit = () => {
    this.props.history.push('/deposit')
  }

  onPresWithdraw = () => {
    this.props.history.push('/withdraw')
  }

  onPressTransactionDetail = (credit: ICredit) => {
    this.props.history.push('/credit-info/detail', { credit })
  }

  onGetCreditinfo = (isFirst = false) => {
    if (isFirst) {
     this.getCreditInfo(isFirst) 
    }
  }

  getCreditInfo=(isFirst = false)=>{
    const queryData: APIPaginationQuery = {
      limit: 10,
      page: isFirst ? 1 : this.props.creditInfoPagination.page + 1,
    }
    this.props.getCreditInfoList(queryData, isFirst)
  }

  onGetCreditMedetail = (createdAt: string, slug: string, status:string) => {
    this.props.meDetailRequest({ createdAt: createdAt, slug: slug, status: status })
  }

  getDataDetail = (keyDetail:string):ICreditDetail[] => {
    return get(this.props.storeCreditMetail,`${keyDetail}`,[])
  }

  renderTransactionList = () => {
    const creditGroupList: Dictionary<ICredit[]> = groupBy<ICredit>(
      map(this.props.creditInfo, credit => ({ ...credit, groupTime: date.calibratingTime(credit.createdAt).format('YYYYMMDD') })),
      'groupTime')

    if (isEmpty(creditGroupList)) { return <></> }

    return (
      <LoadMore
        isLoad={this.props.getCreditInfoListIsFetching}
        handleClickLoadMore={()=>{this.getCreditInfo(false)}}
        style={{ overflowX: 'hidden', maxHeight: '800px' }}
        height={'calc(100vh - 300px)'}
        dataLength={this.props.creditInfo.length}
        next={this.onGetCreditinfo}
        scrollThreshold="15px"
        hasMore={this.state.hasMore}
        loader={(
          <div className="row p2-y">
            <div className="col">
              <PlaceholderLoading
                customCol={[
                  [
                    { col: 4, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
                    { col: 6, show: false },
                    { col: 2, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
                  ],
                  [
                    { col: 5, color: colors.SECONDARY_TEXT, show: true, size: 'S' },
                  ],
                ]}
              />
            </div>
          </div>
        )}
      >
        {
          reverse(keys(creditGroupList).sort()).map((key, index) => {
            const CreditPerDay = map(
              reverse(sortBy(creditGroupList[key], ['createdAt'])),
              (transaction, transactionIndex) =>{
              let status = transaction.groupType.startsWith("BET_GAME_CASINO") ? '' :transaction.status
              let generateStatus = transaction.status
              return(
                <TransactionItemCollapsible
                  action={this.onGetCreditMedetail}
                  creditDetail={this.getDataDetail(transaction.slug+'_'+transaction.createdAt+'_'+status+'_'+generateStatus)}
                  credit={transaction}
                  key={`credit-info-items-${transactionIndex}`}
                />
              )}
            )

            const dateMoment = moment(key, 'YYYYMMDD').local()
            const isToDay = dateMoment.clone().diff(moment().local(), 'day')
            const displayDayString = (isToDay === 0)
              ? constants.today
              : (isToDay === -1)
                ? constants.yesterday
                : dateMoment.format('DD MMM YYYY')

            return (
              <div className="row mt-4 w-100" key={`${key}-${index}`}>
                <div className="col">
                  <div className="display-date-text mb-2">{displayDayString}</div>
                  {CreditPerDay}
                </div>
              </div>
            )
          })
        }
      </LoadMore>
    )
  }

  render() {
    const time = this.props.wallet.updatedTime || ''
    const updatedTime = moment(time).format('lll') || ''
    const updatedTimeText = `${constants.latedUpdate} ${updatedTime}`
    const actionDeposit = this.props.getMeUser.permission === 'USER' ? '' : 'disabled'
    const TransactionList = this.renderTransactionList()

    return (
      <div className="credit-info-list-container" ref={this.pageContainerRef}>
        <div className="container">
          <div className="row d-flex flex-row justify-content-between align-items-center">
            <div className="col">
              <ALink id="backto-previus-page" color="#dd3d45" bold onClick={this.onPressBack}>
                <FontAwesomeIcon icon={faChevronLeft} />
                {constants.back}
              </ALink>
            </div>
            <div className="credit-info-old" onClick={()=>{
              this.props.history.push(routes.creditInfoReport.path)
            }}>
              <span>
                <img
                  className="swap-icon"
                  src={swap}
                  alt={`swap`}
                />
              </span>
              <h5 className="credit-info-old-name ml-1"> {constants.creditTextNew}</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-3">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <CreditCard credit={this.props.wallet.money || 0} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <div className="updated-time-text">{updatedTimeText}</div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col">
                  <div className="credit-panel-action-wrapper">
                  <div className={`temprary-button-wrapper ${actionDeposit} mx-2`} onClick={this.onPressDeposit}>
                      <div className="temp-text-button">
                        <FontAwesomeIcon icon={faPlus} className="temp-icon-button" />
                        {constants.deposit}
                      </div>
                    </div>
                    <div className="temprary-button-wrapper mx-2" onClick={this.onPresWithdraw}>
                      <div className="temp-text-button">
                        <FontAwesomeIcon icon={faMinus} className="temp-icon-button" />
                        {constants.withdraw}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-3">
              <div className="credit-info-transaction-wrapper p2-x p2-y">
                <div className="row pb-3">
                  <div className="col">
                    <h3>{constants.titleText}</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {TransactionList}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TransactionListContainer