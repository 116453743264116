import { createAction } from 'typesafe-actions'
import {
  CONNECT_GAME_REQUEST,
  CONNECT_GAME_SUCCESS,
  CONNECT_GAME_FAILURE,
  CONNECT_GAME_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const connectGameAction = createAction(
  CONNECT_GAME_REQUEST,
  resolve => (gameLocation: TGameLocation, query: IGameConnectQuery) => resolve({ query, gameLocation }))

const connectGameSuccessAction = createAction(
  CONNECT_GAME_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<{ url: string }>>) => resolve(data))

const connectGameFailureAction = createAction(
  CONNECT_GAME_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const connectGameCancelAction = createAction(CONNECT_GAME_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  connectGameAction,
  connectGameSuccessAction,
  connectGameFailureAction,
  connectGameCancelAction,
}