import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const notificationUserCountSocketReducer = (state: ICoreSocketState = initialState, action: RootAction): ICoreSocketState => {
  switch (action.type) {
    case getType(actions.connectNotificationUserCountSocketAction):
      return {
        ...state,
        connected: false,
      }
    case getType(actions.connectedNotificationUserCountSocketAction):
      return {
        connected: true,
      }
    case getType(actions.connectNotificationUserCountSocketErrorAction):
      return {
        connected: false,
      }
    case getType(actions.disconnectNotificationUserCountSocketAction):
      return {
        connected: false,
      }
    case getType(actions.disconnectedNotificationUserCountSocketAction):
      return {
        connected: false,
      }
    case getType(actions.logNotificationUserCountSocket):
      return {
        connected: false,
      }
    default:
      return state
  }
}

export default notificationUserCountSocketReducer