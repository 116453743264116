import { createAction } from 'typesafe-actions'
import {
  POST_BET_LOTTERY_PULL_SAVE_REQUEST,
  POST_BET_LOTTERY_PULL_SAVE_SUCCESS,
  POST_BET_LOTTERY_PULL_SAVE_FAILURE,
  POST_BET_LOTTERY_PULL_SAVE_CANCEL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const postBetPullSaveAction = createAction(
  POST_BET_LOTTERY_PULL_SAVE_REQUEST,
  resolve => (data: IBetLotteryPullSaveRequest) => resolve(data))

const postBetPullSaveSuccessAction = createAction(
  POST_BET_LOTTERY_PULL_SAVE_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IResBetLotteryPullSave>>) => resolve(data))

const postBetPullSaveFailureAction = createAction(
  POST_BET_LOTTERY_PULL_SAVE_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const postBetPullSaveCancelAction = createAction(POST_BET_LOTTERY_PULL_SAVE_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postBetPullSaveAction,
  postBetPullSaveSuccessAction,
  postBetPullSaveFailureAction,
  postBetPullSaveCancelAction,
}