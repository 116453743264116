import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import pmpgAction from 'reduxs/pmpg/actions'
import loaderAction from 'reduxs/loader/actions'
import JackpotContainer from './Jackpot.container'
import pmpgSelector from 'reduxs/pmpg/selectors'
import userAction from 'reduxs/user/clickRef/actions'

const mapStateToProps = (state: RootReducers): IPmpgProps => {
  const summaryPmpgSelected = pmpgSelector.summaryPmpg(state)
  const awardListPmpgSelected = pmpgSelector.awardListPmpg(state)
  const pmpgEraSelected = pmpgSelector.eraPmpg(state)
  return {
    getSummaryPmpgIsFetching: summaryPmpgSelected.isFetching!,
    getSummaryPmpgError: summaryPmpgSelected.error!,
    getSummaryPmpgCode: summaryPmpgSelected.code!,
    getSummaryPmpgResult: summaryPmpgSelected.data!,
    getAwardListPmpgIsFetching: awardListPmpgSelected.isFetching!,
    getAwardListPmpgError: awardListPmpgSelected.error!,
    getAwardListPmpgCode: awardListPmpgSelected.code!,
    getAwardListPmpgResult: awardListPmpgSelected.data!,
    highlightEra: pmpgEraSelected.data!,
  }
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IPmpgActionProps => bindActionCreators({
  loader: loaderAction.loadingAction,
  getSummaryPmpg: pmpgAction.getSummaryPmpgAction,
  getAwardListPmpg: pmpgAction.getAwardListPmpgAction,
  clearSummaryPmpg: pmpgAction.getSummaryPmpgCancelAction,
  clearClickRef: userAction.clearClickRef
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(JackpotContainer)