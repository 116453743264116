import Vendor1 from './logo-joker-80x80.png'
import Vendor2 from './logo-sagame-80x80.png'
import Vendor3 from './ae_sexy_title.png'
import Jk from './jk.svg'
import Pg from './pg.svg'
import DreamTech from './dreamTech.svg'
import DreamGaming from './dreamGaming.svg'
import SlotXO from './slotXO.svg'
import AsiaGaming from './asiaGaming.svg'
import W88 from './w88.svg'
import Wm from './wm.svg'
import SLOT_XO_ICON from './slot-xo-icon.png'
import PG_ICON from './pg-icon.png'
import KINGMAKER_ICON from './kingmaker-icon.png'
import DREAMTECH_ICON from './dreamTech.png'
import JOKER_ICON from './joket-icon.png'
import PP_GAMING_ICON from './pp-gaming.png'
import JIKI_GAMING_ICON from './jili_logo.png'
type partnerListIcon = { [key in string]: string }

const venderList = [
    { id: 1, image: Vendor1, alt: 'JOKER' },
    { id: 2, image: Vendor2, alt: 'SAGAME' },
    { id: 3, image: Vendor3, alt: 'AESEXY' },
]

const partnerList: partnerListIcon = {
    joker: Jk,
    pg: Pg,
    slotxo: SlotXO,
    dreamtech: DreamTech,
    dreamgaming: DreamGaming,
    asiagaming: AsiaGaming,
    wmgame: Wm,
    w88: W88,
    ppgame: PP_GAMING_ICON,
    jiligame: JIKI_GAMING_ICON
}

const casinoGameIconList = [
    { image: SLOT_XO_ICON, code: 'SLOTXO' },
    { image: DREAMTECH_ICON, code: 'DREAMTECH' },
    { image: PG_ICON, code: 'PG' },
    { image: KINGMAKER_ICON, code: 'KINGMAKER' },
    { image: JOKER_ICON, code: 'JOKER' },
]

// eslint-disable-next-line import/no-anonymous-default-export
export default { venderList, partnerList, casinoGameIconList }