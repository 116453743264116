// eslint-disable-next-line import/no-anonymous-default-export
export default {
  notFound: { name: '404', path: '*', exactPath: '*', },
  home: { name: 'home', path: '/home', exactPath: '/home', thPath: 'เข้าสู่ระบบ' },
  landing: { name: 'landing', path: '/', exactPath: '/', thPath: 'หน้าแรก' },
  register: { name: 'register', path: '/register', exactPath: '/register', thPath: 'สมัครสมาชิก' },
  registerAffiliate: {
    name: 'register-affiliate',
    path: '/register/:affiliate',
    exactPath: (affiliate: string) => `/register/${affiliate}`,
    thPath: 'สมัครแอฟฟิลิเอท'
  },
  newmain: { name: 'new-main', path: '/new-main', exactPath: '/new-main' },
  main: { name: 'main', path: '/main', exactPath: '/main' },
  deposit: { name: 'deposit', path: '/deposit', exactPath: '/deposit', thPath: 'ฝากเงิน' },
  withdraw: { name: 'withdraw', path: '/withdraw', exactPath: '/withdraw', thPath: 'ถอนเงิน' },
  transaction: { name: 'transaction', path: '/transaction', exactPath: '/transaction', thPath: 'ธุรกรรม' },
  transactionDetail: { name: 'transaction-detail', path: '/transaction/detail', exactPath: '/transaction/detail' },
  lotto: { name: 'lotto', path: '/lotto', exactPath: '/lotto', thPath: 'หวย' },
  lottoChrildren: {
    name: 'lotto-chrildren', path: '/lotto/:type', exactPath: (type: string) => `/lotto/${type}`, thPath: 'หวย'
  },
  lottoMaking: {
    name: 'lotto-making', path: '/lotto/making/:type', exactPath: (type: string) => `/lotto/making/${type}`, thPath: 'หวย'
  },
  lottoHistory: { name: 'lotto-history', path: '/lotto-history', exactPath: 'lotto-history' },
  lottoCheckout: { name: 'lotto-checkout', path: '/checkout/lotto', exactPath: '/checkout/lotto' },
  lottoFavorite: { name: 'lottoFavorite', path: '/lotto-favorite', exactPath: '/lotto-favorite', thPath: 'เลขชุด' },
  lottoOldFavorite: { name: 'lottoOldFavorite', path: '/lotto-old-favorite', exactPath: '/lotto-old-favorite', thPath: 'โพยเก่า' },
  lottoOldFavoriteDetail: { name: 'lottoOldFavoriteDetail', path: '/lotto-old-favorite/detail', exactPath: '/lotto-old-favorite/detail', thPath: 'ปรับโพยเก่า' },
  lottoFavoriteManagement: { name: 'lotto-favorite-management', path: '/lotto-favorite/management', exactPath: '/lotto-favorite/management', thPath: 'จัดการเลขชุด' },
  lottoFavoriteAdd: { name: 'lotto-favorite-management-add', path: '/lotto-favorite/management/add', exactPath: '/lotto-favorite/management/add', thPath: 'เพิ่มเลขชุด' },
  lottoFavoriteEdit: { name: 'lotto-favorite-management-edit', path: '/lotto-favorite/management/edit', exactPath: '/lotto-favorite/management/edit', thPath: 'แก้ไขเลขชุด' },
  creditInfo: { name: 'credit-info', path: '/credit-info', exactPath: '/credit-info', thPath: 'บัญชี' },
  creditInfoReport: { name: 'credit-info-report', path: '/credit-info-report', exactPath: '/credit-info-report', thPath: 'เครดิต' },
  affiliate: { name: 'affiliate', path: '/affiliate', exactPath: '/affiliate', thPath: 'แนะนำสมาชิก' },
  affiliateWithdrawal: {
    name: 'affiliate-withdrawal', path: '/affiliate/withdrawal', exactPath: '/affiliate/withdrawal', thPath: 'ถอนรายได้'
  },
  redirected: { name: 'redirected', path: '/redirected', exactPath: '/redirected' },
  redirectDownload: { name: 'download', path: '/download', exactPath: '/download', thPath: 'แอป มือถือ' },
  contactus: { name: 'contact-us', path: '/contactus', exactPath: '/contactus', thPath: 'ติดต่อ' },
  newsroom: { name: 'newsroom', path: '/newsroom', exactPath: '/newsroom', thPath: 'ข่าวสาร' },
  forgotPassword: { name: 'forgot-password', path: '/forgot-password', exactPath: '/forgot-password', thPath: 'ลืมรหัสผ่าน' },
  qa: { name: 'qa', path: '/qa', exactPath: '/qa', thPath: 'ข้อตกลง' },
  qaType: { name: 'qa-type', path: '/qa/:type', exactPath: (type: TQAType) => `/qa/${type}`, thPath: 'ข้อตกลง' },
  casino: { name: 'casinoECasino', path: '/casino', exactPath: '/casino', thPath: 'คาสิโน' },
  slot: { name: 'slot', path: '/slot', exactPath: '/slot', thPath: 'สล็อต' },
  slotProvider: { name: 'slot-provider', path: '/slot/games/:provider', exactPath: (provider: string) => `/slot/games/${provider}`, thPath: 'สล็อต' },
  games: { name: 'games', path: '/games', exactPath: '/games' },
  lottoResult: { name: 'lotto-result', path: '/lotto/list/result', exactPath: '/lotto/list/result', thPath: 'ผลหวย' },
  jackpot: { name: 'jackpot', path: '/jackpot', exactPath: '/jackpot', thPath: 'กองเงินกองทอง' },
  notification: { name: 'notification', path: '/notification', exactPath: '/notification', thPath: 'การแจ้งเตือน' }
}