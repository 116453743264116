import project from "constants/project";

export const GET_NOTIFICATION_USER_COUNT_REQUEST =
  "GET_NOTIFICATION_USER_COUNT_REQUEST";
export const GET_NOTIFICATION_USER_COUNT_SUCCESS =
  "GET_NOTIFICATION_USER_COUNT_SUCCESS";
export const GET_NOTIFICATION_USER_COUNT_FAILURE =
  "GET_NOTIFICATION_USER_COUNT_FAILURE";
export const GET_NOTIFICATION_USER_COUNT_CANCEL =
  "GET_NOTIFICATION_USER_COUNT_CANCEL";
export const UPDATE_NOTIFICATION_USER_COUNT_REQUEST =
  "UPDATE_NOTIFICATION_USER_COUNT_REQUEST"
export const UPDATE_NOTIFICATION_USER_COUNT_SUCCESS = 
  "UPDATE_NOTIFICATION_USER_COUNT_SUCCESS"

export const initialState: ReducerState<IV1GetNotificationUserCount> = {
  isFetching: false,
  code: 0,
  data: {
    unreadCount: 0,
  },
  error: "",
};

export const endpoint = {
  getNotificationUserCount: `${
    project.environment[project.environmentName].api
  }/v1/notification/user/count`,
};
