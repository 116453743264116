
import emiter from 'configs/emiter'
import Core from './Modal.component'

const event = (eventName: TEvent, modalProps: IModalStateProps) => {
  return emiter.emit<IModalStateProps>(eventName, modalProps)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Core,
  event,
}