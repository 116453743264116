import { Epic } from 'redux-observable'
import {
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'


const updateEraEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.updatePmpgEraAction)),
    map((action) => actions.updatePmpgEraSuccessAction(action.payload))
  )
// eslint-disable-next-line import/no-anonymous-default-export
export default [
    updateEraEpic,
]
