import pages from 'pages'
import route from 'constants/routes'

const routes: IRoutes[] = [
  {
    name: route.redirected.name,
    path: route.redirected.path,
    component: pages.Redirect,
    private: true,
  },
  {
    name: route.redirectDownload.name,
    path: route.redirectDownload.path,
    component: pages.RedirectDownload,
    thPath: route.redirectDownload.thPath
  },
  {
    exact: true,
    name: route.landing.name,
    path: route.landing.path,
    component: pages.Landing,
    thPath: route.landing.thPath
  },
  {
    exact: true,
    name: route.home.name,
    path: route.home.path,
    component: pages.Home,
    thPath: route.home.thPath
  },
  {
    exact: true,
    name: route.register.name,
    path: route.register.path,
    component: pages.Register,
    thPath: route.register.thPath
  },
  {
    exact: true,
    name: route.registerAffiliate.name,
    path: route.registerAffiliate.path,
    component: pages.Register,
    thPath: route.registerAffiliate.thPath
  },
  {
    name: route.main.name,
    path: route.main.path,
    component: pages.Index,
    private: true,
  },
  {
    name: route.deposit.name,
    path: route.deposit.path,
    component: pages.Deposit,
    private: true,
    thPath: route.deposit.thPath
  },
  {
    name: route.withdraw.name,
    path: route.withdraw.path,
    component: pages.Withdraw,
    private: true,
    thPath: route.withdraw.thPath
  },
  {
    exact: true,
    name: route.transaction.name,
    path: route.transaction.path,
    component: pages.TransactionList,
    private: true,
    thPath: route.transaction.thPath
  },
  {
    exact: true,
    name: route.transactionDetail.name,
    path: route.transactionDetail.path,
    component: pages.TransactionDetail,
    private: true,
  },
  {
    exact: true,
    name: route.lotto.name,
    path: route.lotto.path,
    component: pages.LottoList,
    private: true,
    thPath: route.lotto.thPath
  },
  {
    exact: true,
    name: route.lottoChrildren.name,
    path: route.lottoChrildren.path,
    component: pages.LottoListChrildren,
    private: true,
    thPath: route.lottoChrildren.thPath
  },
  {
    exact: true,
    name: route.lottoMaking.name,
    path: route.lottoMaking.path,
    component: pages.LottoMake,
    private: true,
    thPath: route.lottoMaking.thPath
  },
  {
    exact: true,
    name: route.creditInfo.name,
    path: route.creditInfo.path,
    component: pages.CreditInfo,
    private: true,
    thPath: route.creditInfo.thPath
  },
  {
    exact: true,
    name: route.creditInfoReport.name,
    path: route.creditInfoReport.path,
    component: pages.CreditInfoReport,
    private: true,
    thPath: route.creditInfoReport.thPath
  },
  {
    exact: true,
    name: route.affiliate.name,
    path: route.affiliate.path,
    component: pages.Affiliate,
    private: true,
    thPath: route.affiliate.thPath
  },
  {
    exact: true,
    name: route.lottoFavorite.name,
    path: route.lottoFavorite.path,
    component: pages.LottoFavorite,
    private: true,
    thPath: route.lottoFavorite.thPath
  },
  {
    exact: true,
    name: route.lottoOldFavorite.name,
    path: route.lottoOldFavorite.path,
    component: pages.LottoOldFavorite,
    private: true,
    thPath: route.lottoOldFavorite.thPath
  },
  {
    exact: true,
    name: route.lottoOldFavoriteDetail.name,
    path: route.lottoOldFavoriteDetail.path,
    component: pages.LottoOldFavoriteDetail,
    private: true,
    thPath: route.lottoOldFavoriteDetail.thPath
  },
  {
    exact: true,
    name: route.lottoFavoriteManagement.name,
    path: route.lottoFavoriteManagement.path,
    component: pages.LottoFavoriteManagement,
    private: true,
    thPath: route.lottoFavoriteManagement.thPath
  },
  {
    exact: true,
    name: route.lottoFavoriteAdd.name,
    path: route.lottoFavoriteAdd.path,
    component: pages.LottoFavoriteForm,
    private: true,
    thPath: route.lottoFavoriteAdd.thPath
  },
  {
    exact: true,
    name: route.lottoFavoriteEdit.name,
    path: route.lottoFavoriteEdit.path,
    component: pages.LottoFavoriteForm,
    private: true,
    thPath: route.lottoFavoriteEdit.thPath
  },
  {
    exact: true,
    name: route.contactus.name,
    path: route.contactus.path,
    component: pages.ContactUs,
    private: true,
    thPath: route.contactus.thPath
  },
  {
    exact: true,
    name: route.newsroom.name,
    path: route.newsroom.path,
    component: pages.Newsroom,
    private: true,
    thPath: route.newsroom.thPath
  },
  {
    exact: true,
    name: route.forgotPassword.name,
    path: route.forgotPassword.path,
    component: pages.ForgotPassword,
    thPath: route.forgotPassword.thPath
  },
  {
    exact: true,
    name: route.qa.name,
    path: route.qa.path,
    component: pages.QandA,
    private: true,
    thPath: route.qa.thPath
  },
  {
    exact: true,
    name: route.qaType.name,
    path: route.qaType.path,
    component: pages.QandA,
    private: true,
    thPath: route.qaType.thPath
  },
  // {
  //   exact: true,
  //   name: route.casino.name,
  //   path: route.casino.path,
  //   component: pages.Casino,
  //   private: true,
  //   thPath: route.casino.thPath
  // },
  {
    exact: true,
    name: route.slot.name,
    path: route.slot.path,
    component: pages.CasinoGame,
    private: true,
    thPath: route.slot.thPath
  },
  {
    exact: true,
    name: route.casino.name,
    path: route.casino.path,
    component: pages.CasinoOnline,
    private: true,
    thPath: route.casino.thPath
  },
  {
    exact: true,
    name: route.games.name,
    path: route.games.path,
    component: pages.Games,
    private: true,
  },
  {
    exact: true,
    name: route.affiliateWithdrawal.name,
    path: route.affiliateWithdrawal.path,
    component: pages.AffiliateWithdrawal,
    private: true,
    thPath: route.affiliateWithdrawal.thPath
  },
  {
    exact: true,
    name: route.lottoResult.name,
    path: route.lottoResult.path,
    component: pages.LottoResult,
    private: true,
    thPath: route.lottoResult.thPath,
  },
  {
    exact: true,
    name: route.lottoHistory.name,
    path: route.lottoHistory.path,
    component: pages.LottoHistory,
    private: true,
  },
  {
    exact: true,
    name: route.jackpot.name,
    path: route.jackpot.path,
    component: pages.Jackpot,
    private: true,
    thPath: route.jackpot.thPath,
  },
  {
    name: route.notification.name,
    path: route.notification.path,
    component: pages.Notification,
    private: true,
    thPath: route.notification.thPath,
  },
  {
    exact: true,
    name: route.slotProvider.name,
    path: route.slotProvider.path,
    component: pages.CasinoGamePartner,
    thPath: route.slotProvider.thPath,
    private: true,
  },
  {
    name: route.notFound.name,
    path: route.notFound.path,
    component: pages.ComingSoon,
  },
]

export default routes