import "./notificationCard.style.scss";
import React, { FC } from "react";
import {
  GAME_COMPANY,
  LOTTO_FLAG_ALPHA,
  LOTTO_SLUG_NAME,
  LOTTO_SLUG_TO_TYPE,
  REFERENCE,
} from "constants/variables";
import LottoFlags from "assets/images/global/flags/";
import { isEmpty, noop } from "lodash";
import notificationIcons from "assets/images/global/notification";
import moment from "moment";
import { vendorLists } from "assets/images/e-casino";
import partnerList from "assets/images/vendorsIcon";

const defaultProps: INotificationCardProps & IV1GetNotificationUser = {
  isClick: false,
  createdAt: "",
  id: 0,
  isRead: false,
  message: "",
  reference: "",
  type: "",
  updatedAt: "",
  company: "",
  handleOnClick() {
    noop();
  },
};

const NotificationCard: FC<INotificationCardProps & IV1GetNotificationUser> = (
  props
) => {
  const {
    isClick,
    message,
    reference,
    handleOnClick,
    createdAt,
    type,
    company,
  } = props;

  const formatReference = () => {
    let mappedFormattedReference;
    if (
      (reference === null && type !== null) ||
      (reference !== null && type !== null)
    ) {
      mappedFormattedReference = REFERENCE[type] || "";
      return mappedFormattedReference;
    }
    if (reference === null && type === null) {
      mappedFormattedReference = "";
      return mappedFormattedReference;
    }

    if (reference !== null && type === null) {
      if (reference.includes("LOTTER")) {
        if (reference === "LOTTER") {
          mappedFormattedReference = REFERENCE[reference];
          return mappedFormattedReference;
        }

        if (reference.includes("BONUS")) {
          mappedFormattedReference =
            LOTTO_SLUG_NAME[reference.replace("_BONUS", "") as TLottoSlug] ||
            "";
          return mappedFormattedReference;
        }
        mappedFormattedReference =
          LOTTO_SLUG_NAME[reference.replace("_RESULT", "") as TLottoSlug] || "";
        return mappedFormattedReference;
      }

      if (reference.includes("NEWS")) {
        mappedFormattedReference = "ข่าวสาร";
        return mappedFormattedReference;
      }

      if (reference.includes("GAME_CASINO")) {
        const gameCompanyIndex = GAME_COMPANY.findIndex((item) =>
          reference.includes(item)
        );
        mappedFormattedReference =
          REFERENCE["GAME_CASINO_" + GAME_COMPANY[gameCompanyIndex]] || "";
        return mappedFormattedReference;
      }
      mappedFormattedReference = REFERENCE[reference] || "";
      return mappedFormattedReference;
    }

    mappedFormattedReference = "";
    return mappedFormattedReference;
  };

  const referenceIcon = () => {
    let iconSrc;

    if (
      (reference === null && type !== null) ||
      (reference !== null && type !== null)
    ) {
      iconSrc = notificationIcons[type] || "";
      return iconSrc;
    }

    if (reference === null && type === null) {
      iconSrc = "";
      return iconSrc;
    }

    if (reference !== null && type === null) {
      if (reference.includes("NEWS")) {
        iconSrc = notificationIcons["NEWS_WITHOUT_TYPE"];
        return iconSrc;
      }

      if (reference.includes("LOTTER")) {
        if (reference === "LOTTER") {
          iconSrc = notificationIcons["LOTTER"] || "";
          return iconSrc;
        }
        if (reference.includes("BONUS")) {
          iconSrc = notificationIcons["LOTTER_BONUS"] || "";
          return iconSrc;
        }
        iconSrc =
          LottoFlags[
            LOTTO_FLAG_ALPHA[
              LOTTO_SLUG_TO_TYPE[reference.replace("_RESULT", "") as TLottoSlug]
            ]
          ]?.Icon || "";
        return iconSrc;
      }

      if (reference.includes("GAME_CASINO")) {
        if (reference.includes("JOKER") || reference.includes("SLOT_XO")) {
          iconSrc = partnerList.partnerList['joker']
          return iconSrc
        }
        if (reference.includes("PGGAME")) {
          iconSrc = partnerList.partnerList['pg']
          return iconSrc
        }
        if (reference.includes("DT")) {
          iconSrc = partnerList.partnerList['dreamtech']
          return iconSrc
        }

        const selectedVendor = vendorLists.vendorListCircle.find(
          (vendor) => vendor.code === company
        );
        iconSrc = selectedVendor?.image || "";
        return iconSrc;
      }

      iconSrc = notificationIcons[reference] || "";
      return iconSrc;
    }

    iconSrc = "";
    return iconSrc;
  };

  return (
    <div
      className={`notification-card-container ${isClick && "clicked"}`}
      onClick={handleOnClick}
    >
      <div className="d-flex flex-row align-items-center">
        <div className="black-circle">
          {!isEmpty(referenceIcon()) && (
            <img
              src={referenceIcon()}
              className={
                reference?.includes("GAME_CASINO") &&
                ![
                  "GAME_CASINO_PGGAME",
                  "GAME_CASINO_JOKER",
                  "GAME_CASINO_SLOT_XO",
                  "GAME_CASINO_DT",
                ].includes(reference)
                  ? "company-icon"
                  : `reference-icon`
              }
              alt=""
              style={{
                marginBottom: reference === "TRANSACTION" ? 6 : 0,
                marginLeft: reference === "SLOT" ? 4 : 0,
              }}
            />
          )}
        </div>
        <div className="d-flex flex-grow-1 m3-l flex-column">
          <h4
            className={`${!isClick && "black-text"}`}
            style={{ fontFamily: "SukhumvitSet-Bold" }}
          >
            {formatReference()}
          </h4>
          <div
            className={`body-2 ${!isClick && "black-text"}`}
            style={{ fontFamily: "SukhumvitSet-SemiBold" }}
          >
            {message}
          </div>
          <div className="d-flex flex-row justify-content-between m2-t">
            <div className="secondary-text subtitle-2">
              {moment(createdAt).subtract(7, "h").toNow(true)}
            </div>
            <div className="secondary-text subtitle-2">คลิกเพื่อตรวจสอบ</div>
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationCard.defaultProps = defaultProps;

export default NotificationCard;
