import summary from './summary/epics'
import member from './member/epics'
import withdrawal from './withdrawal/epics'
import click from './click/epics'
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  ...summary,
  ...member,
  ...withdrawal,
  ...click,
]
