import { Epic, StateObservable } from 'redux-observable'
import {
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import actions from './actions'


const socketErrorEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store: StateObservable<RootReducers>) =>
  action$.pipe(
    filter(isActionOf(actions.connectSocketErrorAction)),
    map((actions.logSocket))
  )
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  socketErrorEpic,
]
