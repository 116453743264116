const initialSet: { title: string } = {
  title: '',
}

const initialNumber: IFavoriteNumberRequest = {
  type: '',
  number: '',
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  set: initialSet,
  number: initialNumber,
}