import React, { FunctionComponent } from 'react'
import useLayout from '../../hooks/useLayout'
import './textRunning.style.scss'

const TextRunning: FunctionComponent<ITextRunningProps> = (props) => {
  const { isRenderTextRunner } = useLayout()

  const { text, onClick } = props

  if (!isRenderTextRunner) { return <></> }

  return (
    <div className="text-running-container" onClick={onClick}>
      <div className="text-running">
        <p>{text}</p>
      </div>
    </div>
  )
}

export default TextRunning