import { Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  mergeMap,
  filter,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import userActions from 'reduxs/user/actions'
import actions from './actions'
import jwt from 'jsonwebtoken'
import { get } from 'lodash'
import { transformer } from 'utils'
import moment from 'moment'
import project from 'constants/project'

const verifyEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store$, dependencies) =>
  action$.pipe(
    filter(isActionOf(actions.verify)),
    mergeMap(_ => {
      const token = get(store$, 'value.ludens.user.token.accessToken', '')
      try {
        const result: IJWTVerify = transformer.camelcaseTransform(jwt.verify(
          token,
          project.environment[project.environmentName].secretKey)
        ) as IJWTVerify
        if (moment().unix() > result.exp) {
          return of(userActions.setUserAction({ token: '' }))
        }
        return of(userActions.setUserAction({ token: token }))
      } catch (error) {
        return of(userActions.setUserAction({ token: '' }))
      }
    })
  )
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  verifyEpic,
]
