import walletAction from './wallet/actions'
import tokenAction from './token/actions'
import meAction from './me/actions'
import rememberAction from './remember/actions'
import countAction from './count/actions'
import codeCreditReportAction from './codeCreditReport/actions'
import clickRefAction from './clickRef/actions'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...walletAction,
  ...tokenAction,
  ...meAction,
  ...rememberAction,
  ...countAction,
  ...codeCreditReportAction,
  ...clickRefAction
}
