import { get } from 'lodash'
import prject from 'constants/project'
import { initialState as displayConfigIntialState } from './display/constants'
import { initialState as bitLimitConfigIntialState } from './betLimit/constants'

const displayConfig = (state: RootReducers): ReducerState<IDisplayConfig> =>
    get(state, `${prject.name}.config.display`, displayConfigIntialState)

const betLimitConfig = (state: RootReducers): ReducerState<IBetLimit> =>
    get(state, `${prject.name}.config.betLimit`, bitLimitConfigIntialState)


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    displayConfig,
    betLimitConfig,
}