import { combineReducers } from 'redux'
import wallet from './wallet/reducers'
import token from './token/reducers'
import me from './me/reducers'
import remember from './remember/reducers'
import count from './count/reducers'
import codeCreditReport from './codeCreditReport/reducers'
import clickRef from './clickRef/reducers'

export default combineReducers({
  wallet,
  token,
  me,
  remember,
  count,
  codeCreditReport,
  clickRef
})
