import React, { FC } from 'react'
import './fireEffect.styles.scss'

const defaultProps: IFireEffect = {
  burnCount: 40,
}

type DefaultProps = Readonly<typeof defaultProps>

const FireEffectComponent: FC<IFireEffect & DefaultProps> = (props) => {
  const { burnCount } = props
  return (
    <div id="ui">
      <div className="fire">
        {Array.from({ length: burnCount }, (_, i) => (
          <div className="burn heat" key={`heat-${i}`} />
        ))}
        {Array.from({ length: burnCount }, (_, i) => (
          <div className="burn" key={`burn-${i}`} />
        ))}
      </div>
    </div>
  )
}
FireEffectComponent.defaultProps = defaultProps

export default FireEffectComponent
