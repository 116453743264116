import list from './list/epics'
import yeege from './yeege/epics'
import bet from './bet/epics'
import me from './me/list/epics'
import favorite from './favorite/epics'
import game from './game/epics'
import oldFavorite from './oldFavorite/epics'
import ban from './ban/epics'
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  ...game,
  ...list,
  ...yeege,
  ...bet,
  ...me,
  ...favorite,
  ...oldFavorite,
  ...ban,
]
