import { getType } from "typesafe-actions";
import { RootAction } from "typings/reduxs/Actions";
import { initialState } from "./constants";
import actions from "./actions";

const postNotificationClickReducer = (
  state: ReducerState<{}> = initialState,
  action: RootAction
): ReducerState<any> => {
  switch (action.type) {
    case getType(actions.postNotificationClickAction):
      return {
        ...state,
        isFetching: true,
      };
    case getType(actions.postNotificationClickSuccessAction):
      return {
        ...state,
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.status,
      };

    case getType(actions.postNotificationClickFailureAction):
      return {
        ...state,
        isFetching: false,
        error: action.payload.response?.data.devMessage,
        code: action.payload.response?.status,
      };
    default:
      return state;
  }
};

export default postNotificationClickReducer;
