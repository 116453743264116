
import project from 'constants/project'

export const GET_SUMMARY_PMPG_ERA_REQUEST = 'GET_SUMMARY_PMPG_ERA_REQUEST'
export const GET_SUMMARY_PMPG_ERA_SUCCESS = 'GET_SUMMARY_PMPG_ERA_SUCCESS'
export const GET_SUMMARY_PMPG_ERA_FAILURE = 'GET_SUMMARY_PMPG_ERA_FAILURE'
export const GET_SUMMARY_PMPG_ERA_CANCEL= 'GET_SUMMARY_PMPG_ERA_CANCEL'

export const initialState: ReducerState<IPmpgSummary> = {
  isFetching: false,
  code: 0,
  data: {
    tinTotal: 0,
    tinApproveLimit: 0,
    isTinGive: false,
    bronzeTotal: 0,
    bronzeApproveLimit: 0,
    isBronzeGive: false,
    silverTotal: 0,
    silverApproveLimit: 0,
    isSilverGive: false,
    goldTotal: 0,
    goldApproveLimit: 0,
    isGoldGive: false,
  },
  error: '',
}


export const endpoint = {
  getSummaryPmpg: `${project.environment[project.environmentName].api}/pmpg/summary`,
}