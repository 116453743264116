import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import lotterAction from 'reduxs/lotto/actions'
import loaderAction from 'reduxs/loader/actions'
import lottoSelector from 'reduxs/lotto/selectors'
import LottoOldFavoiteComponent from './LottoOldFavorite.component'

const mapStateToProps = (state: RootReducers): ILottoOldFavoriteComponentProps => {
    const betPullDetail = lottoSelector.betLottoPullDetail(state)
    return ({
        getBetLotteryPullDetailFetching: betPullDetail.isFetching!,
        getBetLotteryPullDetailCode: betPullDetail.code!,
        getBetLotteryPullDetailError: betPullDetail.error!,
        betLotteryPullDetail: betPullDetail.data!,
        betLotteryPullDetailPagination: betPullDetail.pagination!,
      })
}

const mapDispatchToProps = (dispatch: Dispatch<RootAction>): ILottoOldFavoriteComponentActionProps => bindActionCreators({
    getBetLotteryPullDetail: lotterAction.getLotteryPullDetailAction,
    loader: loaderAction.loadingAction,
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LottoOldFavoiteComponent)