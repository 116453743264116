import { createAction } from 'typesafe-actions'
import {
  GET_GAME_BENEFIT_REQUEST,
  GET_GAME_BENEFIT_SUCCESS,
  GET_GAME_BENEFIT_FAILURE,
  GET_GAME_BENEFIT_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getGameBenefitRequestAction = createAction(
  GET_GAME_BENEFIT_REQUEST,
  resolve => (data: ILotterBenefitRequest) => resolve(data))

const getGameBenefitSuccessAction = createAction(
  GET_GAME_BENEFIT_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IResponseBetLotteryBenefit>>) => resolve(data))

const getGameBenefitFailureAction = createAction(
  GET_GAME_BENEFIT_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getGameBenefitCancelAction = createAction(GET_GAME_BENEFIT_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getGameBenefitRequestAction,
  getGameBenefitSuccessAction,
  getGameBenefitFailureAction,
  getGameBenefitCancelAction,
}