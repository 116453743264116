import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchGetBetLimit } from './services'
import actions from './actions'

const getBetLimitEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.getBetLimitAction)),
    exhaustMap(_ =>
      from(fetchGetBetLimit())
        .pipe(
          map(actions.getBetLimitSuccessAction),
          catchError(error => of(actions.getBetLimitFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getBetLimitCancelAction))))
        ),
    )
  )
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  getBetLimitEpic,
]
