import { Home } from './Home'
import { TransactionDetail } from './TransactionDetail'
import { TransactionList } from './TransactionList'
import { CreditInfo } from './CreditInfo'
import { CreditInfoReport } from './CreditInfoReport'
import { ComingSoon } from './ComingSoon'
import { Register } from './Register'
import { Withdraw } from './Withdraw'
import { Deposit } from './Deposit'
import { Main } from './Main'
import { LottoList } from './LottoList'
import { LottoListChrildren } from './LottoListChrildren'
import { LottoMake } from './LottoMake'
import { Affiliate } from './Affiliate'
import { LottoFavorite } from './LottoFavorite'
import { LottoOldFavorite } from './LottoOldFavorite'
import { LottoOldFavoriteDetail } from './LottoOldFavoriteDetail'
import { ContactUs } from './ContactUs'
import { Newsroom } from './Newsroom'
import { ForgotPassword } from './ForgotPassword'
import { QandA } from './QandA'
import { Casino } from './Casino'
import { Games } from './Games'
import { CasinoGame } from './CasinoGame'
import { LottoFavoriteManagement } from './LottoFavoriteManagement'
import { LottoFavoriteForm } from './LottoFavoriteForm'
import { CasinoOnline } from './CasinoOnline'
import { AffiliateWithdrawal } from './AffiliateWithdrawal'
import { LottoResult } from './LottoResult'
import { LottoHistory } from './LottoHistory'
import { Jackpot } from './Jackpot'
import { Redirect } from './Redirect'
import { RedirectDownload } from './RedirectDownload'
import { Landing } from './Landing'
import { Index } from './Index/index'
import { Notification } from './Notification'
import { CasinoGamePartner } from './CasinoGamePartner'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Home,
  Main,
  Games,
  Index,
  QandA,
  Casino,
  Landing,
  Deposit,
  Jackpot,
  Newsroom,
  Withdraw,
  Redirect,
  Register,
  ContactUs,
  Affiliate,
  LottoList,
  LottoMake,
  CasinoGame,
  CreditInfo,
  ComingSoon,
  LottoResult,
  LottoHistory,
  Notification,
  CasinoOnline,
  LottoFavorite,
  ForgotPassword,
  LottoOldFavorite,
  TransactionList,
  CreditInfoReport,
  RedirectDownload,
  TransactionDetail,
  CasinoGamePartner,
  LottoFavoriteForm,
  LottoListChrildren,
  AffiliateWithdrawal,
  LottoOldFavoriteDetail,
  LottoFavoriteManagement,
}