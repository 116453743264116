import AffiliateIcon from "./affiliate.svg";
import TransactionIcon from "./transaction.svg";
import GameCasinoIcon from "./game-casino.svg";
import CustomerSupportIcon from "./customer-support.svg";
import LineIcon from './line.svg'
import StarIcon from './star.svg'
import DebitCardIcon from './debit-card.svg'
import MegaPhoneIcon from './megaphone.svg'
import FerrisWheelIcon from './ferris-wheel.svg'
import JackPotMachineIcon from './jackpot-machine.svg'

const notificationIcons: { [key in string]: any } = {
  SLOT: JackPotMachineIcon,
  LOTTER: FerrisWheelIcon,
  AFFILIATE: AffiliateIcon,
  CASINO: GameCasinoIcon,
  TRANSACTION: TransactionIcon,
  GAME_CASINO: GameCasinoIcon,
  GAME_CASINO_AE_SEXY: GameCasinoIcon,
  GAME_CASINO_JOKER: GameCasinoIcon,
  GAME_CASINO_TLBGAME: GameCasinoIcon,
  GAME_CASINO_SAGAME: GameCasinoIcon,
  GAME_CASINO_DREAMTECH: GameCasinoIcon,
  GAME_CASINO_ALLBET: GameCasinoIcon,
  GAME_CASINO_WMGAME: GameCasinoIcon,
  GAME_CASINO_PG: GameCasinoIcon,
  GAME_CASINO_DREAMGAMING: GameCasinoIcon,
  GAME_CASINO_W88: GameCasinoIcon,
  NEWS_WITHOUT_TYPE: MegaPhoneIcon,
  NEWS: {
    INFO_BROADCAST_ADMIN: CustomerSupportIcon,
    INFO_BROADCAST_TC: CustomerSupportIcon,
    INFO_DIRECT_TC: CustomerSupportIcon,
    INFO_DIRECT_ADMIN: CustomerSupportIcon,
  },
  INFO_BROADCAST_ADMIN: CustomerSupportIcon,
  INFO_BROADCAST_TC: CustomerSupportIcon,
  INFO_DIRECT_TC: CustomerSupportIcon,
  INFO_DIRECT_ADMIN: CustomerSupportIcon,
  LINE: LineIcon,
  INFO: StarIcon,
  BET_HISTORY: DebitCardIcon,
  LOTTER_BONUS: StarIcon,
};

export default notificationIcons;
