import {
  POST_NOTIFICATION_CLICK_CANCEL,
  POST_NOTIFICATION_CLICK_FAILURE,
  POST_NOTIFICATION_CLICK_REQUEST,
  POST_NOTIFICATION_CLICK_SUCCESS,
} from "./constants";
import { createAction } from "typesafe-actions";
import { AxiosResponse, AxiosError } from "axios";

const postNotificationClickAction = createAction(
  POST_NOTIFICATION_CLICK_REQUEST,
  (resolve) => (data: IV1PostNotificationUserClick) => resolve(data)
);

const postNotificationClickSuccessAction = createAction(
  POST_NOTIFICATION_CLICK_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<{}>>) => resolve(data)
);

const postNotificationClickFailureAction = createAction(
  POST_NOTIFICATION_CLICK_FAILURE,
  (resolve) => (error: AxiosError<APIResponse>) => resolve(error)
);

const postNotificationClickCancelAction = createAction(
  POST_NOTIFICATION_CLICK_CANCEL
);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postNotificationClickAction,
  postNotificationClickSuccessAction,
  postNotificationClickFailureAction,
  postNotificationClickCancelAction,
};
