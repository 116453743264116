import React, { Component, createRef, RefObject } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  ErrorModal,
  SuccessModal,
  ALink,
  InfoModal,
  Button,
} from 'components'
import moment from 'moment-timezone'
import { number, date, interactive, transformer } from 'utils'
import colors from 'constants/colors'
import { responseCode } from 'constants/response'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LottoFlags from 'assets/images/global/flags'
import {
  faQuestionCircle,
  faChevronRight,
  faStopwatch,
  faBell,
} from '@fortawesome/free-solid-svg-icons'
import {
  get,
  map,
  sum,
  uniq,
  noop,
  find,
  split,
  values,
  isArray,
  isEmpty,
  reverse,
  isEqual,
  clone,
  merge,
} from 'lodash'
import {
  MakingLotto,
  MakingGame,
  PlayedUser,
  BetResult,
  Summary,
  GameHistory,
  Confirmation,
} from './components'
import { LOTTO_SLUG_NAME, LOTTO_SLUG_TO_QA_TYPE, LOTTO_GAME_TYPES, LOTTO_SLUG_TO_TYPE, LOTTO_FLAG_ALPHA } from 'constants/variables'
import routes from 'constants/routes'
import './lottoMake.style.scss'
import LogoThailandBet from 'assets/images/logo/logothailandbet04.png'
import leftArrow from 'assets/images/global/close/left-arrow.png'
import { NumberPanel } from './components/NumberPanel'
import { WinNumber } from './components/WinNumber'
import { LottoOldFavorite } from './components/LottoOldFavorite'
import { LottoFavorite } from './components/LottoFavorite'

const constants = {
  ok: 'ตกลง',
  numsumLabel: 'ยิงเลข',
  makeLabel: 'แทงหวย',
  numberList: (length: number) => `รายการแทง (${length})`,
  yeegeLabel: (round: string) => `รอบที่ ${round}`,
  roundLabel: (round: string) => `งวดที่ ${round}`,
  back: 'กลับ',
  qa: 'กติกาการเล่น',
  cannotBet: 'ไม่สามารถแทงได้',
  betSuccess: 'คุณได้ทำรายการเสร็จสมบูรณ์',
  makingGameLabel: 'ผลรวม (ยิงเลข)',
  timeups: 'หมดเวลา',
  onProcessing: 'กำลังประมวลผล...',
  openedOn: 'เปิดแทงใน',
  openedOnFull: 'หวยจะเปิดแทงใน',
  notBet: 'ไม่สามารถแทง เลขห้ามแทงได้',
  understand:'เข้าใจ',
  maxBet:'*เดิมพันสูงสุดครั้งละ 1,000 บาท ต่อเลข*',
  historyOld: 'ดึงโพยเก่า',
  betGeneralTap:["กดเลขเอง","จากแผง","วินเลข"],
  yeegeGame:"ใส่ตัวเลขสำหรับการยิงเลขจำนวน 5 หลัก"
}


type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IMakingLottoProps & IMakingLottoActionProps = {
  getUser() { noop() },
  getLottoGame() { noop() },
  loader() { noop() },
  makingBetLotto() { noop() },
  getYeegeSum() { noop() },
  listenYeegeSum() { noop() },
  unlistenYeegeSum() { noop() },
  playYeege() { noop() },
  getPlayedYeegeList() { noop() },
  listenPlayedYeegeList() { noop() },
  unlistenPlayedYeegeList() { noop() },
  getBetResult() { noop() },
  getNumberBan() { noop() },
  clearNumberBan() { noop() },
  clearBetResult() { noop() },
  getBetRate() { noop() },
  getBetNumberRate() { noop() },
  getYeegeGameHistory() { noop() },
  clearYeegeGameHistory() { noop() },
  getbetLimitConf() { noop() },
  postBetPullSave() { noop() },

  getOldFavoriteLottoList() { noop() },
  clearOldFavoriteLottoList() {noop()},
  lottoOldFavoriteList:[],
  getOldFavoriteLottoListIsFetching: false,
  getOldFavoriteLottoListCode: 0,
  getOldFavoriteLottoListError: '',

  getNumberBanIsFetching: false,
  getNumberBanCode: 0,
  getNumberBanError: '',
  numberBan: [],

  makingBetLottoCode: 0,
  makingBetLottoError: '',
  makingBetLottoIsFetching: false,
  makingBetLottoResult: [],
  playYeegeCode: 0,
  playYeegeError: '',
  playYeegeIsFetching: false,
  playYeegeResult: {},
  getYeegeSumIsFetching: false!,
  getYeegeSumError: '',
  getYeegeSumCode: 0,
  yeegeSum: '0',
  getPlayedYeegeListIsFetching: false,
  getPlayedYeegeListError: '',
  getPlayedYeegeListCode: 0,
  playedYeegeList: [],
  getBetResultIsFetching: false,
  getBetResultError: '',
  getBetResultCode: 0,
  betResults: [],
  betRates: [],

  getLottoGameIsFetching: false,
  getLottoGameCode: 0,
  getLottoGameError: '',
  lottoGame: {
    id: 0,
    round: '',
    endTime: '',
    startTime: '',
    createdAt: '',
    status: 'UNKNOWN',
  },
  betRateNumbers: [],
  getBetNumberRateCode: 0,
  getBetNumberRateError: '',
  getBetNumberRateIsFetching: false,
  getYeegeGameHistoryIsFetching: false,
  getYeegeGameHistoryCode: 0,
  getYeegeGameHistoryError: '',
  yeegeGameHistory: [],
  wallet: { money: 0 },
  getBetLimitIsFetching: false,
  getBetLimitCode: 0,
  getBetLimitError: '',
  betLimitConf: {
    betLimit: 0,
  },
  postBetLotteryPullSaveIsFetching: false,
  postBetLotteryPullSaveCode: 0,
  postBetLotteryPullSaveError: '',
  betLotteryPullSave: {
    betLotteryHistoryId: 0,
    betLotteryHistoryTrackId: [],
  },
}

const DEFAULT_GAME_VALUE = '1'
const LAO_SULT_FIXED_GAME_VALUE = '120'

const windowHeight = window.innerHeight

class LottoMakeContainer extends Component<
  IMakingLottoProps & IMakingLottoActionProps & DefaultProps
  & RouteComponentProps<{ type: TLottoSlug }, any, IMakingLottoRouteProps>,
  IMakingLottoState
> {

  static defaultProps = defaultProps
  lottoMakeContainerRef: RefObject<HTMLDivElement> = createRef()
  summaryContainerRef: RefObject<HTMLDivElement> = createRef()

  intervalId: NodeJS.Timeout | null = null

  state: IMakingLottoState = {
    activeModeSwitch: 'LOTTO',
    numberList: [],
    temporaryNumberList: {
      filter: [],
      adding: [],
    },
    defaultGameValue: '1',
    remainingTime: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    lottoStatus: 'UNKNOWN',
    onLottoProcessing: false,
    keyLocation: '',
    statePage : 'MENU',
    nodeStatePage : 'MENU',
    betGeneralTap : 0,
    openListLotto:false,
    isCollapseGameHistory:false
  }

  componentDidMount() {
    this.props.clearNumberBan();
    if (this.props.match.params.type !== "LOTTER_YEGEE") {
      this.props.getNumberBan(this.props.match.params.type)
    }
    this.props.getbetLimitConf()
    this.handleScrollToTop()
    const locationState: IMakingLottoRouteProps = this.props.location.state
    if (!isEmpty(get(this.props.location, 'state.selectedLottoGame', {})) && !isEmpty(get(this.props.match, 'params.type', ''))) {
      this.props.loader(true)
      const game = locationState.selectedLottoGame
      const gameDate = date.calibratingTime(game.endTime).format('DDMMYYYY')
      const gameRound = number.padNumber(locationState.selectedLottoGame.round, 3)

      const slugName = this.props.match.params.type

      const queryData: APIPaginationQuery & IBetLotteryPullListRequest = {
        limit: 10,
        page: 1,
        lottery_type:slugName,
      }

      this.props.getOldFavoriteLottoList(queryData,true)
      this.setState({
        defaultGameValue: LAO_SULT_FIXED_GAME_VALUE,
      })
      this.props.getLottoGame(slugName, gameDate, gameRound)
      this.props.getBetRate({ type: slugName })
      if (isArray(locationState.betList) && slugName !== 'LOTTER_YEGEE') {
        const numbersQuery = map(locationState.betList, (numb) => ({
          code: slugName,
          number: numb.number,
          type: numb.type,
        }))
        this.props.getBetNumberRate(numbersQuery)
      }
      this.setState({
        numberList: this.props.history.action === 'REPLACE' || this.props.history.action === 'POP'
          ? locationState.betList || []
          : [],
        defaultGameValue: (slugName === 'LOTTER_LAO_SUITE' ? LAO_SULT_FIXED_GAME_VALUE : DEFAULT_GAME_VALUE),
      })
    } else {
      return this.props.history.goBack()
    }
  }

  componentDidUpdate(prevProps: IMakingLottoProps) {
    // On get lotto game
    if (prevProps.getLottoGameIsFetching !== this.props.getLottoGameIsFetching
      && !this.props.getLottoGameIsFetching) {
      if (this.props.getLottoGameCode === responseCode.OK) {
        const slugName = this.props.match.params.type
        this.setState({ lottoStatus: this.props.lottoGame.status }, () => {

          const currentUnixTime = moment().local().unix()
          const startUnixTime = date.calibratingTime(this.props.lottoGame.startTime).unix()

          const expire = (slugName === 'LOTTER_YEGEE')
            ? this.props.lottoGame.endTime
            : (currentUnixTime > startUnixTime)
              ? this.props.lottoGame.endTime
              : this.props.lottoGame.startTime

          const status: TGameStatus = (this.props.lottoGame.status === 'OPEN' && slugName !== 'LOTTER_YEGEE')
            ? (currentUnixTime > startUnixTime)
              ? 'OPEN'
              : 'WAIT'
            : this.props.lottoGame.status

          if (status === 'OPEN' || status === 'WAIT') {
            this.props.loader(false)
            const momentEndAt = date.calibratingTime(expire).clone()
            const duration = moment.duration(momentEndAt.diff(moment().local()))
            const hours = duration.hours()
            const minutes = duration.minutes()
            const seconds = duration.seconds()
            if (hours > 0 || minutes > 0 || seconds > 0) {
              this.countingdown(expire)
              if (slugName === 'LOTTER_YEGEE') {
                const lottoGame = this.props.lottoGame
                const gameDate = date.calibratingTime(lottoGame.endTime).format('DDMMYYYY')
                const gameRound = number.padNumber(lottoGame.round, 3)
                const gameQuery = { date: gameDate, round: gameRound }
                this.props.getYeegeSum(gameQuery)
                this.props.getPlayedYeegeList(gameQuery)
                this.props.getYeegeGameHistory({ round: gameRound, day: '5' })
              }
              this.setState({ lottoStatus: status })
            } else {
              this.setState({ lottoStatus: 'CLOSE' }, () => {
                Confirmation.hide()
                this.handleGetBetResult()
              })
            }
          } else {
            this.handleGetBetResult()
          }
        })
      } else {
        this.props.history.goBack()
      }
    }

    if (prevProps.makingBetLottoIsFetching !== this.props.makingBetLottoIsFetching
      && !this.props.makingBetLottoIsFetching) {
      this.props.loader(false)
      if (this.props.makingBetLottoCode === responseCode.OK) {
        this.props.getUser()
        const slugName = this.props.match.params.type
        const betId = map(this.props.makingBetLottoResult, 'id')
        this.props.postBetPullSave({ code: slugName, bet_transaction_id_list: betId })
        SuccessModal.show({
          action: () => {
            this.setState({ numberList: [] }, () => {
              SuccessModal.hide()
              Confirmation.hide()
              const locationState: IMakingLottoRouteProps = this.props.location.state
              const game = locationState.selectedLottoGame
              if (slugName === 'LOTTER_YEGEE') {
                this.props.history.push(routes.lottoMaking.exactPath(this.props.match.params.type),{ selectedLottoGame: game })
              } else {
                setTimeout(() => {
                  this.handleScrollToTop()
                }, 512)
                const locationState: IMakingLottoRouteProps = this.props.location.state
                const game = locationState.selectedLottoGame
                this.props.history.replace(
                  routes.lottoMaking.exactPath(this.props.match.params.type), { selectedLottoGame: game })
              }
            })
          },
          actionText: constants.ok,
          description: constants.betSuccess,
        })
      } else if (this.props.makingBetLottoCode === responseCode.BAD_REQUEST) {
        ErrorModal.show({
          action: () => ErrorModal.hide(),
          actionText: constants.ok,
          title: constants.cannotBet,
          description: this.props.makingBetLottoError,
        })
      } else if (this.props.makingBetLottoCode === responseCode.UNAUTHORIZED) {
        ErrorModal.show({
          action: () => ErrorModal.hide(),
          actionText: constants.ok,
          title: constants.cannotBet,
          description: this.props.makingBetLottoError,
        })
      }
    }

    // Result until time is up
    if (prevProps.getBetResultIsFetching !== this.props.getBetResultIsFetching
      && !this.props.getBetResultIsFetching) {
      if (this.intervalId !== null) {
        clearInterval(this.intervalId)
      }
      if (this.props.betResults.length === 0) {
        const lottoGame = this.props.lottoGame
        const gameDate = date.calibratingTime(lottoGame.endTime).format('DDMMYYYY')
        const gameRound = number.padNumber(lottoGame.round, 3)
        const slugName = this.props.match.params.type
        this.intervalId = setInterval(() => this.props.getBetResult({
          date: gameDate,
          round: gameRound,
          type: slugName,
        }), 120000)
        this.setState({ onLottoProcessing: true })
      }
      else {
        this.setState({ onLottoProcessing: false })
      }

      this.props.loader(false)
    }

    // BEGIN: YEEGE GAME
    if (prevProps.playYeegeIsFetching !== this.props.playYeegeIsFetching
      && !this.props.playYeegeIsFetching) {
      this.props.loader(false)
      if (this.props.playYeegeCode !== responseCode.OK) {
        ErrorModal.show({
          action: () => ErrorModal.hide(),
          actionText: constants.ok,
          title: constants.cannotBet,
          description: this.props.makingBetLottoError,
        })
      }else{
        const slugName = this.props.match.params.type
        if (slugName === 'LOTTER_YEGEE') {
          const lottoGame = this.props.lottoGame
          const gameDate = date.calibratingTime(lottoGame.endTime).format('DDMMYYYY')
          const gameRound = number.padNumber(lottoGame.round, 3)
          const gameQuery = { date: gameDate, round: gameRound }
          this.props.getYeegeSum(gameQuery)
          this.props.getPlayedYeegeList(gameQuery)
          this.props.getYeegeGameHistory({ round: gameRound, day: '5' })
        }
      }
    }

    if (prevProps.getYeegeSumIsFetching !== this.props.getYeegeSumIsFetching
      && !this.props.getYeegeSumIsFetching) {
      if (this.props.lottoGame.status === 'OPEN') {
        const lottoGame = this.props.lottoGame
        const gameDate = date.calibratingTime(lottoGame.endTime).format('DDMMYYYY')
        const gameRound = number.padNumber(lottoGame.round, 3)
        this.props.listenYeegeSum({ date: gameDate, round: gameRound })
      }
    }

    if (prevProps.getPlayedYeegeListIsFetching !== this.props.getPlayedYeegeListIsFetching
      && !this.props.getPlayedYeegeListIsFetching) {
      if (this.props.lottoGame.status === 'OPEN') {
        const lottoGame = this.props.lottoGame
        const gameDate = date.calibratingTime(lottoGame.endTime).format('DDMMYYYY')
        const gameRound = number.padNumber(lottoGame.round, 3)
        this.props.listenPlayedYeegeList({ date: gameDate, round: gameRound })
      }
      // END: YEEGE GAME
    }

    // Get bet number rate
    if (prevProps.getBetNumberRateIsFetching !== this.props.getBetNumberRateIsFetching
      && !this.props.getBetNumberRateIsFetching) {
      this.props.loader(false)
      
      if (this.props.getBetNumberRateCode === responseCode.OK) {
        // const responsedNumberRate = this.props.betRateNumbers
        const locationState: IMakingLottoRouteProps = this.props.location.state
        let {
          adding,
          // filter,
        } = this.state.temporaryNumberList
        let historyMakeLotto = clone(get(locationState,'betList',[]))
        let lottoBetList
        let oldAdding = adding

        const checkBanNumber=(listNumber:ILottoNumber[])=>
          listNumber.filter(item => {
            const isFindBanNumber = (element:any) => {
              if (element === item.number) {
                InfoModal.show({
                  action: InfoModal.hide,
                  title: constants.notBet,
                  actionText: constants.understand,
                  description: this.textNumberBan(),
                },'md')
              }
              return element === item.number
            }
            return this.props.numberBan.findIndex(isFindBanNumber) < 0
          });
          adding = checkBanNumber(adding)
          
          if (oldAdding.length !== 0 || this.props.history.action !== 'REPLACE') {
            lottoBetList = adding
          }
          else {
            historyMakeLotto = checkBanNumber(historyMakeLotto)
            lottoBetList = merge(historyMakeLotto, adding)
          }
        const numberlistWithRate = this.numberlistWithRate(lottoBetList)
        // const numberlistWithRate = map<ILottoNumber, ILottoNumber>(adding, (numberNonRate) => {
          //   const findRate = find<IBetNumberRateRequest & { rate: string }>
          //     (responsedNumberRate, { 'number': numberNonRate.number, 'type': numberNonRate.type })
          //   const rate = get(findRate, 'rate', '0')
          //   return { ...numberNonRate, rate }
          // })
          
          this.setState({
            numberList: [
              ...this.state.numberList,
              ...numberlistWithRate,
            ],
          }, () => this.props.loader(false))
      } else {
        this.props.loader(false)
        // TODO: Error handler
      }
    }

    if (prevProps.postBetLotteryPullSaveIsFetching !== this.props.postBetLotteryPullSaveIsFetching
      && !this.props.postBetLotteryPullSaveIsFetching) {
        this.props.loader(false)
      }

    // getOldFavorite
    if (prevProps.getOldFavoriteLottoListIsFetching !== this.props.getOldFavoriteLottoListIsFetching
      && !this.props.getOldFavoriteLottoListIsFetching) {
      this.props.loader(false)
      if (this.props.getOldFavoriteLottoListCode !== responseCode.OK
        && this.props.getOldFavoriteLottoListCode !== responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: () => ErrorModal.hide,
          description: this.props.getOldFavoriteLottoListError,
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.loader(false)
    this.clearLocalInterval()
    this.props.clearBetResult()
    const slugName = this.props.match.params.type
    if (slugName === 'LOTTER_YEGEE') {
      const lottoGame = this.props.lottoGame
      const gameDate = date.calibratingTime(lottoGame.endTime).format('DDMMYYYY')
      const gameRound = number.padNumber(lottoGame.round, 3)
      const gameQuery = { date: gameDate, round: gameRound }
      this.props.unlistenYeegeSum(gameQuery)
      this.props.unlistenPlayedYeegeList(gameQuery)
      this.props.clearYeegeGameHistory()
    }
    this.props.clearOldFavoriteLottoList()
  }

  textNumberBan =()=> `ปิดรับเลข: ${this.props.numberBan.map((item)=> ` ${item}`)}`

  checkNumberBan=(number:string)=>{
    const isNumber = (element:any) => element === number;
    return this.props.numberBan.findIndex(isNumber) < 0
  }

  clearLocalInterval = () => {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId)
    }
  }

  numberlistWithRate = (adding: ILottoNumber[]) => {
    const responsedNumberRate = this.props.betRateNumbers
    const numberlistWithRate = map<ILottoNumber, ILottoNumber>(adding, (numberNonRate) => {
      const findRate = find<IBetNumberRateRequest & { rate: string }>
        (responsedNumberRate, { 'number': numberNonRate.number, 'type': numberNonRate.type })
      const rate = get(findRate, 'rate', '0')
      return { ...numberNonRate, rate }
    })
    return numberlistWithRate
  }

  handleGetBetResult = () => {
    this.props.loader(true)
    const lottoGame = this.props.lottoGame
    const gameDate = date.calibratingTime(lottoGame.endTime).format('DDMMYYYY')
    const gameRound = number.padNumber(lottoGame.round, 3)
    const slugName = this.props.match.params.type
    this.props.getBetResult({
      date: gameDate,
      round: gameRound,
      type: slugName,
    })
    if (slugName === 'LOTTER_YEGEE') {
      const gameQuery = { date: gameDate, round: gameRound }
      this.props.getYeegeSum(gameQuery)
      this.props.getPlayedYeegeList(gameQuery)
      this.props.getYeegeGameHistory({ round: gameRound, day: '5' })
    }
  }

  countingdown = (endingTime: string) => {
    this.clearLocalInterval()
    const lottoGame = this.props.lottoGame
    const momentEndAt = date.calibratingTime(endingTime)

    this.intervalId = setInterval(() => {
      const diiferentSecond = momentEndAt.diff(moment().local(), 'seconds')
      const days = Math.floor((diiferentSecond / 86400))
      const hours = Math.floor((diiferentSecond / 3600) % 24)
      const minutes = Math.floor((diiferentSecond / 60) % 60)
      const seconds = Math.floor(diiferentSecond % 60)

      if (hours <= 0 && minutes <= 0 && seconds < 0) {
        this.clearLocalInterval()
        const slugName = this.props.match.params.type
        const gameDate = date.calibratingTime(lottoGame.endTime).format('DDMMYYYY')
        const gameRound = number.padNumber(lottoGame.round, 3)
        if (this.state.lottoStatus === 'WAIT') {
          this.props.loader(true)
          this.props.getLottoGame(slugName, gameDate, gameRound)
        } else {
          this.setState({ onLottoProcessing: true }, () => {
            if (slugName === 'LOTTER_YEGEE') {
              const gameQuery = { date: gameDate, round: gameRound }
              this.props.unlistenYeegeSum(gameQuery)
              this.props.unlistenPlayedYeegeList(gameQuery)
            }
            setTimeout(() => {
              this.props.loader(true)
              this.props.getLottoGame(slugName, gameDate, gameRound)
              this.setState({
                statePage : 'MENU',
                nodeStatePage : 'MENU',
                betGeneralTap : 0,
                openListLotto:false,
                isCollapseGameHistory:false
              })

            }, 5000)
          })
        }
      } else if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        this.setState({ remainingTime: { days: 0, hours: 0, minutes: 0, seconds: 0 } }, () => {
          this.clearLocalInterval()
        })
      } else {
        this.setState({ remainingTime: { days, hours, minutes, seconds } })
      }

    }, 1000);
  }

  generateGameSlug = () => {
    const slugName = this.props.match.params.type
    const gameround = this.props.lottoGame.round
    const currentTime = moment().local().format('DDMMYYYYHHmm')
    return `${slugName}_${currentTime}${number.padNumber(gameround, 3)}`
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path)
  }

  handleOnSwitchChanged = (currentTab: TMakeLottoGameMode) => {
    this.setState({ activeModeSwitch: currentTab })
  }

  handleOnAddLottoNumber = (
    lottoNumber: ILottoNumber | ILottoNumber[],
    state: 'ADD' | 'REMOVE',
    isSwitchedNumber: boolean = false,
    trigger?: 'FRONT' | 'BACK' | 'DOOR',
    value?:string ) => {
    const lottoNumbering = (): ILottoNumber | ILottoNumber[] => {
      if (isSwitchedNumber && trigger === undefined) {
        const lottoNumberArray = (pureLottoNumber: ILottoNumber): ILottoNumber[] => {
          const numberAsArray = split(pureLottoNumber.number, '')
          if (numberAsArray.length === 2) {
            if (isEqual(numberAsArray, uniq(numberAsArray))) {
              return [
                pureLottoNumber,
                { ...pureLottoNumber, number: String(sum(reverse(numberAsArray))) },
              ]
            }
          } else if (numberAsArray.length === 3) {
            return uniq<string>([
              `${numberAsArray[0]}${numberAsArray[1]}${numberAsArray[2]}`,
              `${numberAsArray[0]}${numberAsArray[2]}${numberAsArray[1]}`,
              `${numberAsArray[1]}${numberAsArray[0]}${numberAsArray[2]}`,
              `${numberAsArray[1]}${numberAsArray[2]}${numberAsArray[0]}`,
              `${numberAsArray[2]}${numberAsArray[0]}${numberAsArray[1]}`,
              `${numberAsArray[2]}${numberAsArray[1]}${numberAsArray[0]}`,
            ]).map((numb) => ({ ...pureLottoNumber, number: numb }))
          }
          return [pureLottoNumber]
        }

        if (isArray(lottoNumber)) {
          const temporaryList: ILottoNumber[] = []
          lottoNumber.forEach(lot => {
            const lottos = lottoNumberArray(lot)
            lottos.forEach(lotto => temporaryList.push(lotto))
          })
          return temporaryList
        } else {
          return lottoNumberArray(lottoNumber)
        }
      }
      return lottoNumber
    }

    const lotterNumbers = lottoNumbering()
    const finding = (numb: ILottoNumber) => isArray(lotterNumbers)
      ? isEmpty(find(lotterNumbers, { number: numb.number, type: numb.type }))
      : !(numb.number === lotterNumbers.number && numb.type === lotterNumbers.type)

    const newNumberList = this.state.numberList.filter(finding)
    const slugName = this.props.match.params.type

    if (state === 'ADD') {
      if (isArray(lotterNumbers)) {
        let addNumberList: ILottoNumber[] 
        addNumberList = lotterNumbers.map(numb => ({
          ...numb,
          /* value: isEmpty(value)?this.state.defaultGameValue:value, */
          slug: this.generateGameSlug(),
        }))
        if (slugName === 'LOTTER_YEGEE') {
          this.setState({ numberList: [...this.state.numberList, ...addNumberList] },()=>{
            // console.log("set numberList : ",this.state.numberList);
          })
        } else {
          this.props.loader(true)
          this.setState({
            temporaryNumberList: {
              filter: newNumberList,
              adding: addNumberList,
            },
          }, () => {
            const numbersQuery = map(addNumberList, (numb) => ({
              code: slugName,
              number: numb.number,
              type: numb.type,
            }))
            this.props.getBetNumberRate(numbersQuery)
          })
        }
      } else {
        if (slugName === 'LOTTER_YEGEE') {
          this.setState({
            numberList: [
              ...newNumberList,
              {
                ...lotterNumbers,
                value: isEmpty(value)?this.state.defaultGameValue:value,
                slug: this.generateGameSlug(),
              }],
          })
        } else {
          this.props.loader(true)
          this.setState({
            temporaryNumberList: {
              filter: newNumberList,
              adding: [{
                ...lotterNumbers,
                value: isEmpty(value)?this.state.defaultGameValue:value,
                slug: this.generateGameSlug(),
              }],
            },
          }, () => {
            this.props.getBetNumberRate([{
              code: slugName,
              number: lotterNumbers.number,
              type: lotterNumbers.type,
            }])
          })
        }
      }
    } else {
      this.setState({ numberList: newNumberList })
    }
  }

  handleOnMakingBetLotto = (lottoList: ILottoNumber[]) => {
    const slugName = this.props.match.params.type
    const lottos = lottoList
      .map(lotto => ({ ...lotto, value: number.castToInteger(lotto.value) }))
      .filter(lotto => (LOTTO_GAME_TYPES[slugName].includes(lotto.type)))
    Confirmation.show({
      data: lottos,
      betRates: this.props.betRates,
      lottoSlug: slugName,
      onCancel: Confirmation.hide,
      onConfirm: () => {
        this.props.loader(true)
        this.props.makingBetLotto(lottos)
      },
    })
  }

  handleOnPlayYeegeGame = (gameNumber: string) => {
    this.props.loader(true)
    this.props.playYeege({
      number: gameNumber,
      round: this.props.lottoGame.round,
    })
  }

  handleOnBetListChanged = (lottoList: ILottoNumber[]) => {
    this.setState({ numberList: lottoList }, () => {
      const locationState: IMakingLottoRouteProps = this.props.location.state
      // locationState.betList = []
      const makeLottoRouteProps: IMakingLottoRouteProps = {
        betList: [],
        selectedLottoGame: locationState.selectedLottoGame,
        // isClear: isClear
      }
      this.props.history.replace(routes.lottoMaking.exactPath(this.props.match.params.type), makeLottoRouteProps)
      // this.props.history.replace(routes.lottoMaking.path, )
    }
    )
  }

  handleOnGotoSelectFavorite = () => {
    const favoriteRouteProps: ILottoFavoriteRouteProps = {
      betList: this.state.numberList,
      lottoSlug: this.props.match.params.type,
      selectedLottoGame: this.props.lottoGame,
      keyLocation: this.props.history.location.key!,
    }
    this.props.history.replace(routes.lottoFavorite.path, favoriteRouteProps)
  }
  handleOnGotoSelectOldFavorite = () => {
    const favoriteOldRouteProps: IBetLotteryPullListRouteProps = {
      betList: this.state.numberList,
      lottoSlug: this.props.match.params.type,
      selectedLottoGame: this.props.lottoGame,
      keyLocation: this.props.history.location.key!,
    }
    this.props.history.replace(routes.lottoOldFavorite.path, favoriteOldRouteProps)
  }

  renderWaitMake = () => {
    const sumtime = sum(values(this.state.remainingTime))
    const remainingTime = (sumtime > 0)
      ? this.state.remainingTime.days > 0
        ? `${(this.state.lottoStatus === 'WAIT') ? `${constants.openedOnFull} ` : ''}${number.padNumber(String(this.state.remainingTime.days), 2)} วัน ${number.padNumber(String(this.state.remainingTime.hours), 2)} : ${number.padNumber(String(this.state.remainingTime.minutes), 2)} : ${number.padNumber(String(this.state.remainingTime.seconds), 2)}`
        : `${(this.state.lottoStatus === 'WAIT') ? `${constants.openedOnFull} ` : ''}${number.padNumber(String(this.state.remainingTime.hours), 2)} : ${number.padNumber(String(this.state.remainingTime.minutes), 2)} : ${number.padNumber(String(this.state.remainingTime.seconds), 2)}`
      : '-'
    return (
      <div className="row">
        <div className="col">
          <div className="secondary-bg border-rounded p3">
            <div className="wait-make-container secondary-blue-bg p2-x p4-y align-items-center justify-content-center">
              <h2>{remainingTime}</h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  getCustomBetProps = (index: number) => {
    const locationState: ILottoFavoriteRouteProps = this.props.location.state as ILottoFavoriteRouteProps
    const favoriteOldRouteProps: IBetLotteryPullListRouteProps = {
      betList: locationState.betList,
      lottoSlug: locationState.lottoSlug,
      selectedLottoGame: locationState.selectedLottoGame,
      keyLocation: this.props.history.location.key!,
    }

    let transaction = this.props.lottoOldFavoriteList[index]

    // this.props.onNavigateToFavorite!()
    /* this.props.history.push(routes.lottoOldFavoriteDetail.path, {betLotteryPullList:transaction,
      ...favoriteOldRouteProps}) */
    return {
      betLotteryPullList: transaction,
      ...favoriteOldRouteProps
    }
  }

  resetMode=()=>{
    this.setState({
      openListLotto:false,
      isCollapseGameHistory:false,
      activeModeSwitch: 'LOTTO',
    })
  }

  renderGameMode = () => {
    const slugName = this.props.match.params.type
    if (this.state.onLottoProcessing) {
      return (
        <div className="border-rounded secondary-bg p4">
          <div className="row">
            <div className="col text-center m3-y">
              <h2>{constants.onProcessing}</h2>
            </div>
          </div>
        </div>
      )
    } else if (this.state.lottoStatus === 'CLOSE') {
      return (
        <>
          <BetResult results={this.props.betResults} />
          {
            (slugName === 'LOTTER_YEGEE')
              ? <>
              {/* <div className="m2-t" /><GameHistory history={this.props.yeegeGameHistory} /> */}
              </>
              : <></>
          }
        </>
      )
    } else if (this.state.lottoStatus === 'OPEN') {
      switch (this.state.statePage) {
        case "MENU":
          return (<div className='main-page-menu'>
            <div className='box-btn-menu mx-auto'>
            <Button
              size="medium"
              id="history-old"
              type="rectangle"
              text="กำหนดเองอย่างง่าย"
              backgroundColor={colors.PRIMARY_BLUE}
              backgroundHoverColor={colors.SECONDARY_BLUE}
              onClick={()=>{this.setState({statePage:"EASY_BET"})}}
            />
            </div>
            <div className='box-btn-menu mx-auto'>
              <Button
                size="medium"
                id="history-old"
                type="rectangle"
                text="การแทงทั่วไป"
                backgroundColor={colors.PRIMARY_BLUE}
                backgroundHoverColor={colors.SECONDARY_BLUE}
                onClick={()=>{this.setState({statePage:"BET"})}}
              />
            </div>
            <div className='box-btn-menu mx-auto'>
              <Button
                size="medium"
                id="history-old"
                type="rectangle"
                text="ดึงเลขชุด"
                backgroundColor={colors.PRIMARY_BLUE}
                backgroundHoverColor={colors.SECONDARY_BLUE}
                onClick={()=>{this.setState({statePage:"LOTTO_FAVORITE"})}}
              />
            </div>
            <div className='box-btn-menu mx-auto'>
              <Button
                size="medium"
                id="history-old"
                type="rectangle"
                text={constants.historyOld}
                backgroundColor={colors.PRIMARY_BLUE}
                backgroundHoverColor={colors.SECONDARY_BLUE}
                onClick={()=>{this.setState({statePage:"NUMBER_OLD_FAVORITE"})}}
              />
            </div>
          </div>)
        case "EASY_BET":
          return <div className='easy-bet'><MakingLotto
              betLimit={this.props.betLimitConf}
              lottos={this.state.numberList}
              betRates={this.props.betRates}
              gameSlug={this.props.match.params.type}
              onAddedNumber={this.handleOnAddLottoNumber}
              onNavigateToOldFavorite={this.handleOnGotoSelectOldFavorite}
              numberBan={this.props.numberBan}
              mode="EASY"
            /></div>
        case "BET":
          return <div className='bet-general'>
            <div
              id="head-tap"
              className='head-tap'
            >
              {constants.betGeneralTap.map((item,index)=>
              <div className={`item-tap tap-${index} ${this.state.betGeneralTap===index && 'active'}`}
                key={item+index}
                style={{paddingRight:"6px"}}
                onClick={()=>{this.setState({betGeneralTap:index})}}
              >
                {item}
              </div>)}
            </div>
            <div className='body-tap'>
                {this.state.betGeneralTap===0 && <>
                  <MakingLotto
                    betLimit={this.props.betLimitConf}
                    lottos={this.state.numberList}
                    betRates={this.props.betRates}
                    gameSlug={this.props.match.params.type}
                    onAddedNumber={this.handleOnAddLottoNumber}
                    onNavigateToOldFavorite={this.handleOnGotoSelectOldFavorite}
                    numberBan={this.props.numberBan}
                    mode="GENERAL"
                  />
                </>}
                {this.state.betGeneralTap===1 && <>
                  <NumberPanel
                    betLimit={this.props.betLimitConf}
                    lottos={this.state.numberList}
                    betRates={this.props.betRates}
                    gameSlug={this.props.match.params.type}
                    onAddedNumber={this.handleOnAddLottoNumber}
                    onNavigateToOldFavorite={this.handleOnGotoSelectOldFavorite}
                    numberBan={this.props.numberBan}
                    mode="GENERAL"
                  />
                </>}
                {this.state.betGeneralTap===2 && <>
                  <WinNumber 
                    betLimit={this.props.betLimitConf}
                    lottos={this.state.numberList}
                    betRates={this.props.betRates}
                    gameSlug={this.props.match.params.type}
                    onAddedNumber={this.handleOnAddLottoNumber}
                    onNavigateToOldFavorite={this.handleOnGotoSelectOldFavorite}
                    numberBan={this.props.numberBan}
                    mode="GENERAL"
                  />
                </>}
            </div>
          </div>

        case "NUMBER_OLD_FAVORITE":
          return <>
          <LottoOldFavorite
            lottoOldFavoriteList={this.props.lottoOldFavoriteList}
            onNavigateToFavorite={this.handleOnGotoSelectFavorite}
            getCustomBetProps={this.getCustomBetProps}
            handleOnBack={this.handleOnBack}
            history={this.props.history}
            onAddedNumber={this.handleOnAddLottoNumber}
          /></>

        case "LOTTO_FAVORITE":
          return <>
          <LottoFavorite
            history={this.props.history}
            onAddedNumber={this.handleOnAddLottoNumber}
          />
          </>
        default:
          return (<></>)
      }
    } else if (this.state.lottoStatus === 'WAIT') {
      return this.renderWaitMake()
    }
    return (<></>)
  }

  renderSummaryMode = () => {
    const slugName = this.props.match.params.type
    if (this.state.onLottoProcessing) {
      return (<></>)
    } else if (this.state.lottoStatus === 'CLOSE') {
      if (slugName === 'LOTTER_YEGEE') {
        return (
          <>
            {/* <PlayedUser playedYeegeList={this.props.playedYeegeList} /> */}
          </>
        )
      }
      return <></>
    } else if (this.state.lottoStatus === 'OPEN') {
      return (
        <div ref={this.summaryContainerRef}>
          <Summary
            betLimit={this.props.betLimitConf}
            lottoSlug={slugName}
            onNavigateToFavorite={this.handleOnGotoSelectFavorite}
            betRates={this.props.betRates}
            lottoList={this.state.numberList}
            onClickBet={this.handleOnMakingBetLotto}
            onBetListChanged={this.handleOnBetListChanged}
            openListLotto={this.state.openListLotto}
            onChangeOpenListLotto={(e)=>{
              if (e) {
                this.setState({nodeStatePage:(this.state.statePage==="GAME_HISTORY_YEGEE" || this.state.statePage==="GAME_YEGEE")?this.state.nodeStatePage:this.state.statePage},()=>{
                  this.setState({statePage:this.state.nodeStatePage})
                })
              }else{
                this.setState({statePage:this.state.nodeStatePage})
              }
              this.setState({isCollapseGameHistory:false,activeModeSwitch: 'LOTTO'},()=>{
                setTimeout(()=>{
                  this.setState({openListLotto:e})
                },0)
              })
            }}
          />
        </div>
      )
    }
    return <></>
  }

  handleOnBack = () => {
    if (this.state.statePage==="GAME_YEGEE") {
      this.setState({activeModeSwitch: this.state.activeModeSwitch === 'LOTTO' ? 'GAME' : 'LOTTO'})
    }else if (this.state.statePage==="GAME_HISTORY_YEGEE") {
      this.setState({isCollapseGameHistory:!this.state.isCollapseGameHistory})
    }
    if (this.state.statePage!=="MENU") {
      this.setState({statePage:"MENU"})
      return
    }
    this.props.loader(false)
    const slugName = this.props.match.params.type
    const locationState: IMakingLottoRouteProps = this.props.location.state
    if (slugName !== 'LOTTER_YEGEE') {
      // this.props.history.goBack()
      const game = {...locationState.selectedLottoGame,code: slugName}
      this.props.history.push(routes.lotto.path,{selectedLottoGame:game})
    } else {
      this.handleOnClickBreadcrumb(routes.lottoChrildren.exactPath(this.props.match.params.type))
    }
  }

  handleOnQA = () => {
    this.props.history.push(routes.qaType.exactPath(LOTTO_SLUG_TO_QA_TYPE[this.props.match.params.type]))
  }

  handleOnSwitchMode = () => {
    this.setState({openListLotto:false,isCollapseGameHistory: false},()=>{
      setTimeout(()=>{
        this.setState({ activeModeSwitch: this.state.activeModeSwitch === 'LOTTO' ? 'GAME' : 'LOTTO' },()=>{
          if (this.state.activeModeSwitch==="GAME") {
            this.setState({nodeStatePage:this.state.statePage==='GAME_HISTORY_YEGEE'?this.state.nodeStatePage:this.state.statePage},()=>{
              this.setState({statePage:"GAME_YEGEE"}) 
            })
          }else{
            this.setState({statePage:this.state.nodeStatePage})
          }
        })
      },0)
    })
  }

  renderYeegeGame = (maxHeight:number) => {
    const slugName = this.props.match.params.type
    if (slugName !== 'LOTTER_YEGEE' || this.state.onLottoProcessing || this.state.statePage==="NUMBER_OLD_FAVORITE" || this.state.statePage==="LOTTO_FAVORITE") {
      return (<></>)
    }

    const status = this.state.lottoStatus
    return (
      <>
        <div
          id="YegeeGame"
          className={`yeege-game-result-container p1 mb-2 ${this.state.statePage}`}
          style={this.state.statePage==="GAME_YEGEE"?{maxHeight:maxHeight,height:maxHeight}:{}}
        >
          <div id="yeege-game-result" className='yeege-game-result'>
            <div className="flex">
              <h5 className="font-m20r making-game-label">{constants.makingGameLabel}</h5>
              <h3 className="font-m25r white-text yeege-game-result-text">{this.props.yeegeSum || '0'}</h3>
            </div>
            {status === 'OPEN'
              ? (<div className="yeege-game-action-wrapper">
                  <ALink id="goto-yeege-game" fontSize={14} color={colors.SECONDARY_GOLD_TEXT} onClick={this.handleOnSwitchMode}>
                    <div className='font-m15r'>{this.state.activeModeSwitch === 'LOTTO' ? constants.numsumLabel : constants.makeLabel}<FontAwesomeIcon icon={faChevronRight} className="ml-1" /></div>
                  </ALink>
                </div>)
              : <></>}
          </div>
          {(this.state.activeModeSwitch !== 'LOTTO' || status === 'CLOSE')?<PlayedUser playedYeegeList={this.props.playedYeegeList} isLottoClose={status === 'CLOSE'} />:<></>}
          {(this.state.activeModeSwitch !== 'LOTTO')?<>
          <div className='font-m20r mt-auto text-center'>{constants.yeegeGame}</div>
          <MakingGame onClickAddNumber={this.handleOnPlayYeegeGame} />
          </>:<></>}
        </div>
      </>
    )
  }

  handleScrollToTop = () => {
    if (this.lottoMakeContainerRef.current) {
      interactive.rootElement.scrollToTop()
    }
  }

  handleScrollToSummary = () => {
    if (this.summaryContainerRef.current) {
      this.summaryContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      })
    }
  }

  renderLottoSummaryNavigatorButton = () => {
    if (this.state.onLottoProcessing) {
      return <></>
    } else if (this.state.lottoStatus === 'OPEN') {
      const lottoLength = get(this.state.numberList, 'length', 0)
      const displayClass = (lottoLength > 0) ? 'display' : ''
      return (
        <div className={`lotto-summary-navigator-button-wrapper ${displayClass}`}>
          <div className="inside-summary-navigator" onClick={this.handleScrollToSummary}>
            <FontAwesomeIcon icon={faBell} className="bell-notification" />
          </div>
          <div className="badge-number-noti">
            <h6 className="subtitle-1">{lottoLength}</h6>
          </div>
        </div>
      )
    }

    return <></>
  }

  renderHeader = () => {
    const sumtime = sum(values(this.state.remainingTime))
    const remainingTime = (this.state.lottoStatus === 'OPEN' || this.state.lottoStatus === 'WAIT')
      ? (sumtime > 0)
        ? this.state.remainingTime.days > 0
          ? `${(this.state.lottoStatus === 'WAIT') ? `${constants.openedOn} ` : ''}${number.padNumber(String(this.state.remainingTime.days), 2)} วัน ${number.padNumber(String(this.state.remainingTime.hours), 2)} : ${number.padNumber(String(this.state.remainingTime.minutes), 2)} : ${number.padNumber(String(this.state.remainingTime.seconds), 2)}`
          : `${(this.state.lottoStatus === 'WAIT') ? `${constants.openedOn} ` : ''}${number.padNumber(String(this.state.remainingTime.hours), 2)} : ${number.padNumber(String(this.state.remainingTime.minutes), 2)} : ${number.padNumber(String(this.state.remainingTime.seconds), 2)}`
        : '-'
      : (this.state.lottoStatus === 'CLOSE')
        ? constants.timeups
        : '-'

     const momentEndAt = date.calibratingTime(this.props.lottoGame.endTime)
     const momentStartAt = date.calibratingTime(this.props.lottoGame.startTime)

     const FullSecond = momentEndAt.diff(momentStartAt, 'seconds')
     const diiferentSecond = momentEndAt.diff(moment().local(), 'seconds')
     
    let widthTime = (100*diiferentSecond)/FullSecond
    if (widthTime>100) { widthTime=100 }

    const RenderRemainingTime = () => {
      return (
        <>
          <FontAwesomeIcon icon={faStopwatch} className="mr-1" />
          เวลา {remainingTime}
        </>
      )
    }

    const slugName = this.props.match.params.type
    const gameRound = this.props.lottoGame.round
    const badgeColor = (moment().local().unix() > date.calibratingTime(this.props.lottoGame.startTime).unix())
      ? colors.SECONDARY_RED
      : (slugName === 'LOTTER_YEGEE') ? colors.SECONDARY_RED : colors.SECONDARY_BLUE

    const FlagIcon =
      LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[this.props.match.params.type]]].Icon
    return <>
    <div className="row">
      <div id="header-lotto-make" className='col'>
      <div className='header'>
        <img
          src={(slugName === 'LOTTER_YEGEE')?LogoThailandBet:FlagIcon}
          alt="thailand"
          className="logo-thb"
        />
        <div className='w-100 ml-2 d-flex flex-row justify-content-between'>
          <div className="d-flex flex-column align-items-start justify-content-center">
            <h2 className="font-m25r">{LOTTO_SLUG_NAME[slugName]}</h2>
            {slugName === 'LOTTER_YEGEE'&&
            <div id="goto-qa" className='d-flex flex-row font-m15r' onClick={this.handleOnQA}>
              <FontAwesomeIcon icon={faQuestionCircle} className="m1-r" /> {constants.qa}
            </div>}
          </div>
          <div className='d-flex flex-column justify-content-between align-items-end'>
              <h2 className="font-m15r">{(slugName !== 'LOTTER_YEGEE')
                  ? constants.roundLabel(gameRound)
                  : constants.yeegeLabel(gameRound)}</h2>
              <div className='badge-time'>
                <div className='bg-time' style={{backgroundColor:badgeColor ,width:`calc(${widthTime>3?widthTime:3}% - 2px)`}}></div>
                <h6 className='text-time px-1'>{RenderRemainingTime()}</h6>
              </div>
          </div>
        </div>
      </div>
    <div className='font-m23r text-center mt-2'>{constants.maxBet}</div>
      </div>
    </div>
    <div className='box-btn-back mx-auto mt-1 mb-2'>
      <Button
        size="medium"
        id="btn-back"
        type="rectangle"
        text={constants.back}
        backgroundColor={colors.PRIMARY_BLUE}
        backgroundHoverColor={colors.SECONDARY_BLUE}
        onClick={()=>{
          this.setState({openListLotto:false})
          this.handleOnBack()}}
        icon={leftArrow}
        styleContainer='btn-back'
      />
    </div>
    </>
  }
  render() {
    const RenderHeader = this.renderHeader
    const GameModeComponent = this.renderGameMode
    const SummaryModeComponent = this.renderSummaryMode
    // const SummaryNavigatorButton = this.renderLottoSummaryNavigatorButton

    const heightNavbar = get(document.getElementById('navbar-container'),"clientHeight",0);
    const heightTabbar = get(document.getElementById('tabbar-container'),"clientHeight",0);
    const heightJackpot = get(document.getElementById('badge-jackpot'),"clientHeight",0);
    const heightTop = heightNavbar+heightJackpot
    const tabbarNavbar = heightTabbar+heightTop
    
    const heightSetion1 = get(document.getElementById('setion-1'),"clientHeight",0);
    const heightSetion3 = get(document.getElementById('setion-3'),"clientHeight",0);
    const heightYegeeGame = get(document.getElementById('YegeeGame'),"clientHeight",0);
    const heightHeader = get(document.getElementById('header-lotto-make'),"clientHeight",0);
    const heightHistoryOld = get(document.getElementById('history-old'),"clientHeight",0);
    const heightBack = get(document.getElementById('btn-back'),"clientHeight",0);
    const heighFullMain = windowHeight - (tabbarNavbar+10)

    let mobileHandler = windowHeight<700 && window.innerWidth<450 ? 3 : 5

    return (
      <div className={`lotto-make-container secondary-bg-navy ${this.props.match.params.type} ${transformer.checkSizeDevice(windowHeight)}`}
        style={{
          height:`calc(${windowHeight}px - ${tabbarNavbar}px)`,
          marginTop:`calc(${heightTop}px - ${mobileHandler}vh)`,
          marginBottom:heightTabbar+'px',
          minHeight:`calc(${windowHeight}px - ${tabbarNavbar}px)`
        }}
        ref={this.lottoMakeContainerRef}
      >
        <div className="container">
          <div className={`d-flex align-items-start flex-column page-main-newBet`} 
            style={{
              height:`calc(${windowHeight}px - ${tabbarNavbar+10}px)`,
              minHeight:`calc(${windowHeight}px - ${tabbarNavbar+10}px)`,
            }}
          >
            <div id="setion-1"
              style={{
                maxHeight:this.state.openListLotto?'':`calc(${heighFullMain}px - ${heightSetion3}px)`,
                height:this.state.statePage==="GAME_YEGEE"?`calc(${heighFullMain}px - ${heightSetion3}px)`:this.state.statePage==="GAME_HISTORY_YEGEE"?`calc(${heighFullMain}px - ${heightSetion3}px)`:"initial",
              }}
              className="w-100 setion-1">
              <RenderHeader />
              {/* <div className='font-m30r' style={{position:"absolute",top:"0"}}>
                {window.outerHeight} -------- {window.innerHeight} -------- {windowHeight} ------- {transformer.checkSizeDevice(windowHeight)}
              </div> */}
              {this.renderYeegeGame(heighFullMain-(heightSetion3+heightHeader+heightBack+16))}
              {(this.props.match.params.type==="LOTTER_YEGEE" && this.state.statePage!=="GAME_YEGEE" && this.state.statePage!=="NUMBER_OLD_FAVORITE" && this.state.statePage!=="LOTTO_FAVORITE") &&
                <GameHistory
                  history={this.props.yeegeGameHistory}
                  isCollapseProps={!this.state.isCollapseGameHistory}
                  maxHeight={heighFullMain-(heightSetion3+heightYegeeGame+heightYegeeGame+heightHeader+heightHistoryOld+10)}
                  onClick={()=>{
                    this.setState({openListLotto:false,activeModeSwitch: 'LOTTO'},()=>{
                      setTimeout(()=>{
                          if (this.state.statePage==="GAME_HISTORY_YEGEE") {
                            this.setState({ statePage:this.state.nodeStatePage })
                          }else{
                            this.setState({ nodeStatePage:this.state.statePage },()=>{
                              this.setState({ statePage:"GAME_HISTORY_YEGEE" })
                            })
                          }
                          this.setState({ isCollapseGameHistory:!this.state.isCollapseGameHistory })
                      },0)
                    })
                  }}
                />}
              {((this.state.openListLotto?true:this.state.statePage!=="MENU"))&& (this.state.statePage!=="GAME_YEGEE") && this.state.lottoStatus !== 'CLOSE' && this.state.statePage!=="NUMBER_OLD_FAVORITE" && this.state.statePage!=="LOTTO_FAVORITE" &&
              <div className={`${this.props.match.params.type!=="LOTTER_YEGEE"?'box-btn-back mx-auto mt-2 ':""}`}>
                <Button
                  size="medium"
                  id="history-old"
                  type="rectangle"
                  text={constants.historyOld}
                  backgroundColor={colors.PRIMARY_BLUE}
                  backgroundHoverColor={colors.SECONDARY_BLUE}
                  onClick={()=>{
                    this.setState({openListLotto:false, isCollapseGameHistory:false, activeModeSwitch: 'LOTTO'},()=>{
                      this.setState({statePage:"NUMBER_OLD_FAVORITE"})
                    })
                  }}
                  styleContainer='btn-history-old'
                />
              </div>}
            </div>
            <div id="setion-2"
              className={`box-main-page ${this.state.openListLotto&&"hide"} ${((this.state.openListLotto?true:this.state.statePage!=="MENU"))&& 'history-old'}`}
              style={this.state.lottoStatus === 'CLOSE'?{height:"100%"}:{
                height:this.state.openListLotto?'0px':`${heighFullMain-(heightSetion1+heightSetion3)}px`,
                marginBottom:(this.state.openListLotto || this.state.statePage==="GAME_HISTORY_YEGEE"|| this.state.statePage==="GAME_YEGEE")?'0':'10px'
              }}
            >
              <GameModeComponent />
            </div>
            <div id="setion-3" className="mt-auto w-100">
              <SummaryModeComponent />
            </div>
          </div>
        </div>
        {/* <div className="scroll-to-top-lotto-make-wrapper">
          <ButtonIcon
            onClick={this.handleScrollToTop}
            CustomIcon={<FontAwesomeIcon icon={faArrowUp} className="primary-text" />}
            type="custom"
            id="scroll-to-top"
          />
        </div> */}
        {/* <SummaryNavigatorButton /> */}
      </div>
    )
  }
}

export default LottoMakeContainer