import React, { FC } from 'react'
import { noop } from 'lodash'
import { ResponsiveIcon, Button } from 'components'
import thailandIcon from 'assets/images/logo/logothailandbet02.png'
import { Modal } from '../..'
import './announceNewLotto.style.scss'
import LottoFlags from "assets/images/global/flags"

const constants = {
  title: 'ประกาศ',
  subtitle: 'หวยใหม่มาแล้ว',
  actionText: 'เข้าใจ',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IAnnounceNewLottoModal = {
  title: constants.title,
  subtitle: constants.subtitle,
  actionText: constants.actionText,
  action() { noop() },
}

const AnnounceNewLottoModal: FC<DefaultProps> = (props) => {
  return (
    <div className="announce-new-lotto-container border-rounded">
      <div className="container">
        <div>
          <ResponsiveIcon
            alt="logo-thb"
            className="logo-thb mt-4"
            icon={thailandIcon}
          />
        </div>
        <h2 className="m3-t title-text">{props.title}</h2>
        <h2 className="m3-b body-1">{props.subtitle}</h2>
        <div className="d-flex flex-row mb-2 justify-content-center">
          <div className='m1-x'>
            <ResponsiveIcon
              alt="announce-icon"
              className="announce-checked-icon"
              icon={LottoFlags['VNM'].Icon}
            />
          </div>
          <div className='m1-x'>
            <ResponsiveIcon
              alt="announce-icon"
              className="announce-checked-icon"
              icon={LottoFlags['USA'].Icon}
            />
          </div>
        </div>
        {/* primary-red-text */}
        <div className="my-2">
          <h3 className="description-text">
            {`เรียนท่านสมาชิกทุกท่าน ระบบได้ทำการเพิ่มหวยใหม่ `}
            <span className='primary-red-text'>ฮานอยตรุษจีน</span>
            {' '}
            และ
            {' '}
            <span className='primary-red-text'>ดาวน์โจนสตาร์</span>
            {' '}
            ขอให้ทุกท่านสนุกและเพลิดเพลิน
          </h3>
        </div>
        <div className="m2-y">
          <Button
            id="announce-ok-button"
            type="rectangle"
            size="large"
            onClick={props.action}
            text={props.actionText!}
          />
        </div>
      </div>

    </div>
  )
}

AnnounceNewLottoModal.defaultProps = defaultProps

const announceNewLottoModal = {
  show: (props: IAnnounceNewLottoModal) => {
    return Modal.event('MODAL_OVER', {
      state: 'show',
      extraProps: { size: 's' },
      component: (<AnnounceNewLottoModal {...props} />),
    })
  },
  hide: () => Modal.event('MODAL_OVER', { state: 'hide' }),
}

export default announceNewLottoModal