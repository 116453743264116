import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import lotterAction from 'reduxs/lotto/actions'
import loaderAction from 'reduxs/loader/actions'
import lottoSelector from 'reduxs/lotto/selectors'
import LottoOldFavoriteDetailContainer from './LottoOldFavoriteDetail.container'

const mapStateToProps = (state: RootReducers): IBetLotteryPullDetailProps => {
  const betPullDetail = lottoSelector.betLottoPullDetail(state)
  return ({
    getBetLotteryPullDetailFetching: betPullDetail.isFetching!,
    getBetLotteryPullDetailCode: betPullDetail.code!,
    getBetLotteryPullDetailError: betPullDetail.error!,
    betLotteryPullDetail: betPullDetail.data!,
    betLotteryPullDetailPagination: betPullDetail.pagination!,
  })
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IBetLotteryPullDetailActionProps => bindActionCreators({
  getBetLotteryPullDetail: lotterAction.getLotteryPullDetailAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LottoOldFavoriteDetailContainer)