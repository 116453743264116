import lottoActions from './lotto/actions'
import meActions from './me/actions'
import displayActions from './display/actions'
import betLimitActions from './betLimit/actions'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...lottoActions,
  ...meActions,
  ...displayActions,
  ...betLimitActions,
}