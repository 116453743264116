import listActions from './list/actions'
import yeegeActions from './yeege/actions'
import betActions from './bet/actions'
import meActions from './me/actions'
import favoriteActions from './favorite/actions'
import gameActions from './game/actions'
import oldFavoriteActions from './oldFavorite/actions'
import banActions from './ban/actions'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...gameActions,
  ...listActions,
  ...yeegeActions,
  ...betActions,
  ...meActions,
  ...favoriteActions,
  ...oldFavoriteActions,
  ...banActions,
}