import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'
import { get } from 'lodash'

const meDatailReducer = (state: ReducerState<ICreditDetail> = initialState, action: RootAction): ReducerState<ICreditDetail> => {
  switch (action.type) {
    case getType(actions.postCreditMeDeTailRequestAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.postCreditMeDetailRequestSuccessAction):
      let status = JSON.parse(action.payload.config.data).status
      let res: { [key in string]: ICreditDetail[] } = {}
      let dataKey = get(action.payload.data, 'data[0]', {})
      res[`${dataKey.slug}_${dataKey.createDate}_${dataKey.type === 'CASINO' ? '' : dataKey.status}_${status}`] = action.payload.data.data
      return {
        isFetching: false,
        storeData: { ...state.storeData, ...res },
        data: action.payload.data.data,
        code: action.payload.status,
      }

    case getType(actions.postCreditMeDetailFailureAction):
      return {
        ...state,
        isFetching: false,
        error: action.payload.response?.data.devMessage,
        code: action.payload.response?.status,
      }
    default:
      return state
  }
}

export default meDatailReducer