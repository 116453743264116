import { createAction } from 'typesafe-actions'
import {
  GET_DISPLAY_CONFIG_REQUEST,
  GET_DISPLAY_CONFIG_SUCCESS,
  GET_DISPLAY_CONFIG_FAILURE,
  GET_DISPLAY_CONFIG_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getDisplayConfigAction = createAction(GET_DISPLAY_CONFIG_REQUEST)

const getDisplayConfigSuccessAction = createAction(
  GET_DISPLAY_CONFIG_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IDisplayConfig>>) => resolve(data))

const getDisplayConfigFailureAction = createAction(
  GET_DISPLAY_CONFIG_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getDisplayConfigCancelAction = createAction(GET_DISPLAY_CONFIG_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDisplayConfigAction,
  getDisplayConfigSuccessAction,
  getDisplayConfigFailureAction,
  getDisplayConfigCancelAction,
}