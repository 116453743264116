import listActions from './list/actions'
import DetailActions from './detail/actions'
import BetLotteyActions from './lottery/actions'
import GetLotterBenefitActions from './report/actions'
import BetGameActions from './game/actions'
import GetGameBenefitActions from './reportGame/actions'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...listActions,
  ...DetailActions,
  ...BetLotteyActions,
  ...GetLotterBenefitActions,
  ...GetGameBenefitActions,
  ...BetGameActions
}