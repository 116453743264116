import { createAction } from 'typesafe-actions'
import {
  GET_LOTTERY_PULL_DETAIL_REQUEST,
  GET_LOTTERY_PULL_DETAIL_SUCCESS,
  GET_LOTTERY_PULL_DETAIL_CANCEL,
  GET_LOTTERY_PULL_DETAIL_FAILURE,
  CLEAR_LOTTERY_PULL_DETAIL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'


const getLotteryPullDetailAction = createAction(
  GET_LOTTERY_PULL_DETAIL_REQUEST,
  resolve => (query: APIPaginationQuery, isFirst?: boolean) => resolve({ ...query, isFirst }))

const getLotteryPullDetailSuccessAction = createAction(
  GET_LOTTERY_PULL_DETAIL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<APIPagination<IResBetLotteryPullDetail>>>,isFirst:boolean) =>
  resolve({...data, isFirst}))

const getLotteryPullDetailFailureAction = createAction(
  GET_LOTTERY_PULL_DETAIL_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getLotteryPullDetailCancelAction = createAction(GET_LOTTERY_PULL_DETAIL_CANCEL)

const clearLotteryPullDetailAction = createAction(CLEAR_LOTTERY_PULL_DETAIL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getLotteryPullDetailAction,
  getLotteryPullDetailSuccessAction,
  getLotteryPullDetailFailureAction,
  getLotteryPullDetailCancelAction,
  clearLotteryPullDetailAction,
}
