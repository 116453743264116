import saGame from './sa-gaming-logo-circle.png'
import aeSexy from './sexy-bac-logo-circle.png'
import allBet from './allBet-circle.png'
import dreamGaming from './dream-gaming-logo-circle.png'
import wm from './wm-circle.png'
import wmBanner from '../vendorsIcon/wm-banner-2.png'
import prettyGaming from './prettyGame-circle.png'
import asiaGaming from './asiaGaming-circle.png'
import w88 from './w88-circle.png'
import w88_game_1 from './w88_game_1.png'
import games1 from './game_1.png'
import games2 from './game_3.png'
import games3 from './game_4.png'
import all_bet_games1 from './allBet_game_1.png'
import ae_baccarat from '../casino/ae_sexy_baccarat_1.png'
import ae_dragontiger from '../casino/ae_sexy_dragontiger_1.png'
import ae_redblue from '../casino/ae_sexy_redblue_1.png'
import ae_roulette from '../casino/ae_sexy_roulette_1.png'
import ae_sicbo from '../casino/ae_sexy_sicbo_1.png'
import dreamGamingBanner from '../casino/drr.png'
import W88_CASINO_ICON from './w88-casino.png'
import W88_SLOT_ICON from './w88-slot.png'
import W88_LOTTER_ICON from './w88-lotter.png'
import W88_P2P_ICON from './w88-p2p.png'
import ppGaming from './pragmatic-play-circle.svg'
import evoGaming from './evolution-icon.png'

const vendorListCircle: ICasino[] = [
    { id: 1, image: saGame, company: 'SAGAME', code: 'SAGAME', name: 'SA GAMING', thName: 'เอสเอเกมมิ่ง' },
    { id: 2, image: aeSexy, company: 'AESEXY', code: 'AESEXY', name: 'AE CASINO', thName: 'เซ็กซี่คาสิโน' },
    { id: 3, image: allBet, company: 'ALLBET', code: 'ALLBET', name: 'ALLBET', thName: 'ออเบท' },
    { id: 4, image: wm, company: 'WMGAME', code: 'WMGAME', name: 'WM CASINO', thName: 'ดับเบิ้ลยูเอ็มคาสิโน' },
    { id: 5, image: dreamGaming, company: 'DREAMGAMING', code: 'DREAMGAMING', name: 'DREAM GAMING', thName: 'ดรีมเกมมิ่ง' },
    { id: 6, image: w88, company: 'W88', code: 'W88', name: 'W88', thName: 'ดับเบิ้ลยู 88' },
    { id: 7, image: prettyGaming, company: '', code: 'PRETTYGAMING', name: 'PRETTY GAMING', thName: 'พริตตี้เกมมิ่ง' },
    { id: 8, image: asiaGaming, company: '', code: 'ASIAGAMING', name: 'ASIA GAMING', thName: 'เอเซียเกมมิ่ง' },
    { id: 8, image: ppGaming, company: 'PPGAME', code: 'PPGAME', name: 'PPGAME', thName: 'พีพีเกมมิ่ง' },
    { id: 9, image: evoGaming, company: 'EVOLUTION', code: 'EVOLUTION', name: 'EVOLUTION', thName: 'อีโวลูชั่นเกมมิ่ง' },
]

const gameListsSaGames: ICasino[] = [
    { id: 1, image: games1, company: 'SAGAME', code: 'SAGAME' },
    { id: 2, image: games2, company: 'SAGAME', code: 'SAGAME' },
    { id: 3, image: games3, company: 'SAGAME', code: 'SAGAME' },
]

const gameListsAllBet: ICasino[] = [
    { id: 1, image: all_bet_games1, company: 'ALLBET', code: 'ALLBET' },
]

const gameListsAeSexy: ICasino[] = [
    { id: 1, image: ae_baccarat, company: 'AESEXY', code: 'AESEXY' },
    { id: 2, image: ae_dragontiger, company: 'AESEXY', code: 'AESEXY' },
    { id: 3, image: ae_redblue, company: 'AESEXY', code: 'AESEXY' },
    { id: 4, image: ae_roulette, company: 'AESEXY', code: 'AESEXY' },
    { id: 5, image: ae_sicbo, company: 'AESEXY', code: 'AESEXY' },
]

const gameListWmGame: ICasino[] = [
    { id: 1, image: wmBanner, company: 'WMGAME', code: 'WMGAME' }
]
const gameListDreamGaming: ICasino[] = [
    { id: 1, image: dreamGamingBanner, company: 'DREAMGAMING', code: 'DREAMGAMING' }
]

const gameListW88Gaming: ICasino[] = [
    { id: 1, image: w88_game_1, company: 'W88', code: 'W88' }
]

const w88Portal: ICasinoW88[] = [
    {
        icon: W88_CASINO_ICON,
        code: 'CASINO',
        name: 'คาสิโน',
        subCode: 'CASINO'
    },
    {
        icon: W88_SLOT_ICON,
        code: 'SLOT',
        name: 'สล็อต',
        subCode: 'SLOT'
    },
    {
        icon: W88_LOTTER_ICON,
        code: 'LOTTERY',
        name: 'ลอตเตอรี่',
        subCode: 'LOTTERY'
    }, {
        icon: W88_P2P_ICON,
        code: 'P2P',
        name: 'ผู้เล่นปะทะผู้เล่น',
        subCode: 'P2P'
    }
]

const vendorLists = { vendorListCircle, gameListsSaGames, gameListsAeSexy, gameListsAllBet, gameListWmGame, gameListDreamGaming, gameListW88Gaming, w88Portal }

export {
    vendorLists
}
