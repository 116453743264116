import project from 'constants/project'
import { transformer } from 'utils'

export const GET_CREDIT_INFO_BET_GAME_REQUEST = 'GET_CREDIT_INFO_BET_GAME_REQUEST'
export const GET_CREDIT_INFO_BET_GAME_SUCCESS = 'GET_CREDIT_INFO_BET_GAME_SUCCESS'
export const GET_CREDIT_INFO_BET_GAME_FAILURE = 'GET_CREDIT_INFO_BET_GAME_FAILURE'
export const GET_CREDIT_INFO_BET_GAME_CANCEL = 'GET_CREDIT_INFO_BET_GAME_CANCEL'
export const CLEAR_CREDIT_INFO_BET_GAME = 'CLEAR_CREDIT_INFO_BET_GAME'

export const initialState: ICreditInfoBetLotteryState = {
  isFetching: false,
  code: 0,
  data: [],
  pagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  error: '',
}

export const endpoint = {
  getCreditInfoBetGame: (query?: APIPaginationQuery) => `${project.environment[project.environmentName].api}/bet/game${transformer.urlSearchParams(query)}`,
}