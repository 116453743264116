import React, { Component, createRef, RefObject } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  map,
  noop,
  keys,
  sortBy,
  isEmpty,
  reverse,
  groupBy,
  Dictionary,
} from 'lodash'
import moment from 'moment-timezone'
import { responseCode } from 'constants/response'
import {
  ALink,
  ErrorModal,
  TransactionItemCollapsible,
  PlaceholderLoading,
  DatePicker,
  SelectorItem,
  InputSelect
} from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import { date, interactive } from 'utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import './lottoHistory.style.scss'
import 'react-calendar/dist/Calendar.css';
import { LOTTO_TYPE } from 'constants/variables'
import colors from 'constants/colors'


const TBET_STATUS: { [type in TBetStatus | 'EMPTY']: string } = {
  EMPTY: 'ไม่ระบุ',
  WAIT: 'รอตรวจสอบ',
  WINNER: 'ได้',
  LOSER: 'เสีย',
  DRAW: 'เสมอ',
}
type TSubType = 'LOTTER' | 'GAME' | 'EMPTY'
const TSUB_TYPE: { [type in TSubType]: string } = {
  EMPTY: 'ไม่ระบุ',
  GAME: 'เกม',
  LOTTER: 'หวย',
}

const constants = {
  titleText: 'ประวัติการเดิมพัน',
  back: ' ย้อนกลับ',
  ok: 'ตกลง',
  today: 'วันนี้',
  yesterday: 'เมื่อวาน',
  titleSearch: 'ค้นหาประวัติการเดิมพัน',
  pickDate: 'ระบุวันที่เดิมพัน',
  pickStatus: 'ระบุผลการเดิมพัน',
  pickLottoType: 'ระบุประเภทหวยเดิมพัน',
  pickLottosubType: 'ระบุชื่อเดิมพัน',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILottoHistoryProps & ILottoHistoryActionProps = {
  clearLottoHistoryList() { noop() },
  getLottoHistoryList() { noop() },
  getLottoHistoryListCode: 0,
  getLottoHistoryListIsError: '',
  getLottoHistoryListIsFetching: false,
  lottoHistoryPagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  lottoHistory: [],
  loader() { noop() },
  getUser() { noop() },
}

class LottoHistoryListContainer extends
  Component<
  ILottoHistoryProps & ILottoHistoryActionProps & DefaultProps & RouteComponentProps, ILottoHistoryScrollState
  > {

  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  state: ILottoHistoryScrollState = {
    hasMore: true,
    status: '',
    subType: '',
    type: '',
    date: null,
    query: {
      date: '',
      status: '',
      subType: '',
      type: '',
      page: 1,
      limit: 15,
    }
  }

  componentDidMount() {
    this.setState({ date: '' })
    interactive.rootElement.scrollToTop()
    this.props.getUser()
    this.onGetLottoHistory(true)
    this.props.loader(true)
  }

  componentDidUpdate(prevProps: ILottoHistoryProps) {
    if (prevProps.getLottoHistoryListIsFetching !== this.props.getLottoHistoryListIsFetching
      && !this.props.getLottoHistoryListIsFetching) {
      this.props.loader(false)

      if (this.props.getLottoHistoryListCode === responseCode.OK) {
        if (this.props.lottoHistoryPagination.page >= this.props.lottoHistoryPagination.total) {
          this.setState({ hasMore: false })
        } else {
          this.setState({ hasMore: true })
        }
      } else if (this.props.getLottoHistoryListCode !== responseCode.OK
        && this.props.getLottoHistoryListCode !== responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.getLottoHistoryListIsError,
          actionText: constants.ok,
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.clearLottoHistoryList()
  }

  onPressBack = () => {
    this.props.history.goBack()
  }

  onGetLottoHistory = (isFirst: boolean) => {
    this.props.getLottoHistoryList(this.state.query, isFirst)
  }

  onChangeDate = (Pickdate: object) => {
    this.props.clearLottoHistoryList()
    this.setState(ps => ({
      date: Pickdate === null ? '' : Pickdate,
      query: {
        ...ps.query,
        date: Pickdate === null ? '' : moment(Pickdate).format('DDMMYYYY'),
        page: 1,
      }
    }), () => this.props.getLottoHistoryList(this.state.query, true))
    this.props.loader(true)
  }

  onChangeType = (pickType: string | any) => {
    this.props.clearLottoHistoryList()
    this.setState(ps => ({
      type: pickType,
      query: {
        ...ps.query,
        type: pickType !== 'EMPTY' ? pickType : '',
        page: 1,
      }
    }), () => this.props.getLottoHistoryList(this.state.query, true))
    this.props.loader(true)
  }

  onChangeStatus = (pickStatus: string | any) => {
    this.props.clearLottoHistoryList()
    this.setState(ps => ({
      status: pickStatus,
      query: {
        ...ps.query,
        status: pickStatus !== 'EMPTY' ? pickStatus : '',
        page: 1,
      }
    }), () => this.props.getLottoHistoryList(this.state.query, true))
    this.props.loader(true)
  }

  onChangeSubType = (pickSubType: string | any) => {
    this.props.clearLottoHistoryList()
    this.setState(ps => ({
      subType: pickSubType,
      query: {
        ...ps.query,
        subType: pickSubType !== 'EMPTY' ? pickSubType : '',
        page: 1,
      }
    }), () => this.props.getLottoHistoryList(this.state.query, true))
    this.props.loader(true)
  }

  handleScroll = (isFirst = false) => {
    this.setState(ps => ({ query: { ...ps.query, page: isFirst ? 1 : this.props.lottoHistoryPagination.page + 1, } }),
      () => this.props.getLottoHistoryList(this.state.query, isFirst))
  }

  renderLottoTypeOption = ({ item, ...selectProps }: IInputDefaultSelectProps<TLottoType>) => {
    return (
      <SelectorItem
        title={LOTTO_TYPE[item]}
        {...selectProps}
      />
    )
  }

  renderLottoStatusOption = ({ item, ...selectProps }: IInputDefaultSelectProps<TBetStatus>) => {
    return (
      <SelectorItem
        title={TBET_STATUS[item]}
        {...selectProps}
      />
    )
  }

  renderLottoSubType = ({ item, ...selectProps }: IInputDefaultSelectProps<TSubType>) => {
    return (
      <SelectorItem
        title={TSUB_TYPE[item]}
        {...selectProps}
      />
    )
  }

  renderTransactionList = () => {
    const creditGroupList: Dictionary<ICredit[]> = groupBy<ICredit>(
      map(this.props.lottoHistory, lottoHistory =>
        ({ ...lottoHistory, groupTime: date.calibratingTime(lottoHistory.createdAt).format('YYYYMMDD') })),
      'groupTime')

    if (isEmpty(creditGroupList)) { return <></> }

    return (
      <InfiniteScroll
        style={
          {
            overflowX: 'hidden',
            minHeight: '100%',
          }
        }
        height={'50vh'}
        dataLength={this.props.lottoHistory.length}
        next={this.handleScroll}
        hasMore={this.state.hasMore}
        loader={(
          <div className="row p2-y">
            <div className="col">
              <PlaceholderLoading
                customCol={[
                  [
                    { col: 4, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
                    { col: 6, show: false },
                    { col: 2, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
                  ],
                  [
                    { col: 5, color: colors.SECONDARY_TEXT, show: true, size: 'S' },
                  ],
                ]}
              />
            </div>
          </div>
        )}
      >
        {
          reverse(keys(creditGroupList).sort()).map((key, index) => {
            const CreditPerDay = map(
              reverse(sortBy(creditGroupList[key], ['createdAt'])),
              (transaction, transactionIndex) =>
                (<TransactionItemCollapsible credit={transaction} key={`lotto-history-items-${transactionIndex}`} />))
            const dateMoment = moment(key, 'YYYYMMDD').local()
            const isToDay = dateMoment.clone().diff(moment().local(), 'day')
            const displayDayString = (isToDay === 0)
              ? constants.today
              : (isToDay === -1)
                ? constants.yesterday
                : dateMoment.format('DD MMM YYYY')

            return (
              <div className="row mt-4 w-100" key={`${key}-${index}`}>
                <div className="col">
                  <div className="display-date-text mb-2">{displayDayString}</div>
                  {CreditPerDay}
                </div>
              </div>
            )
          })
        }
      </InfiniteScroll>
    )
  }

  renderCriteriaForm = () => {
    const typeList = keys(LOTTO_TYPE)
    const statusList = keys(TBET_STATUS)
    const gameList = keys(TSUB_TYPE)
    return (
      <>
        <div className="row m3-t">
          <div className="col m2-l">
            <span className="body-1">{constants.pickDate}</span>
            <div className="date-picker-container d-flex">
              <DatePicker
                onChange={(value) => this.onChangeDate(value)}
                value={this.state.date}
              />
            </div>
          </div>
        </div>
        <div className="row m3-t">
          <div className="col">
            <span className="body-1">{''}</span>
            <InputSelect<TBetStatus, string>
              backgroundColor={colors.PRIMARY_BG}
              backgroundHoverColor={colors.TERTIARY_BG}
              name="status"
              items={statusList as TBetStatus[]}
              value={this.state.status}
              onChange={(type) => this.onChangeStatus(type)}
              placeholder={constants.pickStatus}
              RenderSelected={this.renderLottoStatusOption}
            />
          </div>
        </div>
        <div className="row m3-t">
          <div className="col">
            <span className="body-1">{''}</span>
            <InputSelect<TLottoType, string>
              backgroundColor={colors.PRIMARY_BG}
              backgroundHoverColor={colors.TERTIARY_BG}
              name="type"
              items={typeList as TLottoType[]}
              value={this.state.type}
              onChange={(type) => this.onChangeType(type)}
              placeholder={constants.pickLottoType}
              RenderSelected={this.renderLottoTypeOption}
            />
          </div>
        </div>
        <div className="row m3-t">
          <div className="col">
            <span className="body-1">{''}</span>
            <InputSelect<TSubType, string>
              backgroundColor={colors.PRIMARY_BG}
              backgroundHoverColor={colors.TERTIARY_BG}
              name="type"
              items={gameList as TSubType[]}
              value={this.state.subType}
              onChange={(type) => this.onChangeSubType(type)}
              placeholder={constants.pickLottosubType}
              RenderSelected={this.renderLottoSubType}
            />
          </div>
        </div>
      </>
    )
  }

  render() {
    const TransactionList = this.renderTransactionList
    const CriteriaForm = this.renderCriteriaForm
    return (
      <div className="lotto-history-list-container" ref={this.pageContainerRef}>
        <div className="container">
          <div className="row">
            <div className="col">
              <ALink id="backto-previus-page" color="#dd3d45" bold onClick={this.onPressBack}>
                <FontAwesomeIcon icon={faChevronLeft} />
                {constants.back}
              </ALink>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3">
              <div className="lotto-history-transaction-wrapper p2-x p2-y">
                <div className="row">
                  <div className="col lotto-history-transaction-wrapper">
                    <h3>{constants.titleSearch}</h3>
                    <div>
                      <CriteriaForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-3">
              <div className="lotto-history-transaction-wrapper p2-x p2-t">
                <div className="row pb-3">
                  <div className="col">
                    <h3>{constants.titleText}</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TransactionList />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LottoHistoryListContainer