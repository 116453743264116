import { connect } from 'react-redux'
import RootContainer from './Root.container'
import { RootAction } from 'typings/reduxs/Actions'
import { bindActionCreators, Dispatch } from 'redux'
import loaderAction from 'reduxs/loader/actions'
import socketAction from 'reduxs/socket/actions'
import configAction from 'reduxs/config/actions'
import authAction from 'reduxs/auth/actions'
import pmpgAction from 'reduxs/pmpg/actions'
import pmpgSelector from 'reduxs/pmpg/selectors'
import notificationAction from 'reduxs/notification/actions';

const mapStateToProps = (state: RootReducers): IRootProps => {
  const eraHighlightSelected = pmpgSelector.eraPmpg(state)
  const summaryPmpgSelected = pmpgSelector.summaryPmpg(state)

  return ({
    accessToken: state.ludens.user.token.accessToken!,
    textRunning: state.ludens.config.me.data?.textRunner!,
    wallet: state.ludens.user.wallet,
    getMeUser: state.ludens.user.me.data!,
    getMeUserisFetching: state.ludens.user.me.isFetching!,
    highlightEra: eraHighlightSelected.data,
    pmpgEra: state.ludens.pmpg.pmpgEra.data!,
    getSummaryPmpgIsFetching: summaryPmpgSelected.isFetching!,
    getSummaryPmpgError: summaryPmpgSelected.error!,
    getSummaryPmpgCode: summaryPmpgSelected.code!,
    getSummaryPmpgResult: summaryPmpgSelected.data!,
    webConfig: state.ludens.config.me.data!,
    notificationUserCountIsFetching: state.ludens.notification.user.count.isFetching!,
    notificationUserCountCode: state.ludens.notification.user.count.code!,
    notificationUserCountError: state.ludens.notification.user.count.error!,
    notificationUserCount: state.ludens.notification.user.count.data!,
    notificationUserRead: state.ludens.notification.user.read.data!,
    notificationUserReadIsFetching: state.ludens.notification.user.read.isFetching!,
    notificationUserReadCode: state.ludens.notification.user.read.code!,
    notificationUserReadError: state.ludens.notification.user.read.error!,
  })
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IRootActionProps => bindActionCreators({
  loader: loaderAction.loadingAction,
  logout: authAction.logoutAction,
  getMeConfig: configAction.getMeConfigAction,
  getDisplayConfig: configAction.getDisplayConfigAction,
  connectSocket: socketAction.connectSocketAction,
  connectNotificationUsercountSocket: socketAction.connectNotificationUserCountSocketAction,
  verifyAccess: authAction.verify,
  getSummaryPmpg: pmpgAction.getSummaryPmpgAction,
  clearSummaryPmpg: pmpgAction.getSummaryPmpgCancelAction,
  getNotificationUserCount: notificationAction.getNotificationUserCountAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(RootContainer)