import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const getYeegeGameListReducer = (
    state: ReducerState<IPmpgEra> = initialState,
    action: RootAction
): ReducerState<any> => {
    switch (action.type) {
        case getType(actions.updatePmpgEraAction):
            return {
                ...state,
            }
        case getType(actions.updatePmpgEraSuccessAction):
            return {
                ...state,
                isFetching: false,
                data: action.payload,
            }
        default:
            return state
    }
}

export default getYeegeGameListReducer