import React, { Component, createRef, RefObject } from 'react'
import { get, isEmpty, lowerCase, noop } from 'lodash'
import { LudensContext } from 'configs/context'
import { RouteComponentProps } from 'react-router-dom'
import colors from 'constants/colors'
import {
  ButtonMenu,
  ALink,
  CreditCard,
  ResponsiveIcon,
  AnnounceModal,
} from 'components'

import HeightLightGame from 'assets/images/games/tao-phaun-trans.png'
import CreditIcon from 'assets/images/main/credit/credit-card.svg'
import ResultIcon from 'assets/images/main/result/result.svg'
// import LottoHistoryIcon from 'assets/images/main/lottoHistory/lottoHistory.svg'
import WalletIcon from 'assets/images/main/wallet/wallet.svg'
import LottoIcon from 'assets/images/main/lotto/lotto.svg'
import ContactIcon from 'assets/images/main/contacts/contacts.svg'
import ProblemIcon from 'assets/images/main/problem/problem.svg'
import MegaphoneIcon from 'assets/images/main/megaphone/megaphone.svg'
import CasinoIcon from 'assets/images/main/casino/casino.svg'
import SlotIcon from 'assets/images/main/slot/slot.svg'
// import GameIcon from 'assets/images/main/game/game.svg'
import FavoriteIcon from 'assets/images/main/favorite/favorite.svg'
import Jackpot from 'assets/images/jackpot-era/jackpot.svg'
import appIcon from 'assets/images/main/appdownload/app3.png'
import project from 'constants/project'

import './main.style.scss'
import routes from 'constants/routes'
import { interactive, number } from 'utils'
import { responseCode } from 'constants/response'
import { ProgressBar } from './components/index'

const constants = {
  creditLabel: 'ยอดเครดิต',
  jackPod: 'แจ็คพอต',
  conditionText: 'ข้อตกลงการใช้งาน',
  creditText: 'เติมเครดิต',
  gotoLotto: 'แทงหวย',
  gotoCredit: 'ข้อมูลเครดิต',
  gotoWD: 'ฝาก - ถอน',
  gotoAffli: 'ระบบแนะนำเพื่อน',
  gotoNews: 'ข่าวสาร',
  gotoContact: 'ติดต่อทีมงาน',
  gotoCasino: 'คาสิโนออนไลน์',
  gotoCasinoGame: 'คาสิโนเกม',
  gotoTHBGame: 'ไทยแลนด์เบทเกม',
  gotoLottoResute: 'ผลหวย',
  gotoLottoHistory: 'รายการเดิมพัน',
  gotoFavoriteNumber: 'เลขชุด',
  playNow: 'เล่นเลย',
  defaultCompanyGame: 'THBGAME',
  closedGame: 'ปิดปรับปรุง ชั่วคราว',
  jackpotTitle: 'วิธีรับแจ็คพอต คลิกเลย',
  currentJackpotLabel: 'กองเงินระดับปัจจุบันระดับ',
  new: 'NEW',
  downloadApp: 'ดาวน์โหลดแอปมือถือ',
  downloadiOSApp: 'ดาวน์โหลดแอปมือถือ iOS',
}

const mapEra: { [key in IEraType]: string } = {
  GOLD: 'ทอง',
  SILVER: 'เงิน',
  BRONZE: 'ทองแดง',
  TIN: 'ดีบุก',
  '': '',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IMainProps & IMainActionProps = {
  clearSummaryPmpg() {
    noop()
  },
  getSummaryPmpg() {
    noop()
  },
  getSummaryPmpgIsFetching: false,
  getSummaryPmpgError: '',
  getSummaryPmpgCode: 0,
  getSummaryPmpgResult: {},
  user: {},
  wallet: {},
  token: '',
  getUser() {
    noop()
  },
  connectGame() {
    noop()
  },
  connectedGameIsFetching: false,
  connectedGameError: '',
  connectedGameCode: 0,
  connectedGame: '',
  getGameList() {
    noop()
  },
  getGameListIsFetching: false,
  getGameListError: '',
  getGameListCode: 0,
  gameList: [],
  displayConfig: {},
  highlightEra: {},
  clearGameList() {
    noop()
  },
  loader() {
    noop()
  },
  clickRef: {
    code: undefined,
    company: undefined,
    path: undefined,
  },
  clearClickRef() {
    noop()
  },
}

class MainContainer extends Component<
  IMainProps & IMainActionProps & DefaultProps & RouteComponentProps
> {
  static contextType = LudensContext

  static defaultProps = defaultProps

  state: IMainContainerState = {
    summary: {},
  }

  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    if (this.props.history.length <= 4) {
      AnnounceModal.show({
        action: () => {
          this.onSelectPortal()
          AnnounceModal.hide()
        },
        title: 'Download Application',
        description: 'สามารถดาวน์โหลดแอปพลิเคชันได้เฉพาะ Android เท่านั้น',
        actionText: 'ดาวน์โหลด',
        actionCancleText: 'ยกเลิก',
        detail: 'Get application to your Android device.',
        actionCancle: AnnounceModal.hide,
        textHowTo: 'วิธีการติดตั้งใช้งาน',
        actionHowTo: () => {
          AnnounceModal.hide()
          this.props.history.push(routes.qaType.exactPath('downloadTLB'))
        },
      })
    }
    this.props.getUser()
    this.props.getGameList('INTERNAL')
    this.context.wallet.changeShown(false)
    this.props.getSummaryPmpg()
    if (
      !isEmpty(this.props.clickRef.path) ||
      this.props.clickRef.path !== undefined
    ) {
      this.onNavigateByClickRef()
    }
    setTimeout(() => {
      interactive.rootElement.scrollToTop()
    }, 128)
  }

  componentDidUpdate(prevProps: IMainProps) {
    if (
      prevProps.getGameListIsFetching !== this.props.getGameListIsFetching &&
      !this.props.getGameListIsFetching
    ) {
      this.props.loader(false)
    }

    if (
      prevProps.connectedGameIsFetching !==
      this.props.connectedGameIsFetching &&
      !this.props.connectedGameIsFetching
    ) {
      if (this.props.connectedGameCode === responseCode.OK) {
        this.props.loader(false)
        interactive.openLink(this.props.connectedGame)
      }
    }
    if (
      prevProps.getSummaryPmpgIsFetching !==
      this.props.getSummaryPmpgIsFetching &&
      !this.props.getSummaryPmpgIsFetching
    ) {
      if (this.props.getSummaryPmpgCode === responseCode.OK) {
        this.props.loader(false)
        this.setState({ summary: this.props.getSummaryPmpgResult })
      }
    }
  }
  onNavigateByClickRef = () => {
    this.props.history.push(this.props.clickRef.path!)
  }

  handleConnectGame = () => {
    const gameObject: IGame = get(this.props.gameList, '0', {})
    if (gameObject) {
      this.props.loader(true)
      const query: IGameConnectQuery = {
        code: gameObject.code,
        isMobile: false,
        company: constants.defaultCompanyGame as TGameCompany,
      }
      this.props.connectGame('INTERNAL', query)
    }
  }

  componentWillUnmount() {
    this.context.wallet.changeShown(true)
    this.props.clearGameList()
    this.props.clearSummaryPmpg()
  }

  onNavigateTo = (path: string) => this.props.history.push(path)

  onSelectPortal = () => {
    const newWindow = window.open(
      project.environmentName !== 'production'
        ? '/downloadst.html'
        : '/download.html'
      , '_blank', 'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
    return
  }

  render() {
    // TODO: Handle later
    // const mapEraType = `${this.props.highlightEra!.highlightEra!.type.toLowerCase()}Total` as handleSummaryTotal
    // const mode = this.context.theme.mode
    const isMaintenance = get(
      this.props.displayConfig,
      `${project.environmentName}.${lowerCase(constants.defaultCompanyGame)}`,
      true
    )
    return (
      <div className="main-container secondary-bg-navy" ref={this.pageContainerRef}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <CreditCard
                    credit={this.props.wallet.money || 0}
                    onClick={() => this.onNavigateTo(routes.creditInfoReport.path)}
                  />
                </div>
              </div>
              <div className="row m2-t">
                <div className="col text-center">
                  <ALink
                    onClick={() =>
                      this.onNavigateTo(routes.qaType.exactPath('privacy'))
                    }
                    id="goto-privacy-condition"
                    color={colors.PRIMARY_TEXT}
                    underline
                  >
                    {constants.conditionText}
                  </ALink>
                </div>
              </div>
              {
                isMaintenance ? (
                  <div className="row m2-t m4-b">
                    <div className="col d-flex justify-content-center">
                      <div className="d-flex">
                        <a
                          href={
                            isMaintenance
                              ? interactive.openLink<IGame>({
                                ...get(this.props.gameList, '0', {}),
                                gameLocation: 'INTERNAL',
                                company: constants.defaultCompanyGame,
                              })
                              : '/'
                          }
                          target="_blank"
                          rel="noreferrer noopener"
                          className={`${!isMaintenance && 'disabled'}`}
                        >
                          <div className="flex d-flex content-heightlight">
                            <ResponsiveIcon
                              icon={HeightLightGame}
                              className="flex content-heightlight-image"
                            />
                            <div className="content-heightlight-hover d-flex align-items-center justify-content-center">
                              <h2>{constants.playNow}</h2>
                            </div>
                            {isMaintenance ? (
                              <></>
                            ) : (
                              <div className="closed-game-play">
                                <h3>{constants.closedGame}</h3>
                              </div>
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : <></>
              }
            </div>
            <div className="col-12 col-lg-8">
              <div className="row">
                <div className="col-12">
                  <div className="col-12 m3-b">
                    <div className="d-flex flex flex-column justify-content-center align-items-center jackpot-content">
                      <ResponsiveIcon icon={Jackpot} alt={``} />
                      <h1>
                        {number.castToMoney(
                          this.props.highlightEra!.highlightEra!.total
                        )}
                      </h1>
                      <div className="jackpot-ranking">
                        <span className="subtitle-2 pr-2 jackpot-label">
                          {constants.currentJackpotLabel}
                        </span>
                        <span
                          className={`body-1 m0-t jackpot-title jackpot-${this.props.highlightEra!.highlightEra!.type
                            }`}
                        >
                          {mapEra[this.props.highlightEra!.highlightEra!.type]}
                        </span>
                      </div>
                      <div className="row d-flex w-100">
                        <div className="col d-flex px-0">
                          <ProgressBar
                            min={this.props.highlightEra!.highlightEra!.average}
                            max={100}
                            era={`summary`}
                          />
                        </div>
                      </div>
                      <span
                        onClick={() => this.onNavigateTo(routes.jackpot.path)}
                        className={`subtitle-1 m1-t jackpot-${this.props.highlightEra!.highlightEra!.type
                          } button-nav`}
                      >
                        {constants.jackpotTitle}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-xl-6">
                  <div className="row">
                    <div className="col-12 m3-b">
                      <ButtonMenu
                        id="goto-lotto-button"
                        onClick={() => this.onNavigateTo(routes.lotto.path)}
                        text={constants.gotoLotto}
                        icon={LottoIcon}
                      />
                    </div>
                    <div className="col-6 m3-b">
                      <ButtonMenu
                        id="goto-casino-button"
                        onClick={() =>
                          this.onNavigateTo(routes.casino.path)
                        }
                        text={constants.gotoCasino}
                        icon={CasinoIcon}
                      />
                    </div>
                    <div className="col-6 m3-b">
                      <ButtonMenu
                        id="goto-slot-button"
                        onClick={() =>
                          this.onNavigateTo(routes.slot.path)
                        }
                        text={constants.gotoCasinoGame}
                        icon={SlotIcon}
                      />
                    </div>
                    {/* <div className="col-12 m3-b">
                      <ButtonMenu
                        id="goto-slot-button"
                        onClick={() => this.onNavigateTo(routes.games.path)}
                        text={constants.gotoTHBGame}
                        icon={GameIcon}
                      />
                    </div> */}
                    <div
                      className="col-12 m3-b"

                    >
                      <ButtonMenu
                        id="goto-lotto-result-button"
                        onClick={() =>
                          this.onNavigateTo(routes.lottoResult.path)
                        }
                        text={constants.gotoLottoResute}
                        icon={ResultIcon}
                      />
                    </div>
                    {/* {project.environmentName !== 'production' ? (
                      <div className="col-6 m3-b">
                        <ButtonMenu
                          id="goto-lotto-result-button"
                          onClick={() =>
                            this.onNavigateTo(routes.lottoHistory.path)
                          }
                          text={constants.gotoLottoHistory}
                          icon={LottoHistoryIcon}
                        />
                      </div>
                    ) : (
                      <></>
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-xl-6">
                  <div className="row">
                    <div className="col-12 m3-b">
                      {/* onClick={()=>this.onSelectPortal() */}
                      <div
                        className="button-menu-container notched-corners"
                        onClick={() => this.onSelectPortal()}
                      >
                        {/* <a href={'index.html'}> */}
                        <div className="corners">
                          <h6 className="text">{constants.new}</h6>
                        </div>
                        <div
                          className={`d-flex flex notched-corners-container `}
                        >
                          <div className="icon-wrapper d-flex align-items-end">
                            <ResponsiveIcon
                              icon={appIcon!}
                              className="icon-button-menu"
                              alt="button-menu"
                            />
                          </div>
                          <div className="flex d-flex align-items-center">
                            <h4 className="title-text-button">
                              {`${constants.downloadApp}`}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 m3-b">
                      <ButtonMenu
                        id="goto-credit-info-button"
                        onClick={() => this.onNavigateTo(routes.creditInfoReport.path)}
                        text={constants.gotoCredit}
                        icon={CreditIcon}
                      />
                    </div>
                    <div className="col-6 m3-b">
                      <ButtonMenu
                        id="goto-transaction-button"
                        onClick={() =>
                          this.onNavigateTo(routes.transaction.path)
                        }
                        text={constants.gotoWD}
                        icon={WalletIcon}
                      />
                    </div>
                    <div className="col-6 m3-b">
                      <ButtonMenu
                        id="goto-affilate-button"
                        onClick={() => this.onNavigateTo(routes.affiliate.path)}
                        text={constants.gotoAffli}
                        icon={MegaphoneIcon}
                      />
                    </div>
                    <div className="col-6 m3-b">
                      <ButtonMenu
                        id="goto-slot-button"
                        onClick={() =>
                          this.onNavigateTo(routes.lottoFavoriteManagement.path)
                        }
                        text={constants.gotoFavoriteNumber}
                        icon={FavoriteIcon}
                      />
                    </div>
                    <div className="col-6 m3-b">
                      <ButtonMenu
                        id="goto-contact-button"
                        onClick={() => this.onNavigateTo(routes.contactus.path)}
                        text={constants.gotoContact}
                        icon={ContactIcon}
                      />
                    </div>
                    <div className="col-6 m3-b">
                      <ButtonMenu
                        id="goto-news-button"
                        onClick={() => this.onNavigateTo(routes.newsroom.path)}
                        text={constants.gotoNews}
                        icon={ProblemIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MainContainer
