import { createAction } from 'typesafe-actions'
import {
  CONNECT_NOTIFICATION_USER_COUNT_SOCKET,
  CONNECTED_NOTIFICATION_USER_COUNT_SOCKET,
  CONNECT_NOTIFICATION_USER_COUNT_SOCKET_ERROR,
  DISCONNECT_NOTIFICATION_USER_COUNT_SOCKET,
  DISCONNECTED_NOTIFICATION_USER_COUNT_SOCKET,
  LOG_NOTIFICATION_USER_COUNT_SOCKET,
} from './constants'

const connectNotificationUserCountSocketAction = createAction(CONNECT_NOTIFICATION_USER_COUNT_SOCKET)

const connectedNotificationUserCountSocketAction = createAction(CONNECTED_NOTIFICATION_USER_COUNT_SOCKET)

const connectNotificationUserCountSocketErrorAction = createAction(
    CONNECT_NOTIFICATION_USER_COUNT_SOCKET_ERROR,
  resolve => (error: any) => resolve(error))

const disconnectNotificationUserCountSocketAction = createAction(DISCONNECT_NOTIFICATION_USER_COUNT_SOCKET)

const disconnectedNotificationUserCountSocketAction = createAction(DISCONNECTED_NOTIFICATION_USER_COUNT_SOCKET)

const logNotificationUserCountSocket = createAction(LOG_NOTIFICATION_USER_COUNT_SOCKET, resolve => (message: any) => resolve(message))

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  logNotificationUserCountSocket,
  connectNotificationUserCountSocketAction,
  connectedNotificationUserCountSocketAction,
  connectNotificationUserCountSocketErrorAction,
  disconnectNotificationUserCountSocketAction,
  disconnectedNotificationUserCountSocketAction,
}