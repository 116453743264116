import { combineReducers } from "redux";
import user from "./user/reducers";
import count from "./count/reducers";
import read from './read/reducers';

export default combineReducers({
  user,
  count,
  read,
});
