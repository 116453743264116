import { combineReducers } from 'redux'
import list from './list/reducers'
import detail from './detail/reducers'
import lottery from './lottery/reducers'
import lotteryBenefit from './report/reducers'
import gameBenefit from './reportGame/reducers'
import game from './game/reducers'
export default combineReducers({
  list,
  detail,
  lottery,
  lotteryBenefit,
  gameBenefit,
  game
})