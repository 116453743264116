import { createAction } from 'typesafe-actions'
import {
    GET_AWARD_LIST_PMPG_REQUEST,
    GET_AWARD_LIST_PMPG_SUCCESS,
    GET_AWARD_LIST_PMPG_FAILURE,
    GET_AWARD_LIST_PMPG_CANCEL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'


const getAwardListPmpgAction = createAction(GET_AWARD_LIST_PMPG_REQUEST)

const getAwardListPmpgSuccessAction = createAction(
    GET_AWARD_LIST_PMPG_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<IPmpgAwardList[]>>) => resolve(data))

const getAwardListPmpgFailureAction = createAction(
    GET_AWARD_LIST_PMPG_FAILURE,
    resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getAwardListPmpgCancelAction = createAction(GET_AWARD_LIST_PMPG_CANCEL)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAwardListPmpgAction,
    getAwardListPmpgSuccessAction,
    getAwardListPmpgFailureAction,
    getAwardListPmpgCancelAction,
}