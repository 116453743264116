import React, { FC, useState, useEffect } from 'react'
import { noop } from 'lodash'
import './collapse.style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

declare interface CollapseProps {
  expanded?: boolean
  maxExpandHeight?: number | string
  minCollapsedHeight?: number
  onStateChanged?(state: boolean): void
  RenderHeaderComponent(): any
  RenderBodyComponent(): any
  iconLeft?: boolean
}

const defaultProps: CollapseProps = {
  expanded: false,
  maxExpandHeight: 400,
  minCollapsedHeight: 0,
  onStateChanged() { noop() },
  RenderHeaderComponent() { return <></> },
  RenderBodyComponent() { return <></> },
  iconLeft:false
}

const Collapse: FC<CollapseProps> = ({
  expanded,
  maxExpandHeight,
  minCollapsedHeight,
  onStateChanged,
  RenderBodyComponent,
  RenderHeaderComponent,
  iconLeft
}) => {

  const [isExpand, setExpand] = useState<boolean>(false)

  useEffect(() => {
    setExpand(expanded!)
  }, [expanded])

  const handleOnClickHeader = () => {
    const state = !isExpand
    setExpand(state)
    onStateChanged!(state)
  }

  return (
    <div>
      <div className='d-flex flex-row align-items-center' onClick={handleOnClickHeader}>
        {iconLeft && <FontAwesomeIcon icon={faChevronRight} className={`ml-1 collapse-icon-letf ${isExpand&&"expand"}`} />}
        <RenderHeaderComponent />
      </div>
      <div className="collapse-wrapper" style={{ maxHeight: isExpand ? maxExpandHeight : minCollapsedHeight }}>
        <RenderBodyComponent />
      </div>
    </div>
  )
}

Collapse.defaultProps = defaultProps

export default Collapse