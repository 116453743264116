import React, { FC, useState } from 'react'
import { isEmpty, noop } from 'lodash'
import './button.style.scss'
import colors from 'constants/colors'
import { ResponsiveIcon } from 'components'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IButton = {
  id: '',
  text: '',
  onClick() { noop() },
  type: 'normal',
  size: 'medium',
  disabled: false,
  buttonType: 'button',
  textResponsive: false,
  backgroundColor: colors.PRIMARY_BLUE,
  backgroundHoverColor: colors.SECONDARY_BLUE,
  href: '',
  icon: '',
  styleContainer: '',
  textColor: colors.PRIMARY_TEXT,
  textClassName: '',
}

const Button: FC<IButton & DefaultProps> = (props) => {

  const {
    id,
    text,
    type,
    href,
    size,
    styleContainer,
    onClick,
    disabled,
    buttonType,
    textResponsive,
    backgroundColor,
    backgroundHoverColor,
    children,
    textColor,
    textClassName,
  } = props

  const containerClass = `col px-2 button-container ${type} ${size} ${styleContainer} ${disabled ? 'disabled' : ''}`

  const [hoverColor, setHoverColor] = useState(backgroundColor)

  const handleOnMouseOver = () => {
    setHoverColor(backgroundHoverColor)
  }

  const handleOnMouseLeave = () => {
    setHoverColor(backgroundColor)
  }


  return (
    isEmpty(href) ?
      // tslint:disable-next-line:jsx-wrap-multiline
      <button
        onMouseLeave={handleOnMouseLeave}
        onMouseOver={handleOnMouseOver}
        id={id}
        type={buttonType}
        style={{ backgroundColor: hoverColor }}
        className={containerClass}
        onClick={disabled ? noop : onClick}
      >
        <div className="button-text p1-x d-flex flex-row align-items-end">
          <ResponsiveIcon
            alt="btn-icon"
            className="button-icon"
            icon={props.icon!}
          />
          {textResponsive ? <h5 className={`text-responsive ${textClassName}`} style={{color: textColor}}>{text}</h5> :  <><h5 className={`${textClassName}`}>{text}</h5>{children}</>}
        </div>
      </button> :
      // tslint:disable-next-line:jsx-wrap-multiline
      <a href={href} style={{ textDecoration: 'none' }}>
        <button
          onMouseLeave={handleOnMouseLeave}
          onMouseOver={handleOnMouseOver}
          id={id}
          type={buttonType}
          style={{ backgroundColor: hoverColor }}
          className={containerClass}
          onClick={disabled ? noop : onClick}
        >
          <div className="button-text p1-x d-flex flex-row align-items-end">
            <ResponsiveIcon
              alt="btn-icon"
              className="button-icon"
              icon={props.icon!}
            />
            <h5>{text}</h5>{children}
          </div>
        </button>
      </a>
  )
}

Button.defaultProps = defaultProps

export default Button