import walletEpic from './wallet/epics'
import tokenEpic from './token/epics'
import meEpic from './me/epics'
import countEpic from './count/epics'
import codeCreditReportEpic from './codeCreditReport/epics'
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  ...walletEpic,
  ...tokenEpic,
  ...meEpic,
  ...countEpic,
  ...codeCreditReportEpic
]
