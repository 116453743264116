import { createAction } from 'typesafe-actions'
import {
  AFFILATE_WITHDRAWAL_REQUEST,
  AFFILATE_WITHDRAWAL_SUCCESS,
  AFFILATE_WITHDRAWAL_FAILURE,
  AFFILATE_WITHDRAWAL_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const affilateWithdrawalAction = createAction(
  AFFILATE_WITHDRAWAL_REQUEST,
  resolve => (data: { money: string }) => resolve(data))

const affilateWithdrawalSuccessAction = createAction(
  AFFILATE_WITHDRAWAL_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IAffilateWithdrawal>>) => resolve(data))

const affilateWithdrawalFailureAction = createAction(
  AFFILATE_WITHDRAWAL_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const affilateWithdrawalCancelAction = createAction(AFFILATE_WITHDRAWAL_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  affilateWithdrawalAction,
  affilateWithdrawalSuccessAction,
  affilateWithdrawalFailureAction,
  affilateWithdrawalCancelAction,
}