import { createContext } from 'react'
import { noop } from 'lodash'

export const LudensContext = createContext<ILudensContext>({
  theme: {
    mode: 'darkMode',
    changeMode(_: string) { noop() },
  },
  wallet: {
    shown: true,
    changeShown(_: boolean) { noop() },
  },
  drawer: {
    isDrawerOpen: false,
    setDrawerOpen(_: boolean) { noop() },
  },
  navbar: {
    isShowNavbar: false,
    setShowNavbar(_: boolean) { noop() },
  },
})
