import { string, object, ObjectSchema, number } from 'yup'
import { number as numberUtil } from 'utils'

const constants = {
  moneyRequired: 'กรุณากรอกจำนวนเงิน',
  moneyMinimunRequired: 'จำนวนเงินถอนขั้นต่ำ 300 บาท',
  moneyMaximunRequired: 'จำนวนเงินถอนได้ครั้งละไม่เกิน 100,000 บาท',
  secondaryMoneyMaximunRequired: 'จำนวนเงินถอนได้ครั้งละไม่เกิน ที่สามารถถอนได้',
}

const scheme: ObjectSchema<{ money: string; maxAmount: number }> = object().shape({
  money: string()
    .required(constants.moneyRequired)
    .minimumMoneyRequire(300, constants.moneyMinimunRequired)
    // .maximumMoneyRequire(100000, constants.moneyMaximunRequired)
    .transformIntegerMoney()
    .test('secondaryMaximumRequire',
      constants.secondaryMoneyMaximunRequired,
      function (value: string) {
        const numberValue = Number(numberUtil.castToInteger(value))
        return numberValue <= this.parent.maxAmount
      }),
  maxAmount: number(),
})

export default scheme