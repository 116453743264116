import project from 'constants/project'

export const POST_BET_LOTTERY_PULL_SAVE_REQUEST = 'POST_BET_LOTTERY_PULL_SAVE_REQUEST'
export const POST_BET_LOTTERY_PULL_SAVE_SUCCESS = 'POST_BET_LOTTERY_PULL_SAVE_SUCCESS'
export const POST_BET_LOTTERY_PULL_SAVE_FAILURE = 'POST_BET_LOTTERY_PULL_SAVE_FAILURE'
export const POST_BET_LOTTERY_PULL_SAVE_CANCEL = 'POST_BET_LOTTERY_PULL_SAVE_CANCEL'

export const initialState: ReducerState<IResBetLotteryPullSave> = {
  isFetching: false,
  code: 0,
  data: {
    betLotteryHistoryId: 0,
    betLotteryHistoryTrackId: [],
  },
  error: '',
}

export const endpoint = {
  postPullSave: `${project.environment[project.environmentName].api}/bet/lottery/pull/save`,
}