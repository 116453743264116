import { combineReducers } from 'redux'
import pmpgEra from './pmpgEra/reducers'
import summaryPmpg from './summary/reducers'
import awardListPmpg from './pmpgAwardList/reducers'


export default combineReducers({
    pmpgEra,
    summaryPmpg,
    awardListPmpg,
})