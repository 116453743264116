import { createAction } from "typesafe-actions";
import {
  GET_NOTIFICATION_USER_REQUEST,
  GET_NOTIFICATION_USER_SUCCESS,
  GET_NOTIFICATION_USER_FAILURE,
  GET_NOTIFICATION_USER_CANCEL,
} from "./constants";
import { AxiosResponse, AxiosError } from "axios";

const getNotificationUserAction = createAction(
  GET_NOTIFICATION_USER_REQUEST,
  (resolve) => (query: IQueryPagination) => resolve(query)
);

const getNotificationUserSuccessAction = createAction(
  GET_NOTIFICATION_USER_SUCCESS,
  (resolve) =>
    (data: AxiosResponse<APIResponse<IPagination<IV1GetNotificationUser>>>) =>
      resolve(data)
);

const getNotificationUserFailureAction = createAction(
  GET_NOTIFICATION_USER_FAILURE,
  (resolve) => (error: AxiosError<APIResponse>) => resolve(error)
);

const getNotificationUserCancelAction = createAction(
  GET_NOTIFICATION_USER_CANCEL
);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getNotificationUserAction,
  getNotificationUserSuccessAction,
  getNotificationUserFailureAction,
  getNotificationUserCancelAction,
};
