import React, { Component, ComponentClass, ChangeEvent } from 'react'
import { noop } from 'lodash'
import { SPACE } from 'constants/regex'
import './inputLotto.style.scss'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IInputTextProps = {
  placeholder: '',
  type: 'text',
  name: '',
  textAlign: 'left',
  value: '',
  errorMessage: '',
  toLowercase: false,
  noWhitespace: false,
  error: false,
  disabled: false,
  useNumberpad: false,
  hiddenErrorBlock: false,
  onBlur() { noop() },
  onFocus() { noop() },
  onChange() { noop() },
  setFieldValue() { noop() },
  underLine: false,
  autoComplete:''
}

const InputText = class extends Component<IInputTextProps & DefaultProps> {

  static defaultProps = defaultProps

  state: any = {
    isFocus: false
  }

  render() {
    const {
      name,
      value,
      onBlur,
      onChange,
      setFieldValue,
      type,
      textAlign,
      useNumberpad,
      placeholder,
      disabled,
      toLowercase,
      noWhitespace,
      underLine,
      errorMessage,
      autoComplete
    } = this.props

    const isUnderLine = underLine ? 'hide' : ''
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (toLowercase || noWhitespace) {
        const fieldValue = (event.target.value || '')
        const fieldName = event.target.name
        let castingValue = fieldValue
        if (toLowercase) {
          castingValue = castingValue.toLowerCase()
        }
        if (noWhitespace) {
          castingValue = castingValue.replace(SPACE, '')
        }
        setFieldValue!(fieldName, castingValue)
      } else {
        return onChange!(event)
      }
    }

    const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({isFocus:false});
      onBlur!(event);
    };

    const handleFocus = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({isFocus: true})
    };


    return (
      <div className={`input-lotto ${name}`}>
        <input
          disabled={disabled}
          id={name}
          name={name}
          type={type}
          value={value}
          autoCapitalize="none"
          pattern={useNumberpad ? '\\d*' : undefined}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          className="input-core"
          onFocus={handleFocus}
          placeholder={`${this.state.isFocus ? '' : placeholder}`}
          style={{ textAlign }}
          autoComplete={autoComplete}
        />
        <div className={`input-underline ${isUnderLine}`} />
        <div className='font-m15r error-msg'>{errorMessage}</div>
      </div>
    )
  }
} as ComponentClass<IInputTextProps>

export default InputText