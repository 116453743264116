import React, {
    FunctionComponent,
    createContext,
    ReactNode,
    useState
} from 'react'

const UserContext = createContext<UserContextType | null>(null)

interface UserProviderProps {
    children: ReactNode
}

const UserProvider: FunctionComponent<UserProviderProps> = (props) => {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [user, setUser] = useState<IUser | null>(null)

    const setContextUser = (user: IUser | null) => {
        if (user) {
            setUser(user)
            setIsAuthenticated(true)
        } else {
            setUser(null)
            setIsAuthenticated(false)
        }
    }

    return (
        <UserContext.Provider
            value={{
                isAuthenticated,
                user: user || null,
                setUser: setContextUser
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export { UserProvider, UserContext }