import { AxiosError, AxiosResponse } from "axios";
import {
  POST_NOTIFICATION_USER_READ_CANCEL,
  POST_NOTIFICATION_USER_READ_FAILURE,
  POST_NOTIFICATION_USER_READ_REQUEST,
  POST_NOTIFICATION_USER_READ_SUCCESS,
} from "./constants";
import { createAction } from "typesafe-actions";

const postNotificationUserReadAction = createAction(
  POST_NOTIFICATION_USER_READ_REQUEST,
  (resolve) => (data: IV1PostNotificationUserRead) => resolve(data)
);

const postNotificationUserReadSuccessAction = createAction(
  POST_NOTIFICATION_USER_READ_SUCCESS,
  (resolve) => (data: AxiosResponse<APIResponse<{}>>) => resolve(data)
);

const postNotificationUserReadFailureAction = createAction(
  POST_NOTIFICATION_USER_READ_FAILURE,
  (resolve) => (error: AxiosError<APIResponse>) => resolve(error)
);

const postNotificationUserReadCancelAction = createAction(
  POST_NOTIFICATION_USER_READ_CANCEL
);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postNotificationUserReadAction,
  postNotificationUserReadSuccessAction,
  postNotificationUserReadFailureAction,
  postNotificationUserReadCancelAction,
};
