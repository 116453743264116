import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import affiliateAction from 'reduxs/affilate/actions'
import loaderAction from 'reduxs/loader/actions'
import affiliateSelector from 'reduxs/affilate/selectors'
import userSelector from 'reduxs/user/selector'
import AffiliateWithdrawalContainer from './AffiliateWithdrawal.container'

const mapStateToProps = (state: RootReducers): IAffiliateWithdrawalProps => {
    const affilateWithdrawal = affiliateSelector.withdrawal(state)
    const wallet = userSelector.wallet(state)
    return {
        withdrawAffiliateIsFetching: affilateWithdrawal.isFetching!,
        withdrawAffiliateError: affilateWithdrawal.error!,
        withdrawAffiliateCode: affilateWithdrawal.code!,
        wallet: wallet,
    }
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IAffiliateWithdrawalActionProps => bindActionCreators({
    loader: loaderAction.loadingAction,
    withdrawAffiliate: affiliateAction.affilateWithdrawalAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(AffiliateWithdrawalContainer)