import project from "constants/project";
import { transformer } from "utils";

export const GET_NOTIFICATION_USER_REQUEST = "GET_NOTIFICATION_USER_REQUEST";
export const GET_NOTIFICATION_USER_SUCCESS = "GET_NOTIFICATION_USER_SUCCESS";
export const GET_NOTIFICATION_USER_FAILURE = "GET_NOTIFICATION_USER_FAILURE";
export const GET_NOTIFICATION_USER_CANCEL = "GET_NOTIFICATION_USER_CANCEL";

export const initialState: ReducerState<IPagination<IV1GetNotificationUser>> = {
  isFetching: false,
  code: 0,
  data: {
    dataList: [],
    limit: 0,
    page: 0,
    totalData: 0,
    totalPage: 0,
  },
  error: "",
};

export const endpoint = {
  notificationUser: (query?: IQueryPagination) => `${
    project.environment[project.environmentName].api
  }/v1/notification/user${transformer.urlSearchParams(query)}`,
};
