import { get } from 'lodash'
import prject from 'constants/project'
import { initialState as initialWalletState } from './wallet/constants'
import { initialState as initialRememberState } from './remember/constants'
import { initialState as initiaMeState } from './me/constants'
import { initialState as initiaCountState } from './count/constants'
import { initialState as initialClickRefState } from './clickRef/constants'

const count = (state: RootReducers): IUserCountState =>
    get(state, `${prject.name}.user.count`, initiaCountState)
const me = (state: RootReducers): IGetMeState =>
    get(state, `${prject.name}.user.me`, initiaMeState)
const wallet = (state: RootReducers): IWallet =>
    get(state, `${prject.name}.user.wallet`, initialWalletState)
const remember = (state: RootReducers): ILogin => {
    const originalRemember: ILogin = get(state, `${prject.name}.user.remember`, initialRememberState)
    if (originalRemember.remember) {
        const username = window.atob(originalRemember.username)
        const password = window.atob(originalRemember.password)
        return { ...originalRemember, username, password }
    }
    return originalRemember
}
const clickRef = (state: RootReducers): IClickRef =>
    get(state, `${prject.name}.user.clickRef`, initialClickRefState)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    me,
    wallet,
    count,
    remember,
    clickRef
}