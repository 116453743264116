import SAGAME_PRESENTER from './gamecamp_sa-gaming@2x.png'
import SEXYGAMING_PRESENTER from './gamecamp_sexy-bac@2x.png'
import DREAMGAMING_PRESENTER from './gamecamp_dream-gaming@2x.png'
import PRETTYGAMING_PRESENTER from './gamecamp_pretty-gaming@2x.png'
import WMCASINO_PRESENTER from './gamecamp_wm@2x.png'
import ALLBET_PRESENTER from './gamecamp_allbet-full@2x.png'
import W88_PRESENTER from './w88 22.12.png'

const presenterCasino: { name: TGameCompany | 'PRETTYGAMING', image: string }[] = [
    { name: 'SAGAME', image: SAGAME_PRESENTER },
    { name: 'AESEXY', image: SEXYGAMING_PRESENTER },
    { name: 'DREAMGAMING', image: DREAMGAMING_PRESENTER },
    { name: 'PRETTYGAMING', image: PRETTYGAMING_PRESENTER },
    { name: 'WMGAME', image: WMCASINO_PRESENTER },
    { name: 'ALLBET', image: ALLBET_PRESENTER },
    { name: 'W88', image: W88_PRESENTER }
]

export default presenterCasino