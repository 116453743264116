import React, { FC } from 'react'
import { noop } from 'lodash'
import { ResponsiveIcon, Button, ALink } from 'components'
import thailandIcon from 'assets/images/logo/logothailandbet02.png'
import welcome from 'assets/images/modal/mobile_welcome.png'
import casino from 'assets/images/modal/mobile_casino.png'
import { Modal } from '../..'
import './Announce.style.scss'
import colors from 'constants/colors'
import downloadIcon from 'assets/images/main/appdownload/download.png'

const constants = {
  title: 'ข่าวด่วน',
  description: 'กรุณารอการตรวจสอบสักครู่',
  actionText: 'เข้าใจแล้ว',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IAnnounceModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() { noop() },
  href:'',
  textHowTo:'',
  actionHowTo() { noop() },
}

const AnnounceModal: FC<IInfoModal & DefaultProps> = (props) => {

  return (
    <div className="announce-modal-container border-rounded">
      <div className="container">
        <div>
          <ResponsiveIcon
            alt="logo-thb"
            className="logo-thb mt-4"
            icon={thailandIcon}
          />
        </div>
        <h2 className="my-0 title-text">{props.title}</h2>
        <span id={'how-to'} onClick={props.actionHowTo} className='how-to button-link-text-bold my-md-3' >{props.textHowTo}</span>
        <div className="d-flex flex-row justify-content-center mb-2">
          <div>
            <ResponsiveIcon
              alt="announce-icon"
              className="announce-checked-icon"
              icon={welcome}
            />
          </div>
          <div>
            <ResponsiveIcon
              alt="announce-icon"
              className="announce-checked-icon"
              icon={casino}
            />
          </div>
        </div>
        <div className="my-2">
          <h3 className="primary-red-text description-text">{props.description}</h3>
          <h5 className="body-2 secondary-text detail-text">{props.detail}</h5>
        </div>
        <div className="m2-y">
          <Button
            id="announce-ok-button"
            type="rectangle"
            size="large"
            href={props.href}
            onClick={props.action}
            text={props.actionText!}
            icon={downloadIcon}
          />
        </div>
        <div className="m2-y">
          <ALink
            id="cancel"
            onClick={props.actionCancle}
            fontSize={18}
            color={colors.PRIMARY_TEXT}
          >{props.actionCancleText}</ALink>
        </div>
      </div>

    </div>
  )
}

AnnounceModal.defaultProps = defaultProps

const successModal = {
  show: (props: IAnnounceModal) => {
    return Modal.event('MODAL_OVER', {
      state: 'show',
      extraProps: { size: 'xl' },
      component: (<AnnounceModal {...props} />),
    })
  },
  hide: () => Modal.event('MODAL_OVER', { state: 'hide' }),
}

export default successModal