import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import userAction from 'reduxs/user/actions'
import lottoAction from 'reduxs/lotto/actions'
import LottoHistoryContainer from './LottoHistory.container'
import lottoSelector from 'reduxs/lotto/selectors'

const mapStateToProps = (state: RootReducers): ILottoHistoryProps => {

  const lottoHistory = lottoSelector.lottoHistoryList(state)
  return ({
    getLottoHistoryListCode: lottoHistory.code!,
    getLottoHistoryListIsError: lottoHistory.error!,
    getLottoHistoryListIsFetching: lottoHistory.isFetching!,
    lottoHistory: lottoHistory.data!,
    lottoHistoryPagination: lottoHistory.pagination!,
})
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ILottoHistoryActionProps => bindActionCreators({
  loader: loaderAction.loadingAction,
  getUser: userAction.getMeAction,
  getLottoHistoryList: lottoAction.getBetHistoryAction,
  clearLottoHistoryList: lottoAction.clearLottoHistoryList,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LottoHistoryContainer)