import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import userCountAction from 'reduxs/user/actions'
import LandingContainer from './Landing.container'
import userCountSelector from 'reduxs/user/selector'

const mapStateToProps = (state: RootReducers): ILandingProps => {
  const countUser = userCountSelector.count(state)
  return {
    CountList: countUser.data!,
    getCountCode: countUser.code!,
    getCountError: countUser.devMessage!,
    getCountIsFetching: countUser.isFetching!,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<RootAction>
): ILandingActionProps =>
  bindActionCreators(
    {
      loader: loaderAction.loadingAction,
      getCount: userCountAction.getCountAction,
      clearCount: userCountAction.getCountCancelAction,
      clickRef: userCountAction.clickRef,
      clearClickRef: userCountAction.clearClickRef,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LandingContainer)
