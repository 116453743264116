import lotto from './lotto/epics'
import me from './me/epics'
import display from './display/epics'
import betLimit from './betLimit/epics'
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  ...lotto,
  ...me,
  ...display,
  ...betLimit,
]
