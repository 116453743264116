import axios from 'axios'
import project from 'constants/project'
import { endpoint } from './constants'
import cryptoJS from 'crypto-js'

export const fetchLogin = (data: ILogin) => {
    const encryptPassword =
        cryptoJS.AES.encrypt(
            data.password,
            project.environment[project.environmentName].aesSecretKey
        ).toString()
    const loginData: ILogin = {
        username: data.username,
        password: encryptPassword,
        remember: data.remember,
    }
    return axios.post(endpoint.login, loginData)
}