import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const getCreditInfoListReducer = (
  state: ICreditInfoListState = initialState,
  action: RootAction
): ICreditInfoListState => {
  switch (action.type) {
    case getType(actions.getCreditInfoListAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getCreditInfoListSuccessAction):
      const data = (action.payload.isFirst)
        ? action.payload.data.data.dataList
        : [...(state.data || []), ...(action.payload.data.data.dataList || [])]
      return {
        isFetching: false,
        data,
        code: action.payload.status,
        pagination: action.payload.data.data,
      }
    case getType(actions.getCreditInfoListFailureAction):
      return {
        isFetching: false,
        error: action.payload.response?.data.devMessage,
        code: action.payload.response?.data.code,
      }
    case getType(actions.clearCreditInfoList):
      return initialState
    default:
      return state
  }
}

export default getCreditInfoListReducer