import { camelCase, snakeCase, keys, split, head, last, get, map } from 'lodash'

const isArray = (array: any) => Array.isArray(array)

const isObject = (object: any) => object === Object(object) && !isArray(object) && typeof object !== 'function';

const camelcaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any }
    const newObject: { [name: string]: any } = {}
    keys(objectData).forEach((key) => {
      newObject[camelCase(key)] = camelcaseTransform(objectData[key]);
    })
    return newObject
  } else if (isArray(data)) {
    const arrayData = data as []
    const newArray = arrayData.map((i) => camelcaseTransform(i))
    return newArray
  }
  return data
}

const snakecaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any }
    const newObject: { [name: string]: any } = {}
    keys(objectData).forEach((key) => {
      newObject[snakeCase(key)] = snakecaseTransform(objectData[key]);
    })
    return newObject
  } else if (isArray(data)) {
    const arrayData = data as []
    const newArray = arrayData.map((i) => snakecaseTransform(i))
    return newArray
  }
  return data
}

const hiddenString = (text: string): string => {
  const splitedString = split(text, '')
  const firstString = head(splitedString)
  const lastString = last(splitedString)
  return `${firstString}****${lastString}`
}

const urlSearchParams = <T = any>(data: T): string => {
  const dataKeys = keys(data) || []
  const params = new URLSearchParams()
  map(dataKeys, (key) => {
    const queryValue = get(data, key, '')
    if (queryValue) {
      params.append(key, queryValue)
    }
  })

  if (params.toString()) {
    return `?${params.toString()}`
  }
  return ''
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const queryToObject = <T>(query: string): any => {
  const urlParams = new URLSearchParams(query)
  const entity = Object.fromEntries(urlParams)
  return entity
}


const checkSizeDevice = (windowHeight: number): "xxs" | "xs" | "s" | "m" | "l" => {
  switch (true) {
    case windowHeight < 600:
      return "xxs"
    case windowHeight < 700:
      return "xs"
    case windowHeight < 790:
      return "s"
    case windowHeight < 899:
      return "m"
    case windowHeight > 900:
      return "l"

    default:
      return "l"
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  camelcaseTransform,
  snakecaseTransform,
  urlSearchParams,
  hiddenString,
  queryToObject,
  checkSizeDevice
}