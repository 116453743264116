import colors from 'constants/colors'
import { isEmpty, noop } from 'lodash'
import React, { FC, useState } from 'react'
import './customButton.styles.scss'
// import LogoThailandBet from 'assets/images/logo/logothailandbet04.png'

interface ICustomButton {
  id: string
  text: string
  onClick?(): void
  type?: ButtonStyle
  size?: ButtonSize
  disabled?: boolean
  buttonType?: ButtonType
  backgroundColor?: string
  backgroundHoverColor?: string
  href?: string
  backgroundIcon?: string
  buttonPaddingClass?: string
}

type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: ICustomButton = {
  id: '',
  text: '',
  onClick() {
    noop()
  },
  type: 'normal',
  size: 'medium',
  disabled: false,
  buttonType: 'button',
  backgroundColor: colors.PRIMARY_BG,
  backgroundHoverColor: colors.SECONDARY_BG,
  href: '',
  backgroundIcon: '',
  buttonPaddingClass: '',
}
const CustomButtonComponent: FC<ICustomButton & DefaultProps> = (props) => {
  const {
    id,
    text,
    type,
    href,
    size,
    onClick,
    disabled,
    buttonType,
    backgroundColor,
    backgroundHoverColor,
    buttonPaddingClass,
  } = props

  const containerClass = `col px-2 button-custom-container ${type} ${size} ${
    disabled ? 'disabled' : ''
  }
${buttonPaddingClass}
  `

  const [hoverColor, setHoverColor] = useState(backgroundColor)

  const handleOnMouseOver = () => {
    setHoverColor(backgroundHoverColor)
  }

  const handleOnMouseLeave = () => {
    setHoverColor(backgroundColor)
  }

  return isEmpty(href) ? (
    <div className="button-custom-container-extra-wrapper">
      <button
        onMouseLeave={handleOnMouseLeave}
        onMouseOver={handleOnMouseOver}
        id={id}
        type={buttonType}
        style={{ backgroundColor: hoverColor }}
        className={containerClass}
        onClick={disabled ? noop : onClick}
      >
        <div className="button-custom-text p1-r d-flex flex-row align-items-end">
          <img
            alt="btn-icon"
            className="button-custom-icon"
            src={props.backgroundIcon!}
          />
          <h5 className="text-colored text-center">{text}</h5>
        </div>
      </button>
    </div>
  ) : (
    <div className="button-custom-container-extra-wrapper">
      <a
        href={href}
        style={{
          textDecoration: 'none',
        }}
      >
        <button
          onMouseLeave={handleOnMouseLeave}
          onMouseOver={handleOnMouseOver}
          id={id}
          type={buttonType}
          style={{ backgroundColor: hoverColor, position: 'relative' }}
          className={containerClass}
          onClick={disabled ? noop : onClick}
        >
          <div className="button-custom-text p1-x d-flex flex-row align-items-end">
            <img
              alt="btn-icon"
              className="button-custom-icon"
              src={props.backgroundIcon!}
            />
            <h5 className="text-colored">{text}</h5>
          </div>
        </button>
      </a>
    </div>
  )
}

CustomButtonComponent.defaultProps = defaultProps

export default CustomButtonComponent
