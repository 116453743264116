import { createAction } from 'typesafe-actions'
import {
  AFFILATE_CLICK_REQUEST,
  AFFILATE_CLICK_SUCCESS,
  AFFILATE_CLICK_FAILURE,
  AFFILATE_CLICK_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const affilateClickAction = createAction(
  AFFILATE_CLICK_REQUEST,
  resolve => (data: { uuid: string }) => resolve(data))

const affilateClickSuccessAction = createAction(
  AFFILATE_CLICK_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IAffilateClick>>) => resolve(data))

const affilateClickFailureAction = createAction(
  AFFILATE_CLICK_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const affilateClickCancelAction = createAction(AFFILATE_CLICK_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  affilateClickAction,
  affilateClickSuccessAction,
  affilateClickFailureAction,
  affilateClickCancelAction,
}