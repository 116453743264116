import { combineReducers } from 'redux'
import lotto from './lotto/reducers'
import me from './me/reducers'
import display from './display/reducers'
import betLimit from './betLimit/reducers'
export default combineReducers({
  lotto,
  me,
  display,
  betLimit,
})