import axios from 'axios'
import { endpoint } from './constants'
import { get } from 'lodash'

export const fetchGetBankList = (data: IGetWebBankParams) => {
    const queryParams = new URLSearchParams({
        operate_type: String(get(data,'operateType','')),
    })
    return axios.get(endpoint.getBankList, {
        params: queryParams,
    })
}