import project from "constants/project";

export const POST_NOTIFICATION_CLICK_REQUEST =
  "POST_NOTIFICATION_CLICK_REQUEST";
export const POST_NOTIFICATION_CLICK_SUCCESS =
  "POST_NOTIFICATION_CLICK_SUCCESS";
export const POST_NOTIFICATION_CLICK_FAILURE =
  "POST_NOTIFICATION_CLICK_FAILURE";
export const POST_NOTIFICATION_CLICK_CANCEL = "POST_NOTIFICATION_CLICK_CANCEL";

export const initialState: ReducerState<{}> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
};

export const endpoint = {
  postNotificationClick: `${
    project.environment[project.environmentName].api
  }/v1/notification/click`,
};
