import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import lotterAction from 'reduxs/lotto/actions'
import loaderAction from 'reduxs/loader/actions'
import lottoSelector from 'reduxs/lotto/selectors'
import LottoOldFavoriteContainer from './LottoOldFavorite.container'

const mapStateToProps = (state: RootReducers): IOldFavoriteLottoProps => {
  const betPullList = lottoSelector.betLottoPullList(state)
  return ({
    getOldFavoriteLottoListIsFetching: betPullList.isFetching!,
    getOldFavoriteLottoListCode: betPullList.code!,
    getOldFavoriteLottoListError: betPullList.error!,
    lottoOldFavoriteList: betPullList.data!,
  })
}



const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IOldFavoriteLottoActionProps => bindActionCreators({
  getOldFavoriteLottoList: lotterAction.getOldFavoriteLottoListAction,
  clearOldFavoriteLottoList: lotterAction.clearOldFavoriteLottoListAction,
  loader: loaderAction.loadingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LottoOldFavoriteContainer)