import project from 'constants/project'
import { transformer } from 'utils'

export const CONNECT_GAME_REQUEST = 'CONNECT_GAME_REQUEST'
export const CONNECT_GAME_SUCCESS = 'CONNECT_GAME_SUCCESS'
export const CONNECT_GAME_FAILURE = 'CONNECT_GAME_FAILURE'
export const CONNECT_GAME_CANCEL = 'CONNECT_GAME_CANCEL'

export const initialState: ReducerState<string> = {
  isFetching: false,
  code: 0,
  data: '',
  error: '',
}

export const endpoint = {
  connectExternalGame: (query: IGameConnectQuery) => `${project.environment[project.environmentName].api}/game/connect${transformer.urlSearchParams(query)}`,
  connectInternalGame: (query: IGameConnectQuery) => `${project.environment[project.environmentName].api}/internal-game/connect${transformer.urlSearchParams(query)}`,
}