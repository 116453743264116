import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import lotterAction from 'reduxs/lotto/actions'
import loaderAction from 'reduxs/loader/actions'
import configAction from 'reduxs/config/actions'
import lottoSelector from 'reduxs/lotto/selectors'
import confSelector from 'reduxs/config/selectors'
import LottoMakeContainer from './LottoMake.container'
import userAction from 'reduxs/user/actions'
const mapStateToProps = (state: RootReducers): IMakingLottoProps => {
  const betRates = lottoSelector.betRates(state)
  const betPullSave = lottoSelector.betLottoPullSave(state)
  const betNumberRates = lottoSelector.betNumberRate(state)
  const yeegeGameHistory = lottoSelector.yeegeGameHistory(state)
  const betLimitConf = confSelector.betLimitConfig(state)
  const betPullList = lottoSelector.betLottoPullList(state)
  return ({
    wallet: state.ludens.user.wallet,
    makingBetLottoIsFetching: state.ludens.lotto.bet.make.isFetching!,
    makingBetLottoError: state.ludens.lotto.bet.make.error!,
    makingBetLottoCode: state.ludens.lotto.bet.make.code!,
    makingBetLottoResult: state.ludens.lotto.bet.make.data!,
    playYeegeIsFetching: state.ludens.lotto.yeege.play.isFetching!,
    playYeegeError: state.ludens.lotto.yeege.play.error!,
    playYeegeCode: state.ludens.lotto.yeege.play.code!,
    playYeegeResult: state.ludens.lotto.yeege.play.data!,
    getYeegeSumIsFetching: state.ludens.lotto.yeege.sum.isFetching!,
    getYeegeSumError: state.ludens.lotto.yeege.sum.error!,
    getYeegeSumCode: state.ludens.lotto.yeege.sum.code!,
    yeegeSum: state.ludens.lotto.yeege.sum.data!,
    getPlayedYeegeListIsFetching: state.ludens.lotto.yeege.playedList.isFetching!,
    getPlayedYeegeListError: state.ludens.lotto.yeege.playedList.error!,
    getPlayedYeegeListCode: state.ludens.lotto.yeege.playedList.code!,
    playedYeegeList: state.ludens.lotto.yeege.playedList.data!,
    getBetResultIsFetching: state.ludens.lotto.bet.result.isFetching!,
    getBetResultError: state.ludens.lotto.bet.result.error!,
    getBetResultCode: state.ludens.lotto.bet.result.code!,
    betResults: state.ludens.lotto.bet.result.data!,
    betRates: betRates.data!,
    getLottoGameIsFetching: state.ludens.lotto.game.isFetching!,
    getLottoGameCode: state.ludens.lotto.game.code!,
    getLottoGameError: state.ludens.lotto.game.error!,
    lottoGame: state.ludens.lotto.game.data!,
    getBetNumberRateIsFetching: betNumberRates.isFetching!,
    getBetNumberRateCode: betNumberRates.code!,
    getBetNumberRateError: betNumberRates.error!,
    betRateNumbers: betNumberRates.data!,
    getYeegeGameHistoryIsFetching: yeegeGameHistory.isFetching!,
    getYeegeGameHistoryCode: yeegeGameHistory.code!,
    getYeegeGameHistoryError: yeegeGameHistory.error!,
    yeegeGameHistory: yeegeGameHistory.data!,
    getBetLimitIsFetching: betLimitConf.isFetching!,
    getBetLimitCode: betLimitConf.code!,
    getBetLimitError: betLimitConf.error!,
    betLimitConf: betLimitConf.data!,
    postBetLotteryPullSaveIsFetching: betPullSave.isFetching!,
    postBetLotteryPullSaveCode: betPullSave.code!,
    postBetLotteryPullSaveError: betPullSave.error!,
    betLotteryPullSave: betPullSave.data!,

    getNumberBanIsFetching: state.ludens.lotto.ban.isFetching!,
    getNumberBanCode: state.ludens.lotto.ban.code!,
    getNumberBanError: state.ludens.lotto.ban.error!,
    numberBan: state.ludens.lotto.ban.data!,

    getOldFavoriteLottoListIsFetching: betPullList.isFetching!,
    getOldFavoriteLottoListCode: betPullList.code!,
    getOldFavoriteLottoListError: betPullList.error!,
    lottoOldFavoriteList: betPullList.data!,
  })
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IMakingLottoActionProps => bindActionCreators({
  getUser: userAction.getMeAction,
  getLottoGame: lotterAction.getLottoAction,
  getBetRate: lotterAction.getBetRateAction,
  getPlayedYeegeList: lotterAction.getPlayedYeegeListAction,
  listenPlayedYeegeList: lotterAction.listenPlayedYeegeListSocket,
  unlistenPlayedYeegeList: lotterAction.unlistenPlayedYeegeListSocket,
  makingBetLotto: lotterAction.makingBetLottoAction,
  getYeegeSum: lotterAction.getYeegeSumAction,
  getNumberBan: lotterAction.getNumberBanAction,
  clearNumberBan: lotterAction.getNumberBanCancelAction,
  listenYeegeSum: lotterAction.listenYeegeSumSocket,
  unlistenYeegeSum: lotterAction.unlistenYeegeSumSocket,
  getBetResult: lotterAction.getBetResultAction,
  playYeege: lotterAction.playYeegeAction,
  loader: loaderAction.loadingAction,
  clearBetResult: lotterAction.clearBetResultAction,
  getBetNumberRate: lotterAction.getBetNumberRateAction,
  getYeegeGameHistory: lotterAction.getYeegeGameHistoryAction,
  clearYeegeGameHistory: lotterAction.clearYeegeGameHistory,
  getbetLimitConf: configAction.getBetLimitAction,
  postBetPullSave: lotterAction.postBetPullSaveAction,
  getOldFavoriteLottoList: lotterAction.getOldFavoriteLottoListAction,
  clearOldFavoriteLottoList: lotterAction.clearOldFavoriteLottoListAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LottoMakeContainer)