import React, { FC } from 'react'
import { isEmpty, map } from 'lodash'
import { date, transformer } from 'utils'
import { LOTTO_GAME_TYPE_NAME } from 'constants/variables'
import './gameHistory.style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Scrollbar from 'react-scrollbars-custom'

declare interface IGameHistoryComponentProps {
    history: IYeegeGameHistory[],
    onClick?(): void,
    maxHeight?:number,
    isCollapseProps:boolean
}

const constants = {
    statistic: 'สถิติการออกย้อนหลัง',
    outdate: 'วันที่',
    rage: 'อันดับรายได้ของผู้ชนะ',
}

const windowHeight = window.innerHeight

const GameHistory: FC<IGameHistoryComponentProps> = ({
    history,
    onClick,
    maxHeight,isCollapseProps
}) => {
    const numbersComponent = (numberString: string, type: TLottoGameType) => {
        return () => (
            <div className="d-flex flex-column flex align-items-center justify-content-center">
                <h6 className="secondary-text-gold font-m15r">{LOTTO_GAME_TYPE_NAME[type]}</h6>
                <h5 className='font-m20r white-text'>{numberString}</h5>
            </div>
        )
    }

    const historyComponent = (game: IYeegeGameHistory, historyIndex: number) => {
        const NumberThreeUp = numbersComponent(game.threeUp, 'THREE_UP')
        const NumberTwoUp = numbersComponent(game.twoUp, 'TWO_UP')
        const NumberTwoDown = numbersComponent(game.twoDown, 'TWO_DOWN')
        return (
            <div
                className={`history-item-wrapper tertiary-bg-blue-new ${isCollapseProps ? '' : 'expanded'}`} key={`game-history-${historyIndex}`}
            >
                <div className="col">
                    <h6 className="flex font-m15r white-text">
                        {constants.outdate} {date.calibratingTime(game.createdAt).format('DD MMM')}
                    </h6>
                    <div className="d-flex flex-row">
                        <NumberThreeUp />
                        <NumberTwoUp />
                        <NumberTwoDown />
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row">
                            <h6 className="flex text-center font-m12r">
                                {constants.rage}
                            </h6>
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row">
                            <h6 className="flex text-center font-m15r white-text">
                                <span className="font-m15r">1:</span>
                                {' '}
                                {game.totalOneBetResult}
                            </h6>
                            <h6 className="flex text-center font-m15r white-text">
                                <span className="font-m15r">2:</span>
                                {' '}
                                {game.totalTwoBetResult}
                            </h6>
                            <h6 className="flex text-center font-m15r white-text">
                                <span className="font-m15r">3:</span>
                                {' '}
                                {game.totalThreeBetResult}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // const heightHeader = () => get(document.getElementById('header'),"clientHeight",0);

    return (
        <div
            style={!isCollapseProps?{maxHeight:maxHeight,height:maxHeight}:{}}
            className={`game-history-container ${transformer.checkSizeDevice(windowHeight)} border-rounded tertiary-bg-navy px-2 py-1 mb-2`}
        >
            <div id="header" className="history-title-wrapper"
                onClick={() => { onClick!() }}
            >
                <div className={`d-flex flex-row align-items-center ${!isCollapseProps&&"mb-2"}`}>
                    <h4 className="flex font-m23r secondary-text-gold">{constants.statistic}</h4>
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        className={`chevron-right-icon font-m25r ${isCollapseProps ? '' : 'expanded'} secondary-text-gold`}
                    />
                </div>
            </div>


            <Scrollbar
                id="separator-history-list"
                removeTracksWhenNotUsed 
                disableTracksWidthCompensation 
            >
            {!isCollapseProps&&<div
                // style={!isCollapseProps?{maxHeight:(maxHeight||0)-(heightHeader()+16)}:{}}
                className={`separator-history-list bg-history tertiary-bg-blue mb-3 ${!isCollapseProps&&!isEmpty(history) ? 'open p-2' : ''}`}
            >
                {map(history, historyComponent)}
            </div>}
            </Scrollbar>
        </div>
    )
}

export default GameHistory