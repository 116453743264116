import React, { FC } from 'react'
// import { number } from 'utils'
import winner from 'assets/images/creditinfo/bonutBG.png'
import './transactionItemReport.style.scss'
import { capitalize, get, includes, isEmpty, upperCase } from 'lodash'
import BronzeIcon from 'assets/images/creditinfo/BronzeIcon.png'
import SilverIcon from 'assets/images/creditinfo/SilverIcon.png'
import GoldIcon from 'assets/images/creditinfo/GoldIcon.png'
import TinIcon from 'assets/images/creditinfo/TinIcon.png'
import {GAME_TYPE} from 'constants/variables'

const constants = {
  bet: 'เดิมพัน',
  pay: 'จ่าย',
  day: 'วันที่ '
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ITransactionItemReport = {
  numbers: '',
  status: '',
  money: 0,
  time: '',
  type: '',
  containerClassName: '',
  day:'',
  bet:'',
  result:'',
  rate:'',
  isGame:false,
  slug:'',
  wordSpecial:''
}

const TransactionItem: FC<ITransactionItemReport & DefaultProps> = (props) => {

  const {
    status,
    rate,
    numbers,
    time,
    type,
    onClick,
    day,
    bet,
    containerClassName,
    result,
    isGame,
    slug,
    wordSpecial
  } = props

  const statusText = () => {
    if (status === 'WAIT') {
      return 'รอผล'
    }else if (status === 'WINNER') {
      return `${isGame?'ชนะ':'ถูกรางวัล'}`
    }else if (status === 'LOSER') {
      return 'เสีย'
    }else if (status === 'CANCEL') {
      return 'ถูกยกเลิก'
    }else if (status === 'DRAW') {
      return 'เสมอ'
    }else if (isBonut) {
      return 'โบนัส'
    }
    return ''
  }

  const statusColor =()=>{
    if (status === 'WAIT') {
      return 'primary-yellow-text'
    }else if (status === 'WINNER') {
      return  'primary-green-text'
    }else if (status === 'LOSER') {
      return 'primary-red-text'
    }else if (status === 'CANCEL' || status === 'DRAW') {
      return 'secondary-text'
    }else if (isBonut) {
      if (status === 'PMPG_BRONZE_AWARD') {
        return 'primary-blue-text'
      }else if (status === 'PMPG_GOLD_AWARD') {
        return 'primary-gold-text'
      }else if (status === 'PMPG_SILVER_AWARD') {
        return 'secondary-red-text'
      }else if (status === 'PMPG_TIN_AWARD') {
        return 'primary-tin-text'
      }
    }
    return ''
  }
  const isBonut = includes(status,'PMPG')
  const bonut=()=>{
    if (isBonut) {
      if (status === 'PMPG_BRONZE_AWARD') {
        return 'ทองแดง'
      }else if (status === 'PMPG_GOLD_AWARD') {
        return 'ทอง'
      }else if (status === 'PMPG_SILVER_AWARD') {
        return 'เงิน'
      }else if (status === 'PMPG_TIN_AWARD') {
        return 'ดีบุก'
      }else{
        return ''
      }
    }
  }
  const iconBonut=()=>{
    if (isBonut) {
      if (status === 'PMPG_BRONZE_AWARD') {
        return BronzeIcon
      }else if (status === 'PMPG_GOLD_AWARD') {
        return GoldIcon
      }else if (status === 'PMPG_SILVER_AWARD') {
        return SilverIcon
      }else if (status === 'PMPG_TIN_AWARD') {
        return TinIcon
      }else{
        return ''
      }
    }
  }

  const renderBetRate = () =>{
    if (isBonut) {
      return (<div className="d-flex flex-column align-items-center">
        <h6 className={`transaction-numbers ${statusColor()}`}>{bonut()}</h6>
        <h6 className="transaction-numbers">{result}</h6>
      </div>)
    }else {
      if (!isGame) {
        return <>
          <div className="d-flex flex-row">
            <h6 className="transaction-betpay">{constants.bet}</h6>
            <h6 className="transaction-numbers">{bet}</h6>
          </div>
          <div className="d-flex flex-row align-items-center">
            <h6 className="transaction-betpay">{constants.pay}</h6>
            <h6 className="transaction-numbers">{rate}</h6>
          </div>
        </>
      }else{
        return <>
          <div className="d-flex flex-column align-items-center">
            <h6 className="transaction-numbers">{constants.bet}</h6>
            <h6 className="transaction-numbers">{bet}</h6>
          </div>
        </>
      }
    }
  }

  const nameGame=()=>{
    const gameNameUnderscore = (slug || '').replace(/GAME_CASINO_JOKER_|GAME_CASINO_THBGAME_|GAME_CASINO_W88_|GAME_CASINO_AESEXY_|GAME_CASINO_DT_|GAME_CASINO_PGGAME_|GAME_CASINO_WMGAME_|GAME_CASINO_ALLBET_/, '')
    const removeRound = gameNameUnderscore.substring(0, gameNameUnderscore.lastIndexOf('_'))
    return capitalize(upperCase(removeRound))
  }

  return (
    <div className="container-padding">
      <div className={`row ${containerClassName} container-transactionReport`} onClick={onClick}>
        <div className={`col d-flex flex-row transactionReport-item-container ${status}`}>
          <div className={`${isGame?'col-3':'col-2'} d-flex flex-column align-items-center box-border justify-content-center`}>
            {!isBonut ?
              <>
                <div className="transaction-numbers">{isGame?get(GAME_TYPE,type):numbers}</div>
                { isGame ? 
                  <h6 className="detail-game text-truncate">
                    {( isEmpty(nameGame()) || nameGame() === 'Undefined' )? '':nameGame()}
                  </h6> :
                  includes(slug,'LOTTER_YEGEE')?<h3 className="round-text">{`(รอบที่ ${Number(slug?.substring(slug?.length-3, slug?.length))})`}</h3>:<></>
                }
              </>
              :
              <>
                <img
                  className="icon-bonus pr-1"
                  src={iconBonut()}
                  alt={`icon-bonus-${status}`}
                />
              </>
            }
          </div>

          <div className="col d-flex flex-column box-border justify-content-center">
            {renderBetRate()}
          </div>

          <div className="col-3 d-flex flex-column text-center box-border justify-content-center">
            <div className={`text-status ${statusColor()}`}>{statusText()}</div>
          </div>

          <div className="col-2 d-flex flex-column text-center justify-content-center">
            <h6 className="transaction-datetime">{constants.day}<span>{day}</span></h6>
            <h6 className="transaction-datetime">{time}</h6>
          </div>
        </div>
        {status === 'WINNER'&&
        <div className="boxWinner">
        <div className="winner-bg">
          <img
            className="winner-imgL imgSize"
            src={winner}
            alt={`winner`}
          />
          <img
            className="winner-imgR imgSize"
            src={winner}
            alt={`winner`}
          />
          <h3 className="winner-text subtitle-2 text-center">{isEmpty(wordSpecial)?'สุดปัง':wordSpecial}</h3>
          <h3 className="winner-value text-center">{result}</h3>
        </div>
        </div>}
      </div>
    </div>
  )
}

TransactionItem.defaultProps = defaultProps

export default TransactionItem