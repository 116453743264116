import project from 'constants/project'

export const GET_BET_HISTORY_REQUEST = 'GET_BET_HISTORY_REQUEST'
export const GET_BET_HISTORY_SUCCESS = 'GET_BET_HISTORY_SUCCESS'
export const GET_BET_HISTORY_FAILURE = 'GET_BET_HISTORY_FAILURE'
export const GET_BET_HISTORY_CANCEL = 'GET_BET_HISTORY_CANCEL'
export const CLEAR_BET_HISTORY_LIST = 'CLEAR_BET_HISTORY_LIST'

export const initialState: ILottoHistoryListState = {
  isFetching: false,
  code: 0,
  data: [],
  pagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  error: '',
}


export const endpoint = {
  getBetHistory: `${project.environment[project.environmentName].api}/lotter/bet/me`,
}