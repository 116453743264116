import React, {
    FunctionComponent,
    createContext,
    ReactNode,
    useState
} from 'react'

const LayoutContext = createContext<LayoutContextType | null>(null)

interface LayoutProviderProps {
    children: ReactNode
}

const LayoutProvider: FunctionComponent<LayoutProviderProps> = (props) => {

    const [textRunning, setTextRunning] = useState<boolean>(true)

    return (
        <LayoutContext.Provider
            value={{
                isRenderTextRunner: textRunning,
                setRenderTextRunner: setTextRunning,
            }}>
            {props.children}
        </LayoutContext.Provider>
    )
}

export { LayoutProvider, LayoutContext }