import React, { Component } from 'react'
import {  noop } from 'lodash'
// import { responseCode } from 'constants/response'
// import transformer from 'utils/transformer'
// import { ErrorModal } from 'components'
import './redirectDownload.style.scss'

// const constants = {
//     ok: 'ตกลง',
//     nullDescription: 'กรุณาติดต่อ Admin',
// }

const defaultProps: IRedirectProps & IRedirectActionProps = {
    loader() { noop() },
    connectGame() { noop() },
    connectedGameIsFetching: false,
    connectedGameError: '',
    connectedGameCode: 0,
    connectedGame: '',
}

class RedirectContainer extends Component<
    IRedirectProps
    & IRedirectActionProps
    >{

    static defaultProps = defaultProps

    componentDidMount() {
        // this.props.loader(true)
        // const queryParam = transformer.queryToObject(window.location.search)
        // const gameCode = get(queryParam, 'code', '')
        // const gameLocation = get(queryParam, 'gameLocation', '')
        // const gameCompany = get(queryParam, 'company', '')
        // if (gameCode && gameLocation && gameCompany) {
        //     const query: IGameConnectQuery = {
        //         code: gameCode,
        //         isMobile: false,
        //         company: gameCompany,
        //     }
        //     this.props.connectGame(gameLocation as TGameLocation, query)
        // } else {
        //     window.location.href = window.location.origin
        // }
    }

    componentDidUpdate(prevProps: IRedirectProps) {
        // if (prevProps.connectedGameIsFetching !== this.props.connectedGameIsFetching
        //     && !this.props.connectedGameIsFetching) {
        //     this.props.loader(false)
        //     if (this.props.connectedGameCode === responseCode.OK) {
        //         if (this.props.connectedGame) {
        //             window.location.href = this.props.connectedGame
        //         } else {
        //             ErrorModal.show({
        //                 action: () => {
        //                     ErrorModal.hide()
        //                     window.location.href = window.location.origin
        //                 },
        //                 actionText: constants.ok,
        //                 description: this.props.connectedGameError,
        //             })
        //         }
        //     } else {
        //         ErrorModal.show({
        //             action: () => {
        //                 ErrorModal.hide()
        //                 window.location.href = window.location.origin
        //             },
        //             actionText: constants.ok,
        //             description: this.props.connectedGameError,
        //         })
        //     }
        // }
    }


    render() {
        return (
            <div className="secondary-bg-navy redirect-download-container" />
        )
    }
}

export default RedirectContainer