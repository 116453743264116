import list from './list/epics'
import detail from './detail/epics'
import lottery from './lottery/epics'
import lotteryBenefit from './report/epics'
import gameBenefit from './reportGame/epics'
import game from './game/epics'
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  ...list,
  ...detail,
  ...lottery,
  ...lotteryBenefit,
  ...gameBenefit,
  ...game
]
