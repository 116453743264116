import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import gameAction from 'reduxs/game/actions'
import RedirectContainer from './Redirect.container'
import gameSelector from 'reduxs/game/selectors'
import loaderAction from 'reduxs/loader/actions'

const mapStateToProps = (state: RootReducers): IRedirectProps => {
    const connectedGameSelected = gameSelector.gameConnect(state)
    return ({
        connectedGameIsFetching: connectedGameSelected.isFetching!,
        connectedGameError: connectedGameSelected.error!,
        connectedGameCode: connectedGameSelected.code!,
        connectedGame: connectedGameSelected.data!,
    })
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IRedirectActionProps => bindActionCreators({
    loader: loaderAction.loadingAction,
    connectGame: gameAction.connectGameAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(RedirectContainer)