import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const postNotificationUserReadReducer = (
    state: ReducerState<{}> = initialState,
    action: RootAction
  ): ReducerState<any> => {
    switch (action.type) {
      case getType(actions.postNotificationUserReadAction):
        return {
          ...state,
          isFetching: true,
        }
      case getType(actions.postNotificationUserReadSuccessAction):
        return {
          ...state,
          isFetching: false,
          data: action.payload.data.data,
          code: action.payload.status,
        }
  
      case getType(actions.postNotificationUserReadFailureAction):
        return {
          ...state,
          isFetching: false,
          error: action.payload.response?.data.devMessage,
          code: action.payload.response?.status,
        }
      default:
        return state
    }
  }
  
  export default postNotificationUserReadReducer