import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import gameAction from 'reduxs/game/actions'
import userAction from 'reduxs/user/me/actions'
import MainContainer from './Main.container'
import gameSelector from 'reduxs/game/selectors'
import loaderAction from 'reduxs/loader/actions'
import displayConfigSelector from 'reduxs/config/selectors'
import pmpgSelector from 'reduxs/pmpg/selectors'
import pmpgAction from 'reduxs/pmpg/actions'
import userSelector from 'reduxs/user/selector'
import clickRefAction from 'reduxs/user/clickRef/actions'

const mapStateToProps = (state: RootReducers): IMainProps => {
  const gameListSelected = gameSelector.gameList(state)
  const eraHighlightSelected = pmpgSelector.eraPmpg(state)
  const summaryPmpgSelected = pmpgSelector.summaryPmpg(state)
  const connectedGameSelected = gameSelector.gameConnect(state)
  const displayConfig = displayConfigSelector.displayConfig(state)
  const clickRef = userSelector.clickRef(state)
  return {
    user: state.ludens.user.me.data!,
    wallet: state.ludens.user.wallet,
    token: state.ludens.user.token.accessToken!,
    getGameListIsFetching: gameListSelected.isFetching!,
    getGameListError: gameListSelected.error!,
    getGameListCode: gameListSelected.code!,
    gameList: gameListSelected.data!,
    connectedGameIsFetching: connectedGameSelected.isFetching!,
    connectedGameError: connectedGameSelected.error!,
    connectedGameCode: connectedGameSelected.code!,
    connectedGame: connectedGameSelected.data!,
    displayConfig: displayConfig.data,
    highlightEra: eraHighlightSelected.data,
    getSummaryPmpgIsFetching: summaryPmpgSelected.isFetching!,
    getSummaryPmpgError: summaryPmpgSelected.error!,
    getSummaryPmpgCode: summaryPmpgSelected.code!,
    getSummaryPmpgResult: summaryPmpgSelected.data!,
    clickRef,
  }
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IMainActionProps =>
  bindActionCreators(
    {
      loader: loaderAction.loadingAction,
      getUser: userAction.getMeAction,
      getGameList: gameAction.getGameListAction,
      clearGameList: gameAction.clearGameListAction,
      connectGame: gameAction.connectGameAction,
      getSummaryPmpg: pmpgAction.getSummaryPmpgAction,
      clearSummaryPmpg: pmpgAction.getSummaryPmpgCancelAction,
      clearClickRef: clickRefAction.clearClickRef,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProos)(MainContainer)
