import React, { Component } from 'react'
import Emitter from 'configs/emiter'
import ModalCore from 'react-bootstrap/Modal'
import './modal.style.scss'

const defaultProps: IModalProps = {
  event: 'MODAL',
}

class Modal extends Component<IModalProps, IModalState> {

  static defaultProps = defaultProps

  state: IModalState = {
    showState: false,
    show: false,
    RenderComponent: <></>,
    extraProps: {},
  }

  componentDidMount() {
    Emitter.on(this.props.event, this.onEventSubscribed)
  }

  componentWillUnmount() {
    Emitter.off(this.props.event)
  }

  onEventSubscribed = ({ state, component, extraProps }: IModalStateProps) => {
    if (state === 'show') {
      if (this.state.show) {
        return this.hideModal(() => {
          this.setState({ showState: true }, () => {
            this.setState({ RenderComponent: component!, extraProps }, () => { this.setState({ show: true }) })
          })
        })
      }
      this.setState({ showState: true }, () => {
        this.setState({ RenderComponent: component!, extraProps }, () => { this.setState({ show: true }) })
      })
    } else if (state === 'hide') {
      this.hideModal()
    }
  }

  hideModal = (callback?: () => void) => {
    this.setState({ showState: false }, () => {
      setTimeout(() => {
        this.setState({
          show: false,
        }, () => {
          this.setState({ extraProps: {}, RenderComponent: <></> }, callback)
        })
      }, 256)
    })
  }

  render() {
    const RenderComponent = this.state.RenderComponent

    return (
      <>
        <ModalCore
          show={this.state.show}
          centered
          animation={false}
          className={`modal-container-dialog ${this.props.event}`}
          backdrop="static"
          backdropClassName={`modal-backdrop ${this.props.event} ${this.state.showState ? 'visible' : 'hiden'}`}
          dialogClassName={`modal-custom-dialog ${this.props.event} ${this.state.showState ? 'show' : 'hide'}`}
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => this.setState({ show: false })}
          {...this.state.extraProps}
        >
          {RenderComponent}
        </ModalCore>
      </>
    )
  }
}

export default Modal