import project from 'constants/project'

export const GET_DISPLAY_CONFIG_REQUEST = 'GET_DISPLAY_CONFIG_REQUEST'
export const GET_DISPLAY_CONFIG_SUCCESS = 'GET_DISPLAY_CONFIG_SUCCESS'
export const GET_DISPLAY_CONFIG_FAILURE = 'GET_DISPLAY_CONFIG_FAILURE'
export const GET_DISPLAY_CONFIG_CANCEL = 'GET_DISPLAY_CONFIG_CANCEL'

export const initialState: ReducerState<IDisplayConfig> = {
  isFetching: false,
  code: 0,
  data: {
    production: {
      JOKER: true,
      AESEXY: true,
      THBGAME: true,
      SAGAME: true,
      ALLBET: true,
      DREAMTECH: true,
      PG: true,
      WMGAME: true,
      DREAMGAMING: true,
      W88: true,
      PPGAME: true,
      EVOLUTION: true
    },
    staging: {
      JOKER: true,
      AESEXY: true,
      THBGAME: true,
      SAGAME: true,
      ALLBET: true,
      DREAMTECH: true,
      PG: true,
      WMGAME: true,
      DREAMGAMING: true,
      W88: true,
      PPGAME: true,
      EVOLUTION: true
    },
    bifrost: {
      JOKER: true,
      AESEXY: true,
      THBGAME: true,
      SAGAME: true,
      ALLBET: true,
      DREAMTECH: true,
      PG: true,
      WMGAME: true,
      DREAMGAMING: true,
      W88: true,
      PPGAME: true,
      EVOLUTION: true
    },
    develop: {
      JOKER: true,
      AESEXY: true,
      THBGAME: true,
      SAGAME: true,
      ALLBET: true,
      DREAMTECH: true,
      PG: true,
      DREAMGAMING: true,
      WMGAME: true,
      W88: true,
      PPGAME: true,
      EVOLUTION: true
    },
  },
  error: '',
}

export const endpoint = {
  getDisplayConfig: `${project.environment[project.environmentName].api}/config/partner`,
}