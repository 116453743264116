import React, { FC } from 'react'
import './customModal.styles.scss'
import Drawer from 'react-drag-drawer'
import { ButtonIcon } from 'components'
import { get, map, noop } from 'lodash'
import { vendorLists } from 'assets/images/e-casino/index'

interface ICustomModalComponentProps {
  onSubmitModal: (val?: string) => void
  onChangeAction: boolean
  config?:IGameConfig
}

const defaultProps: ICustomModalComponentProps = {
  onChangeAction: false,
  onSubmitModal() {
    noop()
  },
  config:{
    JOKER: false,
    AESEXY: false,
    THBGAME: false,
    SAGAME: false,
    DREAMTECH: false,
    ALLBET: false,
    WMGAME: false,
    PG: false,
    DREAMGAMING: false,
    W88: false,
    PPGAME: false,
    EVOLUTION: false
  }
}

const initalIcon = vendorLists.w88Portal

const CustomModalComponent: FC<ICustomModalComponentProps> = (props) => {
  const { onChangeAction, onSubmitModal, config } = props
  const RenderPortalIcon = () => {
    const elem = map(initalIcon, (dataList, i) => {
      let indexCheckOpen=-1
      Object.keys(config!).map((item,index)=>{
        if (item.replace('_','').toUpperCase() === 'W88'+dataList.subCode) {
          indexCheckOpen = index
        }
        return item.toUpperCase()
      })
      let isOpen = get( Object.values(config!),indexCheckOpen,false)

      return (
      <div
        key={`portal-item-${i}`}
        className="col-12 col-xl-6 col-md-6 col-sm-12 col-xs-12 px-0 text-center icon-wrapper"
      >
        {!isOpen && <div className='w88-close'></div>}
        <img
          src={dataList.icon}
          id={`${dataList.icon}`}
          alt={`${dataList.icon}`}
          onClick={() => isOpen?onSubmitModal(dataList.code):()=>{}}
          className={`icon m2-x m1-t align-self-center`}
        />
        <h3 className="m2-b portal-name">{dataList.name}</h3>
      </div>
    )})
    return <>{elem}</>
  }
  return (
    <Drawer
      onOpen={() => document.body.style.setProperty('overflow-y', 'hidden')}
      open={onChangeAction}
      modalElementClass="card"
      containerElementClass="my-shade"
      parentElement={document.body}
    >
      <div className="width">
        <div className="row">
          <div className="col d-flex flex-row-reverse m4-r m3-t">
            <ButtonIcon
              type={'close'}
              id={`close-icon`}
              onClick={() => onSubmitModal()}
            />
          </div>
        </div>
        <div className="row">
          <RenderPortalIcon />
        </div>
      </div>
    </Drawer>
  )
}
CustomModalComponent.defaultProps = defaultProps

export default CustomModalComponent
