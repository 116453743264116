import React, { FC } from 'react';
import { noop } from 'lodash';
import './checkBox.style.scss';


const defaultProps: ICheckBox = {
  id: '',
  isChecked: false,
  onClick() { noop() },
  disabled: false,
  selectAll: false,
  isSelect: false,
  labelClassName: '',
};


const CheckBoxComponent: FC<ICheckBox> = (props) => {
  const { id, onClick, disabled, isChecked, isSelect, selectAll, labelClassName } = props;

  // console.log(selectAll, isSelect)
  // const handleCheck = () => {
  //   // if (selectAll && isSelect) {
  //   //   return true
  //   // }
  //   // else {
  //   //   return false
  //   // }
  //     return true
  // }

  return (
    <section>
      <div className={`check-box-container ${disabled ? 'disabled-check' : ''}`}>
        <input
          id={id}
          name={id}
          type="checkbox"
          onClick={disabled ? noop : onClick}
          disabled={disabled}
          onChange={noop}
          checked={selectAll === true ? true: isSelect ? true : isChecked}
          // checked={handleCheck()}
        />
        <label htmlFor={id} className={`${isChecked ? 'disabled-check' : ''} ${labelClassName}`} />
      </div>
    </section>
  );
};

CheckBoxComponent.defaultProps = defaultProps;

export default CheckBoxComponent;
