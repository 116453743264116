import { createAction } from 'typesafe-actions'
import {
    GET_COUNT_REQUEST,
    GET_COUNT_SUCCESS,
    GET_COUNT_FAILURE,
    GET_COUNT_CANCEL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getCountAction = createAction(GET_COUNT_REQUEST)

const getCountSuccessAction = createAction(
    GET_COUNT_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<IUserCount>>)=>
    resolve(data)
)

const getCountFailureAction = createAction(
    GET_COUNT_FAILURE,
    reslove => (error: AxiosError<APIResponse>) =>
    reslove(error)
)

const getCountCancelAction = createAction(GET_COUNT_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getCountAction,
    getCountSuccessAction,
    getCountFailureAction,
    getCountCancelAction,
}