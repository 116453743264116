import { createAction } from "typesafe-actions";
import {
  GET_NOTIFICATION_USER_COUNT_REQUEST,
  GET_NOTIFICATION_USER_COUNT_SUCCESS,
  GET_NOTIFICATION_USER_COUNT_FAILURE,
  GET_NOTIFICATION_USER_COUNT_CANCEL,
  UPDATE_NOTIFICATION_USER_COUNT_REQUEST,
  UPDATE_NOTIFICATION_USER_COUNT_SUCCESS,
} from "./constants";
import { AxiosResponse, AxiosError } from "axios";

const getNotificationUserCountAction = createAction(
  GET_NOTIFICATION_USER_COUNT_REQUEST
);

const getNotificationUserCountSuccessAction = createAction(
  GET_NOTIFICATION_USER_COUNT_SUCCESS,
  (resolve) =>
    (data: AxiosResponse<APIResponse<IV1GetNotificationUserCount>>) =>
      resolve(data)
);

const getNotificationUserCountFailureAction = createAction(
  GET_NOTIFICATION_USER_COUNT_FAILURE,
  (resolve) => (error: AxiosError<APIResponse>) => resolve(error)
);

const getNotificationUserCountCancelAction = createAction(
  GET_NOTIFICATION_USER_COUNT_CANCEL
);

const updateNotificationUserCountAction = createAction(
  UPDATE_NOTIFICATION_USER_COUNT_REQUEST,
  (resolve) => (data: any) => resolve(data)
);
const updateNotificationUserCountSuccessAction = createAction(
  UPDATE_NOTIFICATION_USER_COUNT_SUCCESS,
  (resolve) => (data: any) => resolve(data)
);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getNotificationUserCountAction,
  getNotificationUserCountSuccessAction,
  getNotificationUserCountFailureAction,
  getNotificationUserCountCancelAction,
  updateNotificationUserCountAction,
  updateNotificationUserCountSuccessAction,
};
