import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const getOldFavoriteLottoListReducer = (
  state: IOldFavoriteLottoState = initialState,
  action: RootAction
): IOldFavoriteLottoState => {
  switch (action.type) {
    case getType(actions.getOldFavoriteLottoListAction):
      return {
        ...state,
        isFetching: true,
      }
    case getType(actions.getOldFavoriteLottoListSuccessAction):
      const data = (action.payload.isFirst)
      ? action.payload.data.data.dataList
      : [...(state.data || []), ...(action.payload.data.data.dataList || [] || null)]
      return {
        ...state,
        isFetching: false,
        data: data,
        pagination: action.payload.data.data,
        code: action.payload.status,
      }

    case getType(actions.getOldFavoriteLottoListFailureAction):
      return {
        ...state,
        isFetching: false,
        error: action.payload.response?.data.devMessage,
        code: action.payload.response?.status,
      }
    case getType(actions.clearOldFavoriteLottoListAction):
      return initialState
    default:
      return state
  }
}

export default getOldFavoriteLottoListReducer