import React, { Component, createRef, RefObject } from "react";
import { get, isEmpty, isEqual, map, noop } from "lodash";
import { ALink, BackButton, ResponsiveIcon } from "components";
import { RouteComponentProps } from "react-router-dom";
import { responseCode } from "constants/response";
import project from "constants/project";
import LogoTlb from "assets/images/logo/logothailandbet02.png";
import "./casinoGamePartner.style.scss";
import { interactive } from "utils";
import routes from "constants/routes";
import partnerList from "assets/images/vendorsIcon";
import { Icon } from "@iconify/react";
import colors from "constants/colors";

import jiliGameBanner from "assets/images/vendorsIcon/jili_logo_banner.png";
import dreamtechGameBanner from "assets/images/vendorsIcon/dreamtech-Banner-1.jpg";
import pgGameBanner from "assets/images/vendorsIcon/pgBanner.svg";

const constants = {
  back: "ย้อนกลับ",
  slot: "สล็อต",
  fishing: "ยิงปลา",
  bingo: "บิงโก",
  ecasino: "E-Casino",
  pageTitle: "เกมส์ค่าย",
  comingSoon: "พบกันเร็วๆนี้",
  closedGame: "ปิดปรับปรุง ชั่วคราว",
  defaultCompanyGame: "JOKER",
  qa: "วิธีเล่นผ่านแอปโทรศัพท์",
  playNow: "เล่นเลย",
  emptyGameList: "ไม่พบข้อมูลรายการเกม",
  moreGame: "เปลี่ยนค่ายเกม",
  changeProvider: "เปลี่ยนค่าย",
  partnerDescription: "(เปลี่ยนค่ายเกม)",
  commingSoon: "พบกันเร็วๆนี้",
  allprovider: "ค่ายเกมส์ทั้งหมด",
};

const defaultProps: ICasinoGameProps & ICasinoGameActionProps = {
  connectGame() {
    noop();
  },
  connectedGameIsFetching: false,
  connectedGameError: "",
  connectedGameCode: 0,
  connectedGame: "",
  getGameList() {
    noop();
  },
  getGameListIsFetching: false,
  getGameListError: "",
  getGameListCode: 0,
  gameList: [],
  loader() {
    noop();
  },
  clearGameList() {
    noop();
  },
  displayConfig: {},
  displayConfigCode: 0,
  displayConfigError: "",
  displayConfigIsFetching: false,
  getDisplayConfig() {
    noop();
  },
  clearClickRef() {
    noop();
  },
  clickRef: {
    code: undefined,
    company: undefined,
    path: undefined,
  },
};

type DefaultProps = Readonly<typeof defaultProps>;

class CasinoGameContainer extends Component<
  ICasinoGameProps &
    ICasinoGameActionProps &
    DefaultProps &
    RouteComponentProps<any, any, ICasinoGameRouteProps>,
  ICasinoGameProviderContainerState
> {
  static defaultProps = defaultProps;
  gameListContainerRef: RefObject<HTMLDivElement> = createRef();
  pageContainerRef: RefObject<HTMLDivElement> = createRef();

  state: ICasinoGameProviderContainerState = {
    currentGameType: "SLOT",
    currentTextMenu: "เกมทั้งหมด",
    currentPartnerMenu: {
      alt: "SLOT",
      icon: LogoTlb,
    },
    gameQuery: {},
    portalIsReady: false,
    displaySorting: [],
    isOpenChangeProvider: false,
  };

  componentDidMount() {
    interactive.rootElement.scrollToTop();
    this.props.loader(true);

    const provider = this.props.match.params.provider;
    this.onGetGameList({
      company: provider as TGameCompany,
      type: "",
      isHot: false,
    });
    this.onGetDisplayConfig();
  }

  componentDidUpdate(prevProps: ICasinoGameProps) {
    if (
      prevProps.displayConfigIsFetching !==
        this.props.displayConfigIsFetching &&
      !this.props.displayConfigIsFetching
    ) {
      this.props.loader(true);
      if (this.props.displayConfigCode === responseCode.OK) {
        this.props.loader(false);
        this.onHandleSortingDisplayConfig();
      } else {
        this.props.loader(false);
        this.onHandleSortingDisplayConfig();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearGameList();
  }

  onGetDisplayConfig = () => {
    this.props.loader(true);
    this.props.getDisplayConfig();
  };

  onGetGameList = (val: IGameQuery, ref?: string) => {
    const provider = val.company || "";
    this.setState(
      (prevState) => ({
        currentPartnerMenu: {
          alt: val.company!,
          icon: partnerList.partnerList[`${provider.toLowerCase()}`],
        },
        currentTextMenu: "เกมสล็อต",
        gameQuery: {
          ...prevState.gameQuery,
          ...val,
        },
      }),
      () => this.props.getGameList("EXTERNAL", val)
    );
  };

  handleChangeProvider = (provider: string) => {
    if (["PG", "DREAMTECH", "JILIGAME"].includes(provider)) {
      switch (provider) {
        case "PG":
          window.open(
            interactive.openLink<any & { gameLocation: TGameLocation }>({
              company: "PGGAME",
              code: "PGGAME",
              gameLocation: "EXTERNAL",
            }),
            "_blank"
          );
          break;
        case "DREAMTECH":
          window.open(
            interactive.openLink<any & { gameLocation: TGameLocation }>({
              company: "DREAMTECH",
              code: "DREAMTECH",
              gameLocation: "EXTERNAL",
            }),
            "_blank"
          );
          break;
        case "JILIGAME":
          window.open(
            interactive.openLink<any & { gameLocation: TGameLocation }>({
              company: "JILIGAME",
              code: "JILIGAME",
              gameLocation: "EXTERNAL",
            }),
            "_blank"
          );
          break;
        default:
          this.props.history.replace(routes.slotProvider.exactPath(provider));
          setTimeout(() => {
            window.location.reload();
          }, 126);
          break;
      }
    } else {
      this.props.history.replace(routes.slotProvider.exactPath(provider));
      setTimeout(() => {
        window.location.reload();
      }, 126);
    }
  };

  onClickBack = () => this.props.history.goBack();

  renderCasinoGame = () => {
    // Render PG, DREAMATCH, JILI
    const provider = get(this.state.currentPartnerMenu, "alt", "");
    if (["PG", "DREAMTECH", "JILIGAME"].includes(provider)) {
      const icon =
        provider === "PG"
          ? pgGameBanner
          : provider === "DREAMTECH"
          ? dreamtechGameBanner
          : provider === "JILIGAME"
          ? jiliGameBanner
          : null;
      if (!icon) {
        return null;
      }
      const onMaintenanced =
        !get(
          this.props.displayConfig,
          `${project.environmentName}.${provider.toLowerCase()}`,
          false
        ) ||
        ["dreamgaming", "slotxo", "asiagaming"].includes(
          provider.toLowerCase()
        );

      return (
        <a
          href={
            !onMaintenanced
              ? interactive.openLink<any & { gameLocation: TGameLocation }>({
                  company: provider,
                  code: provider,
                  gameLocation: "EXTERNAL",
                })
              : "/#"
          }
          className={`col-12 m3-b casino-game-wrapper`}
          target="_blank"
          aria-disabled
          rel="noreferrer noopener"
        >
          <div
            className="casino-game-button-wrapper text-center"
            id="own-portal-provider"
          >
            <ResponsiveIcon
              icon={icon}
              alt={`own-portal-provider-alt`}
              className="casino-provider-image"
            />
            <div className="content-heightlight-hover d-flex align-items-center justify-content-center">
              <h2 className="secondary-text-navy">{constants.playNow}</h2>
            </div>
          </div>
          {onMaintenanced ? (
            <div className="closed-game-play text-center">
              <h3>{constants.closedGame}</h3>
            </div>
          ) : (
            <></>
          )}
        </a>
      );
    }
    // Render game list
    const CasinoGameComponent = !isEmpty(this.props.gameList) ? (
      map(this.props.gameList, (casinoGame, _) => {
        const isMaintenance = get(
          this.props.displayConfig,
          `${project.environmentName}.${(
            casinoGame.company || ""
          ).toLowerCase()}`,
          true
        );
        return (
          <a
            href={
              isMaintenance
                ? interactive.openLink<IGame & { gameLocation: TGameLocation }>(
                    { ...casinoGame, gameLocation: "EXTERNAL" }
                  )
                : "/#"
            }
            className={`col-6 col-md-4 col-xl-2 m3-b casino-game-wrapper ${
              !isMaintenance && "disabled"
            }`}
            key={`casino-game-${_}`}
            target="_blank"
            aria-disabled
            rel="noreferrer noopener"
          >
            <div
              className="casino-game-button-wrapper text-center"
              id={`casino-game-${casinoGame.id}`}
            >
              <ResponsiveIcon
                icon={casinoGame.image}
                alt={`casino-game-${casinoGame.id}-alt`}
                className="casino-game-image"
              />
              <div className="content-heightlight-hover d-flex align-items-center justify-content-center">
                <h2 className="secondary-text-navy">{constants.playNow}</h2>
              </div>
            </div>
            {isMaintenance ? (
              <></>
            ) : (
              <div className="closed-game-play text-center">
                <h3>{constants.closedGame}</h3>
              </div>
            )}
          </a>
        );
      })
    ) : (
      <div className="col-12 text-center">
        <h2>
          {constants.emptyGameList}
          <ALink
            id="backto-provider-list-page"
            color={colors.SECONDARY_GOLD_TEXT}
            fontSize={24}
            onClick={() => this.props.history.push(routes.slot.exactPath)}
          >
            {constants.moreGame}
          </ALink>
        </h2>
      </div>
    );
    return <>{CasinoGameComponent}</>;
  };

  onHandleSortingDisplayConfig = () => {
    const initGameList = {
      aesexy: false,
      allbet: false,
      joker: false,
      pg: false,
      sagame: false,
      wmgame: false,
      w88: false,
      dreamtech: false,
      thbgame: false,
      slotxo: false,
      dreamgaming: false,
      asiagaming: false,
    };
    const extractGameList: any = {
      ...initGameList,
      ...get(this.props.displayConfig, [project.environmentName], ""),
    };
    const gameSorted = Object.keys(extractGameList).sort(
      (a, b) => extractGameList[b] - extractGameList[a]
    );
    this.setState({ displaySorting: gameSorted });
    return;
  };

  renderFAQ = () => {
    return (
      <div
        id="goto-qa-page"
        className="d-flex flex-row qna-container"
        onClick={this.handleOnQA}
      >
        <span className="qna-button-text" style={{ fontSize: "8px" }}>
          {constants.qa}
        </span>
        <Icon icon="jam:info" width="12px" height="12px" />
      </div>
    );
  };

  handleCloseOpenChangeProvider = () => {
    this.setState((prevState) => ({
      isOpenChangeProvider: !prevState.isOpenChangeProvider,
    }));
  };

  renderProviderMenu = () => {
    const data = this.state.displaySorting || [];
    const gameCollection = [
      "joker",
      "pg",
      "slotxo",
      "dreamtech",
      "asiagaming",
      "w88Slot",
      "ppgame",
      "jiligame",
    ];

    const isMaintenance = (code: string): boolean =>
      get(
        this.props.displayConfig,
        `${project.environmentName}.${code}`,
        false
      );

    const components = map(
      data.filter((o) => gameCollection.includes(o)),
      (provider, index) => {
        let providerKey = provider === "w88Slot" ? "w88" : provider;
        const onMaintenanced =
          !isMaintenance(provider) ||
          ["dreamgaming", "slotxo", "asiagaming"].includes(provider);
        const isActive = isEqual(
          providerKey,
          get(this.state.currentPartnerMenu, "alt", "").toLowerCase()
        );

        return (
          <div
            onClick={() => {
              if (!onMaintenanced) {
                this.handleChangeProvider(providerKey.toUpperCase());
              }
            }}
            className={`provider-item-wrapper 
            ${isActive ? "active" : ""} 
            ${onMaintenanced ? "maintanance" : ""}
            ${this.state.isOpenChangeProvider ? "show" : ""}
            `}
            key={`provider-${index}`}
          >
            <ResponsiveIcon
              icon={partnerList.partnerList[providerKey]}
              style={providerKey === 'w88' ? { transform: 'translateY(4px)' } : {}}
              alt={`provider-${index}-alt`}
              className="provider-image"
            />
          </div>
        );
      }
    );

    return (
      <div
        className="provider-collapse-container"
        onClick={this.handleCloseOpenChangeProvider}
      >
        <div className="provider-collapse-wrapper">
          <Icon
            icon="iconoir:refresh-circle"
            width="24px"
            height="24px"
            color={colors.SECONDARY_GOLD_TEXT}
          />
          <p className="provider-change-text">{constants.changeProvider}</p>
        </div>
        <div className="provider-container">
        {components}
        </div>
      </div>
    );
  };

  handleOnQA = () => {
    this.props.history.push(routes.qaType.exactPath("game"));
  };

  render() {
    const FAQ = this.renderFAQ;
    const CasinoGameComponent = this.renderCasinoGame;
    const ProviderPopup = this.renderProviderMenu;
    return (
      <>
        <div
          className="col-12 position-fixed"
          style={{ zIndex: 99, marginTop: 44 }}
        >
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <BackButton id="backto-previus-page" onClick={this.onClickBack} />
            <FAQ />
          </div>
        </div>
        <div
          className="casino-games-container secondary-bg-navy"
          ref={this.pageContainerRef}
          id={`casino-games-container`}
        >
          <div className="container">
            <ProviderPopup />
            <div className="row" style={{ paddingTop: "100px" }}>
              <div className="col">
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h2 className="secondary-text-gold">{constants.pageTitle}</h2>
                  <p
                    className="secondary-text-gold"
                    style={{ fontSize: "20px", marginTop: 8 }}
                  >
                    {get(this.state.gameQuery, "company", "")}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-center">
                  {isEmpty(get(this.state.currentPartnerMenu, "icon", "")) ? (
                    <></>
                  ) : (
                    <ResponsiveIcon
                      icon={get(this.state.currentPartnerMenu, "icon", "")}
                      alt={`slot-provider`}
                      className="slot-provider-icon"
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="row m4-t p4-t mx-2 gx-5"
              ref={this.gameListContainerRef}
            >
              <CasinoGameComponent />
            </div>
          </div>
          {!isEmpty(project.environmentName) ? (
            <></>
          ) : (
            <div className="casino-game-block-wrapper">
              <div className="background-water-line d-flex flex-column align-items-center justify-content-center">
                <img src={LogoTlb} alt="logo" className="logo-icon" />
                <h1 className="m4-t">{constants.comingSoon}</h1>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default CasinoGameContainer;
