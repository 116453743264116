import { createAction } from 'typesafe-actions'
import {
  GET_CREDIT_INFO_BET_GAME_REQUEST,
  GET_CREDIT_INFO_BET_GAME_SUCCESS,
  GET_CREDIT_INFO_BET_GAME_FAILURE,
  GET_CREDIT_INFO_BET_GAME_CANCEL,
  CLEAR_CREDIT_INFO_BET_GAME,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getCreditInfoBetGameAction = createAction(
  GET_CREDIT_INFO_BET_GAME_REQUEST,
  resolve => (query: APIPaginationQuery, isFirst?: boolean) => resolve({ ...query, isFirst }))

const getCreditInfoBetGameSuccessAction = createAction(
  GET_CREDIT_INFO_BET_GAME_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<APIPagination<ICreditBetGame>>>, isFirst: boolean) =>
    resolve({ ...data, isFirst }))

const getCreditInfoBetGameFailureAction = createAction(
  GET_CREDIT_INFO_BET_GAME_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getCreditInfoGameCancelAction = createAction(GET_CREDIT_INFO_BET_GAME_CANCEL)

const clearCreditInfoBetGame = createAction(CLEAR_CREDIT_INFO_BET_GAME)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  clearCreditInfoBetGame,
  getCreditInfoBetGameAction,
  getCreditInfoBetGameSuccessAction,
  getCreditInfoBetGameFailureAction,
  getCreditInfoGameCancelAction,
}