import { createAction } from 'typesafe-actions'
import {
  GET_BET_HISTORY_REQUEST,
  GET_BET_HISTORY_SUCCESS,
  GET_BET_HISTORY_FAILURE,
  GET_BET_HISTORY_CANCEL,
  CLEAR_BET_HISTORY_LIST,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'


const getBetHistoryAction = createAction(
  GET_BET_HISTORY_REQUEST,
  resolve => (query: IGetLottoHistoryParams, isFirst: boolean) =>
  resolve({ ...query, isFirst}))

const getBetHistorySuccessAction = createAction(
  GET_BET_HISTORY_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<APIPagination<ILottoHistory>>>, isFirst: boolean) =>
   resolve({ ...data || [], isFirst})
  )

const getBetHistoryFailureAction = createAction(
  GET_BET_HISTORY_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getBetHistoryCancelAction = createAction(GET_BET_HISTORY_CANCEL)

const clearLottoHistoryList = createAction(CLEAR_BET_HISTORY_LIST)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBetHistoryAction,
  getBetHistorySuccessAction,
  getBetHistoryFailureAction,
  getBetHistoryCancelAction,
  clearLottoHistoryList,
}