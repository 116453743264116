import React, { Component, createRef, RefObject } from 'react'
import { ALink, SuccessModal, ErrorModal } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { get, noop } from 'lodash'
import colors from 'constants/colors'
import { RouteComponentProps } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import { AffiliateWithdrawForm } from './components'
import scheme from './models/scheme'
import { responseCode } from 'constants/response'
import './affiliateWithdrawal.style.scss'
import routes from 'constants/routes'
import { interactive } from 'utils'

const constants = {
    withdrawal: 'ถอนรายได้',
    ok: 'ตกลง',
    back: 'ย้อนกลับ',
    gotoAffiliate: 'กลับสู่หน้าระบบแนะนำ',
    requestedSuccess: 'ถอนรายได้สำเร็จ',
    comingSoonTiltle1: `ปิดปรับปรุงระบบถอนค่าแนะนำสมาชิกชั่วคราว`,
    comingSoonTiltle2: ` เพื่อแก้ไขการจ่ายค่าแนะนำสมาชิกจากยอดชนะเป็นยอดแทง`,
    comingSoonTiltle3: ` พบกันวันที่ 20 ธันวาคม 2563 นี้ ขออภัยในความไม่สะดวก`,
}

const defaultProps: IAffiliateWithdrawalProps & IAffiliateWithdrawalActionProps = {
    loader() { noop() },
    withdrawAffiliate() { noop() },
    withdrawAffiliateIsFetching: false,
    withdrawAffiliateError: '',
    withdrawAffiliateCode: 0,
    wallet: {},
}

class AffiliateWithdrawalContainer extends Component<
    RouteComponentProps<any, any, { availableMoney: number }>
    & IAffiliateWithdrawalProps
    & IAffiliateWithdrawalActionProps,
    { initialState: { money: string } }
    >
{

    static defaultProps = defaultProps
    pageContainerRef: RefObject<HTMLDivElement> = createRef()

    state: { initialState: { money: string; maxAmount: number } } = {
        initialState: { money: '', maxAmount: get(this.props.location, 'state.availableMoney', 0) },
    }

    componentDidMount() {
        interactive.rootElement.scrollToTop()
    }

    componentDidUpdate(prevProps: IAffiliateWithdrawalProps) {
        if (prevProps.withdrawAffiliateIsFetching !== this.props.withdrawAffiliateIsFetching
            && !this.props.withdrawAffiliateIsFetching) {
            if (this.props.withdrawAffiliateCode === responseCode.OK) {
                SuccessModal.show({
                    action: () => {
                        this.props.history.replace(routes.affiliate.path)
                        SuccessModal.hide()
                    },
                    actionText: constants.gotoAffiliate,
                    description: constants.requestedSuccess,
                })
            } else {
                ErrorModal.show({
                    action: ErrorModal.hide,
                    description: this.props.withdrawAffiliateError,
                    actionText: constants.ok,
                })
            }
            this.props.loader(false)
        }
    }

    onPressBack = () => this.props.history.goBack()

    handleOnSubmit = (values: { money: string }) => {
        const castedValue = scheme.cast(values)
        this.props.loader(true)
        this.props.withdrawAffiliate({ money: castedValue.money })
    }

    renderForm = () => {
        const { wallet, location } = this.props
        const originMoney = get(location, 'state.availableMoney', 0)
        const destinationMoney: number = get(wallet, 'money', 0)
        const AffiliateWithdrawFormComponent = (formProps: FormikProps<{ money: string }>) => (
            <AffiliateWithdrawForm
                {...formProps}
                originMoney={originMoney}
                destinationMoney={destinationMoney}
            />
        )

        return (
            <Formik
                initialValues={this.state.initialState}
                validationSchema={scheme}
                enableReinitialize
                onSubmit={this.handleOnSubmit}
            >
                {AffiliateWithdrawFormComponent}
            </Formik>
        )
    }

    render() {
        const AffiliateWithdrawFormComponent = this.renderForm
        return (
            <div className="affiliate-withdrawal-container secondary-bg-navy" ref={this.pageContainerRef}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <ALink id="backto-previus-page" color={colors.PRIMARY_RED} bold onClick={this.onPressBack}>
                                <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
                                {constants.back}
                            </ALink>
                        </div>
                    </div>
                    <div className="row m4-t">
                        <div className="col">
                            <h2>{constants.withdrawal}</h2>
                        </div>
                    </div>
                    <AffiliateWithdrawFormComponent />
                </div>
            </div>
        )
    }
}

export default AffiliateWithdrawalContainer
