import list from './list/epics'
import detail from './detail/epics'
import save from './save/epics'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  ...list,
  ...detail,
  ...save,
]
