import FISHINGOD_PRESENTER from './fiching-game-01.png'
import HAIBA_PRESENTER from './fiching-game-02.png'
import YAOQIAN_PRESENTER from './fiching-game-03.png'

const presenterFishing: { name: string, image: string, type: TGameType, id: string }[] = [
    { name: 'Fish Haiba', image: HAIBA_PRESENTER, type: 'SLOT', id: "kk8nqm3cfwtng" },
    { name: 'Fish Hunter Monster Awaken', image: FISHINGOD_PRESENTER, type: 'SLOT', id: '1jeqx59c7ztqg' },
    { name: 'Fish Hunting: Yao Qian Shu"', image: YAOQIAN_PRESENTER, type: 'SLOT', id: 'wi17jwsu4de7c' },
]

export default presenterFishing