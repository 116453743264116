import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchGameBenefitRequest } from './services'
import actions from './actions'

const lotteryBenefit: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.getGameBenefitRequestAction)),
    exhaustMap(action =>
      from(fetchGameBenefitRequest(action.payload))
        .pipe(
          map(actions.getGameBenefitSuccessAction),
          catchError(error => of(actions.getGameBenefitFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getGameBenefitCancelAction))))
        ),
    )
  )
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  lotteryBenefit,
]
