import { useContext } from 'react'
import { LayoutContext } from '../contexts/LayoutContext'

const layoutContext = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const context = useContext(LayoutContext)
    if (!context) throw new Error('Layout Context must be use inside LayoutProvide')
    return context
}

export default layoutContext