import project from 'constants/project'

export const GET_BET_LIMIT_REQUEST = 'GET_BET_LIMIT_REQUEST'
export const GET_BET_LIMIT_SUCCESS = 'GET_BET_LIMIT_SUCCESS'
export const GET_BET_LIMIT_FAILURE = 'GET_BET_LIMIT_FAILURE'
export const GET_BET_LIMIT_CANCEL = 'GET_BET_LIMIT_CANCEL'

export const initialState: ReducerState<IBetLimit> = {
  isFetching: false,
  code: 0,
  data: {
    betLimit: 0,
  },
  error: '',
}

export const endpoint = {
  getBetLimit: `${project.environment[project.environmentName].api}/config/betlimit`,
}