import { map } from 'lodash'
import lottoIcon from 'assets/images/icon/lotto.svg'
import BargraphIcon from 'assets/images/icon/bargraph.svg'
import YegeeIcon from 'assets/images/icon/24hours.svg'
// import CalendarIcon from 'assets/images/icon/calendar.svg'
import { LottoResultCard, InputNumber, DatePickerMulti } from 'components'
import searchIcon from 'assets/images/icon/search.svg'
import React, { Component, createRef, RefObject } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { interactive } from 'utils'
import './lottoResult.style.scss'
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
const constants = {
  lotto: 'หวย',
  lottoNat: 'หวยหุ้น',
  yegee: 'ยี่กี',
  back: 'ย้อนกลับ',
  lottoResult: 'ผลหวย',
  yeege: 'หวยยี่กี',
  share: 'หวยหุ้น',
  filterDate: 'วันที่',
  search: 'ตรวจเลข',
}

class LottoResultContainer extends Component<ILottoResultProps & ILottoResultActionProps & RouteComponentProps> {

  pageContainerRef: RefObject<HTMLDivElement> = createRef()


  state: ILottoResultState = {
    date: null,
    search: '',
    activeSearch: false,
    selectedLandingMenu: constants.lotto,
  }


  componentDidMount() {
    this.setState({ date: new Date(Date.now()) })
    interactive.rootElement.scrollToTop()
    this.props.loader(true)
    this.props.getLottoResult()
  }

  componentDidUpdate(prevProps: ILottoResultProps) {
    if (prevProps.getLottoIsFetching !== this.props.getLottoIsFetching && !this.props.getLottoIsFetching) {
      this.props.loader(false)
    }
  }

  renderLottoList = (lottos: ILotto[]) => map(lottos, (lotto, index) => (
    <div key={`lotto-card-${lotto.code}-${index}`} className="margin-bottom col-6 col-md-6 col-lg-4 col-sm-6">
      <LottoResultCard lotto={lotto} />
    </div>
  ))

  onChangeDate = (date: Date | any) => {
    this.setState({ date: date })
    this.props.loader(true)
    if (date === null) {
      this.props.getLottoResult()
    }
    else {
      this.props.getLottoResult({ date: moment(new Date(Number(date))).format('DDMMYYYY') })
    }
  }

  renderMenuSearch = () => {
    return (
      <div
        className={`search-box-wrapper ${this.state.activeSearch && 'selected-menu'}`}
        key="search-box"
        id="search-box"
        onClick={() => {
          this.setState({ activeSearch: !this.state.activeSearch })
        }}
      >
        <img
          className={`icon-menu ${this.state.activeSearch ? 'active-filter' : 'default-filter'}`}
          alt={'lotto-icon'}
          src={searchIcon}
        />
        <h4 className={`h4r search-name ${this.state.activeSearch ? 'secondary-text-navy' : 'secondary-text-gold'}`}>
          {constants.search}
        </h4>
      </div>
    )
  }

  onRenderLandingMenu = () => {
    const mappingMenu = map([constants.lotto, constants.lottoNat, constants.yegee], (dataList, index) => (
      <div className="col-4 p-0" key={`item-${index}`}>
        <div
          className={`menu-box-wrapper
        ${this.state.selectedLandingMenu === dataList && 'selected-menu'}
        `}
          key={`item-${index}`}
          id={`menu-${index}-${dataList}`}
          onClick={() => {
            this.handleScrollToTop()
            if (this.state.selectedLandingMenu !== dataList) {
              this.setState({ selectedLandingMenu: dataList })
            }
          }}
        >
          <img
            className={`icon-menu ${this.state.selectedLandingMenu === dataList ? 'active-filter' : 'default-filter'}`}
            alt={'lotto-icon'}
            src={dataList === constants.lotto ? lottoIcon : dataList === constants.lottoNat ? BargraphIcon : YegeeIcon}
          />
          <h4 className={`h4r name-menu ${this.state.selectedLandingMenu === dataList ? 'secondary-text-navy' : 'secondary-text-gold'}`}>
            {dataList}
          </h4>
        </div>
      </div>
    ))
    return (
      <div className="menu-lotto-container col-12 p-0">
        {mappingMenu}
      </div>
    )
  }

  handleScrollToTop = () => {
    if (this.pageContainerRef.current) {
      interactive.rootElement.scrollToTop()
    }
  }

  onChangeSearch(value: string) {
    this.setState({ search: value })
  }

  renderLottoResult(lotto: ILotto[]) {
    return this.renderLottoList(lotto).filter(
      (Lotto) => {
        return Lotto.props.children.props.lotto.lotto.map((value: ILottoResult) => value.numbers)
          .find((lottoNumber: string) => lottoNumber.includes(this.state.search))
      }
    )
  }

  render() {
    const RenderMenuSearch = this.renderMenuSearch
    const RenderLandingMenu = this.onRenderLandingMenu
    const globalLottos = this.props.lottos.filter((lotto) => (!lotto.code.includes('YEGEE') && !lotto.code.includes('SHARE')) || lotto.code.includes('NAT_SHARE_LAO'))
    const shareLottos = this.props.lottos.filter((lotto) => lotto.code.includes('SHARE') && !lotto.code.includes('NAT_SHARE_LAO'))
    const yeegeLottos = this.props.lottos.filter((lotto) => lotto.code.includes('YEGEE'))

    return (
      <div className="lotto-result-container secondary-bg-navy" ref={this.pageContainerRef}>
        <div className={`col-12 position-fixed header ${this.state.activeSearch && ('inactive-search')}`}>
          <div className="container p-0 m1-b">
            <div className="col-12 padding-handle">
              <div className="filter-container">
                <div className="calendar">
                  {/* <img
                    className={`icon-menu default-filter`}
                    alt={'lotto-icon'}
                    src={CalendarIcon}
                  /> */}
                  <div className="d-flex flex-row">
                    {/* <h5 className="title-day subtitle-2">{constants.filterDate}</h5> */}
                    {/* <div className="date-picker-container"> */}
                      <DatePickerMulti
                        onChange={(value) => {
                          this.onChangeDate(value)
                        }}
                        value={this.state.date}
                        icon
                        titleDay={constants.filterDate}
                      />
                    {/* </div> */}
                  </div>
                </div>
                <RenderMenuSearch />
              </div>
              {
                this.state.activeSearch && (
                  <div className="p1-t input-money">
                    <InputNumber
                      format="####"
                      borderStyle="border-input"
                      hiddenErrorBlock={true}
                      decimalScale={0}
                      name="money"
                      onValueChange={({ value }) => this.onChangeSearch(value)}
                      allowNegative={false}
                      placeholder={'กรุณากรอกตัวเลขที่ต้องการตรวจ...'}
                    />
                  </div>
                )
              }
              <div className="px-0 m1-b p2-t">
                <RenderLandingMenu />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {
            globalLottos.length > 0 && this.state.selectedLandingMenu === constants.lotto
              ? (
                <div className={`row margin-top ${this.state.activeSearch && ('active-search')}`}>
                  {this.renderLottoResult(globalLottos)}
                </div>
              )
              : <></>
          }
          {
            shareLottos.length > 0 && this.state.selectedLandingMenu === constants.lottoNat
              ? (
                <>
                  <div className={`row margin-top ${this.state.activeSearch && ('active-search')}`}>
                    {this.renderLottoResult(shareLottos)}
                  </div>
                </>
              )
              : <></>
          }
          {
            yeegeLottos.length > 0 && this.state.selectedLandingMenu === constants.yegee
              ? (
                <>
                  <div className={`row margin-top ${this.state.activeSearch && ('active-search')}`}>
                    {this.renderLottoResult(yeegeLottos)}
                  </div>
                </>
              )
              : <></>
          }
        </div>
      </div>
    )
  }
}

export default LottoResultContainer