import React, { ChangeEvent, Component } from 'react'
import { isEmpty, isEqual, map, noop, } from 'lodash'
import './NumberPanel.style.scss'
import { ButtonRadio, InputNumber } from 'components'

import {
  LOTTO_GAME_TYPES, LOTTO_GAME_TYPE_LENGTH, TEXT_NAME_LOTTO, TEXT_GAME_TYPE_NAME
} from 'constants/variables'
import { get } from 'lodash'
import { Numbersets } from '../Numbersets'
import colors from 'constants/colors'
import { transformer } from 'utils'

const defaultProps: INumberPanelComponentProps = {
  lottos: [],
  betRates: [],
  gameSlug: 'LOTTER_YEGEE',
  onAddedNumber() { noop() },
  onNavigateToOldFavorite() { noop() },
  numberBan: '',
  betLimit: {betLimit: 0},
  mode:'EASY'
}

const listTwoType: TModeTwoType[] = ['DOOR', 'FRONT', 'BACK']
  const listNameTwoType:{[type in TModeTwoType]:string} ={
    FRONT:'รูดหน้า',
    BACK:'รูดหลัง',
    DOOR:'19 ประตู',
  }

const windowHeight = window.innerHeight
class NumberPanel extends Component<INumberPanelComponentProps, INumberPanelComponentState> {
  static defaultProps = defaultProps

  state: INumberPanelComponentState = {
    animated: false,
    numberSet: '',
    gameType: [get(LOTTO_GAME_TYPES, `${this.props.gameSlug}.0`, 'THREE_UP')],
    mainGameType : 'TWO',
    inputMode: 'NUMBERPAD',
    isSwitchedNumber: false,
    valueBet:'',
    modeTwoType:'DOOR'
  }

  renderInputMode = () => {
    
    return (
      <>
        <Numbersets
          modeTwoType={this.state.modeTwoType}
          lottos={this.props.lottos}
          gameMode={this.state.gameType}
          numberBan={this.props.numberBan}
          onAddedNumber={(lottoNumbers:ILottoNumber | ILottoNumber[], state, _, trgger) => {
            if (typeof lottoNumbers === "object") {
              let data = lottoNumbers as ILottoNumber[]
              let set = data.map( (item:ILottoNumber ) => {return {...item,value:isEmpty(this.state.valueBet)?'1':this.state.valueBet}})
              this.props.onAddedNumber(set, state, this.state.isSwitchedNumber, trgger ,this.state.valueBet)
            }
          }}
        />
      </>
    )
  }

  renderLottoGameTypes = () => {
    const gameList:TLottoGameType[] = ['TWO_UP', 'THREE_UP', 'RUN_UP']
    const LottoGamTypesComponent = map(gameList, (gameType, gameTypeIndex) => {
      const isCurrentActive = gameType.includes(this.state.mainGameType)
      return (
        <div
          className={`col-4 px-1 pb-1`}
          key={`lotto-game-type-${gameTypeIndex}-${gameType}`}
        >
          <div className="d-flex">
            <ButtonRadio
              id={`lotto-game-type-${gameType}`}
              text={(<h4 className={`font-m20r ${isCurrentActive ? 'secondary-text-navy' : 'secondary-text-gold'} `}>{TEXT_NAME_LOTTO[gameType]}</h4>)}
              forceState={isCurrentActive}
              defaultState={isCurrentActive}
              onChangeState={state => {
                const setMainType = ()=>{
                  switch (get(this.state.gameType,"[0]",'TWO_UP')) {
                    case "TWO_UP":
                    case "TWO_DOWN":
                      this.setState({mainGameType:"TWO"})
                      break;
                    case "THREE_UP":
                    case "THREE_DOWN":
                      this.setState({mainGameType:"THREE"})
                      break;
                    case "RUN_UP":
                    case "RUN_DOWN":
                      this.setState({mainGameType:"RUN"})
                      break;
                    default:
                      break;
                  }
                }
                
                if (state) {
                  let gameTypes: TLottoGameType[] = []
                  if (this.state.gameType.length <= 0) {
                    gameTypes = [gameType]
                  } else if (this.state.gameType.length > 1) {
                    if (this.state.gameType.includes(gameType)) {
                      gameTypes = this.state.gameType.filter(type => (!isEqual(type, gameType)))
                    } else {
                      gameTypes = [gameType]
                    }
                  } else if (this.state.gameType.length < 2) {
                    if (this.state.gameType.includes(gameType)) {
                      gameTypes = this.state.gameType
                    } else {
                      const gameTypeState: TLottoGameType = get(this.state.gameType, '0', '')
                      const lengthOriginal = LOTTO_GAME_TYPE_LENGTH[gameTypeState] || 0
                      const lengthTarget = LOTTO_GAME_TYPE_LENGTH[gameType]
                      if (lengthOriginal === lengthTarget) {
                        gameTypes = [...this.state.gameType, gameType]
                      } else {
                        gameTypes = [gameType]
                      }
                    }
                  } else {
                    gameTypes = []
                  }

                  return this.setState({
                    gameType: gameTypes,
                    numberSet: '',
                    inputMode: (gameType === 'RUN_DOWN' || gameType === 'RUN_UP' || gameType === 'FOUR_SUITE') ? 'NUMBERPAD' : this.state.inputMode,
                  },setMainType)
                } else {
                  if (this.state.gameType.length > 1) {
                    if (this.state.gameType.includes(gameType)) {
                      const gameTypes = this.state.gameType.filter(type => (!isEqual(type, gameType)))
                      return this.setState({
                        gameType: gameTypes,
                        numberSet: '',
                        inputMode: (gameType === 'RUN_DOWN' || gameType === 'RUN_UP' || gameType === 'FOUR_SUITE') ? 'NUMBERPAD' : this.state.inputMode,
                      },setMainType)
                    }
                  }
                }
              }}
              backgroundColor={colors.SECONDARY_GOLD_TEXT}
              defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
            />
          </div>
        </div>
      )
    })

    return (<div className="d-flex w-100">{LottoGamTypesComponent}</div>)
  }

  renderLottoSubGameTypes = () => {
    const gameList:TLottoGameType[] =
      this.state.mainGameType==='TWO'?['TWO_UP', 'TWO_DOWN']
        :this.state.mainGameType==='THREE'?['THREE_UP', 'THREE_TOAST']
        :this.state.mainGameType==='RUN'?['RUN_UP', 'RUN_DOWN']
        :['FOUR_SUITE']

    const LottoGamTypesComponent = map(gameList, (gameType, gameTypeIndex) => {
      const isCurrentActive = this.state.gameType.includes(gameType)
      return (
        <div
          className={`col-6 px-1 pb-1`}
          key={`lotto-sub-game-type-${gameTypeIndex}-${gameType}`}
        >
          <div className="d-flex">
            <ButtonRadio
              id={`lotto-game-type-${gameType}`}
              text={(
                <>
                  <h4 className={`font-m20r ${isCurrentActive ? 'secondary-text-navy' : 'secondary-text-gold'} `}>{TEXT_GAME_TYPE_NAME[gameType]}</h4>
                </>
              )}
              forceState={isCurrentActive}
              defaultState={isCurrentActive}
              onChangeState={state => {
                if (state) {
                  let gameTypes: TLottoGameType[] = []
                  if (this.state.gameType.length <= 0) {
                    gameTypes = [gameType]
                  } else if (this.state.gameType.length > 1) {
                    if (this.state.gameType.includes(gameType)) {
                      gameTypes = this.state.gameType.filter(type => (!isEqual(type, gameType)))
                    } else {
                      gameTypes = [gameType]
                    }
                  } else if (this.state.gameType.length < 2) {
                    if (this.state.gameType.includes(gameType)) {
                      gameTypes = this.state.gameType
                    } else {
                      const gameTypeState: TLottoGameType = get(this.state.gameType, '0', '')
                      const lengthOriginal = LOTTO_GAME_TYPE_LENGTH[gameTypeState] || 0
                      const lengthTarget = LOTTO_GAME_TYPE_LENGTH[gameType]
                      if (lengthOriginal === lengthTarget) {
                        gameTypes = [...this.state.gameType, gameType]
                      } else {
                        gameTypes = [gameType]
                      }
                    }
                  } else {
                    gameTypes = []
                  }

                  return this.setState({
                    gameType: gameTypes,
                    numberSet: '',
                    inputMode: (gameType === 'RUN_DOWN' || gameType === 'RUN_UP' || gameType === 'FOUR_SUITE') ? 'NUMBERPAD' : this.state.inputMode,
                  })
                } else {
                  if (this.state.gameType.length > 1) {
                    if (this.state.gameType.includes(gameType)) {
                      const gameTypes = this.state.gameType.filter(type => (!isEqual(type, gameType)))
                      return this.setState({
                        gameType: gameTypes,
                        numberSet: '',
                        inputMode: (gameType === 'RUN_DOWN' || gameType === 'RUN_UP' || gameType === 'FOUR_SUITE') ? 'NUMBERPAD' : this.state.inputMode,
                      })
                    }
                  }
                }
              }}
              backgroundColor={colors.SECONDARY_GOLD_TEXT}
              defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
            />
          </div>
        </div>
      )
    })

    return (<div className="d-flex w-100">{LottoGamTypesComponent}</div>)
  }

  render() {
    const GameInput = this.renderInputMode
    const LottoGameTypes = this.renderLottoGameTypes
    const SubLottoGameTypes=this.renderLottoSubGameTypes

    return (
      <div className={`number-panel ${this.props.gameSlug} ${transformer.checkSizeDevice(windowHeight)}`}>
        <div className='d-flex align-items-center w-100'>
          <div className='d-flex font-m20r pl-1 pr-2'>เดินพันราคา</div>
          <div className="col px-1">
            <InputNumber
              textAlign="center"
              placeholder="โปรดใส่ราคาที่ต้องการ"
              hiddenErrorBlock
              thousandSeparator
              allowNegative={false}
              decimalScale={0}
              isAllowed={(values)=>(
                (values.formattedValue === '' ||
                values.floatValue! <= this.props.betLimit.betLimit) && values.value[0]!=='0')
              }
              name={`input-values-bet`}
              onBlur={(event: ChangeEvent<HTMLInputElement>) =>{}}
              onValueChange={({ value }) => {this.setState({valueBet:value})}}
              value={this.state.valueBet}
              borderStyle={"input-bet"}
              autoComplete="off"
            />
          </div>
        </div>
        <LottoGameTypes />
        <SubLottoGameTypes />
        {this.state.mainGameType==='TWO'&&<div className="d-flex w-100">
          {listTwoType.map((mode)=><div key={mode} className="col-4 px-1 ">
            <div className="d-flex">
              <ButtonRadio
                id={`lotto-mode-numberset`}
                text={(<h4 className={`font-m20r ${mode===this.state.modeTwoType ? 'secondary-text-navy' : 'secondary-text-gold'} `}>{listNameTwoType[mode]}</h4>)}
                forceState={mode===this.state.modeTwoType}
                defaultState={mode===this.state.modeTwoType}
                onChangeState={state => {this.setState({modeTwoType:mode})}}
                backgroundColor={colors.SECONDARY_GOLD_TEXT}
                defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
              />
            </div>
          </div>)}
        </div>}
        <GameInput />
      </div>
    )
  }

}

export default NumberPanel