import colors from 'constants/colors'
import { number } from 'utils'
import { isEmpty, noop } from 'lodash'
import './betLotteryDetailItem.style.scss'
import React, { FC, Fragment } from 'react'
import { PlaceholderLoading, CheckBox } from 'components'

declare interface IBetLotteryDetailItem {
  selectAll: boolean
  betLotteryPullDetail: IResBetLotteryPullDetail,
  onClick?(): void,
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IBetLotteryDetailItem = {
  selectAll: false,
  onClick() { noop() },
  betLotteryPullDetail: {
    id: 0,
    slug: '',
    number: '',
    type: 'FOUR_SUITE',
    value: '',
    createdAt: '',
  },
}
const BetLotteryDetailItem: FC<IBetLotteryDetailItem & DefaultProps> = (props) => {
  const { betLotteryPullDetail, selectAll } = props

  const betPullDetailComponent = () => {
      if (isEmpty(betLotteryPullDetail)) {
        return (
          <div className="pt-3">
            <PlaceholderLoading
              customCol={[
                [
                  { col: 3, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
                  { col: 9, show: false },
                ],
                [
                  { col: 2, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
                  { col: 10, show: false },
                ],
                [
                  { col: 4, color: colors.PRIMARY_TEXT, show: true, size: 'S' },
                  { col: 6, show: false },
                  { col: 2, color: colors.SECONDARY_TEXT, show: true, size: 'S' },
                ],
              ]}
            />
          </div>
        )
      }
      else {
        return (
          <Fragment key={`transaction-description-amount-${betLotteryPullDetail.type}`}>
            <div className="row">
              {/* <div className="col d-flex flex-row align-items-center"> */}
                <div className="col-4 number-bet-text font-m15r justify-content-left padding">
                  {betLotteryPullDetail.number}
                </div>
                <div className="col-4 number-bet-text font-m15r justify-content-center">
                  {number.addComma(Number(betLotteryPullDetail.value))}
                </div>
                <div className="col-4 number-bet-text justify-content-center">
                  <CheckBox
                    id={`${betLotteryPullDetail.id}`}
                    isChecked={betLotteryPullDetail.isCheck}
                    onClick={props.onClick}
                    selectAll={selectAll}
                    labelClassName='label-secondary-gold-bg'
                  />
                </div>
              {/* </div> */}
            </div>
          </ Fragment>
        )
      }
  }
  return (
    <div className="row py-2 bet-lottery-item-container">
      <div className="col d-flex bet-lottery-item-wrapper">
        <div className="d-flex flex-column transaction-leading-wrapper">
          {betPullDetailComponent()}
        </div>
      </div>
    </div>
  )
}

BetLotteryDetailItem.defaultProps = defaultProps

export default BetLotteryDetailItem