import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { noop, includes } from "lodash";
import { Icon } from "@iconify/react";
import "./tabbar.style.scss";
const defaultProps: ITabbarProps = {
  tabs: [],
  mode: "darkMode",
  onPressesMenu() {
    noop();
  },
  isOpen: false,
};

const TabbarComponent: FC<ITabbarProps> = ({
  tabs,
  mode,
  onPressesMenu,
  isOpen,
}) => {
  const history = useHistory();
  const location = useLocation();
  const onClickMenu = (path: string, name: string) => {
    history.push(path);
  };

  const MenuItems = () => {
    const Items = tabs!.map(
      ({ title, name, path, disabled, ActiveIcon, icon }, itemIndex) => {
        const isActive = includes(location.pathname, path);
        const activeMenuClass =
          (isActive && !isOpen) || (name === "menu" && isOpen) ? "active" : "";

        return (
          <div
            className={`flex d-flex p0 menu-item-wrapper ${activeMenuClass}`}
            key={`tab-${itemIndex}-${name}`}
            id={`tab-${itemIndex}-${name}`}
          >
            <div
              className={`menu-item border-outset flex d-flex flex-column align-items-center justify-content-center ${
                disabled ? "disabled" : ""
              }
            ${activeMenuClass}`}
              onClick={
                name === "menu" ? onPressesMenu : () => onClickMenu(path, name)
              }
              // onClick={() => disabled ? noop() : onClickMenu(path, name)}
            >
              <div style={{ width: "32px", height: "32px" }}>
                {ActiveIcon ? (
                  ActiveIcon
                ) : (
                  <Icon
                    icon={icon || ""}
                    color={isActive ? "#FAEB98" : "#FAEB98"}
                    style={{ width: "32px", height: "32px" }}
                  />
                )}
              </div>
              {/* {title === 'ฝาก-ถอน' ? (
              <div className="waviy">
                <span className="display t1">ฝ</span>
                <span className="display t2">า</span>
                <span className="display t3">ก</span>
                <span className="primary-red-text">-</span>
                <span className="display t4">ถ</span>
                <span className="display t5">อ</span>
                <span className="display t6">น</span>
              </div>
            ) : <h6 className="subtitle-2 m0-t">{title}</h6>} */}
              <h6 className="subtitle-2 m0-t title-menu">{title}</h6>
            </div>
          </div>
        );
      }
    );
    return <>{Items}</>;
  };
  return (
    <div id="tabbar-container" className={`tabbar-container ${mode}`}>
      {/* innerHeight={window.innerHeight}/screenHeight={window.screen.height}/ */}
      <div className="container d-flex flex-row">
        <MenuItems />
      </div>
    </div>
  );
};

TabbarComponent.defaultProps = defaultProps;

export default TabbarComponent;
