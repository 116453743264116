import { createAction } from 'typesafe-actions'
import {
  PERSISTED_CODE_LOTTERY,
  SET_CODE_LOTTERY,
  CLEAR_CODE_LOTTERY,
  initialState
} from './constants'

const setCodeLotteryAction = createAction(
  SET_CODE_LOTTERY,
  action => (data: ICodeLotterGameState) => action(data))

const clearCodeLotteryAction = createAction(
  CLEAR_CODE_LOTTERY,
  action => () => action(initialState))

const persistedCodeLotteryAction = createAction(
  PERSISTED_CODE_LOTTERY,
  resolve => (data: ICodeLotterGameState) => resolve(data))
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setCodeLotteryAction,
  clearCodeLotteryAction,
  persistedCodeLotteryAction,
}