import React, { Component, RefObject, createRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  // ALink,
  // Breadcrumb,
  LottoActionCard,
  // InfoModal,
} from 'components'
import { noop, isEmpty, map, get } from 'lodash'
import colors from 'constants/colors'
import {
  LOTTO_SLUG_NAME,
  LOTTO_FLAG_ALPHA,
  LOTTO_SLUG_TO_TYPE,
} from 'constants/variables'
import LottoFlags from 'assets/images/global/flags'
import lottoIcon from 'assets/images/icon/lotto.svg'
import BargraphIcon from 'assets/images/icon/bargraph.svg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import './lottoList.style.scss'
import routes from 'constants/routes'
import { date, interactive } from 'utils'
import BGIcon from 'assets/images/main/BG.png'

const constants = {
  lotto: 'หวย',
  lottoNat: 'หวยหุ้น',
  // back: 'ย้อนกลับ',
  lottoLabel: 'แทงหวย',
  closedLabel: 'ปิดรับ ',
  openedYeegeLabel: 'เปิดแทง: ',
  closedStatusLabel: 'ปิดรับแทง',
  waitingStatusLabel: 'รอเปิดรับแทง',
  openedYeegeStatusLabel: '24 ชม.',
  round: 'รอบ',
  accept: 'เข้าใจแล้ว',
  title: 'ประกาศ',
  description:
    'ทาง Thailandbet งดรับแทงหวย/คืนโพย หวยรัฐบาลเลขแม่น้ำหนึ่ง และเลขดังอื่นๆ ขออภัยในความไม่สะดวก',
}

// const IconComponent = BargraphIcon
type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILottoListProps & ILottoListActionProps = {
  getLottoScheduleIsFetching: false,
  getLottoScheduleCode: 0,
  getLottoScheduleError: '',
  lottoSchedule: [],
  loader() {
    noop()
  },
  getLottoSchedule() {
    noop()
  },
  clearClickRef() {
    noop()
  },
  clickRef: {
    code: undefined,
    company: undefined,
    path: undefined,
  },
}

class LottoListContainer extends Component<
  ILottoListProps & ILottoListActionProps & DefaultProps & RouteComponentProps, ILottoListState
> {
  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()


  state: ILottoListState = {
    selectedLandingMenu: '',
    countlottoglobal: 0,
    countlottoNat: 0,
  }

  componentDidMount() {
    if (!isEmpty(get(this.props.history.location, 'state.selectedLottoGame', {}))) {
      const locationState: ILottoGame = get(this.props.history.location, 'state.selectedLottoGame', {})
      if (locationState.code?.includes('SHARE') && !locationState.code.includes('LOTTER_NAT_SHARE_LAO')) {
        this.setState({ selectedLandingMenu: constants.lottoNat })
      }else{
        this.setState({ selectedLandingMenu: constants.lotto })
      }
    }else{
      this.setState({ selectedLandingMenu: constants.lotto })
    }
    // InfoModal.show({
    //   action: InfoModal.hide,
    //   title: constants.title,
    //   actionText: constants.accept,
    //   description: constants.description,
    // })
    interactive.rootElement.scrollToTop()
    this.props.loader(true)
    this.props.getLottoSchedule()
  }

  componentDidUpdate(prevProps: ILottoListProps) {
    if (
      prevProps.getLottoScheduleIsFetching !==
      this.props.getLottoScheduleIsFetching &&
      !this.props.getLottoScheduleIsFetching
    ) {
      this.setState({
        countlottoglobal: this.props.lottoSchedule.filter((lotto) =>
          (lotto.code.includes('YEGEE') || !lotto.code.includes('SHARE')
            || lotto.code.includes('LOTTER_NAT_SHARE_LAO')
          ) && lotto.status === 'OPEN').length,
        countlottoNat: this.props.lottoSchedule.filter((lotto) => lotto.code.includes('SHARE') && !lotto.code.includes('LOTTER_NAT_SHARE_LAO') && lotto.status === 'OPEN').length,
      })

      this.props.loader(false)
      if (!isEmpty(this.props.clickRef.code)) this.props.loader(true)
      for (let i = 0; i < this.props.lottoSchedule.length; i++) {
        if (this.props.lottoSchedule[i].code === this.props.clickRef.code) {
          this.props.lottoSchedule[i].status === 'OPEN'
            ? this.props.lottoSchedule[i].code === 'LOTTER_YEGEE'
              ? this.onNavigateByClickRef(this.props.lottoSchedule[i], true)
              : this.onNavigateByClickRef(this.props.lottoSchedule[i], false)
            : this.onClearClickRef()
          break
        } else {
          this.props.loader(false)
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.loader(false)
  }

  onClearClickRef = () => {
    this.props.loader(false)
    return this.props.clearClickRef()
  }

  onNavigateByClickRef = (lotto: ILottoSchedule, isYeeGee: boolean) => {
    if (isYeeGee) {
      this.props.history.push(routes.lottoChrildren.exactPath(lotto.code))
    } else {
      const lottoGame: ILottoGame = {
        id: 0,
        round: '',
        status: lotto.status,
        createdAt: lotto.startTime,
        endTime: lotto.endTime,
        startTime: lotto.startTime,
      }
      this.props.history.push(routes.lottoMaking.exactPath(lotto.code), {
        selectedLottoGame: lottoGame,
      })
    }
    return this.onClearClickRef()
  }

  renderLottoList = (shareLottos: ILottoSchedule[]) =>
    map(shareLottos, (lotto, index) => {
      const subtitle =
        lotto.code === 'LOTTER_YEGEE'
          ? constants.openedYeegeLabel
          : constants.closedLabel
      const backgroundColor =
        lotto.code === 'LOTTER_YEGEE'
          ? colors.TERTIARY_BUTTON_BLUE
          : colors.SECONDARY_BUTTON_BG
      const description =
        lotto.code === 'LOTTER_YEGEE'
          ? `88 ${constants.round}`
          : isEmpty(lotto.endTime)
            ? '-'
            : date.calibratingTime(lotto.endTime).add('years', 543).format('Do MMM YY')
      // .add('years', 543).format('Do MMMM YY')
      const isCountingDown =
        lotto.code === 'LOTTER_YEGEE' ? false : lotto.status === 'OPEN'

      // Faking lotto game object
      const lottoGame: ILottoGame = {
        id: 0,
        round: '',
        status: lotto.status,
        createdAt: lotto.startTime,
        endTime: lotto.endTime,
        startTime: lotto.startTime,
      }

      const navigate =
        lotto.status === 'OPEN'
          ? lotto.code === 'LOTTER_YEGEE'
            ? () =>
              this.props.history.push(
                routes.lottoChrildren.exactPath(lotto.code),
                { selectedLottoGame: lottoGame }
              )
            : () =>
              this.props.history.push(
                routes.lottoMaking.exactPath(lotto.code),
                { selectedLottoGame: lottoGame }
              )
          : undefined

      const FlagIcon =
        LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lotto.code]]]?.Icon
      return (
        <div
          className={`col-6 col-md-6 col-lg-4 col-sm-6 m2-t ${index}`}
          key={`lotto-${lotto.code}-${index}`}
        >
          <LottoActionCard
            slug={lotto.code}
            id={`lotto-${lotto.code}`}
            onClick={navigate}
            title={LOTTO_SLUG_NAME[lotto.code]}
            subTitle={subtitle}
            icon={FlagIcon}
            backgroundColor={backgroundColor}
            status={lotto.status}
            isCountingdown={isCountingDown}
            closedStatusText={constants.closedStatusLabel}
            waitingStatusText={constants.waitingStatusLabel}
            openedStatusText={constants.openedYeegeStatusLabel}
            description={description}
            expire={lotto.endTime}
            start={lotto.startTime}
          />
        </div>
      )
    })

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path)
  }

  badgeNumber = (dataList: string) => {

    if (dataList === constants.lotto && this.state.countlottoglobal !== 0) {
      return (
        <div className={`badge ${dataList === constants.lottoNat && ('nat')}`}>
          <div className="badge-number">{dataList === constants.lotto
            ? this.state.countlottoglobal : this.state.countlottoNat}
          </div>
        </div>
      )
    }
    else if (dataList === constants.lottoNat && this.state.countlottoglobal !== 0) {
      return (
        <div className={`badge ${dataList === constants.lottoNat && ('nat')}`}>
          <div className="badge-number">{dataList === constants.lotto
            ? this.state.countlottoglobal : this.state.countlottoNat}
          </div>
        </div>
      )
    }
    return <></>
  }
  handleScrollToTop = () => {
    if (this.pageContainerRef.current) {
      interactive.rootElement.scrollToTop()
    }
  }

  onRenderLandingMenu = () => {
    const mappingMenu = map([constants.lotto, constants.lottoNat], (dataList, index) => (
      <div className={`${index===0?"pr-1":"pl-1"} col-6 p-0`} key={`item-${index}`}>
        <div
          className={`menu-box-wrapper
          ${this.state.selectedLandingMenu === dataList && 'selected-menu'}
          `}
          key={`item-${index}`}
          id={`menu-${index}-${dataList}`}
          onClick={() => {
            this.handleScrollToTop()
            if (this.state.selectedLandingMenu !== dataList) {
              this.setState({ selectedLandingMenu: dataList })
            }
          }}
        >
          {/* {this.badgeNumber(dataList)} */}
          <div className='box-icon-badge'>
            <img
              className={`icon-menu ${this.state.selectedLandingMenu === dataList ? 'active-filter' : 'default-filter'} ${dataList !== constants.lotto?'nat':''}`}
              alt={'lotto-icon'}
              src={dataList === constants.lotto ? lottoIcon : BargraphIcon}
            />
            {this.badgeNumber(dataList)}
          </div>
            <h4 className={`m3-r ${this.state.selectedLandingMenu === dataList ? 'secondary-text-navy' : 'secondary-text-gold'}`}>
            {dataList}
          </h4>
        </div>
      </div>
    ))
    return (
      <div className="menu-lotto-container col-12">
        {mappingMenu}
      </div>
    )
  }

  render() {
    const globalLottos = this.props.lottoSchedule.filter((lotto) => (lotto.code.includes('YEGEE') || !lotto.code.includes('SHARE') || lotto.code.includes('LOTTER_NAT_SHARE_LAO')))
    const shareLottos = this.props.lottoSchedule.filter((lotto) => lotto.code.includes('SHARE') && !lotto.code.includes('LOTTER_NAT_SHARE_LAO'))
    const RenderLandingMenu = this.onRenderLandingMenu

    return (
      <div
        className="lotto-list-container secondary-bg-navy"
        ref={this.pageContainerRef}
      >
        <div className={`col-12 position-fixed header`}>
          <div className="container p-0 m1-b">
            <RenderLandingMenu />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <img src={BGIcon} className="bg-images-fixed" alt="bg-images-fixed" />
            </div>
          </div>
          <div className="row lotto-mr">
            {this.state.selectedLandingMenu === constants.lotto
              ? this.renderLottoList(globalLottos)
              : this.renderLottoList(shareLottos)}
          </div>
        </div>
      </div>
    )
  }
}

export default LottoListContainer
