import colors from 'constants/colors'
import './creditInfoReport.style.scss'
import { date, interactive, number } from 'utils'
import { responseCode } from 'constants/response'
import { RouteComponentProps } from 'react-router-dom'
// import InfiniteScroll from 'react-infinite-scroll-component'
import React, { Component, createRef, RefObject } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LottoFlags from 'assets/images/global/flags'
import {
  LOTTO_FLAG_ALPHA,
  LOTTO_SLUG_NAME,
  LOTTO_SLUG_TO_TYPE,
  MONTH,
  LOTTO_GAME_TYPE_NAME,
  SLUG_TYPE_GAME
} from 'constants/variables'
import {
  map,
  noop,
  keys,
  isEmpty,
  reverse,
  groupBy,
  Dictionary,
  get,
  invert,
  padStart,
  includes,
} from 'lodash'
import {
  ALink,
  ErrorModal,
  LoadMore,
  PlaceholderLoading,
  ResponsiveIcon,
  TransactionItemReport,
} from 'components'
import { faChevronLeft, faSync } from '@fortawesome/free-solid-svg-icons'
import swap from 'assets/images/icon/swap.png'
import { Dropdown } from 'components'
import moment from 'moment'
import routes from 'constants/routes'
const constants = {
  profitLoss: 'กำไร / ขาดทุน',
  invest: 'ลงทุน',
  bth: 'บาท',
  titleText: 'ข้อมูลเครดิต',
  creditTextOld: 'ข้อมูลเครดิตเก่า',
  remainingLabel: 'เครดิตคงเหลือ',
  deposit: 'ฝาก',
  withdraw: 'ถอน',
  back: ' ย้อนกลับ',
  latedUpdate: 'อัพเดทล่าสุด:',
  ok: 'ตกลง',
  thailand: 'Thailand',
  credit: 'CREDIT',
  bet: 'Bet',
  cardNumber: '**** **** **** ****',
  today: 'วันนี้',
  yesterday: 'เมื่อวาน',
  listMenu: ['หวย', 'คาสิโน'],
  dataEmpty: 'ไม่พบข้อมูล',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ICreditInfoBetLotteryProps &
  ICreditInfoBetLotteryActionProps = {
  creditInfoLottery: [],
  getCreditInfoListLotteryCode: 0,
  getCreditInfoListLotteryError: '',
  getCreditInfoListLotteryIsFetching: false,
  creditInfoLotteryPagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  codeLotterGame() {
    noop()
  },
  getCreditInfoBetLottery() {
    noop()
  },
  getCreditInfoBetGame() {
    noop()
  },
  getLotterBenefitRequestAction() {
    noop()
  },
  getLotterBenefitCode: 0,
  getLotterBenefitError: '',
  getLotterBenefitIsFetching: false,
  getLotterBenefit: {
    profitAndLoss: 0,
    capital: 0,
  },
  getGameBenefitRequestAction() {
    noop()
  },
  getGameBenefitCode: 0,
  getGameBenefitError: '',
  getGameBenefitIsFetching: false,
  getGameBenefit: {
    profitAndLoss: 0,
    capital: 0,
  },
  getCodeLotterGame: '',
  getOldCodeLotterGame: '',

  getCreditInfoListGameCode: 0,
  getCreditInfoListGameError: '',
  getCreditInfoListGameIsFetching: false,
  creditInfoGame: [],
  creditInfoGamePagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  loader() {
    noop()
  },
}

class TransactionListContainer extends Component<
  ICreditInfoBetLotteryProps &
  ICreditInfoBetLotteryActionProps &
  DefaultProps &
  RouteComponentProps<any, any, ICreditInfoBetLotteryRouteProps>,
  ICreditInfoReportState
> {
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  state: ICreditInfoReportState = {
    hasMore: true,
    selectedLandingMenu: 'หวย',
    year: (Number(moment().format('YYYY')) + 543).toString(),
    codeLottoGame: 'LOTTER_GOVN',
    month: padStart((new Date().getMonth() + 1).toString(), 2, '01'),

    creditInfoGame: [],
    creditInfoLottery: [],

    oldTap: 'JOKER',
    profitAndLoss: 0,
    capital: 0
  }

  componentDidMount() {
    interactive.rootElement.scrollToTop()
    this.props.loader(true)
    this.checkCodeLottoGame()

    if ((this.props.location.state?.notification)) {
      const { year, month } = this.props.location.state.notification
      this.setState({ year, month })
    }
  }

  checkCodeLottoGame = () => {
    let oldCode = get(this.props, 'getOldCodeLotterGame', '')
    const noti = this.props.location.state?.notification

    // getCodeLotterGame is always true since initial values are set
    if (this.props.getCodeLotterGame) {
      if (
        isEmpty(
          Object.keys(SLUG_TYPE_GAME).find(
            (element) => element === this.props.getCodeLotterGame
          )
        )
      ) {
        this.setState({ selectedLandingMenu: noti ? noti.selectedLandingMenu || 'หวย' : constants.listMenu[0], oldTap: isEmpty(oldCode) ? 'JOKER' : oldCode }, () => {
          this.onGetCreditinfo(true)
          this.getLotterBenefit()
        })
      } else {
        this.setState({ selectedLandingMenu: noti ? noti.selectedLandingMenu || 'หวย' : constants.listMenu[1], oldTap: isEmpty(oldCode) ? 'LOTTER_GOVN' : oldCode }, () => {
          this.onGetCreditinfo(true)
          this.getLotterBenefit()
        })
      }
      this.setState({ codeLottoGame: noti ? noti.codeLottoGame : this.props.getCodeLotterGame })
    } else {
      if (noti) {
        this.setState({codeLottoGame: noti.codeLottoGame, selectedLandingMenu: noti.selectedLandingMenu || 'หวย'})
      }
      this.setState({ oldTap: 'JOKER' }, () => {
        this.onGetCreditinfo(true)
        this.getLotterBenefit()
      })
    }
  }

  componentDidUpdate(prevProps: ICreditInfoBetLotteryProps) {
    if (
      prevProps.getCreditInfoListGameIsFetching !==
      this.props.getCreditInfoListGameIsFetching &&
      !this.props.getCreditInfoListGameIsFetching
    ) {
      this.props.loader(false)
      if (this.props.getCreditInfoListGameCode === responseCode.OK) {
        if (
          this.props.creditInfoGamePagination.page >=
          this.props.creditInfoGamePagination.total
        ) {
          this.setState({ hasMore: false })
        }
        this.setState({ creditInfoGame: this.props.creditInfoGame })
      } else if (
        this.props.getCreditInfoListGameCode !== responseCode.OK &&
        this.props.getCreditInfoListGameCode !== responseCode.NOT_FOUND
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.getCreditInfoListGameError,
          actionText: constants.ok,
        })
      }
    }

    if (
      prevProps.getCreditInfoListLotteryIsFetching !==
      this.props.getCreditInfoListLotteryIsFetching &&
      !this.props.getCreditInfoListLotteryIsFetching
    ) {
      this.props.loader(false)
      if (this.props.getCreditInfoListLotteryCode === responseCode.OK) {
        if (
          this.props.creditInfoLotteryPagination.page >=
          this.props.creditInfoLotteryPagination.total
        ) {
          this.setState({ hasMore: false })
        }
        this.setState({ creditInfoLottery: this.props.creditInfoLottery })
      } else if (
        this.props.getCreditInfoListLotteryCode !== responseCode.OK &&
        this.props.getCreditInfoListLotteryCode !== responseCode.NOT_FOUND
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.getCreditInfoListLotteryError,
          actionText: constants.ok,
        })
      }
    }

    if (
      prevProps.getLotterBenefitIsFetching !==
      this.props.getLotterBenefitIsFetching &&
      !this.props.getLotterBenefitIsFetching
    ) {
      this.props.loader(false)
      if (
        this.props.getLotterBenefitCode !== responseCode.OK &&
        this.props.getLotterBenefitCode !== responseCode.NOT_FOUND
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.getLotterBenefitError,
          actionText: constants.ok,
        })
      } else if (this.props.getLotterBenefitCode === responseCode.OK) {
        this.setState({
          profitAndLoss: get(this.props.getLotterBenefit, 'profitAndLoss', 0),
          capital: get(this.props.getLotterBenefit, 'capital', 0)
        })
      }
    }

    if (
      prevProps.getGameBenefitIsFetching !==
      this.props.getGameBenefitIsFetching &&
      !this.props.getGameBenefitIsFetching
    ) {
      this.props.loader(false)
      if (
        this.props.getGameBenefitCode !== responseCode.OK &&
        this.props.getGameBenefitCode !== responseCode.NOT_FOUND
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.getGameBenefitError,
          actionText: constants.ok,
        })
      } else if (this.props.getGameBenefitCode === responseCode.OK) {
        this.setState({
          profitAndLoss: get(this.props.getGameBenefit, 'profitAndLoss', 0),
          capital: get(this.props.getGameBenefit, 'capital', 0)
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.codeLotterGame({ code: this.state.codeLottoGame, oldCode: this.state.oldTap })
  }

  onPressBack = () => {
    this.props.history.push(routes.main.path)
  }

  onPressDeposit = () => {
    this.props.history.push('/deposit')
  }

  onPresWithdraw = () => {
    this.props.history.push('/withdraw')
  }

  onPressTransactionDetail = (credit: ICredit) => {
    this.props.history.push('/credit-info/detail', { credit })
  }

  onGetCreditinfo = (isFirst = false) => {
    if (isFirst) {
      this.setState({ hasMore: true })
      this.handleLoadMore(isFirst)
    }
    
  }

  handleLoadMore=(isFirst = false)=>{
    const queryData: APIPaginationQuery = {
      limit: 10,
      page: isFirst ? 1 : this.isLotterOrGame() ? this.props.creditInfoLotteryPagination.page + 1 : this.props.creditInfoGamePagination.page + 1,
    }
    if (this.isLotterOrGame()) {
      this.props.getCreditInfoBetLottery(
        { ...queryData, ...this.dataDateTime() },
        isFirst
      )
    } else {
      this.props.getCreditInfoBetGame(
        { ...queryData, ...this.dataDateTime() },
        isFirst
      )
    }
  }

  dataDateTime = () => {
    return {
      startDate: `${Number(this.state.year) - 543}-${this.state.month
        }-01T00:00:00.000Z`,
      endDate: `${Number(this.state.year) - 543}-${this.state.month
        }-${date.endDay(this.state.month, this.state.year)}T23:59:59.000Z`,
      code: this.isLotterOrGame() ? this.state.codeLottoGame : get(SLUG_TYPE_GAME, this.state.codeLottoGame, ''),
      // code: '',
    }
  }

  getLotterBenefit = () => {
    this.props.loader(true)
    if (this.isLotterOrGame()) {
      this.props.getLotterBenefitRequestAction(this.dataDateTime())
    } else {
      this.props.getGameBenefitRequestAction(this.dataDateTime())
    }
  }

  isLotterOrGame = () =>
    this.state.selectedLandingMenu === constants.listMenu[0]

  renderTransactionList = () => {
    const transactionGroupListLottery: Dictionary<ICreditBetLottery[]> =
      groupBy<ICreditBetLottery>(
        map(this.state.creditInfoLottery,
          (transaction) => ({ ...transaction })
        ),
        'updatedAt'
      )
    const transactionGroupListGame: Dictionary<ICreditBetGame[]> =
      groupBy<ICreditBetGame>(
        map(this.state.creditInfoGame,
          (transaction) => ({ ...transaction })
        ),
        'updatedAt'
      )
    return (
      <LoadMore
        isLoad={( this.isLotterOrGame() && this.props.getCreditInfoListLotteryIsFetching ) || ( !this.isLotterOrGame() && this.props.getCreditInfoListGameIsFetching )}
        handleClickLoadMore={()=>{this.handleLoadMore(false)}}
        style={{ overflowX: 'scroll', maxHeight: '740px', paddingBottom: '10px' }}
        height={'50vh'}
        dataLength={
          this.isLotterOrGame()
            ? get(this.state.creditInfoLottery, 'length', 0)
            : get(this.state.creditInfoGame, 'length', 0)
        }
        next={this.onGetCreditinfo}
        scrollThreshold="15px"
        hasMore={this.state.hasMore}
        endMessage={(!this.state.hasMore && isEmpty(this.isLotterOrGame() ? transactionGroupListLottery : transactionGroupListGame)) ? <h5 className="m-3">{constants.dataEmpty}</h5> : ''}
        loader={(
          <div className="p2-y px-3">
            {this.isLotterOrGame() ?
              <PlaceholderLoading
                customCol={[
                  [
                    {
                      col: 2,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                    {
                      col: 5,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                    {
                      col: 3,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                    {
                      col: 2,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                  ],
                  [
                    { col: 2, show: false },
                    {
                      col: 5,
                      color: colors.PRIMARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                    { col: 3, show: false },
                    {
                      col: 2,
                      color: colors.PRIMARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                  ],
                ]}
              /> :
              <PlaceholderLoading
                customCol={[
                  [
                    {
                      col: 3,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                    {
                      col: 4,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                    {
                      col: 3,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                    {
                      col: 2,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                  ],
                  [
                    {
                      col: 3,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                    {
                      col: 4,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                    { col: 3, show: false },
                    {
                      col: 2,
                      color: colors.SECONDARY_TEXT,
                      show: true,
                      size: 'S',
                    },
                  ],
                ]}
              />
            }
          </div>
        )}
      >
        {this.isLotterOrGame() ?
          map(reverse(keys(transactionGroupListLottery).sort()), (key) => {
            const groupListCreditBetLottery: Dictionary<ICreditBetLottery[]> = groupBy<ICreditBetLottery>(transactionGroupListLottery[key], 'type')

            return map(keys(groupListCreditBetLottery), (item, index) => {
              return <div key={index + item}>
                <h5 key={index + item} className="container-padding credit-info-old-name">{get(LOTTO_GAME_TYPE_NAME, item)}</h5>
                {map(groupListCreditBetLottery[item], (transaction, transactionIndex) => {

                  return (
                    <TransactionItemReport
                      numbers={transaction.number}
                      containerClassName="mt-2 mx-0"
                      key={`${transaction.status}-${transaction.createdAt}-${transactionIndex}`}
                      money={Number(transaction.value)}
                      day={moment(transaction.updatedAt).format('D')}
                      status={transaction.status}
                      time={moment(transaction.updatedAt).format('HH:mm')}
                      type={transaction.type}
                      bet={number.addComma(Number(transaction.value))}
                      result={number.addComma(Number(transaction.result))}
                      isGame={!this.isLotterOrGame()}
                      rate={number.addComma(Number(transaction.rate))}
                      slug={transaction.slug!}
                      wordSpecial={get(transaction, 'wordSpecial')}
                    />
                  )
                })}
              </div>
            })
          })
          :
          map(reverse(keys(transactionGroupListGame).sort()), (key, index) => {
            const TransactionDay = map(
              transactionGroupListGame[key],
              (transaction, transactionIndex) => {
                return (
                  <TransactionItemReport
                    numbers={transaction.number}
                    containerClassName="mt-2 mx-0"
                    key={`${transaction.status}-${transaction.createdAt}-${transactionIndex}`}
                    money={Number(transaction.value)}
                    day={moment(transaction.updatedAt).format('D')}
                    status={transaction.status}
                    time={moment(transaction.updatedAt).format('HH:mm')}
                    slug={transaction.slug!}
                    type={transaction.type}
                    bet={number.addComma(Number(transaction.value))}
                    result={number.addComma(Number(transaction.result))}
                    isGame={!this.isLotterOrGame()}
                    wordSpecial={get(transaction, 'wordSpecial')}
                  />
                )
              }
            )
            return TransactionDay
          })
        }
      </LoadMore>
    )
  }

  onRenderLandingMenu = () => {
    const mappingMenu = map(constants.listMenu, (dataList, i) => (
      <div
        className={`menu-box-wrapper p-2 col-6
         ${this.state.selectedLandingMenu === dataList && 'selected-menu'}
        `}
        key={`item-${i}`}
        id={`menu-${i}-${dataList}`}
        onClick={() => {
          if (this.state.selectedLandingMenu !== dataList) {
            this.setState({ selectedLandingMenu: dataList }, () => {
              this.setState(
                { codeLottoGame: this.state.oldTap, oldTap: this.state.codeLottoGame }, () => {
                  this.getdateOnchangefilter()
                }
              )
            })
          }
        }}
      >
        <h5
          className={
            this.state.selectedLandingMenu === dataList
              ? 'secondary-text-gold'
              : ''
          }
        >
          {dataList}
        </h5>
      </div>
    ))
    return <div className="landing-menu-container col-12">{mappingMenu}</div>
  }

  year = () => {
    const data = []
    const yearNow = Number(moment().format('YYYY')) + 543
    for (let index = yearNow - 2562; index >= 0; index--) {
      data[index] = {
        value: (yearNow - index).toString(),
        label: (
          <div className="d-flex flex-row justify-content-center align-items-center dropdown-font"
            style={{ cursor: 'pointer' }}>
            {(yearNow - index).toString()}
          </div>
        ),
        disabled: false,
      }
    }
    return data
  }

  optionsGameBet = () => {
    return Object.keys(SLUG_TYPE_GAME).map((val: string) => {
      return {
        value: val,
        label: (
          <div className="d-flex flex-row justify-content-center align-items-center dropdown-font"
            style={{ cursor: 'pointer' }}>
            {val}
          </div>
        ),
        disabled: false,
      }
    })
  }

  optionsLotteryBet = () => {
    const LOTTO_NAME = Object.keys(LOTTO_SLUG_NAME).filter(item => !includes(item, 'PMPG'));
    return LOTTO_NAME.map((val: string) => {
      const nameFlag = get(LOTTO_SLUG_TO_TYPE, val, '')
      const FlagIcon = get(
        LottoFlags,
        `${get(LOTTO_FLAG_ALPHA, nameFlag, '')}.Icon`,
        ''
      )
      return {
        value: get(LOTTO_SLUG_NAME, val),
        label: (
          <div className="d-flex flex-row justify-content-center align-items-center dropdown-font"
            style={{ cursor: 'pointer' }}>
            <ResponsiveIcon
              icon={FlagIcon}
              alt="flag"
              className="lotto-slug-dropdown-flag"
            />
            <div className="ml-2">{get(LOTTO_SLUG_NAME, val)}</div>
          </div>
        ),
        disabled: false,
      }
    })
  }

  valueCodeBet = () => {
    if (this.isLotterOrGame()) {
      return get(LOTTO_SLUG_NAME, this.state.codeLottoGame)
    } else {
      return this.state.codeLottoGame
    }
  }

  getdateOnchangefilter = () => {
    this.setState({ creditInfoLottery: [], creditInfoGame: [] })
    this.props.loader(true)
    this.getLotterBenefit()
    this.onGetCreditinfo(true)
  }

  colorProfit = (value: number) =>
    value < 0 ? 'primary-red-text' : value > 0 ? 'primary-green-text' : ''

  checkNull = (value: string | number = 0) =>
    `${value === null ? 0 : Number(value)} `

  render() {
    const RenderLandingMenu = this.onRenderLandingMenu
    const TransactionList = this.renderTransactionList()

    return (
      <div className="credit-info-list-report-container" ref={this.pageContainerRef}>
        <div className="container">
          <div className="row">
            <div className="col">
              <ALink
                id="backto-previus-page"
                color={colors.PRIMARY_RED}
                bold
                onClick={this.onPressBack}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                {constants.back}
              </ALink>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <div className="credit-info-report p2-x p2-y tertiary-bg-navy">
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-7">
                    <div className="d-flex flex-row align-items-center">
                      <span className="aab">{constants.titleText}</span>
                      <div
                        className={`temprary-button-wrapper ml-1-lg`}
                        onClick={() => {
                          this.onGetCreditinfo(true)
                          this.getLotterBenefit()
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faSync}
                          className="primary-text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-5 d-flex flex-row justify-content-end align-items-center">
                    <div
                      className="credit-info-old"
                      onClick={() => {
                        this.props.history.push(routes.creditInfo.path)
                      }}
                    >
                      <span>
                        <img className="swap-icon" src={swap} alt={`swap`} />
                      </span>
                      <span className="credit-info-old-name ml-1">
                        {' '}
                        {constants.creditTextOld}
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-xs col-sm-8 col-md-6 col-lg-6 col-xl-6 text-profit-loss-invest mt-3 mb-2">
                    <div className="row mb-3">
                      <h5 className="col-5 col-sm-5 col-md-5 lg-4 text-profit-loss-invest">
                        {constants.profitLoss}
                      </h5>
                      <div className="col d-flex flex-row justify-content-end align-items-center pl-0">
                        <h5
                          className={`text-right text-profit ${this.colorProfit(
                            this.state.profitAndLoss
                          )}`}
                        >
                          {number.addComma(Number(this.checkNull(this.state.profitAndLoss)))}
                        </h5>
                        <h5 className="text-profit-loss-invest ml-1">
                          {constants.bth}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <h5 className="col-5 col-sm-5 col-md-5 lg-4 text-profit-loss-invest">
                        {constants.invest}
                      </h5>
                      <div className="col d-flex flex-row justify-content-end align-items-center pl-0">
                        <h5 className="text-right text-profit">
                          {number.addComma(Number(this.checkNull(this.state.capital)))}
                        </h5>
                        <h5 className="text-profit-loss-invest ml-1">
                          {constants.bth}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">
                        <Dropdown
                          placeholder={'ปี'}
                          value={this.state.year}
                          options={this.year()}
                          handleOnChange={(target) => {
                            this.setState(
                              { year: target.value },
                              this.getdateOnchangefilter
                            )
                          }}
                        />
                      </div>
                      <div className="col-6 pl-0 mb-2">
                        <Dropdown
                          placeholder={'เดือน'}
                          value={get(
                            MONTH,
                            Number(this.state.month) - 1
                          )}
                          options={MONTH.map((val: string) => {
                            return {
                              value: val,
                              label: (
                                <div className="d-flex flex-row  dropdown-font"
                                  style={{ cursor: 'pointer' }}>
                                  {val}
                                </div>
                              ),
                              disabled: false,
                            }
                          })}
                          handleOnChange={(target) => {
                            this.setState(
                              {
                                month: padStart(
                                  (
                                    MONTH.indexOf(target.value) + 1
                                  ).toString(),
                                  2,
                                  '0'
                                ),
                              },
                              this.getdateOnchangefilter
                            )
                          }}
                        />
                      </div>
                      <div className="col-12 ">
                        <Dropdown
                          placeholder={'เลือกประเภท'}
                          value={this.valueCodeBet()}
                          options={
                            this.isLotterOrGame()
                              ? this.optionsLotteryBet()
                              : this.optionsGameBet()
                          }
                          handleOnChange={(target) => {
                            if (this.isLotterOrGame()) {
                              const INVERTED_LOTTO_SLUG_NAME =
                                invert(LOTTO_SLUG_NAME)
                              const lottoSlugName =
                                INVERTED_LOTTO_SLUG_NAME[target.value]
                              this.setState(
                                { codeLottoGame: lottoSlugName },
                                this.getdateOnchangefilter
                              )
                            } else {
                              this.setState(
                                { codeLottoGame: target.value },
                                this.getdateOnchangefilter
                              )
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="credit-info-transaction-wrapper  p2-t">
                      <div className="row">
                        <RenderLandingMenu />
                      </div>
                      {TransactionList}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TransactionListContainer
