import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import "./notification.style.scss";
import BGIcon from "assets/images/main/BG.png";
import { NotificationCard } from "./components/NotificationCard";
import routes from "constants/routes";
import {
  cloneDeep,
  get,
  isEmpty,
  lowerCase,
  noop,
  startCase,
  toLower,
} from "lodash";
import moment from "moment";
import { GAME_COMPANY, REFERENCE_PATH } from "constants/variables";
import { interactive } from "utils";
import project from "constants/project";
import { InfoNotificationModal } from "components/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { PlaceholderLoading } from "components";
import partnerList from "assets/images/vendorsIcon";

const constants = {
  notification: "การแจ้งเตือน",
  new: "ใหม่",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: INotificationProps & INotificationActionProps = {
  getLottoScheduleIsFetching: false,
  getLottoScheduleCode: 0,
  getLottoScheduleError: "",
  lottoSchedule: [],
  loader() {
    noop();
  },
  getLottoSchedule() {
    noop();
  },
  getGameListIsFetching: false,
  getGameListError: "",
  getGameListCode: 0,
  gameList: [],
  displayConfig: {},
  displayConfigCode: 0,
  displayConfigError: "",
  displayConfigIsFetching: false,
  getDisplayConfig() {
    noop();
  },
  getGameList() {
    noop();
  },
  notificationUser: {
    dataList: [],
    limit: 0,
    page: 0,
    totalData: 0,
    totalPage: 0,
  },
  notificationUserCode: 0,
  notificationUserError: "",
  notificationUserIsFetching: false,
  getNotificationUser() {
    noop();
  },
  getNotificationUserCount() {
    noop();
  },
  notificationUserCount: {
    unreadCount: 0,
  },
  notificationUserCountCode: 0,
  notificationUserCountError: "",
  notificationUserCountIsFetching: false,
  notificationClick: {},
  notificationClickCode: 0,
  notificationClickError: "",
  notificationClickIsFetching: false,
  postNotificationClick() {
    noop();
  },
  postNotificationUserRead() {
    noop();
  },
  notificationUserRead: {},
  notificationUserReadIsFetching: false,
  notificationUserReadCode: 0,
  notificationUserReadError: "",
  clickRef() {
    noop();
  },
  getYeegeGameList() {
    noop();
  },
  getYeegeGameListCode: 0,
  getYeegeGameListError: "",
  getYeegeGameListIsFetching: false,
  yeegeGameList: [],
};

class NotificationContainer extends Component<
  INotificationProps &
    INotificationActionProps &
    DefaultProps &
    RouteComponentProps<any, any, INotificationRouteProps>,
  INotificationState
> {
  state: INotificationState = {
    notificationUserList: [],
    page: 1,
    selectedNotificationUserId: 0,
    isNotificationUserClickCalled: false,
  };

  componentDidMount() {
    this.props.loader(true);
    this.props.getLottoSchedule();
    this.props.getYeegeGameList();
  }

  componentDidUpdate(prevProps: INotificationProps) {
    if (
      prevProps.getLottoScheduleIsFetching !==
        this.props.getLottoScheduleIsFetching &&
      !this.props.getLottoScheduleIsFetching
    ) {
      this.props.getNotificationUser({ limit: 10 });
    }

    if (
      prevProps.notificationUserIsFetching !==
        this.props.notificationUserIsFetching &&
      !this.props.notificationUserIsFetching
    ) {
      const arrayOfItemsIds = this.props.notificationUser.dataList.map(
        (item) => item.id
      );
      this.props.postNotificationUserRead({ messageIdList: arrayOfItemsIds });
      this.setState({
        notificationUserList: this.state.notificationUserList.concat(
          this.props.notificationUser.dataList
        ),
      });
      this.props.loader(false);
    }

    if (
      prevProps.notificationUserReadIsFetching !==
        this.props.notificationUserReadIsFetching &&
      !this.props.notificationUserReadIsFetching
    ) {
      this.props.getNotificationUserCount();
    }

    if (
      prevProps.notificationClickIsFetching !==
        this.props.notificationClickIsFetching &&
      !this.props.notificationClickIsFetching
    ) {
      const clonedNotificationUserList = cloneDeep(
        this.state.notificationUserList
      );
      const index = clonedNotificationUserList.findIndex(
        (item) => item.id === this.state.selectedNotificationUserId
      );
      if (index !== -1) {
        this.setState({ isNotificationUserClickCalled: false });
        clonedNotificationUserList[index].isClick = true;
        this.setState({ notificationUserList: clonedNotificationUserList });
      }
    }

    if (
      prevProps.getGameListIsFetching !== this.props.getGameListIsFetching &&
      !this.props.getGameListIsFetching
    ) {
      this.props.loader(false);
      if (!isEmpty(this.props.gameList)) {
        const casinoGame = this.props.gameList[0];

        const isMaintenance = get(
          this.props.displayConfig,
          `${project.environmentName}.${lowerCase(casinoGame.company)}`,
          true
        );

        window.open(
          isMaintenance
            ? interactive.openLink<IGame & { gameLocation: TGameLocation }>({
                ...casinoGame,
                gameLocation: "EXTERNAL",
              })
            : "/"
        );
      }
    }
  }

  onGetGameList = (val: IGameQuery, ref?: string) => {
    if (["PG", "DREAMTECH"].includes(val.company!)) {
      this.props.loader(false);
      if (["PG"].includes(ref!)) {
        window.open(
          interactive.openLink<any & { gameLocation: TGameLocation }>({
            company: "PGGAME",
            code: "PGGAME",
            gameLocation: "EXTERNAL",
          }),
          "_blank"
        );
      } else if (["DREAMTECH"].includes(ref!)) {
        window.open(
          interactive.openLink<any & { gameLocation: TGameLocation }>({
            company: "DREAMTECH",
            code: "DREAMTECH",
            gameLocation: "EXTERNAL",
          }),
          "_blank"
        );
      }
    } else {
      this.props.loader(true);
      this.props.getGameList("EXTERNAL", val);
    }
  };

  formatCompany = (
    ref: string
  ): {
    company: TGameCompany;
    name: string;
  } => {
    const formatGameNameToStartCase = (gameCompany: string) => {
      let name = ref.replace(`${gameCompany}_`, "");
      name = startCase(toLower(name.replace("_", " ")));
      name = name.replace("Of", "of");
      return name;
    };

    if (ref.includes("JOKER_A_NIGHT_OUT")) {
      return {
        company: "JOKER",
        name: "A Night Out",
      };
    }
    if (ref.includes("AE_SEXY")) {
      const name = formatGameNameToStartCase("AE_SEXY");

      return {
        company: "AESEXY",
        name,
      };
    }
    if (ref.includes("SA_GAMING")) {
      const name = formatGameNameToStartCase("SA_GAMING");

      return {
        company: "SAGAME",
        name,
      };
    }
    if (ref.includes("PG")) {
      const name = formatGameNameToStartCase("PG");

      return {
        company: "PG",
        name,
      };
    }
    if (ref.includes("DREAM_TECH")) {
      const name = formatGameNameToStartCase("DREAM_TECH");

      return {
        company: "DREAMTECH",
        name,
      };
    }
    if (ref.includes("ALLBET")) {
      const name = formatGameNameToStartCase("ALLBET");

      return {
        company: "ALLBET",
        name,
      };
    }
    if (ref.includes("WMGAME")) {
      const name = formatGameNameToStartCase("WMGAME");

      return {
        company: "WMGAME",
        name,
      };
    }
    if (ref.includes("DREAMGAMING")) {
      const name = formatGameNameToStartCase("DREAMGAMING");

      return {
        company: "DREAMGAMING",
        name,
      };
    }
    if (ref.includes("W88")) {
      const name = formatGameNameToStartCase("W88");

      return {
        company: "W88",
        name,
      };
    }
    if (ref.includes("SLOT_XO")) {
      const name = formatGameNameToStartCase("SLOT_XO");

      return {
        company: "AESEXY",
        name,
      };
    }
    return {
      company: "THBGAME",
      name: "TAO_PAUND",
    };
  };

  handleNotificationCardClick = (item: IV1GetNotificationUser) => {
    this.setState({ selectedNotificationUserId: item.id }, () => {
      this.props.postNotificationClick({ messageId: item.id });
      if (item.reference !== null) {
        if (item.reference.includes("LOTTER")) {
          if (item.reference === "LOTTER") {
            this.props.history.push(routes.lotto.path);
          } else if (
            item.reference.includes("RESULT") ||
            item.reference.includes("BONUS")
          ) {
            const month = (moment(item.createdAt).month() + 1).toString();
            this.props.history.push(routes.creditInfoReport.path, {
              notification: {
                year: (moment(item.createdAt).year() + 543).toString(),
                codeLottoGame: item.reference.includes("RESULT")
                  ? item.reference.replace("_RESULT", "")
                  : item.reference.replace("_BONUS", ""),
                month: month.length >= 2 ? month : "0" + month,
              },
            });
          } else {
            if (item.reference.includes("YEGEE")) {
              this.props.history.push(
                routes.lottoChrildren.exactPath("LOTTER_YEGEE")
              );
            } else {
              const selectedLotto = this.props.lottoSchedule.filter(
                (lotto) => lotto.code === item.reference
              );
              if (selectedLotto[0].status === "OPEN") {
                const lottoGame: ILottoGame = {
                  id: 0,
                  round: "",
                  status: selectedLotto[0].status,
                  createdAt: selectedLotto[0].startTime,
                  endTime: selectedLotto[0].endTime,
                  startTime: selectedLotto[0].startTime,
                };
                this.props.history.push(
                  routes.lottoMaking.exactPath(item.reference),
                  { selectedLottoGame: lottoGame }
                );
              }
            }
          }
        } else if (item.reference === "CASINO") {
          this.props.history.push(routes.casino.path);
        } else if (item.reference.includes("INFO")) {
          InfoNotificationModal.show({
            action: () => {
              InfoNotificationModal.hide();
            },
            description: `${item.message}`,
          });
        } else if (item.reference.includes("GAME")) {
          if (item.reference.includes("RESULT")) {
            const selectedGameIndex = GAME_COMPANY.findIndex((game) => {
              return item.reference!.includes(game);
            });
            const formattedCodeLottoGame = GAME_COMPANY[
              selectedGameIndex
            ].replace("_", "");

            const month = (moment(item.createdAt).month() + 1).toString();
            this.props.history.push(routes.creditInfoReport.path, {
              notification: {
                year: (moment(item.createdAt).year() + 543).toString(),
                codeLottoGame: formattedCodeLottoGame,
                month: month.length >= 2 ? month : "0" + month,
                selectedLandingMenu: "คาสิโน",
              },
            });
          } else {
            let formattedReference = item.reference.replace("GAME_CASINO_", "");

            if (
              formattedReference.includes("JOKER") ||
              formattedReference.includes("SLOT_XO")
            ) {
              this.props.history.push(routes.slot.path, {
                onClickPartnerData: {
                  alt: "JOKER",
                  icon: partnerList.partnerList["joker"],
                },
              });
            } else if (formattedReference.includes("PGGAME")) {
              this.props.history.push(routes.slot.path, {
                onClickPartnerData: {
                  alt: "PG",
                  icon: partnerList.partnerList["pg"],
                },
              });
            } else if (formattedReference.includes("DT")) {
              this.props.history.push(routes.slot.path, {
                onClickPartnerData: {
                  alt: "DREAMTECH",
                  icon: partnerList.partnerList["dreamtech"],
                },
              });
            } else {
              const createStoreClick: IClickRef = {
                code: undefined,
                company: this.formatCompany(formattedReference).company,
                path: routes.casino.path,
              };
              this.props.clickRef(createStoreClick);
              this.props.history.push(routes.casino.path);
              this.onGetGameList(this.formatCompany(formattedReference));
            }
          }
        } else if (item.reference.includes("LINE")) {
          window.open(`https://line.me/R/ti/p/@thailandbet`);
        } else if (item.reference.includes("SLOT")) {
          this.props.history.push(routes.slot.path, { isNotiSlot: true });
        } else {
          const referencePath = REFERENCE_PATH[item.reference];

          if (referencePath) {
            this.props.history.push(referencePath);
          }
        }
      }
    });
  };

  renderNotificationCards = () => {
    return this.state.notificationUserList.map((item, index) => {
      return (
        <NotificationCard
          {...item}
          company={
            item.reference?.includes("GAME_CASINO")
              ? this.formatCompany(item.reference.replace("GAME_CASINO_", ""))
                  .company
              : ""
          }
          key={index}
          handleOnClick={() => this.handleNotificationCardClick({ ...item })}
        />
      );
    });
  };

  getMoreNotificationUserList = () => {
    const getMoreNotificationUserList = () => {
      if (this.state.page < this.props.notificationUser.totalPage) {
        this.setState({ page: this.state.page + 1 }, () => {
          this.props.getNotificationUser({ page: this.state.page, limit: 10 });
        });
      }
    };

    setTimeout(() => {
      if (this.state.isNotificationUserClickCalled) {
        this.setState({ page: 0 }, () => {
          getMoreNotificationUserList();
        });
      } else {
        getMoreNotificationUserList();
      }
    }, 1000);
  };

  render() {
    return (
      <div className="notification-container">
        <div className="container-fluid position-fixed notification-header">
          <div className="container">
            <div className="row mt-2">
              <div className="col-12">
                <h2
                  className="secondary-text-gold"
                  style={{ fontFamily: "SukhumvitSet-SemiBold" }}
                >
                  {constants.notification}
                </h2>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <div className="secondary-text-gold body-1">
                  {constants.new}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ paddingTop: 120 }}>
          <img src={BGIcon} className="bg-images-fixed" alt="bg-images-fixed" />
          <div className="row mt-3">
            <div className="col-12" style={{ padding: 0 }}>
              <InfiniteScroll
                dataLength={this.state.notificationUserList.length}
                hasMore={
                  this.state.notificationUserList.length <
                  this.props.notificationUser.totalData
                }
                style={{ overflowX: "hidden" }}
                scrollThreshold={1}
                next={this.getMoreNotificationUserList}
                loader={
                  <div className="row p2-y">
                    <div className="col">
                      <PlaceholderLoading isNotificationLoader />
                    </div>
                  </div>
                }
              >
                {this.renderNotificationCards()}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationContainer;
