import React, { Component } from 'react'
import { range, chunk, map, min, isEqual, isEmpty, noop, startsWith, endsWith, get, reduce, filter, chain, difference, sortBy, includes } from 'lodash'
import { ButtonRadio, ButtonRadioGroup } from 'components'
import { number } from 'utils'
import { LOTTO_GAME_TYPE_LENGTH, LOTTO_GAME_TYPE_NAME } from 'constants/variables'
import colors from 'constants/colors'
import expandIcon from 'assets/images/icon/expand.png'
import './Numbersets.style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Scrollbar from 'react-scrollbars-custom'

const windowHeight = window.innerHeight

const defaultProps: NumbersetsProps = {
  gameMode: ['THREE_UP'],
  lottos: [],
  onAddedNumber() { noop() },
  numberBan:[],
  modeTwoType:'DOOR'
}

class NumbersetsComponent extends Component<NumbersetsProps, NumbersetsState> {

  static defaultProps = defaultProps

  state: NumbersetsState = {
    gameNumberLength: LOTTO_GAME_TYPE_LENGTH[get(this.props.gameMode, '0', '') as TLottoGameType],
    currentNumberIndex: 0,
    maxNumber: 0,
    selectedIndexFrontNumbers: [],
    openModal: false
  }

  componentDidMount() {
    const gameType: TLottoGameType = get(this.props.gameMode, '0', '')
    const gameNumberLength = LOTTO_GAME_TYPE_LENGTH[gameType]
    const maxNumber = (gameNumberLength === 3)
      ? 1000
      : (gameNumberLength === 2)
        ? 100
        : 0
    this.setState({ maxNumber })
  }

  componentDidUpdate(prevProps: NumbersetsProps) {
    if (prevProps.gameMode !== this.props.gameMode) {
      const gameType: TLottoGameType = get(this.props.gameMode, '0', '')
      const gameNumberLength = LOTTO_GAME_TYPE_LENGTH[gameType]
      const maxNumber = (gameNumberLength === 3)
        ? 1000
        : (gameNumberLength === 2)
          ? 100
          : 0
      this.setState({ gameNumberLength, currentNumberIndex: 0, maxNumber, selectedIndexFrontNumbers: [] })
    }
  }

  handleOnNumberFunctionChanged = (state: boolean, selectedNumber: string) => {
    if (state) {
      const actureIndex = Number(selectedNumber) / 100
      this.setState({ currentNumberIndex: actureIndex })
    }
  }

  handleOnSelectNumber = (slectedNumber: string, state: boolean) => {
    const lottoNumbers = this.props.gameMode.map((mode) => ({
      number: slectedNumber,
      type: mode,
    }))
    this.props.onAddedNumber(lottoNumbers, state ? 'ADD' : 'REMOVE')
  }

  handleOnChangeSelectedNumberFunction = (selectedIndex: boolean[], currentSelected: number, state: boolean, trigger: 'FRONT' | 'BACK' | 'DOOR') => { 
    this.setState({ selectedIndexFrontNumbers: selectedIndex }, () => {
      const triggerFunction = trigger === 'FRONT'
        ? (paddedNumber: string) =>
          startsWith(paddedNumber, String(currentSelected))
        : trigger === 'BACK'
          ? (paddedNumber: string) =>
            endsWith(paddedNumber, String(currentSelected))
          : trigger === 'DOOR'
            ? (paddedNumber: string) =>
              (startsWith(paddedNumber, String(currentSelected)) || endsWith(paddedNumber, String(currentSelected)))
            : () => true
      const { maxNumber, gameNumberLength } = this.state

      let numbers = range(maxNumber)
        .map((numberList) => number.padNumber(String(numberList), gameNumberLength)).filter(triggerFunction);

      if (trigger === 'DOOR') {
        if (!state) {
          const selectedIndexExcludingCurrentSelectedIndex = map(
            selectedIndex,
            (value, index) => {
              return value ? index : -1;
            }
          )
            .filter((value) => value !== -1 && value !== currentSelected)
            .map(String);
          numbers = numbers.filter(
            (item) =>
              !selectedIndexExcludingCurrentSelectedIndex.includes(item[0]) &&
              !selectedIndexExcludingCurrentSelectedIndex.includes(item[1])
          );

          const firstIndexLottos = map(this.props.lottos, "number[0]");
          const secondIndexLottos = map(this.props.lottos, "number[1]");

          const countFirstIndexLottosOccurences = chain(firstIndexLottos)
            .countBy()
            .toPairs()
            .value();
          const countSecondIndexLottosOccurences = chain(secondIndexLottos)
            .countBy()
            .toPairs()
            .value();

          const mostFrequentFirstIndexLottos = map(
            countFirstIndexLottosOccurences
              .sort((a, b) => b[1] - a[1])
              .filter((e, i, a) => e[1] === a[0][1]),
            0
          );
          const mostFrequentSecondIndexLottos = map(
            countSecondIndexLottosOccurences
              .sort((a, b) => b[1] - a[1])
              .filter((e, i, a) => e[1] === a[0][1]),
            0
          );

          const differenceInFirstIndexLottos = difference(
            mostFrequentFirstIndexLottos,
            mostFrequentSecondIndexLottos
          );
          const differenceInSecondIndexLottos = difference(
            mostFrequentSecondIndexLottos,
            mostFrequentFirstIndexLottos
          );
          numbers = numbers
            .filter((item) => !differenceInFirstIndexLottos.includes(item[0]))
            .filter((item) => !differenceInSecondIndexLottos.includes(item[1]));
        }
      } else if (trigger === "FRONT") {
        if (!state) {
          const secondIndexLottos = map(this.props.lottos, "number[1]");

          const countSecondIndexLottosOccurences = chain(secondIndexLottos)
            .countBy()
            .toPairs()
            .value();

          const mostFrequentSecondIndexLottos = map(
            countSecondIndexLottosOccurences
              .sort((a, b) => b[1] - a[1])
              .filter((e, i, a) => e[1] === a[0][1]),
            0
          );

          const secondIndexLottosWithMoreThanTenOccurences = map(
            countSecondIndexLottosOccurences.filter((e) => e[1] >= 10),
            0
          );

          if (!isEmpty(secondIndexLottosWithMoreThanTenOccurences)) {
            numbers = numbers.filter(
              (item) => !mostFrequentSecondIndexLottos.includes(item[1])
            );
          }
        }
      } else {
        if (!state) {
          const firstIndexLottos = map(this.props.lottos, "number[0]");

          const countFirstIndexLottosOccurences = chain(firstIndexLottos)
            .countBy()
            .toPairs()
            .value();

          const mostFrequentFirstIndexLottos = map(
            countFirstIndexLottosOccurences
              .sort((a, b) => b[1] - a[1])
              .filter((e, i, a) => e[1] === a[0][1]),
            0
          );
          const firstIndexLottosWithMoreThanTenOccurences = map(
            countFirstIndexLottosOccurences.filter((e) => e[1] >= 10),
            0
          );

          if (!isEmpty(firstIndexLottosWithMoreThanTenOccurences)) {
            numbers = numbers.filter(
              (item) => !mostFrequentFirstIndexLottos.includes(item[0])
            );
          }
        }
      }

      const selectedNumbers = reduce<TLottoGameType, ILottoNumber[]>(this.props.gameMode, (prev, curr) => {
        const selectedNumber = numbers.map(numberSelect => ({ number: numberSelect, type: curr }))
        return [...prev, ...selectedNumber]
      }, [])
      this.props.onAddedNumber(selectedNumbers, state ? 'ADD' : 'REMOVE', false, trigger)
    })
  }

  renderNumberFunction = (hideExpand:boolean) => {
    const { gameNumberLength, maxNumber, currentNumberIndex } = this.state
    const rangeOfMaxNumber = range(maxNumber)
    const numbersets = chunk(rangeOfMaxNumber, 100)

    if (gameNumberLength === 3) {
      const minEachsets = map(numbersets, min)
      const paddedEachsets = map(minEachsets, (set) => number.padNumber(String(set), 3))
      const eachPaddedNumbersets = chunk(paddedEachsets, hideExpand?3:5)

      const Numbersets = map(eachPaddedNumbersets, (numberset, numbersetIndex) => {
        const Numbers = map(numberset, (numbers, numbersIndex) => {
          const isCurrentActive = isEqual(number.padNumber(String(currentNumberIndex * 100), 3), numbers)
          return (
            <div
              className={`m1-t ${hideExpand&&"w-100"}`}
              key={`numbers-${numbers}-${numbersIndex}`}
              style={{ paddingRight: 4, paddingLeft: 4 }}
            >
              <ButtonRadio
                // stylename="outline"
                id={`numberset-${numbers}`}
                text={<div className={`font-m20r ${isCurrentActive ? 'secondary-text-navy' : 'secondary-text-gold'} `}>{Number(numbers)/100}xx</div>}
                paddingX={8}
                paddingY={3}
                forceState={isCurrentActive}
                defaultState={isCurrentActive}
                onChangeState={state => this.handleOnNumberFunctionChanged(state, numbers)}
                backgroundColor={isCurrentActive?colors.SECONDARY_GOLD_TEXT:colors.SECONDARY_BLUE}
                defaultBackgroundColor={colors.SECONDARY_BLUE}
                color={isCurrentActive?colors.TERTIARY_BG_NAVY:colors.SECONDARY_GOLD_TEXT}
              />
            </div>
          )
        })
        return (
          <div
            className="col-auto d-flex flex-row p-0"
            key={`numbersets-${numbersetIndex}`}
          >
            {Numbers}
          </div>)
      })

      return (<>
      {!hideExpand&&<div className='d-flex w-100'>
        <div className='col-auto px-1'>
          <ButtonRadio
            id={`lotto-mode-numberset`}
            text={(<div className='btn-expand'>
              <span className='mr-1'>{"ขยายตัวเลข"}</span>
              <img src={expandIcon} className="expand-icon" alt='expandIcon' />
            </div>
            )}
            forceState={false}
            defaultState={false}
            onChangeState={state => {this.setState({openModal:!this.state.openModal})}}
            backgroundColor={colors.SECONDARY_GOLD_TEXT}
            defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
          />
        </div>
      </div>}
      <div className="d-flex flex-column w-100">{Numbersets}</div></>)
    }

    const datasets = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    if (gameNumberLength === 2) {
      let numberBanNew:IListNumberBan[]=[]
      this.props.numberBan.map((item)=>{
        if (item.length===4) {
          numberBanNew.push({number: item,type: "FOUR_SUITE"})
        }else if (item.length===3) {
          numberBanNew.push({number: item,type: "THREE_UP"})
          numberBanNew.push({number: item,type: "THREE_TOAST"})
        }else if (item.length===2) {
          numberBanNew.push({number: item,type: "TWO_UP"})
          numberBanNew.push({number: item,type: "TWO_DOWN"})
        }else{
          numberBanNew.push({number: item,type: "RUN_UP"})
          numberBanNew.push({number: item,type: "RUN_DOWN"})
        }
        return ''
      })
      const numberIndexs = (trigger: 'FRONT' | 'BACK' | 'DOOR') => datasets.map(numb => {
        
        const traggerFunction = (trigger === 'FRONT')
          ? (lotto: ILottoNumber) =>
            (startsWith(lotto.number, String(numb)) && (this.props.gameMode.includes(lotto.type)))
          : (trigger === 'BACK')
            ? (lotto: ILottoNumber) =>
              (endsWith(lotto.number, String(numb)) && (this.props.gameMode.includes(lotto.type)))
            : (trigger === 'DOOR')
              ? (lotto: ILottoNumber) => ((startsWith(lotto.number, String(numb)) || endsWith(lotto.number, String(numb)))
                && (this.props.gameMode.includes(lotto.type)))
              : noop

        const filteredNumberIndexs = this.props.lottos.filter(traggerFunction)
        const filteredNumberBans = numberBanNew.filter(traggerFunction)
        
        
        const lengthOfapproved = this.props.gameMode.length === 1 ?
          trigger === "DOOR" ? 18 : 9 : trigger === "DOOR" ? 36 : 18
        return (filteredNumberIndexs.length + filteredNumberBans.length > lengthOfapproved) && filteredNumberIndexs.length!==0
      })
      return (
        <>
          {this.props.modeTwoType==='DOOR'&&<div className="d-flex w-100">
            <div className="col px-1 d-flex flex-column justify-content-center align-items-center">
              <ButtonRadioGroup
                id="nineteendoor-number"
                forceSelectedData={numberIndexs('DOOR')}
                onChange={(selectedNumbers, currentSelected, state) =>
                  this.handleOnChangeSelectedNumberFunction(selectedNumbers, currentSelected, state, 'DOOR')}
                dataset={datasets}
              />
            </div>
          </div>}
          {this.props.modeTwoType==='FRONT'&&<div className="d-flex w-100">
            <div className="col px-1 d-flex flex-column justify-content-center align-items-center">
              <ButtonRadioGroup
                id="front-number"
                forceSelectedData={numberIndexs('FRONT')}
                onChange={(selectedNumbers, currentSelected, state) =>
                  this.handleOnChangeSelectedNumberFunction(selectedNumbers, currentSelected, state, 'FRONT')}
                dataset={datasets}
              />
            </div>
          </div>}
          {this.props.modeTwoType==='BACK'&&<div className="d-flex w-100">
            <div className="col px-1 d-flex flex-column justify-content-center align-items-center">
              <ButtonRadioGroup
                id="back-number"
                forceSelectedData={numberIndexs('BACK')}
                onChange={(selectedNumbers, currentSelected, state) =>
                  this.handleOnChangeSelectedNumberFunction(selectedNumbers, currentSelected, state, 'BACK')}
                dataset={datasets}
              />
            </div>
          </div>}
        
        <div className='d-flex w-100'>
          <div className='col-auto px-1'>
            <ButtonRadio
                id={`lotto-mode-numberset`}
                text={(<div className='btn-expand'>
                  <span className='mr-1'>{"ขยายตัวเลข"}</span>
                  <img src={expandIcon} className="expand-icon" alt='expandIcon' />
                </div>
                )}
                forceState={false}
                defaultState={false}
                onChangeState={state => {this.setState({openModal:!this.state.openModal})}}
                backgroundColor={colors.SECONDARY_GOLD_TEXT}
                defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
              />
          </div>
        </div>
        </>
      )
    }

    const datasets5 = [0, 1, 2, 3, 4]
    const datasets10 = [5, 6, 7, 8, 9]
    if (gameNumberLength === 1) {
      let forceSelectedRunNum5 = datasets5.map((item)=>{
        if (this.props.gameMode.length<2) {
          return !isEmpty(this.props.lottos.filter((i)=> Number(i.number)===item && i.type===get(this.props.gameMode,"[0]")))
        }else{
          return !isEmpty(this.props.lottos.filter((i)=> Number(i.number)===item && i.type===get(this.props.gameMode,"[0]"))) && !isEmpty(this.props.lottos.filter((i)=> Number(i.number)===item && i.type===get(this.props.gameMode,"[1]")))
        }
      })
      let forceSelectedRunNum10 = datasets10.map((item)=>{
        if (this.props.gameMode.length<2) {
          return !isEmpty(this.props.lottos.filter((i)=> Number(i.number)===item && i.type===get(this.props.gameMode,"[0]")))
        }else{
          return !isEmpty(this.props.lottos.filter((i)=> Number(i.number)===item && i.type===get(this.props.gameMode,"[0]"))) && !isEmpty(this.props.lottos.filter((i)=> Number(i.number)===item && i.type===get(this.props.gameMode,"[1]")))
        }
      })
      return <>
      <div className='d-flex w-100'>
        <div className='col-12 px-1'>
        <ButtonRadioGroup
          id="run-set-number"
          forceSelectedData={forceSelectedRunNum5}
          onChange={(selectedNumbers, currentSelected, state) =>{
            let numSet = this.props.gameMode.map((item)=>{return{number:String(currentSelected),type:item}})
            this.props.onAddedNumber(numSet,state?"ADD":"REMOVE")
          }}
          dataset={datasets5}
        />
        <ButtonRadioGroup
          id="run-set-number"
          forceSelectedData={forceSelectedRunNum10}
          onChange={(selectedNumbers, currentSelected, state) =>{
            let numSet = this.props.gameMode.map((item)=>{return{number:String(currentSelected),type:item}})
            this.props.onAddedNumber(numSet,state?"ADD":"REMOVE")
          }}
          dataset={datasets10}
        />
        </div>
      </div>
    </>
    }

    return <></>
  }

  renderNumbersets = () => {
    const { lottos } = this.props
    const { gameNumberLength, maxNumber } = this.state
    const rangeOfMaxNumber = range(maxNumber)
    const paddedNumbers = map(rangeOfMaxNumber, (numb) => number.padNumber(String(numb), gameNumberLength))
    const numbersets = chunk(paddedNumbers, 100)
    const eachNumbersets = chunk(numbersets[this.state.currentNumberIndex], this.state.openModal?5:10)

    const Numbersets = map(eachNumbersets, (numberset, numbersetIndex) => {
      const Numbers = map(numberset, (numbers, numbersIndex) => {
        let isSelected: boolean;
        if (this.props.gameMode.length === 1) {
          const selectedLotto = filter(lottos, (lott) => this.props.gameMode.includes(lott.type) && lott.number === numbers)
          isSelected = !isEmpty(selectedLotto)
        } else {
          const selectedFirstGameModeLotto = filter(lottos, (lott) => {
            return ( lott.type === this.props.gameMode[0] && lott.number === numbers )
          })

          const selectedSecondGameModeLotto = filter(
            lottos,
            (lott) =>
              lott.type === this.props.gameMode[1] && lott.number === numbers
          );
          isSelected =
            !isEmpty(selectedFirstGameModeLotto) &&
            !isEmpty(selectedSecondGameModeLotto);
        }

        return (
          <div
            className="m1-t px-1"
            key={`numbers-${numbers}-${numbersIndex}`}
          >
            <ButtonRadio
              id={`numberset-${numbers}`}
              text={(<div className={`font-m20r ${isSelected ? 'secondary-text-navy' : 'secondary-text-gold'} `}>{number.padNumber(String(Number(numbers)%100),2)}</div>)}
              paddingX={5}
              paddingY={3}
              forceState={isSelected}
              defaultState={isSelected}
              onChangeState={state => this.handleOnSelectNumber(numbers, state)}
              backgroundColor={isSelected?colors.SECONDARY_GOLD_TEXT:colors.SECONDARY_BLUE}
              defaultBackgroundColor={colors.SECONDARY_BLUE}
              color={isSelected?colors.TERTIARY_BG_NAVY:colors.SECONDARY_GOLD_TEXT}
            />
          </div>
        )
      })
      return (
        <div
          className="col-12 col-md-12 d-flex flex-row flex-wrap justify-content-between pl-0 pr-3 "
          key={`numbersets-${numbersetIndex}`}
        >
          {Numbers}
        </div>
      )
    })

    return (
      <Scrollbar
          id="Numbersets"
          removeTracksWhenNotUsed 
          disableTracksWidthCompensation 
      >
        <div className="d-flex flex-wrap w-100">{Numbersets}</div>
      </Scrollbar>)
  }

  sortGameMode = ()=> sortBy(this.props.gameMode,[(i)=>includes(i,'UP')?1:2])

  render() {
    const Numbersets = this.renderNumbersets

    var heightNavbar = get(document.getElementById('navbar-container'),"clientHeight",0);
    var heightTabbar = get(document.getElementById('tabbar-container'),"clientHeight",0);
    var heightJackpot = get(document.getElementById('badge-jackpot'),"clientHeight",0);
    var heightTop = heightNavbar+heightJackpot
    var tabbarNavbar = heightTabbar+heightTop
    var heighFullMain = windowHeight - (tabbarNavbar+10)
    var heightSetion1 = get(document.getElementById('setion-1'),"clientHeight",0);
    return (
      <>
        {this.renderNumberFunction(false)}
        <Numbersets />
        <div className={`open-modal ${this.state.openModal&&'open'}`} style={{
          top:(heightSetion1),
          height:`${heighFullMain-heightSetion1}px`
        }}>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <div className='font-m20r'>{`ขยายแผง : ${get(LOTTO_GAME_TYPE_NAME,get(this.sortGameMode(), '0', ''))} ${get(LOTTO_GAME_TYPE_NAME,get(this.sortGameMode(), '1', ''),'')}`}</div>
            <FontAwesomeIcon icon={faTimes}
              onClick={()=>{
                this.setState({openModal:false})
              }}
              className="font-m25r delete-lotto-button-icon-expand ml-auto"
            />
          </div>
          {this.state.gameNumberLength===3&&this.renderNumberFunction(true)}
          <Numbersets />
        </div>
      </>
    )
  }
}

export default NumbersetsComponent