import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  mergeMap,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { fetchGetCreditInfo } from './services'
import actions from './actions'
import { RootAction } from 'typings/reduxs/Actions'

const getCreditInfoEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.getCreditInfoBetLotteryAction)),
    exhaustMap(action =>
      from(fetchGetCreditInfo(action.payload))
        .pipe(
          mergeMap(response => of(actions.getCreditInfoBetLotterySuccessAction
            (response, action.payload.isFirst || false))),
          catchError(error => of(actions.getCreditInfoBeyLotteryFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.getCreditInfoLotteryCancelAction))))
        ),
    )
  )
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  getCreditInfoEpic,
]
