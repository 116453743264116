import React, { Component, createRef, RefObject } from 'react'
import { noop, isEmpty } from 'lodash'
import { ALink, SuccessModal, ErrorModal } from 'components'
import colors from 'constants/colors'
import { Formik, FormikProps } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { RouteComponentProps } from 'react-router-dom'
import { ForgotPasswordForm } from './components'
import initialValues from './models/initialValues'
import scheme from './models/scheme'
import './forgotPassword.style.scss'
import { responseCode } from 'constants/response'
import { interactive } from 'utils'

const constants = {
  ok: 'ตกลง',
  back: 'ย้อนกลับ',
  gotoMain: 'กลับสู่หน้าหลัก',
  requestedSuccess: 'เปลี่ยนรหัสผ่านสำเร็จ',
  forgotPassword: 'ลืมรหัสผ่าน',
  OTPMsg: 'ระบบกำลังดำเดินการส่งหมายเลข OTP ไปที่เบอร์โทรศัพท์ของท่าน',
  usrOrOtpNotfound: 'ไม่พบ ชื่อผู้ใช้ หรือ เบอร์โทรศัพท์ของท่าน',
  pleaseTryagain: 'กรุณาลองใหม่อีกครั้ง',
  otpNotMatch: 'หมายเลข OTP ผิดกรุณาลองใหม่อีกครั้ง',
}

const defaultProps: IForgotPasswordProps & IForgotPasswordActionProps = {
  requestedForgotPassword: {
    id: 0,
    otp: 0,
    userId: 0,
    reqCount: 0,
    createdAt: '',
    updatedAt: '',
  },
  forgotPasswordIsFetching: false,
  forgotPasswordCode: 0,
  forgotPasswordError: '',
  resetPasswordIsFetching: false,
  resetPasswordCode: 0,
  resetPasswordError: '',
  forgotPasswordRequest() {
    noop()
  },
  resetPassword() {
    noop()
  },
  loader() {
    noop()
  },
}

class ForgotPasswordContainer extends Component<
  IForgotPasswordProps & IForgotPasswordActionProps & RouteComponentProps,
  IForgotPasswordState
  > {
  static defaultProps = defaultProps

  state = {
    isValidOtp: false,
  }

  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    interactive.rootElement.scrollToTop()
  }

  componentDidUpdate(prevProps: IForgotPasswordProps) {
    if (
      prevProps.forgotPasswordIsFetching !==
      this.props.forgotPasswordIsFetching &&
      !this.props.forgotPasswordIsFetching
    ) {
      this.props.loader(false)
      if (this.props.forgotPasswordCode === responseCode.OK) {
        SuccessModal.show({
          action: () => this.setState({ isValidOtp: true }, SuccessModal.hide),
          description: constants.OTPMsg,
          actionText: constants.ok,
        })
      } else if (this.props.forgotPasswordCode === responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: () => this.setState({ isValidOtp: false }, ErrorModal.hide),
          description: constants.usrOrOtpNotfound,
          actionText: constants.ok,
        })
      } else {
        ErrorModal.show({
          action: () => this.setState({ isValidOtp: false }, ErrorModal.hide),
          description: this.props.forgotPasswordError,
          actionText: constants.ok,
        })
      }
    }

    if (
      prevProps.resetPasswordIsFetching !==
      this.props.resetPasswordIsFetching &&
      !this.props.resetPasswordIsFetching
    ) {
      this.props.loader(false)
      if (this.props.resetPasswordCode === responseCode.OK) {
        SuccessModal.show({
          action: () => {
            this.props.history.replace('/main')
            SuccessModal.hide()
          },
          actionText: constants.gotoMain,
          description: constants.requestedSuccess,
        })
      } else if (this.props.resetPasswordCode === responseCode.UNAUTHORIZED) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: constants.otpNotMatch,
          actionText: constants.ok,
        })
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.resetPasswordError,
          actionText: constants.ok,
        })
      }
    }
  }

  onClickBack = () => {
    this.props.history.goBack()
  }

  onRequestOTP = (username: string, phoneNumber: string) => {
    const requestData: IForgotPasswordRequest = { username, phoneNumber }
    this.props.loader(true)
    this.props.forgotPasswordRequest(requestData)
  }

  onSubmitForm = (values: IForgotPasswordForm) => {
    if (!isEmpty(this.props.requestedForgotPassword)) {
      const submitForm: IResetPasswordRequest = {
        otp: values.otp,
        forgotPasswordId: this.props.requestedForgotPassword.id,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      }
      this.props.loader(true)
      this.props.resetPassword(submitForm)
    } else {
      ErrorModal.show({
        action: () => {
          this.props.history.replace('/main')
          ErrorModal.hide()
        },
        actionText: constants.ok,
        description: constants.pleaseTryagain,
      })
    }
  }

  renderForgotPasswordForm = () => {
    const ForgotPasswordFormComponent = (
      formProps: FormikProps<IForgotPasswordForm>
    ) => {
      return (
        <ForgotPasswordForm
          {...formProps}
          handleOtpRequest={this.onRequestOTP}
          isValid={this.state.isValidOtp!}
        />
      )
    }
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={scheme}
        enableReinitialize
        onSubmit={this.onSubmitForm}
      >
        {ForgotPasswordFormComponent}
      </Formik>
    )
  }

  render() {
    const FormComponent = this.renderForgotPasswordForm
    return (
      <div
        className="forgot-password-container secondary-bg-navy"
        ref={this.pageContainerRef}
      >
        <div className="container">
          <div className="row m4-b">
            <div className="col">
              <ALink
                id="backto-previus-page"
                color={colors.PRIMARY_RED}
                bold
                onClick={this.onClickBack}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="m1-r" />
                {constants.back}
              </ALink>
            </div>
          </div>
          <div className="row m2-b">
            <div className="col">
              <h2>{constants.forgotPassword}</h2>
            </div>
          </div>
          <FormComponent />
        </div>
      </div>
    )
  }
}

export default ForgotPasswordContainer
