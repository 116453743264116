import { createAction } from 'typesafe-actions'
import {
  GET_LOTTER_BENEFIT_REQUEST,
  GET_LOTTER_BENEFIT_SUCCESS,
  GET_LOTTER_BENEFIT_FAILURE,
  GET_LOTTER_BENEFIT_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getLotterBenefitRequestAction = createAction(
  GET_LOTTER_BENEFIT_REQUEST,
  resolve => (data: ILotterBenefitRequest) => resolve(data))

const getLotterBenefitSuccessAction = createAction(
  GET_LOTTER_BENEFIT_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IResponseBetLotteryBenefit>>) => resolve(data))

const getLotterBenefitFailureAction = createAction(
  GET_LOTTER_BENEFIT_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getLotterBenefitCancelAction = createAction(GET_LOTTER_BENEFIT_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getLotterBenefitRequestAction,
  getLotterBenefitSuccessAction,
  getLotterBenefitFailureAction,
  getLotterBenefitCancelAction,
}