import React, { Component, createRef, RefObject } from "react";
import { filter, find, forIn, isEmpty, keys, map, noop, pickBy } from "lodash";
import "./LottoOldFavorite.style.scss";
import { Button, ErrorModal, InfoModal, LottoBetItem, ResponsiveIcon } from "components";
import moment from "moment";
import colors from "constants/colors";
import { responseCode } from "constants/response";
import { ConfirmModal } from "./components";
import { Scrollbar } from "react-scrollbars-custom";
import leftArrow from 'assets/images/global/close/left-arrow.png'

const constants = {
  backText: "ย้อนกลับ",
  favoriteList: "รายการชุดตัวเลข",
  favoriteManagement: "จัดการเลขชุด",
  select: "เลือก",
  historyOld: "ดึงโพยเก่า",
  note: "ระบบสะสมเพียง 10 โพย ล่าสุดเท่านั้น",
  historyBet: "ประวัติการแทง",
  notFound: "ไม่พบข้อมูล",
  lotter: "แทงหวย",
  detail: "รายละเอียด",
  selectAll: "เลือกทั้งหมด",
  confirm: "ยืนยันดึงโพย",
  announce: "โพยดังกล่าวไม่มีข้อมูล",
  accept: "เข้าใจ",
  description: "กรุณาเลือกเลขจากโพยดังกล่าวเพื่อใช้งานระบบดึงโพยจากประวัติ",
};

const defaultProps: ILottoOldFavoriteComponentProps &
  ILottoOldFavoriteComponentActionProps = {
  lottoOldFavoriteList: [],
  onNavigateToFavorite() {
    noop();
  },
  getBetLotteryPullDetail() {
    noop();
  },
  loader() {
    noop();
  },
  getBetLotteryPullDetailFetching: false,
  getBetLotteryPullDetailCode: 0,
  getBetLotteryPullDetailError: "",
  betLotteryPullDetail: [],
  betLotteryPullDetailPagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
};

class LottoOldFavorite extends Component<
  ILottoOldFavoriteComponentProps & ILottoOldFavoriteComponentActionProps,
  ILottoOldFavoriteComponentState
> {
  lottoOldFavoriteBetItemsWrapperRef: RefObject<HTMLDivElement> = createRef();
  scrollableLottoOldFavoriteBetItemsRef: RefObject<HTMLDivElement> =
    createRef();

  static defaultProps = defaultProps;
  pageContainerRef: RefObject<HTMLDivElement> = createRef();
  state: ILottoOldFavoriteComponentState = {
    expandedItem: [],
    hasMore: true,
    betLotteryPullDetail: [],
    selectAll: true,
    keys: {
      TWO_UP: true,
      TWO_DOWN: true,
      THREE_UP: true,
      THREE_TOAST: true,
      FOUR_SUITE: true,
      RUN_UP: true,
      RUN_DOWN: true,
    },
    totalValue: 0,
    totalNumbers: 0,
  };

  componentDidUpdate(prevProps: ILottoOldFavoriteComponentProps) {
    if (
      prevProps.getBetLotteryPullDetailFetching !==
        this.props.getBetLotteryPullDetailFetching &&
      !this.props.getBetLotteryPullDetailFetching
    ) {
      this.props.loader(false);
      if (this.props.getBetLotteryPullDetailCode === responseCode.OK) {
        const isCheckCurentBet: IResBetLotteryPullDetail[] =
          this.state.betLotteryPullDetail.filter((betDeatil) => {
            return betDeatil.isCheck === true;
          });

        this.setState(
          {
            keys: isEmpty(isCheckCurentBet)
              ? this.handleIsCheckSelect(true)
              : this.state.keys,
            betLotteryPullDetail: isEmpty(isCheckCurentBet)
              ? this.handleIsSelectAll(true)
              : this.unionByBetDetail(isCheckCurentBet),
          },
          () => {
            this.sumNumberBet();
          }
        );
        if (
          this.props.betLotteryPullDetailPagination.page >=
          this.props.betLotteryPullDetailPagination.total
        ) {
          this.setState({ hasMore: false });
        }
      } else if (
        this.props.getBetLotteryPullDetailCode !== responseCode.OK &&
        this.props.getBetLotteryPullDetailCode !== responseCode.NOT_FOUND
      ) {
        ErrorModal.show({
          action: () => ErrorModal.hide,
          description: this.props.getBetLotteryPullDetailError,
        });
      }
    }
  }

  sumNumberBet = () => {
    const numberIsSelect = filter(this.state.betLotteryPullDetail, "isCheck");
    if (!isEmpty(numberIsSelect)) {
      const sum = (numberList: number[]) =>
        numberList.reduce(
          (acc: Number, curr: Number) => Number(acc) + Number(curr)
        );
      this.setState({
        totalNumbers: numberIsSelect.length,
        totalValue: sum(numberIsSelect.map((item) => Number(item.value))),
      });
    } else {
      this.setState({
        totalNumbers: 0,
        totalValue: 0,
      });
    }
  };

  unionByBetDetail = (dataList: IResBetLotteryPullDetail[]) => {
    const defaultBetList: any = this.handleIsSelectAll(true);
    map(defaultBetList, (data, index) => {
      dataList.forEach((dataIsCheck) => {
        if (data.id === dataIsCheck.id) {
          defaultBetList[index] = dataIsCheck;
        }
      });
    });

    // Check is select key then new page
    const keysSelect: IBetType = this.state.keys;
    forIn(this.state.keys, (value, key) => {
      if (value) {
        const newBetList = defaultBetList.filter(
          (betDeatil: any) =>
            betDeatil.type === key && betDeatil.isCheck === false
        );
        if (!isEmpty(newBetList)) {
          keysSelect[key as TLottoGameType] = false;
        }
      }
    });
    this.setState({ keys: keysSelect }, () => this.handleCheckSelectAll());
    return defaultBetList;
  };

  handleCheckSelectAll = () => {
    const isSelectTypeList = keys(pickBy(this.state.keys)).length;
    const numberAllType = Object.keys(this.state.keys).length;
    const betLotteryPullDetail: IResBetLotteryPullDetail[] =
      this.state.betLotteryPullDetail;
    const notNumberCheck = find(betLotteryPullDetail, ["isCheck", false]);
    this.setState({
      selectAll:
        isSelectTypeList === numberAllType && !notNumberCheck ? true : false,
    });
  };

  handleIsCheckSelect = (isCheck: boolean) => {
    const isCheckSelect: any = {};
    Array.from(
      new Set(
        this.props.betLotteryPullDetail.map(
          (data: IResBetLotteryPullDetail) => data.type
        )
      )
    ).map((lottoType: TLottoGameType) => {
      isCheckSelect[lottoType] = isCheck;
      return true;
    });
    return isCheckSelect;
  };

  handleIsSelectAll = (isCheck: boolean) => {
    return this.props.betLotteryPullDetail.map((v) => ({
      ...v,
      isCheck: v.isCheck ? true : isCheck,
    }));
  };

  onSelectAll = () => {
    this.setState({ selectAll: !this.state.selectAll }, () => {
      if (!this.state.selectAll) {
        this.setState(
          {
            betLotteryPullDetail: this.handleIsSelectAll(false),
            keys: this.handleIsCheckSelect(false),
          },
          () => this.sumNumberBet()
        );
      } else {
        this.setState(
          {
            betLotteryPullDetail: this.handleIsSelectAll(true),
            keys: this.handleIsCheckSelect(true),
          },
          () => this.sumNumberBet()
        );
      }
    });
  };

  onCheckBox = (numberBet: IResBetLotteryPullDetail) => {
    const betLotteryPullDetail: IResBetLotteryPullDetail[] =
      this.state.betLotteryPullDetail;
    const index = betLotteryPullDetail.map((l) => l.id).indexOf(numberBet.id);
    if (index > -1) {
      numberBet.isCheck = !numberBet.isCheck;
      betLotteryPullDetail[index] = numberBet;
      this.setState({
        betLotteryPullDetail: betLotteryPullDetail,
      });
      this.sumNumberBet();
      this.handleSelectTypeWithCheckBox(numberBet.type);
      this.handleCheckSelectAll();
    }
  };

  handleSelectTypeWithCheckBox = (type: TLottoGameType) => {
    const isCheckSelect: IBetType = this.state.keys;
    const notNumberCheck = filter(this.state.betLotteryPullDetail, {
      type: type,
      isCheck: false,
    });
    if (isEmpty(notNumberCheck)) {
      isCheckSelect[type] = true;
    } else {
      isCheckSelect[type] = false;
      this.setState({ keys: isCheckSelect });
    }
  };

  onCheckBoxSelect = (listBetType: IResBetLotteryPullDetail[]) => {
    const isCheckSelect: IBetType = this.state.keys;
    let isAllSelect: IResBetLotteryPullDetail[];
    isCheckSelect[listBetType[0].type] = !isCheckSelect[listBetType[0].type];
    this.setState({ keys: isCheckSelect }, () => {
      this.handleCheckSelectAll();
    });

    if (isCheckSelect[listBetType[0].type]) {
      isAllSelect = listBetType.map((data) => ({
        ...data,
        isCheck: true,
      }));
    } else {
      isAllSelect = listBetType.map((data) => ({
        ...data,
        isCheck: false,
      }));
    }

    const betLotteryPullDetail: IResBetLotteryPullDetail[] =
      this.state.betLotteryPullDetail;
    map(this.state.betLotteryPullDetail, (dataList, i) => {
      isAllSelect.forEach((dataIsCheck) => {
        if (dataList.id === dataIsCheck.id) {
          betLotteryPullDetail[i] = dataIsCheck;
        }
      });
    });
    this.setState({ betLotteryPullDetail: betLotteryPullDetail }, () =>
      this.sumNumberBet()
    );
  };

  renderHistoryLotterList = () => {
    if (!isEmpty(this.state.expandedItem)) {
      const { transaction, index } = this.state.expandedItem[0];
      return (
        <LottoBetItem
          containerClassName="mx-0"
          key={`${transaction.lotteryType}-${transaction.createdAt}-${index}`}
          totalNumbers={transaction.totalNumber}
          totalValue={transaction.totalValue}
          time={moment(transaction.createdAt, "YYYYMMDDHHmmss").add(543, 'y').format("ll")}
          onClick={() => {
            this.onClickBetListActive(
              filter(this.state.betLotteryPullDetail, { isCheck: true }),
              index
            );
          }}
          expandedItem={this.state.expandedItem}
          betLotteryPullDetail={this.state.betLotteryPullDetail}
          selectAll={this.state.selectAll}
          keys={this.state.keys}
          onCheckBoxSelect={this.onCheckBoxSelect}
          onSelectAll={this.onSelectAll}
          onCheckBox={this.onCheckBox}
        />
      );
    }
    const historyBetItem = map(
      this.props.lottoOldFavoriteList,
      (transaction, index) => {
        return (
          <LottoBetItem
            containerClassName="mx-0"
            key={`${transaction.lotteryType}-${transaction.createdAt}-${index}`}
            totalNumbers={transaction.totalNumber}
            totalValue={transaction.totalValue}
            time={moment(transaction.createdAt, "YYYYMMDDHHmmss").add(543, 'y').format("ll")}
            onClick={() => {
              this.handleOnGotoCustomBet(transaction, index);
            }}
            expandedItem={this.state.expandedItem}
            betLotteryPullDetail={this.state.betLotteryPullDetail}
            selectAll={this.state.selectAll}
            keys={this.state.keys}
            onCheckBoxSelect={this.onCheckBoxSelect}
            onSelectAll={this.onSelectAll}
            onCheckBox={this.onCheckBox}
          />
        );
      }
    );
    return historyBetItem;
  };

  onGetBetLotteryPullDetail = (isFirst = false, index: number) => {
    const { betLotteryPullList } = this.props.getCustomBetProps!(index);
    const queryData: APIPaginationQuery & IBetLotteryPullDetailRequest = {
      id: betLotteryPullList.id,
      limit: betLotteryPullList.totalNumber,
      page: isFirst ? 1 : this.props.betLotteryPullDetailPagination.page + 1,
    };
    this.props.getBetLotteryPullDetail(queryData, isFirst);
  };

  handleOnGotoCustomBet = (transaction: IOldFavoriteSet, index: number) => {
    if (isEmpty(this.state.expandedItem)) {
      this.setState({ expandedItem: [{ transaction, index }] }, () => {
        this.props.loader(true);
        this.onGetBetLotteryPullDetail(true, index);
      });
    }
  };

  onClickBetListActive = (
    favoriteItems: IResBetLotteryPullDetail[],
    index: number
  ) => {
    const { betLotteryPullList } = this.props.getCustomBetProps!(index);
    if (isEmpty(favoriteItems)) {
      InfoModal.show(
        {
          action: InfoModal.hide,
          title: constants.announce,
          actionText: constants.accept,
          description: constants.description,
        },
        "md"
      );
    } else {
      ConfirmModal.show({
        onCancel: ConfirmModal.hide,
        onConfirm: () => {
          /* this.props.loader(true); */
          this.onConfirmBet(favoriteItems, index);
          ConfirmModal.hide();
        },
        betDetail: betLotteryPullList,
        totalNumbers: this.state.totalNumbers,
        totalValue: this.state.totalValue,
        title: constants.confirm,
      });
    }
  };

  onConfirmBet = (favoriteItems: IResBetLotteryPullDetail[], index: number) => {
    const lottoNumbers: ILottoNumber[] = map(
      this.state.betLotteryPullDetail.filter((item) => item.isCheck),
      (item) => {
        return {
          number: item.number,
          type: item.type,
          value: item.value,
        };
      }
    );
    this.props.onAddedNumber!(lottoNumbers, "ADD", false, undefined, "");
  };

  render() {
    const historyBetItem = this.renderHistoryLotterList();
    return (
      <div
        className="lotto-old-favorite-component tertiary-bg-navy h-100 mt-auto"
        ref={this.pageContainerRef}
      >

          <div className="row">
            <div className="col">
              <div className="d-flex flex-row align-items-center px-3">
                <h2 className="flex font-m20r secondary-text-gold">
                  {constants.historyOld}
                </h2>
                <div className="font-m15r secondary-text-gold">
                  {constants.note}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex flex-row align-items-center m1-b">
                <h4 className="flex font-m15r white-text pl-3">
                  {constants.historyBet}
                </h4>
                <div>
                  <Button
                    size="small"
                    id="history-old"
                    type="rectangle"
                    text={constants.lotter}
                    textResponsive={false}
                    backgroundColor={colors.TERTIARY_BG_NAVY}
                    backgroundHoverColor={colors.SECONDARY_BLUE}
                    onClick={this.props.handleOnBack}
                    textColor={colors.SECONDARY_GOLD_TEXT}
                    textClassName={"font-m20r"}
                    // icon={leftArrow}
                    // onClick={this.onPressBack}
                  >
                    <ResponsiveIcon
                      alt="btn-icon"
                      className="button-icon"
                      icon={leftArrow}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar
            id="lotto-old-favorite-bet-items-wrapper"
            ref={this.lottoOldFavoriteBetItemsWrapperRef as any}
            removeTracksWhenNotUsed 
            disableTracksWidthCompensation 
          >
            <div
              className="col"
              id="scrollable-lotto-old-favorite-bet-items"
              ref={this.scrollableLottoOldFavoriteBetItemsRef}
            >
              <div className="border-rounded">
                {this.props.lottoOldFavoriteList!.length === 0 ? (
                  <h5 className="flex font-m20r secondary-text m2-l m1-t">
                    {constants.notFound}
                  </h5>
                ) : (
                  historyBetItem
                )}
              </div>
            </div>
          </Scrollbar>
        </div>
    );
  }
}

export default LottoOldFavorite;
