import listActions from './list/actions'
import addActions from './add/actions'
import editActions from './edit/actions'
import removeActions from './remove/actions'
import getActions from './get/actions'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...listActions,
  ...addActions,
  ...editActions,
  ...removeActions,
  ...getActions,
}