import React, { Component, createRef, RefObject } from "react";
import { isEqual, isEmpty, noop, includes } from "lodash";
import {
  Modal,
  Drawer,
  Navbar,
  Tabbar,
  // InfoModal,
  // TextRunning,
  AlertNotification,
  ErrorModal,
} from "components";
import { interactive } from "utils";
import {
  BrowserRouter as Router,
  RouteComponentProps,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import routers from "constants/routes";
import routes from "configs/routes";
import { Loader } from "../Loader";
import { LudensContext } from "configs/context";
import { UserProvider } from "../../contexts/UserContext";
import { LayoutProvider } from "../../contexts/LayoutContext";
import { LOTTO_SLUG_NAME } from "constants/variables";
// import { ReactComponent as HomeIcon } from 'assets/images/global/menu/home.svg'
// import { ReactComponent as HomeColorIcon } from 'assets/images/global/menu/home-color.svg'
// import { ReactComponent as BillIcon } from 'assets/images/global/menu/bill.svg'
// import { ReactComponent as BillColorIcon } from 'assets/images/global/menu/bill-color.svg'
// import { ReactComponent as ChipIcon } from 'assets/images/global/menu/chip.svg'
// import { ReactComponent as ChipColorIcon } from 'assets/images/global/menu/chip-color.svg'
// import { ReactComponent as GameIcon } from 'assets/images/global/menu/deposit.svg'
// import { ReactComponent as GameColorIcon } from 'assets/images/global/menu/deposit.svg'
import NotificationColorIcon from "assets/images/global/menu/notification-color.svg";
import { responseCode } from "constants/response";
import "../Home/home.style.scss";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IRootProps & IRootActionProps = {
  clearSummaryPmpg() {
    noop();
  },
  getSummaryPmpg() {
    noop();
  },
  getSummaryPmpgIsFetching: false,
  getSummaryPmpgError: "",
  getSummaryPmpgCode: 0,
  getSummaryPmpgResult: {},
  pmpgEra: {},
  wallet: { money: 0 },
  accessToken: "",
  textRunning: "",
  getMeUser: {},
  getMeUserisFetching: false,
  loader() {
    noop();
  },
  logout() {
    noop();
  },
  getMeConfig() {
    noop();
  },
  getDisplayConfig() {
    noop();
  },
  connectSocket() {
    noop();
  },
  verifyAccess() {
    noop();
  },
  webConfig: {
    id: 0,
    textRunner: "",
    contactUrl: "",
    contactLine: "",
    contactPhoneNumber: "",
    updatedAt: "",
  },
  getNotificationUserCount() {
    noop();
  },
  notificationUserCount: {
    unreadCount: 0,
  },
  notificationUserCountCode: 0,
  notificationUserCountError: "",
  notificationUserCountIsFetching: false,
  notificationUserRead: {},
  notificationUserReadCode: 0,
  notificationUserReadError: "",
  notificationUserReadIsFetching: false,
  connectNotificationUsercountSocket() {
    noop();
  },
};

// const mapIconCurrectBonus: { [key in IEraType]: string } = {
//   GOLD: GoldIcon,
//   SILVER: SilverIcon,
//   BRONZE: BronzeIcon,
//   TIN: TinIcon,
//   '': Coin,
// }

const constants = {
  jackpotTitle: "",
  accept: "เข้าใจแล้ว",
  title: "ประกาศ",
  description:
    "ทาง Thailandbet งดรับแทงหวย/คืนโพย หวยรัฐบาลเลขแม่น้ำหนึ่ง และเลขดังอื่นๆ ขออภัยในความไม่สะดวก",
  errorTitle: "ไม่สามารถเข้าใช้งานได้",
  errorDescription:
    "ผู้ใช้ที่มีสิทธิ Technical Support , Marketing และ LotterMaster ไม่สามารถเข้าใช้งานระบบได้",
  closeService:
    "ปิดปรับปรุงระบบชั่วคราวตั้งแต่เวลา 24.00น. วันที่ 10 ก.พ. 2563 และจะกลับมาเปิดให้บริการอีกครั้งเวลา 11.00น. ของวันที่ 11 ก.พ. 2563 ขออภัยในความไม่สะดวก",
};

class RootContainer extends Component<
  IRootProps & IRootActionProps & DefaultProps,
  IRootStates
> {
  static defaultProps = defaultProps;
  containerRef: RefObject<HTMLDivElement> = createRef();

  state: IRootStates = {
    themeMode: "darkMode",
    isShowNavbar: true,
    isShownWallet: true,
    isOpenDrawer: false,
    redirect: false,
  };

  Notification = () => {
    const unreadCount = this.props.notificationUserCount.unreadCount;
    return (
      <div className="d-flex position-relative" style={{ height: '100%'}}>
        <img
          src={NotificationColorIcon}
          alt={"notification-icon"}
          className="tab-menu-icon"
        />
        {unreadCount > 0 && (
          <div
            className={`position-absolute ${unreadCount > 0 && "red-circle"}`}
          >
            <div
              className="white-text"
              style={{
                fontSize: 10,
                textAlign: "center",
                lineHeight: "12px",
                marginTop: 2.5,
              }}
            >
              {unreadCount < 99 ? unreadCount : "99+"}
            </div>
          </div>
        )}
      </div>
    );
  };

  Menus = () => {
    const Notification = this.Notification;

    return [
      {
        title: "หน้าหลัก",
        name: "main",
        path: routers.main.path,
        icon: "lets-icons:home",
      },
      {
        title: "แทงหวย",
        name: "lotto",
        path: routers.lotto.path,
        icon: "fluent:lottery-24-regular",
      },
      {
        title: "คาสิโน",
        name: "cosino",
        path: routers.casino.path,
        icon: "cil:casino",
      },
      {
        title: "สล็อต",
        name: "slot",
        path: routers.slot.path,
        icon: "mdi:slot-machine-outline",
      },
      {
        title: "ฝากถอน",
        name: "game",
        path: routers.transaction.path,
        icon: "majesticons:money-plus-line",
      },
      {
        title: "แจ้งเตือน",
        name: "notification",
        path: routers.notification.path,
        ActiveIcon: <Notification />,
        InactiveIcon: <Notification />,
      },
      // {
      //   title: "เมนู",
      //   name: "menu",
      //   path: "menu",
      //   icon: "",
      // },
    ];
  };

  shouldComponentUpdate(
    nextProps: IRootProps & IRootActionProps & DefaultProps,
    nextState: IRootStates
  ) {
    return (
      !isEqual(nextProps.accessToken, this.props.accessToken) ||
      !isEqual(nextProps.textRunning, this.props.textRunning) ||
      !isEqual(nextProps.wallet, this.props.wallet) ||
      !isEqual(nextProps.getMeUser, this.props.getMeUser) ||
      !isEqual(nextProps.highlightEra, this.props.highlightEra) ||
      !isEqual(nextProps.pmpgEra, this.props.pmpgEra) ||
      !isEqual(
        nextProps.getSummaryPmpgIsFetching,
        this.props.getSummaryPmpgIsFetching
      ) ||
      !isEqual(nextProps.getSummaryPmpgError, this.props.getSummaryPmpgError) ||
      !isEqual(nextProps.getSummaryPmpgCode, this.props.getSummaryPmpgCode) ||
      !isEqual(
        nextProps.getSummaryPmpgResult,
        this.props.getSummaryPmpgResult
      ) ||
      !isEqual(nextState.themeMode, this.state.themeMode) ||
      !isEqual(nextState.isShownWallet, this.state.isShownWallet) ||
      !isEqual(nextState.isOpenDrawer, this.state.isOpenDrawer) ||
      !isEqual(nextState.isShowNavbar, this.state.isShowNavbar) ||
      !isEqual(
        nextProps.notificationUserCount.unreadCount,
        this.props.notificationUserCount.unreadCount
      )
    );
  }

  componentDidMount() {
    this.props.getNotificationUserCount();

    this.setState(
      { redirect: window.location.pathname === routers.redirectDownload.path },
      () => {
        // this.props.verifyAccess()
        if (!isEmpty(this.props.accessToken)) {
          if (!this.state.redirect) {
            this.props.connectSocket();
            this.props.connectNotificationUsercountSocket();
            this.props.getMeConfig();
            this.props.getDisplayConfig();
            if (
              this.props.getMeUser.permission === "MARKETING" ||
              this.props.getMeUser.permission === "LOTTER_MASTER" ||
              this.props.getMeUser.permission === "TECHNICAL_SUPPORT"
            ) {
              this.handleOnLogout();
              ErrorModal.show({
                action: ErrorModal.hide,
                title: constants.errorTitle,
                actionText: constants.accept,
                description: constants.errorDescription,
              });
            }
            this.props.getSummaryPmpg();
          }
        }

        const announcement = false;
        if (announcement) {
          // InfoModal.show({
          //   action: InfoModal.hide,
          //   title: constants.title,
          //   actionText: constants.accept,
          //   description: constants.closeService,
          // })
        }
        if (!isEmpty(this.props.pmpgEra)) {
          this.setState({ themeMode: "blueMode" });
        }

        if (!this.state.redirect) {
          // InfoModal.show({
          //   action: InfoModal.hide,
          //   title: constants.title,
          //   actionText: constants.accept,
          //   description: constants.description,
          // })
        }
      }
    );
  }

  componentWillUnmount() {
    this.props.clearSummaryPmpg();
  }

  changeThemeMode = (mode: TThemeMode) =>
    this.setState({
      themeMode: this.state.themeMode === "darkMode" ? "lightMode" : "darkMode",
    });

  changeShowWallet = (shown: boolean) =>
    this.setState({
      isShownWallet: shown,
    });

  changeDrawerOpen = (shown: boolean) =>
    this.setState({
      isOpenDrawer: shown,
    });

  changeShowNavbar = (shown: boolean) =>
    this.setState({
      isShowNavbar: shown,
    });

  handleOnClickBurgerMenu = () => {
    this.setState({ isOpenDrawer: !this.state.isOpenDrawer });
  };

  componentDidUpdate(prevProps: IRootProps) {
    if (
      (this.props.getMeUser.permission! === "MARKETING" ||
        this.props.getMeUser.permission === "LOTTER_MASTER" ||
        this.props.getMeUser.permission! === "TECHNICAL_SUPPORT") &&
      !this.props.getMeUserisFetching &&
      prevProps.getMeUserisFetching
    ) {
      this.handleOnLogout();
      ErrorModal.show({
        action: ErrorModal.hide,
        title: constants.errorTitle,
        actionText: constants.accept,
        description: constants.errorDescription,
      });
    }
    if (
      this.props.accessToken !== prevProps.accessToken &&
      this.props.accessToken
    ) {
      if (
        prevProps.getSummaryPmpgIsFetching !==
          this.props.getSummaryPmpgIsFetching &&
        !this.props.getSummaryPmpgIsFetching
      ) {
        if (this.props.getSummaryPmpgCode === responseCode.OK) {
          this.props.loader(false);
        }
      }
    }
    interactive.rootElement.setRef(this.containerRef);
  }

  handleOnCloseDrawer = () => {
    this.setState({ isOpenDrawer: false });
  };

  handleOnLogout = () => {
    this.handleOnCloseDrawer();
    this.props.logout();
  };

  renderGuardRoute = ({
    component: RouteComponent,
    name,
    path,
    exact,
    thPath,
  }: IRoutes) => {
    const renderRoute = (routeProps: RouteComponentProps) => {
      if (isEmpty(this.props.accessToken)) {
        return (
          <Redirect
            to={{ pathname: "/", state: { from: routeProps.location } }}
          />
        );
      }
      if (!isEmpty(thPath)) {
        if (
          includes(routeProps.location.pathname, "LOTTER") ||
          includes(routeProps.location.pathname, "making")
        ) {
          const lottoSlug = routeProps.location.pathname.split("/").pop();
          const lottoName = LOTTO_SLUG_NAME[lottoSlug as TLottoSlug];
          const titleName = lottoName.includes("หวย")
            ? lottoName
            : `${thPath}${lottoName}`;
          document.title = `${titleName} | Thailandbet`;
        } else {
          document.title = `${thPath} | Thailandbet`;
        }
      } else {
        document.title = `Thailandbet`;
      }
      return <RouteComponent {...routeProps} />;
    };

    return (
      <Route
        key={`${name}-page`}
        exact={exact}
        path={path}
        render={renderRoute}
      />
    );
  };

  renderRedirectRoute = ({
    component: RouteComponent,
    name,
    path,
    exact,
    thPath,
  }: IRoutes) => {
    const renderRoute = (routeProps: RouteComponentProps) => {
      if (!isEmpty(this.props.accessToken)) {
        if (routeProps.location.pathname === "/download") {
          return <RouteComponent {...routeProps} />;
        }
        return (
          <Redirect
            to={{ pathname: "/main", state: { from: routeProps.location } }}
          />
        );
      }
      if (!isEmpty(thPath)) {
        document.title = `${thPath} | Thailandbet`;
      }
      return <RouteComponent {...routeProps} />;
    };
    return (
      <Route
        key={`${name}-page`}
        exact={exact}
        path={path}
        render={renderRoute}
      />
    );
  };

  renderPageElement = () => (
    <Switch>
      {routes.map((route) =>
        isEqual(route.name, "404") ? (
          <Route key={`${route.name}-page`} component={route.component} />
        ) : route.private ? (
          this.renderGuardRoute(route)
        ) : (
          this.renderRedirectRoute(route)
        )
      )}
    </Switch>
  );

  renderNavbar = () => {
    return (
      <LudensContext.Consumer>
        {({ theme, wallet }) => (
          <Navbar
            webConfig={this.props.webConfig}
            jackpotIcon={this.props.highlightEra!.highlightEra!.type}
            jackpot={this.props.highlightEra!.highlightEra!.total}
            mode={theme.mode}
            isDisplayWallet={wallet.shown}
            wallet={this.props.wallet}
            onPressesMenu={this.handleOnClickBurgerMenu}
            isAuthorized={!isEmpty(this.props.accessToken)}
            jackpotTitle={constants.jackpotTitle}
          />
        )}
      </LudensContext.Consumer>
    );
  };

  renderTabbar = () => {
    if (!isEmpty(this.props.accessToken)) {
      return (
        <LudensContext.Consumer>
          {({ theme }) => (
            <Tabbar
              mode={theme.mode}
              tabs={this.Menus()}
              onPressesMenu={this.handleOnClickBurgerMenu}
              isOpen={this.state.isOpenDrawer}
            />
          )}
        </LudensContext.Consumer>
      );
    }
    return <></>;
  };

  renderDrawer = () => {
    if (!isEmpty(this.props.accessToken)) {
      return (
        <LudensContext.Consumer>
          {({ theme }) => (
            <Drawer
              mode={theme.mode}
              isOpen={this.state.isOpenDrawer}
              onPressLogout={this.handleOnLogout}
              onCloseDrawer={this.handleOnCloseDrawer}
              onPressBackground={this.handleOnClickBurgerMenu}
            />
          )}
        </LudensContext.Consumer>
      );
    }
    return <></>;
  };

  render() {
    const PageElement = this.renderPageElement;
    const PageNavbar = this.renderNavbar;
    const PageTabbar = this.renderTabbar;
    const PageDrawer = this.renderDrawer;
    const contextProviderValues = {
      theme: { mode: this.state.themeMode, changeMode: this.changeThemeMode },
      wallet: {
        shown: this.state.isShownWallet,
        changeShown: this.changeShowWallet,
      },
      drawer: {
        isDrawerOpen: this.state.isOpenDrawer,
        setDrawerOpen: this.changeDrawerOpen,
      },
      navbar: {
        isShowNavbar: this.state.isShowNavbar,
        setShowNavbar: this.changeShowNavbar,
      },
    };
    return (
      <LudensContext.Provider value={contextProviderValues}>
        <UserProvider>
          <LayoutProvider>
            <div
              className={`${contextProviderValues.theme.mode}`}
              ref={this.containerRef}
            >
              {window.location.pathname === routers.redirectDownload.path ? (
                <Router>
                  <div className="mobile-handler-redirect">
                    <PageElement />
                  </div>
                </Router>
              ) : (
                <Router>
                  {/* <TextRunning text={this.props.textRunning} /> */}
                  {this.state.isShowNavbar && <PageNavbar />}
                  <div className="mobile-handler secondary-bg-navy">
                    <PageElement />
                  </div>
                  <PageTabbar />
                  <PageDrawer />
                </Router>
              )}
            </div>
            <AlertNotification.Core />
            <Modal.Core event="MODAL" />
            <Modal.Core event="MODAL_OVER" />
            <Loader />
          </LayoutProvider>
        </UserProvider>
      </LudensContext.Provider>
    );
  }
}

export default RootContainer;
