import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const getPmpgAwardList = (
    state: ReducerState<IPmpgAwardList[]> = initialState,
    action: RootAction
): ReducerState<any> => {
    switch (action.type) {
        case getType(actions.getAwardListPmpgAction):
            return {
                ...state,
                isFetching: true,
            }
        case getType(actions.getAwardListPmpgSuccessAction):
            const data = action.payload.data.data ?
            (action.payload.data.data || []) : (state.data || [])
            return {
                ...state,
                isFetching: false,
                data,
                code: action.payload.status,
            }

        case getType(actions.getAwardListPmpgFailureAction):
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
                code: action.payload.response?.data.code,
            }
        default:
            return state
    }
}

export default getPmpgAwardList