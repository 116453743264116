import React, { Component, createRef, RefObject } from 'react'
import { get, lowerCase, map, noop } from 'lodash'
import { ALink, ResponsiveIcon } from 'components'
import { interactive } from 'utils'
import { RouteComponentProps } from 'react-router-dom'
import { responseCode } from 'constants/response'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import colors from 'constants/colors'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import TaoPhaunImage from 'assets/images/games/tao-phaun-trans.png'
import './games.style.scss'
import project from 'constants/project'

const constants = {
  comingSoon: 'พบกันเร็วๆนี้',
  back: 'ย้อนกลับ',
  thbGame: 'ไทยแลนด์เบทเกม',
  defaultCompanyGame: 'THBGAME',
  closedGame: 'ปิดปรับปรุง ชั่วคราว',
}

const defaultProps: IGameProps & IGameActionProps = {
  connectGame() { noop() },
  connectedGameIsFetching: false,
  connectedGameError: '',
  connectedGameCode: 0,
  connectedGame: '',
  getGameList() { noop() },
  getGameListIsFetching: false,
  getGameListError: '',
  getGameListCode: 0,
  gameList: [],
  loader() { noop() },
  clearGameList() { noop() },
  displayConfig: {},
}

class GamesContainer extends Component<IGameProps & IGameActionProps & RouteComponentProps> {

  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    interactive.rootElement.scrollToTop()
    this.props.loader(true)
    this.props.getGameList('INTERNAL')
  }

  componentDidUpdate(prevProps: IGameProps) {
    if (prevProps.getGameListIsFetching !== this.props.getGameListIsFetching && !this.props.getGameListIsFetching) {
      this.props.loader(false)
    }

    if (prevProps.connectedGameIsFetching !== this.props.connectedGameIsFetching
      && !this.props.connectedGameIsFetching) {
      this.props.loader(false)
      if (this.props.connectedGameCode === responseCode.OK) {
        const newWindow = window.open()
        if (newWindow) {
          newWindow.opener = null
          newWindow.location.href = this.props.connectedGame
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.clearGameList()
  }

  handleConnectGame = (game: IGame) => {
    this.props.loader(true)
    const query: IGameConnectQuery = {
      code: game.code,
      isMobile: false,
      company: constants.defaultCompanyGame as TGameCompany,
    }
    this.props.connectGame('INTERNAL', query)
  }

  renderGames = () => {
    const GameComponent = map(this.props.gameList, (game, _) => {
      const isMaintenance = get(this.props.displayConfig, `${project.environmentName}.${lowerCase(constants.defaultCompanyGame)}`, true)
      return (
        <a
          href={isMaintenance
            ? interactive.openLink<IGame & { gameLocation: TGameLocation }>
              ({ ...game, gameLocation: 'INTERNAL', company: constants.defaultCompanyGame as TGameCompany })
            : '/'
          }
          className={`col-6 col-md-4 col-xl-3 m3-b game-wrapper ${!isMaintenance && 'disabled'}`}

          key={`game-${game.id}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="game-button-wrapper p1-t text-center" id={`game-${game.id}`}>
            <ResponsiveIcon icon={TaoPhaunImage} alt={`game-${game.id}-alt`} className="game-image" />
          </div>
          {
            isMaintenance
              ? <></>
              : (
                <div className="closed-game-play">
                  <h3>{constants.closedGame}</h3>
                </div>
              )
          }
        </a>
      )
    })
    return <div className="row">{GameComponent}</div>
  }

  onClickBack = () => this.props.history.goBack()

  render() {
    const isMaintenance = get(this.props.displayConfig, `${project.environmentName}.${lowerCase(constants.defaultCompanyGame)}`, true)
    const GameComponent = this.renderGames
    return (
      <div className="games-container secondary-bg-navy" ref={this.pageContainerRef}>
        <div className="container">
          <div className="row">
            <div className="col">
              <ALink id="backto-previus-page" color={colors.PRIMARY_RED} bold onClick={this.onClickBack}>
                <FontAwesomeIcon icon={faChevronLeft} className="m1-r" />
                {constants.back}
              </ALink>
            </div>
          </div>
          <div className="row p4-t">
            <div className="col">
              <h2>{constants.thbGame}</h2>
            </div>
          </div>
          {
            isMaintenance ? 
            (<div className="row m3-t">
              <div className="col">
                <GameComponent />
              </div>
            </div>) : <></>
          }
        </div>
      </div>
    )
  }
}

export default GamesContainer