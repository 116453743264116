import './jackpot.style.scss'
import { number, interactive } from 'utils'
import {
  noop,
  keys,
  map,
  get,
} from 'lodash'
import colors from 'constants/colors'
import { LudensContext } from 'configs/context'
import 'react-circular-progressbar/dist/styles.css'
import { RouteComponentProps } from 'react-router-dom'
import {
  ALink,
} from 'components'
import React, { Component, createRef, RefObject } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Coin from 'assets/images/pmpg/chinese-coin.svg'
import BronzeIcon from 'assets/images/pmpg/bronze.svg'
import SilverIcon from 'assets/images/pmpg/diamond.svg'
import GoldIcon from 'assets/images/pmpg/chest.svg'
import TinIcon from 'assets/images/pmpg/tin.svg'
import GoldBackgroud from 'assets/images/pmpg/gold-backgroud.svg'
import TinBackgroud from 'assets/images/pmpg/tin-backgroud.svg'
import DiamondBackground from 'assets/images/pmpg/diamond-backgroud.svg'
import BronzeBackground from 'assets/images/pmpg/bronze-background.svg'
import OneRank from 'assets/images/pmpg/crowns.svg'
import TwoRank from 'assets/images/pmpg/helmet.svg'
import ThreeRank from 'assets/images/pmpg/viking-helmet.svg'
import { ProgressBar } from './components/ProgressBar'
import { CSSProperties } from 'react'


const mapIconRanking: { [key in number]: any } = {
  0: OneRank,
  1: TwoRank,
  2: ThreeRank,
}

const mapIconCurrectBonus: { [key in IEraType]: string } = {
  GOLD: GoldIcon,
  SILVER: SilverIcon,
  BRONZE: BronzeIcon,
  TIN: TinIcon,
  '': Coin,
}

const constants = {
  comingSoon: 'พบกันเร็วๆนี้',
  back: 'ย้อนกลับ',
  jackpot: 'กองเงินกองทอง',
  currentJackpot: 'โบนัสปัจจุบัน',
  userRanking: 'อันดับสูงสุด',
  defaultCompanyGame: 'THBGAME',
  closedGame: 'ปิดปรับปรุง ชั่วคราว',
  gold: 'ทอง',
  silver: 'เงิน',
  brozne: 'ทองแดง',
  tin: 'ดีบุก',
  termOfuse: 'เงื่อนไขและกฎกติกาการรับโบนัส',
}

const termOfuse = {
  paragraph1: `กฎกติกาการได้รับโบนัส
  เมื่อประเภทของ โบนัสในยุคต่างๆนั้นเต็ม หรือ ทะลุยอด เช่น โบนัสยุคทอง ยอดสูงสุด 1 ล้านบาท ยอดสะสมได้ 1,000,142 บาท ระบบจะสุ่มลูกค้าที่จะรับโบนัสจากการเดิมพันในระบบเกมของทาง Thailandbet เท่านั้น\n`,
  paragraph2: `เกมในระบบที่เข้าร่วมรายการ\n`,
  paragraph3: `1.ยี่กี\n`,
  paragraph4: `โบนัสยุคทองจะทำการแจกโบนัสสูงสุดตามจำนวนเงินสะสมดังกล่าว 1,000,142 บาท หากลูกค้าผู้โชคดีได้เดิมพันเกมยี่กีในกติกา 2 ตัวบนอัตราจ่ายบาทละ 95 บาทลูกค้าได้เดิมพันเป็นจำนวนเงิน 1,000 บาท ลูกค้าผู้โชคดีได้รับโบนัสแจ๊กพอตจะได้รับโบนัสตามอัตราจ่ายของยี่กีคือ อัตราจ่ายบาทละ 95 บาท ถูกรางวัล 95,000 บาท ระบบจะทำการแจกโบนัสให้ลูกค้าผู้โชคดี 95,000 บาท โดยทันที ทำให้ยอดสะสมโบนัสยุคทองถูกปันผลออกมา 95,000 บาทจาก 1,000,142 บาท คงเหลือ 905,142 บาท โบนัสยุคทองจะเริ่มสะสมยอดจนถึง หรือ ทะลุ 1 ล้านบาท เพื่อแจกโบนัสอีกครั้ง\n`,
  paragraph5: `เคล็ดลับการได้รางวัล หากลูกค้าพบประเภทของโบนัสยุคต่างๆ ที่ทะลุยอดสะสม ให้ลูกค้าเดิมพันในราคาชนะที่ไม่เกินจากยอดของโบนัสในยุคนั้นๆ หากลูกค้าเป็นผู้โชคดีลูกค้าจะได้โบนัสทันทีจากยอดชนะ ยิ่งยอดสะสมเยอะยิ่งต้องแทงเยอะเพื่อรับโบนัสจากยุคต่างๆให้ได้มากที่สุด\n`,
  paragraph6: `เงินที่ได้จากระบบโบนัสของเกม ยี่กี จะถูกหัก ค่าน้ำระบบแนะนำสมาชิก 8 % และ ค่าน้ำระบบ 1 %`,
}

const dummyData: IPmpgAwardList[] = [{ no: 1 }, { no: 2 }, { no: 3 }, { no: 4 }, { no: 5 }]

const defaultProps: IPmpgProps & IPmpgActionProps = {
  clearSummaryPmpg() { noop() },
  getAwardListPmpg() { noop() },
  getSummaryPmpg() { noop() },
  getSummaryPmpgIsFetching: false,
  getSummaryPmpgError: '',
  getSummaryPmpgCode: 0,
  getSummaryPmpgResult: {},
  getAwardListPmpgIsFetching: false,
  getAwardListPmpgError: '',
  getAwardListPmpgCode: 0,
  getAwardListPmpgResult: [],
  highlightEra: {},
  loader() { noop() },
  clearClickRef() {noop()}
}

class GamesContainer extends Component<
  IPmpgProps & IPmpgActionProps & RouteComponentProps
> {
  static defaultProps = defaultProps
  static contextType = LudensContext
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  state: IPmpgContainerState = {
    summary: {},
    sumPmpgInterval: setInterval(() => this.props.getSummaryPmpg(), 10000),
  }

  componentDidMount() {
    this.props.getAwardListPmpg()
    this.props.getSummaryPmpg()
    this.props.loader(true)
    setTimeout(() =>
    interactive.rootElement.scrollToTop()
    , 128)
    this.props.clearClickRef()
  }

  componentDidUpdate(prevProps: IPmpgProps) {
    if (
      prevProps.getSummaryPmpgIsFetching !==
      this.props.getSummaryPmpgIsFetching &&
      !this.props.getSummaryPmpgIsFetching
    ) {
      this.setState((_, props) => ({ summary: props.getSummaryPmpgResult }))
      this.props.loader(false)
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.sumPmpgInterval)
    this.props.clearSummaryPmpg()
  }

  renderUserRanking = (values: IPmpgAwardList[]) => {
    const castingData = get(values[0], 'no', dummyData) !== 0 ? values : dummyData
    const styles: CSSProperties =
    get(values[0], 'no', 0) !== 0
        ? {}
        : {
          visibility: 'hidden',
        }
    const mapRanking = map(keys(castingData), (key: any, index) => {
      return (
        <div
          key={`ranking-${key}`}
          className="d-flex member-ranking row flex-column justify-content-center align-items-center m1-t m0-b"
        >
          <div className="bonus-ranking">
            <img
              className="icon-ranking"
              src={mapIconRanking[index]}
              alt={`Coin`}
            />
            <div className="row bonus-content d-flex justify-content-between align-items-end">
              <div className="col-2  text-center">
                <span className="index-text" style={styles}>
                  {get(castingData[key], 'no', '')}
                </span>
              </div>
              <div className="col-5 pr-0">
                <span className="name-text" style={styles}>
                  {get(castingData[key], 'username', '')}
                </span>
              </div>
              <div className="col-5 text-right px-0">
                <span className="money-text" style={styles}>
                  {number.castToMoney(get(castingData[key], 'result', 0))}
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    })
    return mapRanking
  }

  renderTermOfUse = (values: object) => (
    <div className="container m2-t">
      <h2 className="text-ranking">{constants.termOfuse}</h2>
      <div className="row">
        <div className="col-12 px-3 d-flex flex-column justify-content-start align-items-center m3-t">
          <span className="body-2">
            {
              map(values, (paragraph: string, index) => (
                <>
                  {index !== 'paragraph6' ?
                    (
                      <>
                        <div className="paragraph" key={index}>{paragraph}</div>
                        <br />
                      </>
                    ) : (
                      <div className="term-hatch" key={index}>{termOfuse.paragraph6}</div>
                    )
                  }
                </>
              ))
            }
          </span>
        </div>
      </div>
    </div>
  )

  render() {
    const mapEraType = `${this.props.highlightEra!.highlightEra!.type.toLowerCase()}Total` as handleSummaryTotal
    const RenderRanking = this.renderUserRanking(
      this.props.getAwardListPmpgResult
    )
    const RenderTermOfUse = this.renderTermOfUse(termOfuse)
    const mode = this.context.theme.mode
    return (
      <div
        className={`jackpot-container ${mode}`}
        ref={this.pageContainerRef}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <ALink
                id="backto-previus-page"
                color={colors.PRIMARY_RED}
                bold
                onClick={() => this.props.history.replace('/main')}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="m1-r" />
                {constants.back}
              </ALink>
            </div>
          </div>

          <div className="row p4-t">
            <div className="col">
              <div className={`jackpot-form-container ${mode}`}>
                <div className="d-flex flex-column justify-content-center align-items-center m3-t m3-b">
                  <h1 className="text-title">{constants.jackpot}</h1>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center m3-t">
                  <h2 className="text-bonus">{constants.currentJackpot}</h2>
                </div>
                <div className="d-flex row flex-column justify-content-center align-items-center m1-t m3-b">
                  <div className="bonus-value col-10 col-lg-3 col-md-5 col-sm-7 col-xs-8 justify-content-center align-items-center">
                    <div className="d-flex inline align-items-center">
                      <img
                        className="icon-bonus"
                        src={
                          mapIconCurrectBonus[
                          this.props.highlightEra.highlightEra!.type
                          ]
                        }
                        alt={this.props.highlightEra.highlightEra!.type}
                      />
                      <h1 className="icon-text">
                        {number.castToMoney(
                          this.props.getSummaryPmpgResult[mapEraType]!
                        )}
                      </h1>
                    </div>
                  </div>
                </div>

                <div className="row bonus-ranking-container d-flex justify-content-center">
                  <div className="col-12 col-lg-3 col-md-6 col-sm-12 m4-t d-flex justify-content-center">
                    <div className="icon-position">
                      <div className="icon-wrapper d-flex justify-content-center align-items-stretch">
                        <img
                          className="icon-background"
                          src={TinBackgroud}
                          alt={`GoldBackgroud`}
                        />
                        <img
                          className="icon-bonus"
                          src={TinIcon}
                          alt={`TinIcon`}
                        />
                        <h1 className="icon-text">{constants.tin}</h1>
                      </div>
                      <ProgressBar
                        min={this.props.getSummaryPmpgResult!.tinTotal}
                        max={this.props.getSummaryPmpgResult!.tinApproveLimit}
                        era={`tin`}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-lg-3 col-md-6 col-sm-12 m4-t d-flex justify-content-center">
                    <div className="icon-position">
                      <div className="icon-wrapper d-flex justify-content-center align-items-stretch">
                        <img
                          className="icon-background"
                          src={BronzeBackground}
                          alt={`BronzeBackground`}
                        />
                        <img
                          className="icon-bonus"
                          src={BronzeIcon}
                          alt={`BronzeIcon`}
                        />
                        <h1 className="icon-text">{constants.brozne}</h1>
                      </div>
                      <ProgressBar
                        min={this.props.getSummaryPmpgResult!.bronzeTotal}
                        max={
                          this.props.getSummaryPmpgResult!.bronzeApproveLimit
                        }
                        era={`bronze`}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-lg-3 col-md-6 col-sm-12 m4-t d-flex justify-content-center">
                    <div className="icon-position">
                      <div className="icon-wrapper d-flex justify-content-center align-items-stretch">
                        <img
                          className="icon-background"
                          src={DiamondBackground}
                          alt={`DiamondBackground`}
                        />
                        <img
                          className="icon-bonus"
                          src={SilverIcon}
                          alt={`SilverIcon`}
                        />
                        <h1 className="icon-text">{constants.silver}</h1>
                      </div>
                      <ProgressBar
                        min={this.props.getSummaryPmpgResult!.silverTotal}
                        max={
                          this.props.getSummaryPmpgResult!.silverApproveLimit
                        }
                        era={`silver`}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-lg-3 col-md-6 col-sm-12 m4-t d-flex justify-content-center">
                    <div className="icon-position">
                      <div className="icon-wrapper d-flex justify-content-center align-items-stretch">
                        <img
                          className="icon-background"
                          src={GoldBackgroud}
                          alt={`GoldBackgroud`}
                        />
                        <img
                          className="icon-bonus"
                          src={GoldIcon}
                          alt={`GoldIcon`}
                        />
                        <h1 className="icon-text">{constants.gold}</h1>
                      </div>
                      <ProgressBar
                        min={this.props.getSummaryPmpgResult!.goldTotal}
                        max={this.props.getSummaryPmpgResult!.goldApproveLimit}
                        era={`gold`}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center m3-t">
                  <h2 className="text-ranking">{constants.userRanking}</h2>
                </div>
                <div className="for-nth-child container px-4">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-lg-8 col-md-12 col-sm-12">
                      {RenderRanking}
                    </div>
                  </div>
                </div>
                {RenderTermOfUse}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GamesContainer