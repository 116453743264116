import React, { ChangeEvent, Component } from 'react'
import { get, noop, map, isEqual, isEmpty, includes, sortBy, last } from 'lodash'
import {
  ButtonRadio,
  SelectorItem,
  InputNumberLotto,
  InputNumber,
  Button,
} from 'components'
import {
  LOTTO_GAME_TYPES,
  LOTTO_GAME_TYPE_NAME,
  LOTTO_GAME_TYPE_LENGTH
} from 'constants/variables'
import { number, transformer } from 'utils'
import colors from 'constants/colors'
import { Numbersets } from '../../components'
import close from 'assets/images/icon/close-small.png'
import check from 'assets/images/icon/check.png'
import refresh from 'assets/images/icon/refresh.png'
import refreshActive from 'assets/images/icon/refreshBlue.png'
import shuffle from 'assets/images/icon/shuffle.png'
import shuffleActive from 'assets/images/icon/shuffleBlue.png'
import './makingLotto.style.scss'
import { fetchGetBetNumberRate } from 'reduxs/lotto/bet/rate/services'

const constants = {
  switchNumberMode: 'กลับเลข',
  switchSixBack: 'หกกลับ',
  numberpadMode: 'โหมดแป้นตัวเลข',
  numbersetMode: 'โหมดชุดตัวเลข',
  betRate: 'บาทละ x ',
  suitRate: 'ชุดละ',
  gameType: 'ประเภทการแทง',
  placeholderGameType: 'เลือกประเภทการแทง',
  placeholderNumber: (numberSet: number) => `เลข ${numberSet} ตัว`,
  historyOld: 'ดึงโพยเก่า',
  cancel:'ยกเลิก',
  finish:'เสร็จสิ้น'
}

const defaultProps: IMakingLottoComponentProps = {
  lottos: [],
  betRates: [],
  gameSlug: 'LOTTER_YEGEE',
  onAddedNumber() { noop() },
  onNavigateToOldFavorite() { noop() },
  numberBan: '',
  betLimit: {betLimit: 0},
  mode:'EASY'
}

const windowHeight = window.innerHeight

class MakingLotto extends Component<IMakingLottoComponentProps, IMakingLottoComponentState> {

  static defaultProps = defaultProps

  state: IMakingLottoComponentState = {
    animated: false,
    numberSet: '',
    gameType: [get(LOTTO_GAME_TYPES, `${this.props.gameSlug}.0`, 'THREE_UP')],
    inputMode: 'NUMBERPAD',
    isSwitchedSixBack: false,
    isSwitchedNumber: false,
    valueBet:'',
    errorMsg:'',
    clickFinish:false,
    rateNumber:[]
  }

  handleOnGotoOldFavoriteSelect = () => {
    this.props.onNavigateToOldFavorite!()
  }

  isRejectAddingNumber = () => {
    const gameType: TLottoGameType = get(this.state.gameType, '0', '')
    const length = LOTTO_GAME_TYPE_LENGTH[gameType] || 0
    if(this.state.numberSet.length < length){
      this.setState({errorMsg:"ตัวเลขที่ต้องการแทงไม่ถูกต้อง"})
    }else{
      this.setState({errorMsg:"",clickFinish:false})
    }
    return this.state.numberSet.length < length
  }

  handleOnClickNumberPad = (num: number) => {
    const gameType: TLottoGameType = get(this.state.gameType, '0', '')
    const length = LOTTO_GAME_TYPE_LENGTH[gameType] || 0
    if (num === -1) {
      return this.setState({ numberSet: this.state.numberSet.slice(0, -1) })
    } else if (this.state.numberSet.length >= length) { return }

    const newValue = this.state.numberSet.concat(String(num))
    return this.setState({ numberSet: newValue }, () => {
      this.handleOnAddNumber()
    })
  }

  handleOnAddNumber = () => {
  
    if (this.isRejectAddingNumber()) { return }
    let lottoNumbers: ILottoNumber[] = map(this.state.gameType, (gameType) => ({
      number: this.state.numberSet, type: gameType,
      value:isEmpty(this.state.valueBet)?'1':this.state.valueBet
    }))
    if ( (includes(this.state.gameType[0],"THREE")||includes(this.state.gameType[0],"TWO")) && this.state.isSwitchedNumber ) {  //TWO
      let numSet = this.state.gameType.reduce((result, type,index)=>{

        result.push({number:this.state.numberSet,type:type,value:isEmpty(this.state.valueBet)?'1':this.state.valueBet})
        // check ไม่เอาเลขซ้ำตอนกลับเลข
        if (
          this.state.numberSet.split("")[0] !== last(this.state.numberSet.split(""))
        ) {
          result.push({
            number: this.state.numberSet.split("").reverse().join(""),
            type: type,
            value: isEmpty(this.state.valueBet) ? "1" : this.state.valueBet,
          });
        }
        return result
      }, [] as ILottoNumber[])
      this.props.onAddedNumber(numSet,"ADD",false,undefined,isEmpty(this.state.valueBet)?'':this.state.valueBet)
      this.setState({ animated: true }, () => {
        const timeoutInstance = setTimeout(() => {
          this.setState({ numberSet: '', animated: false,errorMsg:"",clickFinish:false }, () => {
            clearTimeout(timeoutInstance)
          })
        }, 768)
      })
      return
    }
    
    this.props.onAddedNumber(lottoNumbers, 'ADD', this.state.isSwitchedSixBack, undefined,isEmpty(this.state.valueBet)?'':this.state.valueBet)
    this.setState({ animated: true }, () => {
      const timeoutInstance = setTimeout(() => {
        this.setState({ numberSet: '', animated: false,errorMsg:"",clickFinish:false }, () => {
          clearTimeout(timeoutInstance)
        })
      }, 768)
    })
  }

  handleOnChangeInputMode = () => {
    if (this.state.inputMode === 'NUMBERPAD') {
      return this.setState({ inputMode: 'NUMBERSET', numberSet: '' })
    }
    return this.setState({ inputMode: 'NUMBERPAD', numberSet: '' })
  }

  renderLottoGameTypeOption = ({ item, ...selectProps }: IInputDefaultSelectProps<TLottoGameType>) => {
    const combindedBetRateType = `${this.props.gameSlug}_${item}` as TBetType
    const betRate: IBetRate = get(this.props.betRates.filter((rate) => rate.type === combindedBetRateType), '0', {})
    const rateAsMoney = number.castToMoney(Number(betRate.rate || '0'))
    const betRateText = `${constants.betRate} ${rateAsMoney}`
    return (
      <SelectorItem
        title={LOTTO_GAME_TYPE_NAME[item]}
        subTitle={betRateText}
        {...selectProps}
      />
    )
  }

  textNumberBan = () => this.props.gameSlug !== "LOTTER_YEGEE" ? `ปิดรับเลข: ${this.props.numberBan.map((item: string) => ` ${item}`)}` : ''

  getRateNumber=()=>{
    const gameType: TLottoGameType = get(this.state.gameType, '0', '')
    const length = LOTTO_GAME_TYPE_LENGTH[gameType] || 0

    if (this.state.numberSet.length === length && this.props.gameSlug!=='LOTTER_YEGEE') {
      fetchGetBetNumberRate(this.state.gameType.map((item)=>({code:this.props.gameSlug,type:item as TLottoGameType,number:this.state.numberSet}))).then((res)=>{
        this.setState({rateNumber:sortBy(res,[(item)=>(includes(item.type,"UP")?1:2)])})
      })
    }else {
      this.setState({rateNumber:[]})
    }
    
  }

  renderLottoNumber = () => {
    const gameType: TLottoGameType = get(this.state.gameType, '0', '')
    const length = LOTTO_GAME_TYPE_LENGTH[gameType] || 0
    const lengtLimit = length === 3 ? 1000 : length === 2 ? 100 : 10
    const limitMoney = (numbers: NumberFormatValues) => {
      const { floatValue } = numbers
      if (floatValue! <= lengtLimit) {
        return floatValue! <= lengtLimit;
      }
      else if (floatValue === undefined) {
        return true
      }
      else {
        return false
      }
    }

    return (
      <InputNumberLotto
        decimalScale={0}
        name={"bet-number-"+this.props.mode}
        textAlign={'center'}
        allowNegative={false}
        allowLeadingZeros
        onValueChange={(value) => {
            this.setState({ numberSet: value.value.replace(/\D/g, "") },()=>{
              this.isRejectAddingNumber()
              this.getRateNumber()
            })
        }}
        isAllowed={ (val)=>( limitMoney(val) && val.value.toString().length <= length ) }
        value={this.state.numberSet}
        underLine={true}
        placeholder={"โปรดใส่เลขที่ต้องการแทง"}
        errorMessage={this.state.clickFinish?this.state.errorMsg:''}
        autoComplete="off"
      />
    )
  }

  handleCancel=()=>{
    this.setState({valueBet:'',numberSet: '',errorMsg:''})
  }

  renderInputMode = () => {
    //ทำ type ให้ตรงกับเรทเพื่อเอาไปหา betRate
    const combindedBetRateType1 = `${this.props.gameSlug}_${this.state.gameType[0]}` as TBetType
    const combindedBetRateType2 = `${this.props.gameSlug}_${this.state.gameType[1]}` as TBetType
    //ถ้าใส่เลขครบจะมี rateNumber ใช้เพื่อแสดงเรทเฉพาะเลข **betRates เป็นเรทรวม
    const betRate1: IBetRate = isEmpty(this.state.rateNumber)?
                get(this.props.betRates.filter((rate) => rate.type === combindedBetRateType1), '0', {}):
                get(this.state.rateNumber.filter((rate) => (rate.type === this.state.gameType[0]) && (rate.code === this.props.gameSlug) ), '0', {})
    const betRate2: IBetRate = isEmpty(this.state.rateNumber)?
                get(this.props.betRates.filter((rate) => rate.type === combindedBetRateType2), '0', {}):
                get(this.state.rateNumber.filter((rate) => (rate.type === this.state.gameType[1]) && (rate.code === this.props.gameSlug) ), '0', {})

    const betRate = Number(betRate1.rate || '0') + Number(betRate2.rate || '0')
    const rateAsMoney1 = number.castToMoneyWithoutPrefix(Number(betRate1.rate || '0'))
    const rateAsMoney2 = number.castToMoneyWithoutPrefix(Number(betRate2.rate || '0'))
    const getTextType = (type:string)=>type==='UP'?'บน x':type==='TOAST'?'โต๊ด x':type==='DOWN'?'ล่าง x':''
    let subType1 = get(betRate1,'type',' ').split("_")
    let subType2 = get(betRate2,'type',' ').split("_")
    const betRateText1 = `${this.props.gameSlug === 'LOTTER_LAO_SUITE' ? constants.suitRate : getTextType(get(subType1,[subType1.length-1],''))} ${rateAsMoney1}`
    const betRateText2 = `${this.props.gameSlug === 'LOTTER_LAO_SUITE' ? constants.suitRate : getTextType(get(subType2,[subType2.length-1],''))} ${rateAsMoney2}`
    let betRateText = getTextType(get(subType1,[subType1.length-1],''))==='บน x'?
      (`${!isEmpty(betRate1)?betRateText1:''}${!isEmpty(betRate2)?('  '+betRateText2):''}`):
      (`${!isEmpty(betRate2)?betRateText2:''}${!isEmpty(betRate1)?('  '+betRateText1):''}`)


    if (!isEmpty(this.state.rateNumber)) {
      subType1 = get(this.state.rateNumber,'[0].type',' ').split("_")
      subType2 = get(this.state.rateNumber,'[1].type',' ').split("_")
      betRateText = `${ this.props.gameSlug === 'LOTTER_LAO_SUITE' ? constants.suitRate : getTextType(get(subType1,[subType1.length-1],'')) } ${get(this.state.rateNumber,"[0].rate",'')} ${ this.props.gameSlug === 'LOTTER_LAO_SUITE' ? constants.suitRate : getTextType(get(subType2,[subType2.length-1],'')) } ${get(this.state.rateNumber,"[1].rate",'')}`
    }

    switch (this.state.inputMode) {
      case 'NUMBERPAD':
        const LottoNumbersets = this.renderLottoNumber
        return (<>
          {this.props.mode === "EASY" ? <>
            <div className="row w-100">
              <div className='col-12 px-1'>
                <div className='font-m20r'>กำหนดเองอย่างง่าย</div>
              </div>
            </div>
            <div className="row w-100">
              <div className='col-12 px-1'>
                <div className='font-m20r'>ใส่ตัวเลขสำหรับการแทง</div>
              </div>
            </div>
            <div className="row w-100">
              <div className="col px-1 text-center selected-number-ditgit input-number-bet">
                <LottoNumbersets />
              </div>
            </div>
            <div className="row w-100">
              <div className='col px-1'>
                <div className='font-m20r'>ใส่ราคา</div>
              </div>
              <div className='col px-1 text-right'>
                <div className='font-m20r'>{betRateText}</div>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-12 px-1">
                <InputNumber
                  textAlign="center"
                  placeholder="โปรดใส่ราคาที่ต้องการแทง"
                  hiddenErrorBlock
                  thousandSeparator
                  allowNegative={false}
                  decimalScale={0}
                  isAllowed={(values)=>(
                    (values.formattedValue === '' ||
                    values.floatValue! <= this.props.betLimit.betLimit) && values.value[0]!=='0')
                  }
                  name={`input-values-bet`}
                  onValueChange={({ value }) => {
                    this.setState({valueBet:value})
                  }}
                  value={this.state.valueBet}
                  borderStyle={"input-bet"}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="row w-100">
              <div className='col px-1'>
                <div className='font-m20r'>เมื่อชนะได้</div>
              </div>
              <div className='col px-1 text-right'>
                <div className='font-m20r'>{number.castToMoneyWithoutPrefix(betRate*(Number(this.state.valueBet)===0?1:Number(this.state.valueBet)))}</div>
              </div>
            </div>
          </> : <>
            <div className="row w-100">
              <div className="col px-1 text-center selected-number-ditgit input-number-bet" >
                <LottoNumbersets />
              </div>
            </div>
          </>}
          <div className="row w-100">
              <div className="col px-1 btn-cancel">
                <Button
                  size="medium"
                  id="btn-cancel-bet"
                  type="rectangle"
                  text={constants.cancel}
                  backgroundColor={colors.PRIMARY_RED}
                  backgroundHoverColor={colors.SECONDARY_RED}
                  onClick={this.handleCancel}
                  icon={close}
                  styleContainer='btn-bet'
                />
              </div>
              <div className="col px-1 btn-finish">
                <Button
                  size="medium"
                  id="btn-finish-bet"
                  type="rectangle"
                  text={constants.finish}
                  backgroundColor={colors.PRIMARY_BLUE}
                  backgroundHoverColor={colors.SECONDARY_BLUE}
                  onClick={()=>{
                    this.handleOnAddNumber()
                    this.setState({clickFinish:true})
                  }}
                  icon={check}
                  styleContainer='btn-bet'
                />
              </div>
            </div>
          </>
        )
      case 'NUMBERSET':
        return (
          <>
            <div className="row m4-t">
              <div className="col">
                <Numbersets
                  lottos={this.props.lottos}
                  gameMode={this.state.gameType}
                  numberBan={this.props.numberBan}
                  onAddedNumber={(lottoNumbers:ILottoNumber | ILottoNumber[], state, _, trgger) => {
                    if (typeof lottoNumbers === "object") {
                      this.props.onAddedNumber({...lottoNumbers,value:isEmpty(this.state.valueBet)?'1':this.state.valueBet}, state, this.state.isSwitchedSixBack, trgger)
                    }else if(typeof lottoNumbers === "object"){
                      let data = lottoNumbers as ILottoNumber[]
                      let set = data.map( (item:ILottoNumber ) => {return {...item,value:isEmpty(this.state.valueBet)?'1':this.state.valueBet}})
                      this.props.onAddedNumber(set, state, this.state.isSwitchedSixBack, trgger)
                    }
                    this.props.onAddedNumber(lottoNumbers, state, this.state.isSwitchedSixBack, trgger)
                  }}
                />
              </div>
            </div>
          </>
        )
      default:
        return (
          <></>
        )
    }
  }

  renderLottoGameTypes = () => {
    const gameList = LOTTO_GAME_TYPES[this.props.gameSlug]

    const LottoGamTypesComponent = map(gameList, (gameType, gameTypeIndex) => {
    const isCurrentActive = this.state.gameType.includes(gameType)
      return (
        <div
          className={`col-4 px-1 pb-1`}
          key={`lotto-game-type-${gameTypeIndex}-${gameType}`}
        >
          <div className="d-flex">
            <ButtonRadio
              id={`lotto-game-type-${gameType}`}
              text={(<h4 className={`font-m20r ${isCurrentActive ? 'secondary-text-navy' : 'secondary-text-gold'} `}>{LOTTO_GAME_TYPE_NAME[gameType]}</h4>)}
              forceState={isCurrentActive}
              defaultState={isCurrentActive}
              onChangeState={state => {
                if (state) {
                  let gameTypes: TLottoGameType[] = []
                  if (this.state.gameType.length <= 0) {
                    gameTypes = [gameType]
                  } else if (this.state.gameType.length > 1) {
                    if (this.state.gameType.includes(gameType)) {
                      gameTypes = this.state.gameType.filter(type => (!isEqual(type, gameType)))
                    } else {
                      gameTypes = [gameType]
                    }
                  } else if (this.state.gameType.length < 2) {
                    if (this.state.gameType.includes(gameType)) {
                      gameTypes = this.state.gameType
                    } else {
                      const gameTypeState: TLottoGameType = get(this.state.gameType, '0', '')
                      const lengthOriginal = LOTTO_GAME_TYPE_LENGTH[gameTypeState] || 0
                      const lengthTarget = LOTTO_GAME_TYPE_LENGTH[gameType]
                      if (lengthOriginal === lengthTarget) {
                        gameTypes = [...this.state.gameType, gameType]
                      } else {
                        gameTypes = [gameType]
                      }
                    }
                  } else {
                    gameTypes = []
                  }

                  return this.setState({
                    gameType: gameTypes,
                    numberSet: '',
                    inputMode: (gameType === 'RUN_DOWN' || gameType === 'RUN_UP' || gameType === 'FOUR_SUITE') ? 'NUMBERPAD' : this.state.inputMode,
                  })
                } else {
                  if (this.state.gameType.length > 1) {
                    if (this.state.gameType.includes(gameType)) {
                      const gameTypes = this.state.gameType.filter(type => (!isEqual(type, gameType)))
                      return this.setState({
                        gameType: gameTypes,
                        numberSet: '',
                        inputMode: (gameType === 'RUN_DOWN' || gameType === 'RUN_UP' || gameType === 'FOUR_SUITE') ? 'NUMBERPAD' : this.state.inputMode,
                      })
                    }
                  }
                }
              }}
              backgroundColor={colors.SECONDARY_GOLD_TEXT}
              defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
            />
          </div>
        </div>
      )
    })

    return (<div className="row w-100">{LottoGamTypesComponent}</div>)
  }

  render() {
    const GameInput = this.renderInputMode
    const LottoGameTypes = this.renderLottoGameTypes

    return (
      <div className={`making-lotto ${transformer.checkSizeDevice(windowHeight)} ${this.props.mode} ${this.props.gameSlug}`}>
        {this.props.mode === "GENERAL" && <div className='d-flex align-items-center w-100'>
          <div className='d-flex font-m20r pl-1 pr-2'>เดินพันราคา</div>
          <div className="col px-1">
            <InputNumber
              textAlign="center"
              placeholder="โปรดใส่ราคาที่ต้องการ"
              hiddenErrorBlock
              thousandSeparator
              allowNegative={false}
              decimalScale={0}
              isAllowed={(values)=>(
                (values.formattedValue === '' ||
                values.floatValue! <= this.props.betLimit.betLimit) && values.value[0]!=='0')
              }
              name={`input-values-bet`}
              onBlur={(event: ChangeEvent<HTMLInputElement>) =>{ }}
              onValueChange={({ value }) => { this.setState({valueBet:value}) }}
              value={this.state.valueBet}
              borderStyle={"input-bet"}
              autoComplete="off"
            />
          </div>
        </div>}
        <LottoGameTypes />
        {
          (this.state.gameType.includes('RUN_DOWN')
            || this.state.gameType.includes('RUN_UP')
            || this.state.gameType.includes('FOUR_SUITE'))
            ? (this.props.mode === "GENERAL"?<>
              <div className="row w-100 align-items-center blank">
                <div className="col px-1 text-center">
                  <ButtonRadio
                    id="blank"
                    text={<div className='btn-six-back'>
                      <img src={shuffle} className="refresh-icon" alt='Six Back' />
                      <span className={`secondary-text-navy`}>blank</span>
                    </div>}
                  />
                </div>
              </div>
            </>:<></>)
            : (
              this.props.mode === "GENERAL" && <>
                <div className="row w-100 align-items-center">
                  <div className="col px-1 text-center">
                    <ButtonRadio
                      id="switch-number-mode"
                      forceState={this.state.isSwitchedNumber}
                      text={<div className='btn-six-back'>
                        <img src={this.state.isSwitchedNumber?shuffleActive:shuffle} className="refresh-icon" alt='Six Back' />
                        <span className={`${this.state.isSwitchedNumber ? 'secondary-text-navy' : 'secondary-text-gold'}`}>{constants.switchNumberMode}</span>
                      </div>}
                      onChangeState={()=>{this.setState({isSwitchedNumber:!this.state.isSwitchedNumber,isSwitchedSixBack:false})}}
                      backgroundColor={colors.SECONDARY_GOLD_TEXT}
                      defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
                    />
                  </div>
                  {includes(this.state.gameType[0],"THREE")&&<div className="col px-1 text-center">
                    <ButtonRadio
                      id="switch-number-mode"
                      forceState={this.state.isSwitchedSixBack}
                      text={<div className='btn-six-back'>
                        <img src={this.state.isSwitchedSixBack?refreshActive:refresh} className="refresh-icon" alt='Six Back' />
                        <span className={`${this.state.isSwitchedSixBack ? 'secondary-text-navy' : 'secondary-text-gold'}`}>{constants.switchSixBack}</span>
                      </div>}
                      onChangeState={()=>{this.setState({ isSwitchedSixBack: !this.state.isSwitchedSixBack,isSwitchedNumber:false })}}
                      backgroundColor={colors.SECONDARY_GOLD_TEXT}
                      defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
                    />
                  </div>}
                </div>
              </>
            )
        }
        <GameInput />
      </div>
    )
  }

}

export default MakingLotto