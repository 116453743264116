import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import loaderAction from 'reduxs/loader/actions'
import creditAction from 'reduxs/credit/actions'
import userAction from 'reduxs/user/actions'
import CreditInfoReportContainer from './CreditInfoReport.container'
import creditInfoSelector from 'reduxs/credit/selector'

const mapStateToProps = (state: RootReducers): ICreditInfoBetLotteryProps => {
  const creditInfoBetLottery = creditInfoSelector.creditInfoBetLottery(state)
  const creditInfoBetGame = creditInfoSelector.creditInfoBetGame(state)
  const getLotterBenefit = creditInfoSelector.getLotterBenefit(state)
  const getGameBenefit = creditInfoSelector.getGameBenefit(state)
  const getCodeLotterGame = creditInfoSelector.getCodeLotterGame(state)
  return ({
    getCreditInfoListLotteryCode: creditInfoBetLottery.code!,
    getCreditInfoListLotteryError: creditInfoBetLottery.error!,
    getCreditInfoListLotteryIsFetching: creditInfoBetLottery.isFetching!,
    creditInfoLottery: creditInfoBetLottery.data!,
    creditInfoLotteryPagination: creditInfoBetLottery.pagination!,

    getCreditInfoListGameCode: creditInfoBetGame.code!,
    getCreditInfoListGameError: creditInfoBetGame.error!,
    getCreditInfoListGameIsFetching: creditInfoBetGame.isFetching!,
    creditInfoGame: creditInfoBetGame.data!,
    creditInfoGamePagination: creditInfoBetGame.pagination!,

    getLotterBenefitCode: getLotterBenefit.code!,
    getLotterBenefitError: getLotterBenefit.error!,
    getLotterBenefitIsFetching: getLotterBenefit.isFetching!,
    getLotterBenefit: getLotterBenefit.data!,

    getGameBenefitCode: getGameBenefit.code!,
    getGameBenefitError: getGameBenefit.error!,
    getGameBenefitIsFetching: getGameBenefit.isFetching!,
    getGameBenefit: getGameBenefit.data!,

    getCodeLotterGame: getCodeLotterGame.code!,
    getOldCodeLotterGame: getCodeLotterGame.oldCode!,
  })
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ICreditInfoBetLotteryActionProps => bindActionCreators({
  loader: loaderAction.loadingAction,
  getCreditInfoBetLottery: creditAction.getCreditInfoBetLotteryAction,
  getCreditInfoBetGame: creditAction.getCreditInfoBetGameAction,
  clearCreditInfoList: creditAction.clearCreditInfoList,
  getGameBenefitRequestAction: creditAction.getGameBenefitRequestAction,
  getLotterBenefitRequestAction: creditAction.getLotterBenefitRequestAction,
  codeLotterGame: userAction.setCodeLotteryAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(CreditInfoReportContainer)