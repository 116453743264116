import './dropdown.style.scss'
import React, { FC } from 'react'
import Select, { MenuPlacement } from 'react-select'
import colors from 'constants/colors'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IDropdown = {
  placeholder: '',
  controlBackgroundColor: colors.SECONDARY_BG_NAVY,
  singleValueTextColor: colors.PRIMARY_TEXT,
  options: [],
  handleOnChange: () => {},
  menuPlacement:'bottom',
  disabled:false
}

const Dropdown: FC<IDropdown & DefaultProps> = (props) => {
  const { placeholder, controlBackgroundColor, singleValueTextColor, options, handleOnChange, value ,menuPlacement, disabled} =
    props
  return (
    <Select
      menuPlacement={menuPlacement as MenuPlacement }
      placeholder={placeholder}
      value={options?.filter(val=>val.value===value)}
      components={{
        // DropdownIndicator:() => null,
        IndicatorSeparator:() => null,
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: controlBackgroundColor,
          boxShadow: 'none',
          border: 0,
        }),
        menu: (provided, state) => ({
          ...provided,
          zIndex: 99,
          backgroundColor:colors.PRIMARY_BG,
          color: colors.PRIMARY_TEXT,
        }),
        singleValue: (provided) => ({
          ...provided,
          color: singleValueTextColor,
        }),
        input: (provided) => ({
          ...provided,
          color: colors.PRIMARY_TEXT,
        }),
        option: (styles,state) => {
          return {
            ...styles,
            backgroundColor: state.isSelected ? colors.PRIMARY_BLUE : colors.SECONDARY_BG_NAVY,
            '&:hover': {
              backgroundColor: colors.PRIMARY_BLUE,
            }
          };
        },
      }}
      isDisabled={disabled}
      options={options}
      onChange={handleOnChange}
      isSearchable={false}
      isOptionDisabled={(option) => option.disabled}
    />
  )
}

Dropdown.defaultProps = defaultProps

export default Dropdown
