import project from 'constants/project'

export const AFFILATE_CLICK_REQUEST = 'AFFILATE_CLICK_REQUEST'
export const AFFILATE_CLICK_SUCCESS = 'AFFILATE_CLICK_SUCCESS'
export const AFFILATE_CLICK_FAILURE = 'AFFILATE_CLICK_FAILURE'
export const AFFILATE_CLICK_CANCEL = 'AFFILATE_CLICK_CANCEL'

export const initialState: ReducerState<IAffilateClick> = {
  isFetching: false,
  code: 0,
  data: { totalClick: '' },
  error: '',
}

export const endpoint = {
  affilateClick: `${project.environment[project.environmentName].api}/affilate/click`,
}