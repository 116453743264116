import { connect } from "react-redux";
import loaderAction from "reduxs/loader/actions";
import { Dispatch, bindActionCreators } from "redux";
import { RootAction } from "typings/reduxs/Actions";
import configAction from "reduxs/config/actions";
import NotificationContainer from "./Notification.container";
import gameAction from "reduxs/game/actions";
import gameSelector from "reduxs/game/selectors";
import displayConfigSelector from "reduxs/config/selectors";
import notificationAction from 'reduxs/notification/actions';
import userCountAction from 'reduxs/user/actions'
import lotterAction from 'reduxs/lotto/actions'

const mapStateToProps = (state: RootReducers): INotificationProps => {
  const gameListSelected = gameSelector.gameList(state);
  const displayConfig = displayConfigSelector.displayConfig(state);
  return {
    getLottoScheduleIsFetching: state.ludens.config.lotto.isFetching!,
    getLottoScheduleCode: state.ludens.config.lotto.code!,
    getLottoScheduleError: state.ludens.config.lotto.error!,
    lottoSchedule: state.ludens.config.lotto.data!,
    getGameListIsFetching: gameListSelected.isFetching!,
    getGameListError: gameListSelected.error!,
    getGameListCode: gameListSelected.code!,
    gameList: gameListSelected.data!,
    displayConfig: displayConfig.data!,
    displayConfigCode: displayConfig.code!,
    displayConfigError: displayConfig.error!,
    displayConfigIsFetching: displayConfig.isFetching!,
    notificationUserIsFetching: state.ludens.notification.user.user.isFetching!,
    notificationUserCode: state.ludens.notification.user.user.code!,
    notificationUserError: state.ludens.notification.user.user.error!,
    notificationUser: state.ludens.notification.user.user.data!,
    notificationUserCountIsFetching: state.ludens.notification.user.count.isFetching!,
    notificationUserCountCode: state.ludens.notification.user.count.code!,
    notificationUserCountError: state.ludens.notification.user.count.error!,
    notificationUserCount: state.ludens.notification.user.count.data!,
    notificationClick: state.ludens.notification.click.data!,
    notificationClickIsFetching: state.ludens.notification.click.isFetching!,
    notificationClickCode: state.ludens.notification.click.code!,
    notificationClickError: state.ludens.notification.click.error!,
    notificationUserRead: state.ludens.notification.user.read.data!,
    notificationUserReadIsFetching: state.ludens.notification.user.read.isFetching!,
    notificationUserReadCode: state.ludens.notification.user.read.code!,
    notificationUserReadError: state.ludens.notification.user.read.error!,
    getYeegeGameListIsFetching: state.ludens.lotto.yeege.gameList.isFetching!,
    getYeegeGameListError: state.ludens.lotto.yeege.gameList.error!,
    getYeegeGameListCode: state.ludens.lotto.yeege.gameList.code!,
    yeegeGameList: state.ludens.lotto.yeege.gameList.data!,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<RootAction>
): INotificationActionProps =>
  bindActionCreators(
    {
      loader: loaderAction.loadingAction,
      getLottoSchedule: configAction.getLottoScheduleAction,
      getGameList: gameAction.getGameListAction,
      getDisplayConfig: configAction.getDisplayConfigAction,
      getNotificationUser: notificationAction.getNotificationUserAction,
      getNotificationUserCount: notificationAction.getNotificationUserCountAction,
      postNotificationClick: notificationAction.postNotificationClickAction,
      postNotificationUserRead: notificationAction.postNotificationUserReadAction,
      clickRef: userCountAction.clickRef,
      getYeegeGameList: lotterAction.getYeegeGameListAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer);
