import HOT_ICON from './hot-menu.png'
import LOTTER_ICON from './lotter-menu.png'
import CASINO_ICON from './casino-menu.png'
import SLOT_ICON from './slot-menu.png'
import FISTING_ICON from './fishing-menu.png'
import BONUS_ICON from './bonus-web.png'
import ALLFILATE_ICON from './affilate-web.png'
import MOBILE_APP_ICON_1 from './mobile-download-1.png'
import MOBILE_APP_ICON_2 from './mobile-download-2.png'
import MOBILE_APP_DOWNLOAD_ANDROID_ICON from './play-store-1.png'
import MOBILE_APP_DOWNLOAD_APPLE_ICON from './app-store-1.png'

const menuIcon: ILandingMenuComponent[] = [
    { icon: HOT_ICON, name: 'สุดฮิต!' },
    { icon: LOTTER_ICON, name: 'หวย' },
    { icon: CASINO_ICON, name: 'คาสิโน' },
    { icon: SLOT_ICON, name: 'สล็อต' },
    { icon: FISTING_ICON, name: 'ยิงปลา' },
]


const bonusAndAffiliate: { title: string, content: string, icon: string }[] = [
    {
        title: 'แนะนำสมาชิก',
        content: `<span class="subtitle-2 offset-1">ชวนเพื่อนสมัครสมาชิก 
        รับค่าแนะนำตลอดการเดิมพันของคนถูกชวนสูงสุด 
        <span class="text-yellow font-weight-bold">8%</span>
        </span>`,
        icon: ALLFILATE_ICON
    },
    {
        title: 'โบนัส',
        content: `<span class="offset-1 subtitle-2">ทุกการเดิมพันที่เข้าร่วมรายการมีโอกาสลุ้นโบนัส สูงสุด <span class="text-yellow font-weight-bold">1,000,000</span> บาท</span>`,
        icon: BONUS_ICON
    },]

const downloadAppImage = [
    { image: MOBILE_APP_ICON_1, imageButton: MOBILE_APP_DOWNLOAD_ANDROID_ICON, type: 'android' },
    { image: MOBILE_APP_ICON_2, imageButton: MOBILE_APP_DOWNLOAD_APPLE_ICON, type: 'ios' },
]

const IconLists = { menuIcon, bonusAndAffiliate, downloadAppImage }

export default IconLists