import project from 'constants/project'
import { transformer } from 'utils'

export const GET_GAME_BENEFIT_REQUEST = 'GET_GAME_BENEFIT_REQUEST'
export const GET_GAME_BENEFIT_SUCCESS = 'GET_GAME_BENEFIT_SUCCESS'
export const GET_GAME_BENEFIT_FAILURE = 'GET_GAME_BENEFIT_FAILURE'
export const GET_GAME_BENEFIT_CANCEL = 'GET_GAME_BENEFIT_CANCEL'

export const initialState: ILotterBenefitState = {
  isFetching: false,
  code: 0,
  data: {},
  error: '',
}

export const endpoint = {
  GameBenefitRequest: (query: ILotterBenefitRequest) => `${project.environment[project.environmentName].api}/bet/game/benefit${transformer.urlSearchParams(query)}`,
}