import project from 'constants/project'
import { transformer } from 'utils'

export const GET_GAME_LIST_REQUEST = 'GET_GAME_LIST_REQUEST'
export const GET_GAME_LIST_SUCCESS = 'GET_GAME_LIST_SUCCESS'
export const GET_GAME_LIST_FAILURE = 'GET_GAME_LIST_FAILURE'
export const GET_GAME_LIST_CANCEL = 'GET_GAME_LIST_CANCEL'
export const CLEAR_GAME_LIST = 'CLEAR_GAME_LIST'

export const initialState: ReducerState<IGame[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  getExternalGameList: (query: IGameQuery) => `${project.environment[project.environmentName].api}/game/list${transformer.urlSearchParams(query)}`,
  getInternalGameList: (query: IGameQuery) => `${project.environment[project.environmentName].api}/internal-game/list${transformer.urlSearchParams(query)}`,
}