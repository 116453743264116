import { createAction } from 'typesafe-actions'
import {
    GET_SUMMARY_PMPG_ERA_REQUEST,
    GET_SUMMARY_PMPG_ERA_SUCCESS,
    GET_SUMMARY_PMPG_ERA_FAILURE,
    GET_SUMMARY_PMPG_ERA_CANCEL,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'


const getSummaryPmpgAction = createAction(GET_SUMMARY_PMPG_ERA_REQUEST)

const getSummaryPmpgSuccessAction = createAction(
    GET_SUMMARY_PMPG_ERA_SUCCESS,
    resolve => (data: AxiosResponse<APIResponse<IPmpgSummary>>) => resolve(data))

const getSummaryPmpgFailureAction = createAction(
    GET_SUMMARY_PMPG_ERA_FAILURE,
    resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getSummaryPmpgCancelAction = createAction(GET_SUMMARY_PMPG_ERA_CANCEL)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getSummaryPmpgAction,
    getSummaryPmpgSuccessAction,
    getSummaryPmpgFailureAction,
    getSummaryPmpgCancelAction,
}