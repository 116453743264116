
import React, { FC, useState, useEffect } from 'react'
import './progressbar.style.scss'

const ProgressBar: FC<any> = ({min, max, era}) => {
    const [style, setStyle] = useState({})

    useEffect(() => {
        const mapPercent = (100*min)/max
		const newStyle = {
			opacity: 1,
			width: `${mapPercent}%`,
            maxWidth: `100%`,
		}
		setStyle(newStyle);
    }, [min, max])

    return (
        <div className="progress-summary">
            <div className={min === 100 ? `progress-done-summary-full ` : `progress-done-${era}`} style={style} />
        </div>
    )
}

export default ProgressBar
