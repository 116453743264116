import io from 'socket.io-client'
import { MiddlewareAPI, Dispatch } from 'redux'
import socketAction from 'reduxs/socket/actions'
import userAction from 'reduxs/user/actions'
import lottoAction from 'reduxs/lotto/actions'
import notificationAction from 'reduxs/notification/actions'
import pmpgAction from 'reduxs/pmpg/actions'
import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import project from 'constants/project'
import { transformer } from 'utils'

const onConnect = (handleStore: MiddlewareAPI<Dispatch, RootReducers>, socket: string) => (event: any) => {
  switch(socket) {
    case 'socket':
      handleStore.dispatch(socketAction.connectedSocketAction())
      break;
    case 'notificationUserCountSocket':
      handleStore.dispatch(socketAction.connectedNotificationUserCountSocketAction())
      break;
    default:
      handleStore.dispatch(socketAction.connectedSocketAction())
  }
}

const onDisconnect = (handleStore: MiddlewareAPI<Dispatch, RootReducers>, socket: string) => () => {
  switch(socket) {
    case 'socket':
      handleStore.dispatch(socketAction.disconnectedSocketAction())
      break;
    case 'notificationUserCountSocket':
      handleStore.dispatch(socketAction.disconnectedNotificationUserCountSocketAction())
      break;
    default:
      handleStore.dispatch(socketAction.disconnectedSocketAction())
  }
}

const onError = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>, socket: string) => (error: any) => {
  switch(socket) {
    case 'socket':
      handlerStore.dispatch(socketAction.connectSocketErrorAction(error))
      break;
    case 'notificationUserCountSocket':
      handlerStore.dispatch(socketAction.connectNotificationUserCountSocketErrorAction(error))
      break;
    default:
      handlerStore.dispatch(socketAction.connectSocketErrorAction(error))
  }
}

const onUpdateWallet = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responseWallet: APIResponse<IWallet> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer.camelcaseTransform(responseWallet) as APIResponse<IWallet>
    handlerStore.dispatch(userAction.walletUpdateRequestSocketAction(transformed.data))
  }

const onUpdateYeegeGame = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responseYeegeGameList: APIResponse<ILottoGame[]> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer.camelcaseTransform(responseYeegeGameList) as APIResponse<ILottoGame[]>
    handlerStore.dispatch(lottoAction.updateYeegeGameListAction(transformed.data))
  }

const onUpdateYeegeSum = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responseYeegeSum: APIResponse<string> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer.camelcaseTransform(responseYeegeSum) as APIResponse<string>
    handlerStore.dispatch(lottoAction.updateYeegeSumAction(transformed.data))
  }

const onUpdatePlayedYeegeList = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responsePlayedYeegeList: APIResponse<IYeegePlay[]> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer.camelcaseTransform(responsePlayedYeegeList) as APIResponse<IYeegePlay[]>
    handlerStore.dispatch(lottoAction.updatePlayedYeegeListAction(transformed.data))
  }

const onUpdatePmpgEra = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responsePmpgEra: APIResponse<IPmpgEra> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer.camelcaseTransform(responsePmpgEra) as APIResponse<IPmpgEra>
    handlerStore.dispatch(pmpgAction.updatePmpgEraAction(transformed.data))
  }

const onUpdateNotificationUserCount = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responseNotificationUserCount: APIResponse<any> = (typeof response === 'string')
      ? JSON.parse(response) : response
    const transformed = transformer.camelcaseTransform(responseNotificationUserCount) as APIResponse<any>
    handlerStore.dispatch(notificationAction.updateNotificationUserCountAction(transformed.data))
  }

let socket: SocketIOClient.Socket | null = null
let notificationUserCountSocket: SocketIOClient.Socket | null = null 

const socketMiddleware = (store: MiddlewareAPI<Dispatch, RootReducers>) => (next: Dispatch) => (action: RootAction) => {

  if (store.getState().ludens.user.token.accessToken) {
    switch (action.type) {
      case getType(socketAction.connectSocketAction):
        socket = io(project.environment[project.environmentName].socket, {
          query: { token: store.getState().ludens.user.token.accessToken },
        })
        if (socket.connected) {
          socket.disconnect()
        }
        socket.connect()
        socket.on('connect', onConnect(store, 'socket'))
        socket.on('disconnect', onDisconnect(store, 'socket'))
        socket.on('error', onError(store, 'socket'))
        socket.on(`wallet_${store.getState().ludens.user.me.data?.id}`, onUpdateWallet(store))
        socket.on('yegee_game', onUpdateYeegeGame(store))
        socket.on('api/pmpg/era', onUpdatePmpgEra(store))
        break;
      case getType(socketAction.connectNotificationUserCountSocketAction):
        notificationUserCountSocket = io(project.environment[project.environmentName].notificationUserCountSocket, {
          query: { token: store.getState().ludens.user.token.accessToken },
        })
        if (notificationUserCountSocket.connected) {
          notificationUserCountSocket.disconnect()
        }
        notificationUserCountSocket.connect()
        notificationUserCountSocket.on('connect', onConnect(store, 'notificationUserCountSocket'))
        notificationUserCountSocket.on('disconnect', onDisconnect(store, 'notificationUserCountSocket'))
        notificationUserCountSocket.on('error', onError(store, 'notificationUserCountSocket'))
        notificationUserCountSocket.on(`api/v1/notification/user/count`, onUpdateNotificationUserCount(store))
        break;
      case getType(socketAction.disconnectSocketAction):
        if (socket) {
          if (socket.connected) {
            socket.disconnect()
          }
        }
        break;
      case getType(socketAction.disconnectNotificationUserCountSocketAction):
        if (notificationUserCountSocket) {
          if (notificationUserCountSocket.connected) {
            notificationUserCountSocket.disconnect()
          }
        }
        break;
      case getType(lottoAction.listenYeegeSumSocket):
        socket?.on(`yegee_play_sum_${action.payload.date}${action.payload.round}`, onUpdateYeegeSum(store))
        break;
      case getType(lottoAction.unlistenYeegeSumSocket):
        store.dispatch(lottoAction.clearYeegeSum())
        socket?.off(`yegee_play_sum_${action.payload.date}${action.payload.round}`)
        break;
      case getType(lottoAction.listenPlayedYeegeListSocket):
        socket?.on(`yegee_play_list_${action.payload.date}${action.payload.round}`, onUpdatePlayedYeegeList(store))
        break;
      case getType(lottoAction.unlistenPlayedYeegeListSocket):
        store.dispatch(lottoAction.clearPlayedYeegeList())
        socket?.off(`yegee_play_list_${action.payload.date}${action.payload.round}`)
        break;
      default:
        return next(action);
    }
  }

  return next(action)

}


export default socketMiddleware