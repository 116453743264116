import { combineReducers } from 'redux'
import summary from './summary/reducers'
import member from './member/reducers'
import withdrawal from './withdrawal/reducers'
import click from './click/reducers'

export default combineReducers({
  summary,
  member,
  withdrawal,
  click,
})