import React, { Component, createRef, RefObject } from 'react'
import { noop, get } from 'lodash'
import { FormikProps, Formik } from 'formik'
import { responseCode } from 'constants/response'
import { SuccessModal, ErrorModal } from 'components'
import { RouteComponentProps } from 'react-router-dom'
import initialValues from './models/initialValues'
import scheme from './models/scheme'
import { WithdrawForm } from './components'
import './withdraw.style.scss'
import { interactive } from 'utils'

const constants = {
  ok: 'ตกลง',
  gotoMain: 'รายการฝาก - ถอน',
  requestedSuccess: 'กรุณารอการตรวจสอบสักครู่',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IWithdrawProps & IWithdrawActionProps = {
  user: {},
  loader() { noop() },
  withdrawRequest() { noop() },
  withdrawRequestResult: {},
  withdrawRequestCode: 0,
  withdrawRequestError: '',
  withdrawRequestIsFetching: false,
}

class Withdraw extends Component<IWithdrawProps & IWithdrawActionProps & DefaultProps & RouteComponentProps> {
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    interactive.rootElement.scrollToTop()
  }

  componentDidUpdate(prevProps: IWithdrawProps) {
    if (prevProps.withdrawRequestIsFetching !== this.props.withdrawRequestIsFetching
      && !this.props.withdrawRequestIsFetching) {
      if (this.props.withdrawRequestCode === responseCode.OK) {
        SuccessModal.show({
          action: () => {
            this.props.history.replace('/transaction')
            SuccessModal.hide()
          },
          actionText: constants.gotoMain,
          description: constants.requestedSuccess,
        })
      } else if (this.props.withdrawRequestCode === 400229) {
        ErrorModal.show({
          title:"ไม่สามารถถอนได้",
          action: ErrorModal.hide,
          description: this.props.withdrawRequestError,
          actionText: constants.ok,
        })
      }
      else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.withdrawRequestError,
          actionText: constants.ok,
        })
      }
      this.props.loader(false)

    }
  }

  onSubmitWithdraw = (values: IWithdraw) => {
    const castedValue = scheme.cast(values)
    const withdrawRequestValue: IWithdrawRequest = {
      money: castedValue.money,
      description: castedValue.description || '-',
    }
    this.props.loader(true)
    this.props.withdrawRequest(withdrawRequestValue)
  }

  onCancelHandler = () => {
    this.props.history.goBack()
  }

  renderWithdrawForm = () => {
    const { user } = this.props
    const bank: IBank = get(user, 'bank', {})
    const wallet: IWallet = get(user, 'wallet', {})
    const WithdrawFormComponent = (formProps: FormikProps<IWithdraw>) => (
      <WithdrawForm
        {...formProps}
        extraProps={{ userBank: bank, wallet }}
        onBackPresses={this.onCancelHandler}
        onCancelPresses={this.onCancelHandler}
      />
    )

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={scheme}
        enableReinitialize
        onSubmit={this.onSubmitWithdraw}
      >
        {WithdrawFormComponent}
      </Formik>
    )
  }

  render() {
    const WithdrawFormComponent = this.renderWithdrawForm
    return (
      <div className="withdraw-container secondary-bg-navy" ref={this.pageContainerRef}>
        <div className="container">
          <WithdrawFormComponent />
        </div>
      </div>
    )
  }
}

export default Withdraw