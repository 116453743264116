import React, { SFC } from 'react'
import { Banner, InputNumber, Button, ALink } from 'components'
import colors from 'constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { noop, isEmpty } from 'lodash'
import { FormikProps } from 'formik'

import lotto from 'assets/images/banner/banner-16.9-lotto.png'
import aesexy from 'assets/images/banner/banner-16.9-ae.png'
import w88 from  'assets/images/banner/banner-16.9-w88.png'
import dreamgame from 'assets/images/banner/banner-16.9-dg.png'
import wm from 'assets/images/banner/banner-16.9-wm.png'
import dreamtech from 'assets/images/banner/banner-16.9-dreamtech.png'
import pg from 'assets/images/banner/banner-16.9-pg.png'
import allbet from 'assets/images/banner/banner-16.9-allbet.png'
import joker from 'assets/images/banner/banner-16.9-joker.png'
import affilate from 'assets/images/banner/banner-16.9-aff.png'
import sexybaccarat from 'assets/images/banner/thailandbet_sexy_baccarat_4.3.jpg'
import yeeke from 'assets/images/banner/banner-16.9-yeeke.png'
import './registerStep1.style.scss'

const constants = {
  title: 'สมัครสมาชิก',
  subTitle: 'ขั้นตอนที่ 1',
  placeholderPhoneNumber: 'หมายเลขโทรศัพท์ 10 หลัก',
  placeholderHint: 'รหัส 4 ตัวในภาพ',
  buttonRequestOTP: 'ขอ OTP',
  backText: 'ย้อนกลับ',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IRegisterFormProps = {
  onConfirmPresses() {
    noop()
  },
  onBackStep() {
    noop()
  },
}

const CURRENT_STEP = 1

const RegisterStep1: SFC<
  FormikProps<IRegister> & IRegisterFormProps & DefaultProps
> = (props) => {
  const {
    values,
    touched,
    errors,
    onBackStep,
    handleBlur,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    onConfirmPresses,
  } = props

  const onPressRequestOTP = () => {
    if (!errors.phoneNumber) {
      onConfirmPresses!(CURRENT_STEP, values)
    }
  }

  const resetMobileForm = () => {
    setFieldValue('phoneNumber', '')
    setFieldError('phoneNumber', '')
    setFieldTouched('phoneNumber', false)
  }

  const onPressBackStep = () => {
    resetMobileForm()
    onBackStep!(CURRENT_STEP)
  }

  return (
    <div className="register-step-1-form container">
      <div className="row">
        <div className="col">
          <ALink
            id="backto-previus-page"
            color={colors.PRIMARY_RED}
            bold
            onClick={onPressBackStep}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="m1-r" />
            {constants.backText}
          </ALink>
        </div>
      </div>
      <div className="row pt-4 pb-4">
        <div className="col">
          <Banner
            ImageIcon={[
              lotto,
              aesexy,
              w88,
              dreamgame,
              wm,
              dreamtech,
              pg,
              allbet,
              joker,
              affilate,
              sexybaccarat,
              yeeke,
            ]}
            size="large"
          />
        </div>
      </div>
      <div className="row p2-t">
        <div className="col">
          <h3>
            {constants.title}
            <span className="header-remark secondary-red-text">
              {constants.subTitle}
            </span>
          </h3>
        </div>
      </div>
      <div className="row p3-t">
        <div className="col">
          <InputNumber
            decimalScale={0}
            name="phoneNumber"
            format="### ### ####"
            onBlur={handleBlur}
            allowNegative={false}
            onValueChange={({ value }) => setFieldValue('phoneNumber', value)}
            value={values.phoneNumber}
            errorMessage={errors.phoneNumber}
            placeholder={constants.placeholderPhoneNumber}
            error={!!errors.phoneNumber && touched.phoneNumber}
          />
        </div>
      </div>
      <div className="row p2-t">
        <div className="col">
          <Button
            id="register-step-1-submit-button"
            disabled={!!errors.phoneNumber || isEmpty(values.phoneNumber)}
            text={constants.buttonRequestOTP}
            onClick={onPressRequestOTP}
          />
        </div>
      </div>
    </div>
  )
}

RegisterStep1.defaultProps = defaultProps

export default RegisterStep1
