import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const coreSocketReducer = (state: ILogin = initialState, action: RootAction): ILogin => {
  switch (action.type) {
    case getType(actions.rememberMe):
      if (action.payload.remember) {
        const username = window.btoa(action.payload.username)
        const password = window.btoa(action.payload.password)
        return {
          username,
          password,
          remember: action.payload.remember,
        }
      } else {
        return {
          username: '',
          password: '',
          remember: action.payload.remember,
        }
      }
    default:
      return state
  }
}

export default coreSocketReducer