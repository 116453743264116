import React, { Component, createRef, RefObject } from 'react'
import { ALink, AlertNotification, Collapse, PlaceholderLoading } from 'components'
import { noop, map, get, keys, snakeCase, capitalize, upperCase, isEmpty, includes } from 'lodash'
import colors from 'constants/colors'
import { RouteComponentProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faDice, faLink, faCopy, faChessKnight, faCoins } from '@fortawesome/free-solid-svg-icons'
import { date, interactive, number } from 'utils'
import copy from 'copy-to-clipboard'
import './affiliate.style.scss'
import routes from 'constants/routes'
import { LOTTO_SLUG_NAME } from 'constants/variables'
import InfiniteScroll from 'react-infinite-scroll-component'

const constants = {
	back: 'ย้อนกลับ',
	totalIncome: 'รายได้ทั้งหมดที่เคยได้',
	totalMember: 'สมาชิกที่แนะนำได้',
	totalClick: 'จำนวนคลิก',
	totalBet: 'ยอดเดิมพันสมาชิก',
	availableIncome: 'สามารถถอนได้',
	howTo: 'วิธีรับคำแนะนำ',
	percent: 'เปอร์เซ็นต์ส่วนแบ่ง',
	divider: 'ส่วนแบ่ง',
	income: 'ยอดเดิมพันทั้งหมด',
	recommendedLink: 'ลิงก์แนะนำ',
	member: 'สมาชิก',
	totalMaked: 'ยอดเดิมพัน',
	prefixMemberCreatedAt: 'เป็นสมาชิกเมื่อ',
	multipy2Percent: 100,
	copied: 'คัดลอกแล้ว!',
	lotto: 'หวยออนไลน์',
	game: 'เกม',
	withdraw: 'ถอนเงิน',
	totalBetUser: 'ยอดชนะ',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IAffilateProps & IAffilateActionProps = {
	affilateUuid: '',
	getAffilateSummary() { noop() },
	getAffilateSummaryCode: 0,
	getAffilateSummaryError: '',
	getAffilateSummaryIsFetching: false,
	getAffilateSummaryResult: {},
	getAffilateMember() { noop() },
	getAffilateMemberCode: 0,
	getAffilateMemberError: '',
	getAffilateMemberIsFetching: false,
	getAffilateMemberResult: [],
	loader() { noop() },
	clearClickRef() { noop() }
}

class AffilateContainer extends
	Component<RouteComponentProps & DefaultProps & IAffilateProps & IAffilateActionProps, IAffilateContainerState> {

	static defaultProps = defaultProps
	pageContainerRef: RefObject<HTMLDivElement> = createRef()

	state: IAffilateContainerState = {
		userCollapseState: [],
		count: {
			prev: 0,
			next: 10
		},
		hasMore: true,
		currentData: []
	}

	componentDidMount() {
		this.props.loader(true)
		this.props.getAffilateSummary()
		this.props.getAffilateMember()
		setTimeout(() => {
			this.props.clearClickRef()
		    interactive.rootElement.scrollToTop()
		}, 256)
	}

	componentDidUpdate(prevProps: IAffilateProps) {
		if (prevProps.getAffilateSummaryIsFetching !== this.props.getAffilateSummaryIsFetching
			&& !this.props.getAffilateSummaryIsFetching) {
			this.props.loader(false)
		}

		if (prevProps.getAffilateMemberIsFetching !== this.props.getAffilateMemberIsFetching
			&& !this.props.getAffilateMemberIsFetching) {
			this.props.loader(false)
			this.setState({ userCollapseState: map(this.props.getAffilateMemberResult, (member) => false) })
			this.setState({
				currentData:this.state.currentData.concat(this.props.getAffilateMemberResult.slice(this.state.count.prev, this.state.count.next))
		  	},()=>{
				if (this.state.currentData.length === this.props.getAffilateMemberResult.length) {
					this.setState({hasMore:false});
					return;
				}
			})
		}
	}

	onPressBack = () => this.props.history.goBack()

	onClickWithdraw = () =>
		this.props.history.push(
			routes.affiliateWithdrawal.path,
			{ availableMoney: Number(get(this.props.getAffilateSummaryResult, 'income', '0')) }
		)

	onPressQAAffilate = () => this.props.history.push(routes.qaType.exactPath('affiliate'))

	onPressCopy = (link: string) => {
		copy(link)
		AlertNotification.show({ text: constants.copied, countdown: true })
	}

	getMoreDataAffilateMember = () => {
		if (this.state.currentData.length === this.props.getAffilateMemberResult.length) {
		  this.setState({hasMore:false});
		  return;
		}
		this.setState({count:{ prev: this.state.count.prev + 10, next: this.state.count.next + 10 }},()=>{
			setTimeout(() => {
				this.setState({
					currentData:this.state.currentData.concat(this.props.getAffilateMemberResult.slice(this.state.count.prev, this.state.count.next))
			  })
		  }, 125)
		})
	}

	onStateCollapseChanged = (state: boolean, index: number) => {
		const newCollapseState = [...this.state.userCollapseState]
		newCollapseState[index] = state
		this.setState({ userCollapseState: newCollapseState })
	}

	renderMemberListComponent = () => {
		const MemberListComponent = map(this.state.currentData, (memberData, memberIndex) => {
			const memberName = memberData.memberName
			const totalMakedBet = number.castToMoney(Number(memberData.totalBet || '0'))
			// const totalMakedMoney = number.castToMoney(Number(memberData.totalIncome || '0'))
			const createdAt = date.calibratingTime(memberData.createdAt).add('years', 543).format('Do MMMM YY')

			// const affLottoTotal = number.castToMoney(Number(get(memberData.lotter, 'affilateIncome', '0')))
			// const affGameTotal = number.castToMoney(Number(get(memberData.game, 'affilateIncome', '0')))

			const lottoTotal = number.castToMoney(Number(get(memberData, 'totalLottery', '0')))
			const gameTotal = number.castToMoney(Number(get(memberData, 'totalGame' || '0')))

			// totalBetUser
			const collapseState = this.state.userCollapseState[memberIndex]
			return (
				<Collapse
					expanded={collapseState}
					maxExpandHeight={800}
					key={`qa-${memberIndex}-${memberData.createdAt}`}
					onStateChanged={state => this.onStateCollapseChanged(state, memberIndex)}
					RenderHeaderComponent={this.renderHeader(memberName!, totalMakedBet, totalMakedBet, createdAt, collapseState)}
					RenderBodyComponent={this.renderContent(lottoTotal, gameTotal, gameTotal, lottoTotal,get(memberData, 'game' ,{}),get(memberData, 'lotter' ,{}))}
				/>
			)
		})

		return (<>{MemberListComponent}</>)
	}

	renderHeader = (
		memberName: string,
		totalMakedBet: string,
		totalMakedMoney: string,
		createdAt: string,
		state: boolean
	) => () => (
		<div className="m3-t d-flex flex-row align-items-center tertiary-bg-navy header-collapse w-100">
			<div className="flex">
				<h5>{memberName}</h5>
				<div className="subtitle-2 secondary-text">
					{constants.prefixMemberCreatedAt} {createdAt}
				</div>
			</div>
			<div className="text-right min-width">
				<div className="subtitle-2 secondary-text text-right">{constants.totalMaked}</div>
				<h4>{totalMakedMoney}</h4>
			</div>
			<div className="p2-l p1-r">
				<FontAwesomeIcon
					icon={faChevronRight}
					className={`secondary-text-gold chevron-right-icon ${state ? 'expanded' : ''}`}
				/>
			</div>
		</div>

	)

	renderContent = (lottoTotal: string, gameTotal: string, affGameTotal: string, affLottoTotal: string,listGame:object,listLotto:object) => () => {
		return (
			<div className="m1-t">
				<div className="m1-l p1-y content d-flex flex-row align-items-center">
					<div className="flex d-flex flex-row">
						<FontAwesomeIcon icon={faDice} className="secondary-text m1-r" />
						<h5>{constants.lotto}</h5>
					</div>
					<div className="text-right min-width">
						<h4>{affLottoTotal}</h4>
					</div>
				</div>
				{keys(listLotto).map((item,index)=>{
					return <div className="m1-l p1-y content d-flex flex-row align-items-center" key={index}>
						<div className="flex d-flex flex-row">
							<h6 className='m1-l text-name-lotto secondary-text'>{get(LOTTO_SLUG_NAME,snakeCase(item).toUpperCase())}</h6>
						</div>
						<div className="text-right min-width">
							<h6 className='secondary-text'>{number.castToMoney(Number(get(listLotto,item+'.totalBet')))}</h6>
						</div>
					</div>
				})}
				<div className="m1-l p1-y content d-flex flex-row align-items-center">
					<div className="flex d-flex flex-row">
						<FontAwesomeIcon icon={faChessKnight} className="secondary-text m1-l m1-r" />
						<h5>{constants.game}</h5>
					</div>
					<div className="text-right min-width">
						<h4>{affGameTotal}</h4>
					</div>
				</div>
				{keys(listGame).map((item,index)=>{
					  const nameGame=()=>{
						const gameNameUnderscore = (snakeCase(item).toUpperCase() || '').replace(/GAME_CASINO_JOKER_|GAME_CASINO_THBGAME_|GAME_CASINO_W88_|GAME_CASINO_AESEXY_|GAME_CASINO_DT_|GAME_CASINO_PGGAME_|GAME_CASINO_WMGAME_|GAME_CASINO_ALLBET_/, '')
						const split = gameNameUnderscore.split('_')
						let removeGame = upperCase(gameNameUnderscore).replace("GAME ",'')

						if ( (typeof Number(split[split.length-1])==='number') && (!isNaN(Number(split[split.length-1]))) ) {
							if (removeGame.includes('W 88')) {
								return 'Casino W88'
							}
							const removeRound = removeGame.substring(0, removeGame.lastIndexOf('_'))
							return capitalize(upperCase(removeRound))
						}else{
							if (includes(removeGame,"CASINO ")) {
								let splitCasino = removeGame.split(' ')
								return capitalize(upperCase(get(splitCasino,"[0]",'')))+" "+upperCase(get(splitCasino,"[1]",''))
							}else{
								return capitalize(upperCase(removeGame))
							}
						}
					  }
					
					return <div className="m1-l p1-y content d-flex flex-row align-items-center" key={index}>
						<div className="flex d-flex flex-row">
							<h6 className='m1-l text-name-lotto secondary-text'>{nameGame()}</h6>
						</div>
						<div className="text-right min-width">
							<h6 className='secondary-text'>{number.castToMoney(Number(get(listGame,item+'.totalBet')))}</h6>
						</div>
					</div>
				})}
			</div>
		)
	}
	render() {
		const { getAffilateSummaryResult } = this.props
		const totalIncome = getAffilateSummaryResult.totalIncome
		const totalMember = getAffilateSummaryResult.totalRegistered
		const totalClick = getAffilateSummaryResult.totalClick
		const totalBet = getAffilateSummaryResult.totalBet
		const availableIncome = getAffilateSummaryResult.income
		const UserListComponent = this.renderMemberListComponent

		const affiliateDivider = (affiliate?: IAffilateIncome) => {
			if (affiliate) { return (Number(affiliate.rate!) * constants.multipy2Percent) }
			return ''
		}
		const lottoPercent = `${constants.divider} 0 - ${affiliateDivider(getAffilateSummaryResult.lotter)}%`
		const lotterTotalBet = getAffilateSummaryResult.lotter?.totalBet || 0

		const gamePercent = `${constants.divider} ${affiliateDivider(getAffilateSummaryResult.game)}%`
		const gameTotalBet = getAffilateSummaryResult.game?.totalBet || 0

		const link = `${window.location.host}/register/${this.props.affilateUuid}`
		return (
			<div className="affilate-container" ref={this.pageContainerRef}>
				<div className="container">
					<div className="row">
						<div className="col">
							<ALink id="backto-previus-page" color={colors.PRIMARY_RED} bold onClick={this.onPressBack}>
								<FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
								{constants.back}
							</ALink>
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-md-4 m3-t text-center">
							<h2 className="secondary-text-gold">{number.castToMoney(Number(totalIncome))}</h2>
							<h4 className="m1-t">{constants.totalIncome}</h4>
						</div>
						<div className="col-6 col-md-4 m3-t text-center">
							<h2 className="secondary-text-gold">{number.castToMoney(Number(totalBet))}</h2>
							<h4 className="m1-t">{constants.totalBet}</h4>
						</div>
						<div className="col-6 col-md-4 m3-t text-center">
							<h2 className="secondary-text-gold">{totalMember}</h2>
							<h4 className="m1-t">{constants.totalMember}</h4>
						</div>
						<div className="col-6 col-md-6 m3-t text-center">
							<h2 className="secondary-text-gold">{totalClick}</h2>
							<h4 className="m1-t">{constants.totalClick}</h4>
						</div>
						<div className="col-12 col-md-6 m3-t text-center">
							<h2 className="secondary-text-gold">{number.castToMoney(Number(availableIncome))}</h2>
							<h4 className="m1-t">
								{constants.availableIncome}
								{' '}
								<ALink id="goto-withdraw-affiliate" color={colors.SECONDARY_GOLD_TEXT} bold onClick={this.onClickWithdraw}>
									{constants.withdraw}
									<FontAwesomeIcon icon={faCoins} className="ml-1" />
								</ALink>
							</h4>
						</div>
					</div>
					<div className="row  m4-t">
						<div className="col text-center">
							<ALink id="goto-qa-howto-aff" color={colors.SECONDARY_GOLD_TEXT} onClick={this.onPressQAAffilate}>
								{constants.howTo}
							</ALink>
						</div>
					</div>
					<div className="row  m2-t">
						<div className="col">
							<div className="border-rounded tertiary-bg-navy p2">
								<h3>{constants.percent}</h3>
								<div className="m3-t d-flex flex-row align-items-center">
									<FontAwesomeIcon icon={faDice} className="secondary-text m1-r" />
									<div className="p1-x flex">
										<h5>{constants.lotto}</h5>
										<div className="subtitle-2 secondary-text">{lottoPercent}</div>
									</div>
									<div>
										<div className="subtitle-2 secondary-text text-right">{constants.income}</div>
										<h4 className="primary-green-text">{number.castToMoney(Number(lotterTotalBet))}</h4>
									</div>
								</div>
								<div className="m1-l m3-t d-flex flex-row align-items-center">
									<FontAwesomeIcon icon={faChessKnight} className="secondary-text m1-r" />
									<div className="p1-x flex">
										<h5>{constants.game}</h5>
										<div className="subtitle-2 secondary-text">{gamePercent}</div>
									</div>
									<div>
										<div className="subtitle-2 secondary-text text-right">{constants.income}</div>
										<h4 className="primary-green-text">{number.castToMoney(Number(gameTotalBet))}</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row  m2-t">
						<div className="col">
							<div className="border-rounded tertiary-bg-navy p2">
								<h3>{constants.recommendedLink}</h3>
								<div className="m3-t d-flex flex-row align-items-center">
									<FontAwesomeIcon icon={faLink} className="secondary-text m1-r" />
									<div className="p1-x flex overflow-hidden">
										<div className="d-block text-truncate">
											<div className="subtitle-1 primary-text">{link}</div>
										</div>
									</div>
									<div className="copy-button-container" onClick={() => this.onPressCopy(link)}>
										<FontAwesomeIcon icon={faCopy} className="primary-text" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row  m2-t">
						<div className="col">
							<div className="border-rounded tertiary-bg-navy p2">
								<h3>{constants.member}</h3>
								{!isEmpty(this.props.getAffilateMemberResult)&&<InfiniteScroll
									style={{overflowX: 'hidden',minHeight: '100%'}}
									height={'50vh'}
									dataLength={this.state.currentData.length}
									next={this.getMoreDataAffilateMember}
									hasMore={this.state.hasMore}
									scrollThreshold={1}
									loader={(
									<div className="row p2-y">
										<div className="col">
										<PlaceholderLoading
											customCol={[
											[
												{ col: 4, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
												{ col: 6, show: false },
												{ col: 2, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
											],
											[
												{ col: 5, color: colors.SECONDARY_TEXT, show: true, size: 'S' },
											],
											]}
										/>
										</div>
									</div>
									)}
								>
									<UserListComponent />
								</InfiniteScroll>}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default AffilateContainer