import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import userAction from 'reduxs/user/me/actions'
import pmpgSelector from 'reduxs/pmpg/selectors'
import pmpgAction from 'reduxs/pmpg/actions'
import loaderAction from 'reduxs/loader/actions'

import IndexContainer from './Index.container'
import userSelector from 'reduxs/user/selector'
interface IIndexProps {
  user: IUser
  wallet: IWallet
  highlightEra?: IPmpgEra
  webConfig: IWebConfig
  clickRef: IClickRef
}

interface IIndexActionProps {
  getUser(): void
  getSummaryPmpg(): void
  clearSummaryPmpg(): void
  loader(state: boolean): void
}

const mapStateToProps = (state: RootReducers): IIndexProps => {
  const eraHighlightSelected = pmpgSelector.eraPmpg(state)
  const clickRef = userSelector.clickRef(state)
  return {
    user: state.ludens.user.me.data!,
    wallet: state.ludens.user.wallet,
    highlightEra: eraHighlightSelected.data,
    webConfig: state.ludens.config.me.data!,
    clickRef,
  }
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): IIndexActionProps =>
  bindActionCreators(
    {
      loader: loaderAction.loadingAction,
      getUser: userAction.getMeAction,
      getSummaryPmpg: pmpgAction.getSummaryPmpgAction,
      clearSummaryPmpg: pmpgAction.getSummaryPmpgCancelAction,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProos)(IndexContainer)
