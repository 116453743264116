import React, { FC } from 'react'
import { number } from 'utils'
import moment from 'moment-timezone'
import './transactionItem.style.scss'

const constants = {
  remainingAmount: 'คงเหลือ',
}

const transactionName: { [name: string]: string } = {
  DEPOSIT: 'ฝากเครดิต',
  WITHDRAW: 'ถอนเครดิต',
  DEPOSIT_GAME_FISHING: 'ฝากเงินจากเกมยิงปลา',
  WITHDRAW_GAME_FISHING: 'ถอนเงินเข้าเกมยิงปลา',
  AFFILATE_WITHDRAW: 'ย้ายเงินจากกระเป๋าระบบแนะนำสมาชิก',
  SYSTEM_INCOME: 'รายได้ระบบ',
  SYSTEM_MONEY_LOSS: 'จ่ายผู้เล่น',
  DEPOSIT_SYSTEM_INCOME: 'ลูกค้าฝากเข้าระบบ',
  WITHDRAW_SYSTEM_INCOME: 'ลูกค้าแจ้งถอนเงินออกจากระบบ',
  WITHDRAW_SYSTEM_MONEY_LOSS: 'ลูกค้าถอนเงินออกจากระบบ',
  DEPOSIT_W88_P2P: 'ฝากเครดิตจากเกม Casino W88 ผู้เล่นปะทะผู้เล่น',
  WITHDRAW_W88_P2P: 'ถอนเครดิตเข้าเกม Casino W88 ผู้เล่นปะทะผู้เล่น',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ITransactionItem = {
  status: '',
  money: 0,
  time: '',
  type: '',
  containerClassName: '',
}

const TransactionItem: FC<ITransactionItem & DefaultProps> = (props) => {

  const {
    status,
    money,
    time,
    type,
    onClick,
    containerClassName,
  } = props

  const name = `${transactionName[type]} ${status === 'WAIT' || status === 'RESERVE'? '(รอตรวจสอบ)' : (status === 'FAIL' ? '(ไม่สำเร็จ)' : '')}`
  const displayMoney = number.castToMoney(money)
  const displayTime = moment(time, 'YYYYMMDDHHmmss').format('lll')
  const statusClass = () => {
    switch (type) {
      case 'DEPOSIT':
      case 'DEPOSIT_GAME_FISHING':
      case 'DEPOSIT_W88_P2P':
      case 'AFFILATE_WITHDRAW':
      case 'SYSTEM_INCOME':
      case 'DEPOSIT_SYSTEM_INCOME':
      case 'WITHDRAW_SYSTEM_INCOME':
        return `${Number(money) === 0.00 ? '' :'deposit'}${status === 'WAIT' ? 'pending' : (status === 'FAIL' ? 'failed' : '')}`
      case 'WITHDRAW':
        return `withdraw ${status === 'RESERVE' || status === 'WAIT'  ? 'pending' : (status === 'FAIL' ? 'failed' : '')}`
      case 'WITHDRAW_GAME_FISHING':
      case 'WITHDRAW_W88_P2P':
      case 'SYSTEM_MONEY_LOSS':
      case 'WITHDRAW_SYSTEM_MONEY_LOSS':
        return `withdraw ${status === 'WAIT' ? 'pending' : (status === 'FAIL' ? 'failed' : '')}`
      default:
        return ''
    }
  }

  return (
    <div className={`row ${containerClassName}`} onClick={onClick}>
      <div className="col d-flex flex-row transaction-item-container">
        <div className="d-flex flex-column display-transaction-wrapper">
          <div className="transaction-name">{name}</div>
          <div className="transaction-time">{displayTime}</div>
        </div>
        <div className="d-flex flex-column text-right">
          <div className={`transaction-money ${statusClass()}`}>{displayMoney}</div>
          <div className="transaction-remaining">{constants.remainingAmount}</div>
        </div>
      </div>
    </div>
  )
}

TransactionItem.defaultProps = defaultProps

export default TransactionItem