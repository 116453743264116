import moment, { Moment } from 'moment-timezone'
import { ESCAPED_TIMEZONE } from 'constants/regex'
import { isEmpty, get } from 'lodash'

const convertTimeToMoment = (hours: string = '00', minutes: string = '00'): Moment => {
  const momentTime = moment(`${hours}:${minutes}`, 'HH:mm')
  return momentTime
}

const calibratingTime = (timeasString?: string): Moment => {
  const localTime = moment().local()
  if (isEmpty(timeasString)) { return localTime }

  const localTimezoneArray = localTime.format().match(ESCAPED_TIMEZONE)
  const escapedTimezoneArray = timeasString!.match(ESCAPED_TIMEZONE)

  const servertime = get(escapedTimezoneArray, '0', '')
  const localTimezone = get(localTimezoneArray, '1', '')
  if (isEmpty(servertime)) { return localTime }

  const calibratedTimeString = `${servertime}${localTimezone}`
  const calibratedTime = moment(calibratedTimeString).local()

  return calibratedTime
}

const endDay = (month: string, year: string) => {
  switch (month) {
    case '01':
    case '03':
    case '05':
    case '07':
    case '08':
    case '10':
    case '12':
      return '31'
    case '04':
    case '06':
    case '09':
    case '11':
      return '30'
    case '02':
      return (Number(year) - 543) % 4 === 0 ? '29' : '28'
    default:
      return '30'
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  convertTimeToMoment,
  calibratingTime,
  endDay
}