import React, { FC } from 'react'
import { noop } from 'lodash'
import './datepicker.style.scss'
import DatePicker from 'react-date-picker';

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IDatePicker = {
  value: null,
  onChange(date: Date | Date[]) { noop() },
  clearIcon: undefined,
}

const constants = {
  localeType: 'th',
  dayPlaceholder: 'วัน',
  monthPlaceholder: 'เดือน',
  yearPlaceholder: 'ปี',
}

const IDatePicker: FC<IDatePicker & DefaultProps> = (props) => {

  const {
    value,
    onChange,
    clearIcon,
  } = props

  return (
    <div className="date-picker-container">
      <DatePicker
        clearIcon={clearIcon}
        locale={constants.localeType}
        maxDate={new Date()}
        value={value as any}
        dayPlaceholder={constants.dayPlaceholder}
        monthPlaceholder={constants.monthPlaceholder}
        yearPlaceholder={constants.yearPlaceholder}
        onChange={onChange}
      />
    </div>
  )
}

IDatePicker.defaultProps = defaultProps

export default IDatePicker