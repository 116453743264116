import project from 'constants/project'

export const GET_YEEGE_GAME_HISTORY_REQUEST = 'GET_YEEGE_GAME_HISTORY_REQUEST'
export const GET_YEEGE_GAME_HISTORY_SUCCESS = 'GET_YEEGE_GAME_HISTORY_SUCCESS'
export const GET_YEEGE_GAME_HISTORY_FAILURE = 'GET_YEEGE_GAME_HISTORY_FAILURE'
export const GET_YEEGE_GAME_HISTORY_CANCEL = 'GET_YEEGE_GAME_HISTORY_CANCEL'
export const CLEAR_YEEGE_GAME_HISTORY = 'CLEAR_YEEGE_GAME_HISTORY'

export const initialState: ReducerState<IYeegeGameHistory[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  getYeegeGameHistory: `${project.environment[project.environmentName].api}/lotter/yegee/play/history`,
}