import { LOTTERS } from 'constants/variables'
import { noop, map } from 'lodash'
import React, { Component } from 'react'
import './gameMenuList.styles.scss'
import { FireEffect } from 'components'
import LogoThailandBet from 'assets/images/logo/logothailandbet04.png'
import ASSETS_CASINO_PRESENTERS from 'assets/images/landingContent/presenter-game-casino'
import ASSETS_GAME_PRESENTERS from 'assets/images/landingContent/presenter-game-slot'
import ASSETS_FISHING_GAME_PRESENTERS from 'assets/images/landingContent/fish-game'
import routes from 'constants/routes'

interface IGameMenuListComponent {
  selectedMenu: TLandingMenuComponent
  onClickGame: (data: IGameMenuCallback) => void
}

interface IGameMenuCallback {
  company: TGameCompany | undefined
  code: string | undefined
  path: string | undefined
}

const defaultProps: IGameMenuListComponent = {
  selectedMenu: 'สุดฮิต!',
  onClickGame() {
    noop()
  },
}

type DefaultProps = Readonly<typeof defaultProps>

class GameMenuListComponent extends Component<
  IGameMenuListComponent & DefaultProps
> {
  static defaultProps = defaultProps

  RenderCasinoMenu = (filterd: boolean) => {
    const subComponent = (
      dataList: {
        name: TGameCompany | 'PRETTYGAMING'
        image: string
      },
      i: number
    ) => (
      <div
        key={`casino-item-${i}`}
        id={`casino-id-${i}`}
        className="col-4 mb-lg-3 mb-md-2 mb-sm-1 mb-2 px-1 px-lg-2 px-md-2 px-sm-2 casino-card-wrapper"
      >
        <div
          className="game-card-box casino-card-radius"
          tabIndex={0}
          onClick={() =>
            this.props.onClickGame({
              company: dataList.name as TGameCompany,
              code: undefined,
              path: routes.casino.path,
            })
          }
          key={`casino-${i}`}
        >
          <img
            className="casino-icon"
            src={dataList.image}
            alt={`${dataList.name}`}
          />
          {[0, 1, 2].includes(i) && (
            <div className="line-libbins-casino">
              <img
                src={LogoThailandBet}
                className="inside-line-libbins"
                alt="TLB-LOGO"
              />
              <div className="inside-line-fire">
                <FireEffect burnCount={32} />
              </div>
            </div>
          )}
        </div>
      </div>
    )
    const mapIconList = map(
      ASSETS_CASINO_PRESENTERS.filter(
        (o) => !['PRETTYGAMING'].includes(o.name)
      ),
      (dataList, i) =>
        !filterd
          ? subComponent(dataList, i)
          : [0, 1, 2].includes(i) && subComponent(dataList, i)
    )
    return (
      <div className="casino-game-container">
        <div className="row px-lg-1">{mapIconList}</div>
      </div>
    )
  }

  RenderSlotMenu = (filterd: boolean) => {
    const subComponent = (
      dataList: {
        name: TGameCompany
        image: string
      },
      i: number
    ) => (
      <div
        key={`casino-item-${i}`}
        id={`casino-id-${i}`}
        className="col-4 mb-lg-3 mb-md-2 mb-sm-1 mb-2 px-1 px-lg-2 px-md-2 px-sm-2 casino-card-wrapper"
      >
        <div
          className="game-card-box casino-card-radius"
          tabIndex={0}
          onClick={() =>
            this.props.onClickGame({
              code: undefined,
              company: dataList.name,
              path: routes.games.path,
            })
          }
          key={`slot-${i}`}
        >
          <img
            className="casino-icon"
            src={dataList.image}
            alt={`${dataList.name}`}
          />
          {[0, 1, 2].includes(i) && (
            <div className="line-libbins-casino">
              <img
                src={LogoThailandBet}
                className="inside-line-libbins"
                alt="TLB-LOGO"
              />
              <div className="inside-line-fire">
                <FireEffect burnCount={32} />
              </div>
            </div>
          )}
        </div>
      </div>
    )
    const mapIconList = map(ASSETS_GAME_PRESENTERS, (dataList, i) =>
      !filterd
        ? subComponent(dataList, i)
        : [0, 1, 2].includes(i) && subComponent(dataList, i)
    )
    return (
      <div className="casino-game-container">
        <div className="row px-lg-1">{mapIconList}</div>
      </div>
    )
  }

  RenderLotterMenu = (filterd: boolean) => {
    const subComponent = (dataList: ICLotters, i: number) =>
      dataList.code !== undefined && (
        <div
          key={`lotter-item-${i}`}
          id={`lotter-id-${i}`}
          className={`col-4 mb-lg-3 mb-md-2 mb-sm-1 mb-2 px-1 px-lg-2 px-md-2 px-sm-2 lotter-card-wrapper`}
        >
          <div
            className="game-card-box lotter-card-radius"
            tabIndex={0}
            onClick={() =>
              this.props.onClickGame({
                path: routes.lotto.path,
                code: dataList.code,
                company: undefined,
              })
            }
            key={`lotter-${i}`}
          >
            {[0, 1, 2].includes(i) && (
              <div className="line-libbins-lotter">
                <img
                  src={LogoThailandBet}
                  className="inside-line-libbins"
                  alt="TLB-LOGO"
                />
                <div className="inside-line-fire">
                  <FireEffect burnCount={32} />
                </div>
              </div>
            )}
            <img
              className={`lotter-icon-large`}
              src={dataList.Icon}
              alt={`${dataList.code}`}
            />
            <span
              className={`text-center text-label ${
                dataList.code === 'LOTTER_YEGEE' && 'text-yellow'
              }
              `}
            >
              {dataList.code === 'LOTTER_YEGEE' ? 'จับยี่กี' : dataList.name}
            </span>
          </div>
        </div>
      )
    const lotterList = map(LOTTERS, (dataList, i) =>
      !filterd
        ? subComponent(dataList, i)
        : [0, 1, 2].includes(i) && subComponent(dataList, i)
    )
    return (
      <div className="lotter-game-container">
        <div className="row px-lg-1">{lotterList}</div>
      </div>
    )
  }
  RenderFishingMenu = (filterd: boolean) => {
    const subComponent = (
      dataList: { name: string; image: string; type: TGameType; id: string },
      i: number
    ) => (
      <div
        key={`casino-item-${i}`}
        id={`casino-id-${i}`}
        className="col-4 mb-lg-3 mb-md-2 mb-sm-1 mb-2 px-1 px-lg-2 px-md-2 px-sm-2 casino-card-wrapper"
      >
        <div
          className="game-card-box casino-card-radius"
          tabIndex={0}
          onClick={() =>
            this.props.onClickGame({
              code: `FISHING_${dataList.id}`,
              company: undefined,
              path: routes.games.path,
            })
          }
          key={`casino-${i}`}
        >
          <img
            className={`casino-icon`}
            src={dataList.image}
            alt={`${dataList.name}`}
          />
          {[0, 1, 2].includes(i) && (
            <div className="line-libbins-fishing">
              <img
                src={LogoThailandBet}
                className="inside-line-libbins"
                alt="TLB-LOGO"
              />
              <div className="inside-line-fire">
                <FireEffect burnCount={32} />
              </div>
            </div>
          )}
        </div>
      </div>
    )
    const mapIconList = map(ASSETS_FISHING_GAME_PRESENTERS, (dataList, i) =>
      !filterd
        ? subComponent(dataList, i)
        : [0, 1, 2].includes(i) && subComponent(dataList, i)
    )
    return (
      <div className="casino-game-container">
        <div className="row px-lg-1">{mapIconList}</div>
      </div>
    )
  }
  RenderHotGameMenu = () => {
    return (
      <>
        {this.RenderLotterMenu(true)}
        {this.RenderCasinoMenu(true)}
        {this.RenderSlotMenu(true)}
        {this.RenderFishingMenu(true)}
      </>
    )
  }

  RenderLogicComponent = () =>
    this.props.selectedMenu === 'สุดฮิต!' ? (
      this.RenderHotGameMenu()
    ) : this.props.selectedMenu === 'หวย' ? (
      this.RenderLotterMenu(false)
    ) : this.props.selectedMenu === 'คาสิโน' ? (
      this.RenderCasinoMenu(false)
    ) : this.props.selectedMenu === 'สล็อต' ? (
      this.RenderSlotMenu(false)
    ) : this.props.selectedMenu === 'ยิงปลา' ? (
      this.RenderFishingMenu(false)
    ) : (
      <></>
    )

  render() {
    const RenderLogicComponent = this.RenderLogicComponent
    return (
      <div className="game-menu-contanier">
        <div className="container py-4">
          <RenderLogicComponent />
        </div>
      </div>
    )
  }
}

export default GameMenuListComponent
