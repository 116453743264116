import Qr from 'assets/images/qa/qr.png'
import Block from 'assets/images/qa/block.svg'
import Accept from 'assets/images/qa/accept.svg'
import Security from 'assets/images/qa/security.svg'
import Download from 'assets/images/qa/download.svg'
import LoadJoker from 'assets/images/qa/loadJoker.svg'
import JokerLogin from 'assets/images/qa/jokerLogin.svg'
import loginTLBapp from 'assets/images/qa/loginTLBapp.png'

import TLB1 from 'assets/images/qa/TLB1.jpg'
import TLB2 from 'assets/images/qa/TLB2.jpg'
import TLB3 from 'assets/images/qa/TLB3.jpg'
import TLB4 from 'assets/images/qa/TLB4.jpg'
// import TLB5 from 'assets/images/qa/TLB5.jpg'
// import TLB6 from 'assets/images/qa/TLB6.jpg'
import project from 'constants/project'
const linkDownloadTLB = project.environmentName === 'develop' || project.environmentName === 'staging' ? 'https://genesis.tlbetmantra.com/api/v1/app/android/tinkywinky/beta' : 'https://genesis.tlbetmantra.com/api/v1/app/android/tinkywinky/production'

const qacontent: IQAContent[] = [
    {
        id: 1,
        type: 'privacy',
        title: 'ข้อตกลงการใช้งาน',
        content: `
  <div class="qa-content-container">
      <h3>1. ด้านการรักษาสิทธิ์</h3>
      <ul>
          <li>
              สงวนสิทธิ์สมาชิก ผู้ใช้งานระบบ ต้องมีอายุมากกว่า 22 ปีขึ้นไปเท่านั้น
          </li>
          <li>
              การตั้งรหัสผ่านประจำตัว ควรหลีกเลี่ยงการใช้ชื่อผู้ใช้งาน (username) และรหัสผ่าน (password) ที่เหมือนหรือคล้ายกับเว็บอื่นๆ หรือคล้ายรหัสผ่านในสื่อโซเชี่ยวต่างๆ และควรเปลี่ยนรหัสผ่านอย่างน้อย ทุกๆ 2-3 เดือนต่อครั้ง หากมีการเข้าสวมรอยเข้าใช้ยูสเซอร์เนมของผู้ที่ไม่ได้เป็นเจ้าของตัวจริง ตรวจสอบแล้วเกิดจากความประมาทของตัวสมาชิกเอง ทางบริษัทจะไม่รับผิดชอบทุกกรณี
          </li>
          <li>
              สงวนสิทธิ์สมาชิกที่ไม่ได้ล๊อคอินเข้าระบบภายในระยะเวลา 1 ปี (365 วัน) ยูสเซอร์เนมดังกล่าวจะถูกลบจากระบบโดยอัตโนมัติ
          </li>
          <li style="color:#dd3d45">
              แจ้งกฏกติกาการแทงหวยทุกชนิด หากพบการแทงที่ผิดปกติโดยมีเจตนาการปั่นยอดแทงเพื่อรับค่าแนะนำสมาชิก จะโดนยึดค่าแนะนำสมาชิกและเครดิตทั้งหมดทุกกรณีโดยไม่มีข้อยกเว้น
          </li>
      </ul>
      <h3>2. ด้านกฏ กติกา การออกผลรางวัล</h3>
      <ul>
          <li>
              หากมีปัจจัยที่ไม่ได้เกิดขึ้นเป็นประจำหรือที่ควรจะเป็น ส่งผลให้มีการเปลี่ยนแปลงผลรางวัล ยกตัวอย่างเช่น ระบบล่มในหวยยี่กี สมาชิกบางส่วนไม่สามารถเข้าระบบเพื่อยิงเลขได้ หรือตลาดหุ้นต่างประเทศมีการดีดผล เนื่องจากปัจจัยที่ไม่ควรจะเป็นเช่นทุกวัน ผลรางวัลดังกล่าว ทางบริษัทจะพิจารณาตามความเหมาะสม โดยให้คำตัดสินจากทางบริษัทเป็นคำตัดสินสุดท้าย โดยอาศัยหลักความยุติธรรมเป็นที่ตั้ง
          </li>
      </ul>
      <h3>3. ด้านการฝาก ถอนเครดิต</h3>
      <ul>
          <li>
              การเติมเครดิตขั้นต่ำ เริ่มต้นที่ 1 บาท การแจ้งฝากทุกธนาคารห้ามแจ้งฝากข้ามวันหลังจากโอนเงินเข้ามาแล้วให้สมาชิกแจ้งฝากเลยทุกครั้ง หากเกินจะถือเป็นโมฆะเพราะจะไม่สามารถตรวจสอบยอดเงินได้ และทางเราจะไม่รับผิดชอบหากเกิดปัญหา
          </li>
          <li>
          การแจ้งถอนสามารถถอนเครดิต ขั้นต่ำ 1 บาท แต่ไม่เกิน 100,000 บาท
          </li>
      </ul>
  </div>`,
    },
    {
        id: 2,
        type: 'yeege',
        title: 'กติกาการเล่น หวยยี่กี',
        content: `
        <div class="qa-content-container">
    <p>ออกผลรางวัลโดยการให้สมาชิกทุกๆท่าน ร่วมส่งตัวเลขจำนวน 5 หลัก จากนั้นทางระบบจะนำตัวเลขที่สมาชิกส่งเข้ามาทั้งหมดในรอบนั้นๆ มารวมกัน</p>
    <p>ผลรวม 3 ตัวท้าย จะถูกนำมาออกผลรางวัลเป็น เลข 3 ตัวบนของหวยยี่กีรอบนั้น ผลรวมหลักพัน และหลักหมื่น จะถูกนำมาออกผลรางวัลเป็น เลข 2 ตัวล่าง ของหวยยี่กีรอบนั้น</p>
    <p>หวยยี่กี ของ Thailand Bet เปิดรับแทงวันละ 88 รอบ จะออกผลรางวัลทุกๆ 15 นาที เปิดรอบแรกเวลา 06:00 น. จนถึง เวลา 03:45 น. หลังจากหวยยี่กีปิดรับแทงแต่ละรอบ ระบบยิงเลข จะเปิดให้ยิงอีก 2 นาที หลังจากระบบยิงเลขปิด อีก 1 นาที ระบบจะทำการตรวจโพย โดยใช้เวลาการจ่ายเงินประมาณ 1-3 นาที</p>
    <p>ตัวอย่าง เช่น ยี่กีรอบที่ 1 ระบบปิดรับแทงเวลา 06:00 น. ระบบยิ่งเลขจะสามารถยิงเลขได้จนถึงเวลา 06:15 น ต่อไประบบจะทำการตรวจโพยและจะจ่ายเงินสมาชิกทั้งหมดเสร็จภายในเวลาไม่เกิน 06:17 น.</p>
    <p>หมายเหตุ โปรดตั้งเวลาของอุปกรณ์เข้าเล่นของท่านให้ตรงกับเวลาในเว็บ เพื่อที่ท่านจะได้ไม่สับสนกับเวลาปิดรับ เวลาที่แสดงผลการส่งตัวเลขจะใช้เวลาจากเชิฟเวอร์จริง</p>
    <ul>
        <li>เปิดรับแทงวันละ 88 รอบ ออกผลรางวัลทุกๆ 15 นาที</li>
        <li>รอบแรกเริ่มเวลา 06:00 น. (เริ่มทายผลได้ตั้งแต่ 05:00 น. ทายผลล่วงหน้าได้ทุกรอบ)</li>
        <li>ปิดรับแทงรอบสุดท้าย เวลา 03:45 น.</li>
        <li>สามารถส่งตัวเลขได้ฟรี ไม่จำกัดจำนวนครั้ง ด้วยกติกาที่ชัดเจนและสามารถตรวจสอบได้</li>
        <li>เลขวิ่งบน รับทายผล 3 ตัวบนเหมือนหวยรัฐบาล</li>
        <li>หากเลขที่สมาชิกร่วมทายผลมีไม่ถึง 20 ลำดับ ไม่ว่าในกรณีใดก็ตาม ทางระบบจะทำการคืนโพยและคืนยอดเงินที่แทงเข้ามาในรอบนั้นทันที</li>
        <li><span style="text-decoration: underline; font-weight: bold; color: rgb(255, 0, 0);">สำคัญ!</span>หากระบบล่มหรือขัดข้องระหว่างก่อนปิดการร่วมส่งทายผลตัวเลขออกรางวัล จนเป็นสาเหตุทำให้สมาชิกไม่สามารถเข้าใช้งานระบบได้ ทางทีมงานจะทำการตัดสินการออกรางวัลตามความเหมาะสม การตัดสินขึ้นอยู่กับดุลพินิจของทีมงาน ทีมงานจะตัดสินด้วยความโปร่งใสและเป็นธรรมที่สุด <span style="text-decoration: underline;">ตัวอย่าง</span> เช่น ก่อนหมดเวลาร่วมส่งผลทายผลตัวเลขออกรางวัล 1 นาที ระบบเกิดปัญหาขัดข้องไม่สามารถเข้าใช้งานได้ ทำให้สมาชิกไม่สามารถเข้ามาร่วมทายผลตัวเลขได้ กรณีนี้ทางทีมงานจะทำการยกเลิกและคืนเงินเครดิตในรอบนั้นทันที ถึงแม้ว่าก่อนหน้านั้นจะมีสมาชิกยิงเลขเข้ามามากกว่า 20 ลำดับก็ตาม</li>
    </ul>
</div>
`,
    },
    {
        id: 3,
        type: 'affiliate',
        title: 'วิธีรับค่าแนะนำ',
        content: `
    <div class="qa-content-container">

        <p class="m1-b">
            ลิงก์ช่วยแชร์รับค่าแนะนำสูงสุด 8% ฟรี (แค่คัดลอกลิงก์ไปแชร์ก็ได้เงินแล้ว) ยิ่งแชร์มากยิ่งได้มาก
            <br> ท่านสามารถนำลิงก์ด้านล่างนี้หรือนำป้ายแบนเนอร์ ไปแชร์ในช่องทางต่างๆ ไม่ว่าจะเป็น เว็บไชต์ส่วนตัว, Blog, Facebook หรือ Social Network อื่นๆ หากมีการสมัครสมาชิกโดยคลิกผ่านลิงก์ของท่านเข้ามา ลูกค้าที่สมัครเข้ามาก็จะอยู่ภายใต้เครือข่ายของท่านทันที และหากลูกค้าภายใต้เครือข่ายของท่านมีการเดิมพันเข้ามา ทุกยอดการเดิมพันชนะ ท่านจะได้รับส่วนแบ่งในการแนะนำ 8% ทันทีโดยไม่มีเงื่อนไข
            <br>
        <p>สามารถทำรายได้เดือน 100,000 บาทง่ายๆเลยทีเดียว เพราะทางเรามีหวยเปิดรับทายผลทุกวัน มีมากกว่าวันละ 200 รอบ เปิดรับแทงออนไลน์ตลอด 24 ชม. และรายได้ทุกบาททุกสตางค์ของท่านสามารถตรวจสอบได้ทุกขั้นตอน งานนี้แจกจริง จ่ายจริง ที่นี้ที่เดียวที่ให้คุณมากกว่าใคร ก๊อปปี้ลิงก์และข้อความด้านล่างนี้ นำไปแชร์ได้เลย</p>
        <p style="color:#dd3d45">การป้องกันการทุจริตระบบแนะนำสมาชิกหวย </p>
        <p>หากลูกสมาชิกภายในระบบแนะนำสมาชิกของท่าน แทงหวยโดยตั้งใจขาดทุนเพื่อให้กำไรมาตกที่ค่าแนะนำสมาชิกของท่าน อัตราจ่ายส่วนแบ่งสูงสุด 8% จะถูกลดลงตามการ กระจายแทงตัวเลขของลูกสมาชิกของท่าน ถือเป็นข้อตกลงในการใช้งานระบบแนะนำสมาชิก</p>
        <ul>
            <li>
            ลูกค้ามีโอกาสได้ส่วนแบ่งสูงสุด 8% จนถึง 0.0016% ตามพฤติกรรมการแทงของลูกสมาชิก
            </li>
        </ul>
        <br>
    </div>
    `,
    },

    {
        id: 4,
        type: 'game',
        title: 'วิธีการใช้งาน Joker App',
        content: `
        <div class="qa-content-container">

        <p class="m1-b">
            สามารถดาวน์โหลดโจ๊กเกอร์เกมสล็อต เพื่อเล่นออนไลน์บนมือถือได้แล้ว ด้วยขั้นตอนง่ายๆ ดังนี้
            <br>
            <u>การดาวน์โหลดและติดตั้ง</u>
            <br>
            ท่านสามารถดาวน์โหลด Joker App ได้ที่นี่ <a href="https://dl.yutongstone.com/joker/lobby/live/android/lobby-android.1.2.39.apk"><u style="color:#dd3d45">คลิก JokerApp</u></a> หรือ แสกน QR Code
            <img src=${Qr} style="display:block; marginRight:auto; marginLeft:auto">
            <div style="text-align: center">เฉพาะแอนดรอยด์เท่านั้น</div>
            <br>
            <u>ขั้นตอนที่: 1</u>
            <br>
            เมื่อดาวน์โหลดเสร็จแล้ว ให้ทำการติดตั้งแอป โดยการกดเลือกเปิดไฟล์ที่ดาวน์โหลดมาชื่อว่า lobby-android.1.2.39.apk
            <br>
            <img src=${Download} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:40%; min-width: 40vh;">
            <br>
            <u>ขั้นตอนที่: 1.1</u>
            <br>
            หากการติดตั้ง ถูก “ปิดกั้นการติดตั้งไว้” ให้กดกเลือก “การตั้งค่า” <span style="color:#dd3d45">ตรงกรอบสี่เหลี่ยมสีแดง</span>
            <img src=${Block} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:35%; min-width: 30vh;">
            <br>
            และกดเปิด “ไม่รู้จักที่มา”  <span style="color:#dd3d45">ตามกรอบสี่เหลี่ยมสีแดง</span> ให้เป็นสีเขียว
            <img src=${Security} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:35%; min-width: 30vh;">
            <br>
            และทำการยืนยันการเปิด การติดตั้งไม่รู้จักที่มา โดยกดปุ่ม “ตกลง”
            <br>
            <img src=${Accept} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:35%; min-width: 30vh;">
            <br>
            <u>ขั้นตอนที่: 2</u>
            <br>
            จากหน้ารายการดาวน์โหลด ทำการติดตั้งแอปโดยเลือกปุ่ม “ติดตั้ง” <span style="color:#dd3d45">ตามกรอบสี่เหลี่ยมสีแดง</span> เพื่อติดตั้ง
            <br>
            <img src=${LoadJoker} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:35%; min-width: 30vh;">
            <br>
            <u>การเข้าสู่ระบบ Joker App</u>
            <br>
            เมื่อทำการติดตั้งเสร็จแล้ว และต้องการเข้าสู่ระบบ โดยใช้ชื่อผู้ใช้จาก Thailandbet จะต้องใส่ตัวอักษร “f3uc.” นำหน้า <u>ชื่อผู้ใช้</u>
            <br>
            <br>
            <span style="color:#3B4FE8">ตัวอย่างเช่น </span>
            <br>
            ชื่อผู้ใช้และรหัสผ่านของ Thailandbet
            <br>
            ชื่อผู้ใช้: tlb998877
            <br>
            รหัสผ่าน: 123456
            <br>
            <br>
            ชื่อผู้ใช้ Joker App คือ
            <br>
            ชื่อผู้ใช้: f3uc.tlb998877
            <br>
            รหัสผ่าน: 123456
            <br>
            <img src=${JokerLogin} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:50%; min-width: 45vh;">
        <br>
    </div>
    `,
    },

    {
        id: 5,
        type: 'downloadTLB',
        title: 'วิธีการใช้งาน TLB App (android)',
        content: `
        <div class="qa-content-container">

        <p class="m1-b">
            สามารถดาวน์โหลดแอปพลิเคชัน TLB เพื่อเล่นออนไลน์บนมือถือได้แล้ว ด้วยขั้นตอนง่ายๆ ดังนี้
            <br>
            <u>การดาวน์โหลดและติดตั้ง</u>
            <br>
            ท่านสามารถดาวน์โหลด TLB App ได้ที่นี่ <a href=${linkDownloadTLB}><u style="color:#dd3d45">คลิก TLB App</u></a>
            <br>
            <u>ขั้นตอนที่: 1</u>
            <br>
            เมื่อดาวน์โหลดเสร็จแล้ว ให้ทำการติดตั้งแอป โดยการกดเลือกเปิดไฟล์ที่ดาวน์โหลดมาชื่อว่า tlb-v1001.apk
            <br>
            <img src=${TLB1} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:40%; min-width: 40vh;">
            <br>
            <u>ขั้นตอนที่: 1.1</u>
            <br>
            หากการติดตั้ง ถูก “ปิดกั้นการติดตั้งไว้” ให้กดเลือก “การตั้งค่า” <span style="color:#dd3d45">ตรงกรอบสี่เหลี่ยมสีแดง</span>
            <img src=${TLB2} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:35%; min-width: 30vh;">
            <br>
            และกดเปิด “ไม่รู้จักที่มา” หรือ “อนุญาตจากแหล่งที่มานี้” <span style="color:#dd3d45">ตามกรอบสี่เหลี่ยมสีแดง</span>
            <img src=${TLB3} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:35%; min-width: 30vh;">
            <br>
            หากมีการให้ทำการยืนยันการเปิด การติดตั้งไม่รู้จักที่มา กดปุ่ม “ตกลง”
            <br>
            <u>ขั้นตอนที่: 2</u>
            <br>
            จากหน้ารายการดาวน์โหลด ทำการติดตั้งแอปโดยเลือกปุ่ม “ติดตั้ง” <span style="color:#dd3d45">ตามกรอบสี่เหลี่ยมสีแดง</span> เพื่อติดตั้ง
            <br>
            <img src=${TLB4} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:35%; min-width: 30vh;">
            <br>
            <u>การเข้าสู่ระบบ TLB App</u>
            <br>
            เมื่อทำการติดตั้งเสร็จแล้ว และต้องการเข้าสู่ระบบ สามารถใช้ชื่อผู้ใช้จาก Thailandbet ได้เลย
            <br>
            <br>
            <span style="color:#3B4FE8">ตัวอย่างเช่น </span>
            <br>
            ชื่อผู้ใช้และรหัสผ่านของ Thailandbet
            <br>
            ชื่อผู้ใช้: tlb998877
            <br>
            รหัสผ่าน: 123456
            <br>
            
            <img src=${loginTLBapp} style="marginTop:10px; display:block; marginRight:auto; marginLeft:auto; width:30%; min-width: 30vh;">
        <br>
    </div>
    `,
    },
]

export default qacontent