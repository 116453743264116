import React, { FC } from 'react'
import { noop } from 'lodash'
import { ResponsiveIcon, Button } from 'components'
import LogoTlbIcon from 'assets/images/global/notification/logo-tlb.svg'
import { Modal } from '../../'
import './infoNotification.style.scss'
import colors from 'constants/colors'
const constants = {
  title: 'ประกาศ',
  description: '',
  actionText: 'เข้าใจ',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IInfoNotificationModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() { noop() },
}

const InfoNotificationModal: FC<IInfoNotificationModal & DefaultProps> = (props) => {

  return (
    <div className="info-notification-modal-container border-rounded">
      <ResponsiveIcon
        alt="info-notification-icon"
        className="info-notification-checked-icon"
        icon={LogoTlbIcon}
      />
      <h2 className="title m2-t">{props.title}</h2>
      <p className="body-1">{props.description}</p>
      <div className="m3-y">
        <Button
          id="info-notification-ok-button"
          backgroundColor={colors.TERTIARY_GREEN}
          backgroundHoverColor={colors.TERTIARY_GREEN_HOVER}
          type="rectangle"
          onClick={props.action}
          text={props.actionText!}
        />
      </div>
    </div>
  )
}

InfoNotificationModal.defaultProps = defaultProps

const infoNotificationModal = {
  show: (props: IInfoNotificationModal, size: string = 'md') => {
    return Modal.event('MODAL_OVER', {
      state: 'show',
      extraProps: { size: size },
      component: (<InfoNotificationModal {...props} />),
    })
  },
  hide: () => Modal.event('MODAL_OVER', { state: 'hide' }),
}

export default infoNotificationModal