import React, { Component, createRef, RefObject } from 'react'
import { noop, trim } from 'lodash'
import { Formik, FormikProps } from 'formik'
import { RouteComponentProps } from 'react-router-dom'
import { responseCode } from 'constants/response'
import { ErrorModal, ButtonIcon, Banner } from 'components'
import { LoginForm, LottoList } from './components'
import initialValues from './models/initialValues'
import scheme from './models/scheme'
import LogoThailandBet from 'assets/images/logo/logothailandbet03.png'
import './home.style.scss'
import routes from 'constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import lotto from 'assets/images/banner/banner-4.3-lotto.png'
import aesexy from 'assets/images/banner/banner-4.3-ae.png'
import w88 from 'assets/images/banner/banner-16.9-w88.png'
import dreamgame from 'assets/images/banner/banner-4.3-dg.png'
import wm from 'assets/images/banner/banner-16.9-wm.png'
import dreamtech from 'assets/images/banner/banner-4.3-dreamtech.png'
import pg from 'assets/images/banner/banner-4.3-pg.png'
import allbet from 'assets/images/banner/banner-4.3-allbet.png'
import joker from 'assets/images/banner/banner-4.3-joker.png'
import affilate from 'assets/images/banner/banner-4.3-aff.png'
import sexybaccarat from 'assets/images/banner/banner-4.3-sexybar.png'
import yeeke from 'assets/images/banner/banner-4.3-yeeke.png'
import { interactive } from 'utils'

const constants = {
  ok: 'ตกลง',
  userNotFound: 'ไม่พบชื่อผู้ใช้ในระบบ',
  passwordNotMatch: 'ชื่อผู้ใช้หรือรหัสผ่านผิด',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IHomeProps & IHomeActionProps = {
  getLottoList() {
    noop()
  },
  lottoList: [],
  getLottoCode: 0,
  getLottoError: '',
  getLottoIsFetching: false,
  login() {
    noop()
  },
  loginResult: [],
  loginCode: 0,
  loginError: '',
  loginIsFetching: false,
  loader() {
    noop()
  },
  remember: {
    password: '',
    username: '',
    remember: false,
  },
}

class HomeContainer extends Component<
  IHomeProps & IHomeActionProps & DefaultProps & RouteComponentProps,
  { formState: ILogin }
> {
  static defaultProps = defaultProps
  homeContainerRef: RefObject<HTMLDivElement> = createRef()

  state: { formState: ILogin } = {
    formState: initialValues,
  }

  componentDidMount() {
    this.props.loader(false)
    this.props.getLottoList()
    setTimeout(() => {
      interactive.scrollToTop(this.homeContainerRef)
    }, 128)
    this.setState({ formState: this.props.remember })
  }

  componentDidUpdate(prevProps: IHomeProps) {
    if (
      prevProps.loginIsFetching !== this.props.loginIsFetching &&
      !this.props.loginIsFetching
    ) {
      this.props.loader(false)
      if (this.props.loginCode === responseCode.OK) {
        this.props.history.replace('/main')
      } else {
        let errorMessage = ''
        if (this.props.loginCode === responseCode.NOT_FOUND) {
          errorMessage = constants.userNotFound
        } else if (this.props.loginCode === responseCode.BAD_REQUEST) {
          errorMessage = constants.passwordNotMatch
        } else {
          errorMessage = this.props.loginError
        }
        ErrorModal.show({
          action: ErrorModal.hide,
          description: errorMessage,
          actionText: constants.ok,
        })
      }
    }
  }

  onSubmitLogin = (values: ILogin) => {
    this.props.loader(true)
    const loginObject: ILogin = {
      username: trim(values.username),
      password: trim(values.password),
      remember: values.remember,
    }
    this.props.login(loginObject)
  }

  onNavigateToRegister = () => {
    this.props.history.push(routes.register.path)
  }

  onNavigateToForgotPassword = () => {
    this.props.history.push(routes.forgotPassword.path)
  }

  handleScrollToTop = () => {
    if (this.homeContainerRef.current) {
      this.homeContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      })
    }
  }

  renderLoginForm = () => {
    const LoginFormComponent = (formProps: FormikProps<ILogin>) => {
      return (
        <LoginForm
          onNavigateToRegister={this.onNavigateToRegister}
          onNavigateToForgotPassword={this.onNavigateToForgotPassword}
          {...formProps}
        />
      )
    }
    return (
      <Formik
        initialValues={this.state.formState}
        validationSchema={scheme}
        enableReinitialize
        onSubmit={this.onSubmitLogin}
      >
        {LoginFormComponent}
      </Formik>
    )
  }

  renderLottoList = () => {
    return <LottoList data={this.props.lottoList} />
  }

  render() {
    const RenderLoginFormComponent = this.renderLoginForm
    const RenderLottoListComponent = this.renderLottoList
    return (
      <div className="home-container secondary-bg-navy" ref={this.homeContainerRef}>
        <div className="login-container secondary-bg-navy">
          <div className="container">
            <div className="d-flex flex-column justify-content-center align-items-center m3-b">
              <img
                alt="thailand bet logo"
                src={LogoThailandBet}
                className="login-logo m3-b"
              />
            </div>
            <RenderLoginFormComponent />
          </div>
        </div>
        <div className="pb-4 secondary-bg-navy">
          <div className="container">
            <Banner
              ImageIcon={[
                lotto,
                aesexy,
                w88,
                dreamgame,
                wm,
                dreamtech,
                pg,
                allbet,
                joker,
                affilate,
                sexybaccarat,
                yeeke,
              ]}
              size="small"
            />
          </div>
        </div>
        <div className="pb-4 secondary-bg-navy">
          <div className="container">
            <RenderLottoListComponent />
          </div>
        </div>
        <div className="scroll-to-top-wrapper">
          <ButtonIcon
            onClick={this.handleScrollToTop}
            CustomIcon={<FontAwesomeIcon icon={faArrowUp} className="primary-text" />}
            type="custom"
            id="scroll-to-top"
          />
        </div>
      </div>
    )
  }
}

export default HomeContainer
