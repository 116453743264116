import React, { Component, createRef, RefObject } from 'react'
import { noop, isEmpty, get } from 'lodash'
import { FormikProps, Formik } from 'formik'
import { RouteComponentProps } from 'react-router-dom'
import { responseCode } from 'constants/response'
import { ErrorModal, SuccessModal, InfoModal } from 'components'
import { date, interactive } from 'utils'
import { DepositStep1, DepositStep2 } from './components'
import initialValues from './models/initialValues'
import scheme from './models/scheme'
import './deposit.style.scss'

const constants = {
  ok: 'ตกลง',
  gotoMain: 'รายการฝาก - ถอน',
  requestedSuccess: 'กรุณารอการตรวจสอบสักครู่',
  pleaseTryAgain: 'โปรดลองใหม่อีกครั้ง',
  pleaseProcessInTime: 'หมดเวลาทำรายการ',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IDepositProps & IDepositActionProps = {
  user: {},
  loader() { noop() },
  getBankList() { noop() },
  bankList: [],
  getBankListCode: 0,
  getBankListError: '',
  getBankListIsFetching: false,
  depositRequest() { noop() },
  depositRequestResult: {},
  depositRequestCode: 0,
  depositRequestError: '',
  depositRequestIsFetching: false,
  getTransactionRequest() { noop() },
  signTransactionRequest() { noop() },
  transactionRequest: {},
  transactionRequestCode: 0,
  transactionRequestError: '',
  transactionRequestIsFetching: false,
  cancelingTransactionRequest() { noop() },
  transactionCancel: {},
  transactionCancelCode: 0,
  transactionCancelError: '',
  transactionCancelIsFetching: false,
  clearClickRef() {noop()}
}

class DepositContainer extends
  Component<IDepositProps & IDepositActionProps & DefaultProps & RouteComponentProps, IDepositStates> {

  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  state: IDepositStates = {
    currentStep: 1,
    initialFormValue: initialValues,
  }

  componentDidMount() {
    interactive.rootElement.scrollToTop()
    this.props.getBankList({operateType: 'DEPOSIT'})
    this.props.getTransactionRequest()
    this.props.loader(true)
    this.props.clearClickRef()
  }

  componentDidUpdate(prevProps: IDepositProps) {
    if (this.state.currentStep===1) {
      this.props.getBankList({operateType: 'DEPOSIT'})
    }
    if (prevProps.depositRequestIsFetching !== this.props.depositRequestIsFetching
      && !this.props.depositRequestIsFetching) {
      if (this.props.depositRequestCode === responseCode.OK) {
        SuccessModal.show({
          action: () => {
            this.props.history.replace('/transaction')
            SuccessModal.hide()
          },
          actionText: constants.gotoMain,
          description: constants.requestedSuccess,
        })
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.depositRequestError,
          actionText: constants.ok,
        })
      }
      this.props.loader(false)
    }

    // Request
    if (prevProps.transactionRequestIsFetching !== this.props.transactionRequestIsFetching
      && !this.props.transactionRequestIsFetching) {
      this.props.loader(false)
      if (this.props.transactionRequestCode === responseCode.OK) {
        if (!isEmpty(this.props.transactionRequest)) {
          const { money, webBank } = this.props.transactionRequest
          this.setState({
            currentStep: 2,
            initialFormValue: {
              ...this.state.initialFormValue,
              money: String(money),
              webBankId: webBank?.id || 0,
            },
          })
        }
      } else if (this.props.transactionRequestCode === responseCode.REQUEST_TIMEOUT) {
        // TODO: when before transaction timeout
      } else if (this.props.transactionRequestCode === responseCode.UNDEFINED) {
        // TODO: when before transaction undefined
      } else if (this.props.transactionRequestCode === responseCode.NOT_FOUND) {
        // TODO: when never transaction request before
      } else {
        ErrorModal.show({
          action: () => { ErrorModal.hide(); return this.props.history.goBack(); },
          description: `${this.props.transactionRequestError} ${constants.pleaseTryAgain}`,
          actionText: constants.ok,
        })
      }
    }

    // Cancel
    if (prevProps.transactionCancelIsFetching !== this.props.transactionCancelIsFetching
      && !this.props.transactionCancelIsFetching) {
      this.props.loader(false)
      if (this.props.transactionCancelCode === responseCode.OK) {
        const webBankId = get(this.props.transactionRequest, 'webBank.id', 0)
        this.setState({
          currentStep: 1,
          initialFormValue: {
            ...this.state.initialFormValue,
            webBankId,
            money: '',
          },
        })
      } else {
        ErrorModal.show({
          action: () => { ErrorModal.hide(); return this.props.history.goBack(); },
          description: `${this.props.transactionRequestError} ${constants.pleaseTryAgain}`,
          actionText: constants.ok,
        })
      }
    }

    if (prevProps.getBankListIsFetching !== this.props.getBankListIsFetching
      && !this.props.getBankListIsFetching) {
      if (this.props.getBankListCode === responseCode.OK) {
        if (this.props.bankList.length > 0) {
            const webBankId = get(this.props.bankList, '0.id', 0)
            this.setState({
              initialFormValue: { ...this.state.initialFormValue, webBankId },
            })
        }
      }
      else{
        ErrorModal.show({
          action: () => { ErrorModal.hide(); return this.props.history.goBack(); },
          description: `${this.props.getBankListError}`,
          actionText: constants.ok,
        })
      }
    }
  }
  onSubmitDeposit = (values: IDepositForm) => {
    const castedValue = scheme.cast(values)
    const depositRequestValues: IDepositRequest = {
      money: Number(values.money).toFixed(2).toString(),
      depositTime: date.convertTimeToMoment(castedValue.depositHours, castedValue.depositMinutes).toISOString(),
      description: castedValue.description || '-',
      webBankId: castedValue.webBankId,
    }
    this.props.loader(true)
    this.props.depositRequest(depositRequestValues)
  }

  onNextStepHandler = (values: IDepositForm) => {
    const castedValue = scheme.cast(values)
    this.props.loader(true)
    this.props.signTransactionRequest({
      webBankId: castedValue.webBankId,
      money: castedValue.money,
    })
  }

  onBackStepHandler = (step: number) => {
    if (step === 2) {
      return this.setState({ currentStep: 1 })
    }
    return this.props.history.goBack()
  }

  onCancelHandler = () => {
    const transactionRequestId = get(this.props.transactionRequest, 'id', 0)
    this.props.loader(true)
    this.props.cancelingTransactionRequest(transactionRequestId)
    setTimeout(() => {
      interactive.rootElement.scrollToTop()
      }, 256);
  }

  onTimesupRequest = (callback: () => void) => {
    InfoModal.show({
      action: () => { ErrorModal.hide(); callback(); },
      title: constants.pleaseProcessInTime,
      description: constants.pleaseTryAgain,
      actionText: constants.ok,
    })
  }

  renderDepositForm = () => {
    const DepositFormComponent = (formProps: FormikProps<IDepositForm>) => {
      if (this.state.currentStep === 1) {
        const { bankList, user } = this.props
        const userBank: IBank = get(user, 'bank', {})
        return (
          <DepositStep1
            {...formProps}
            extraProps={{ banks: bankList, userBank }}
            onBackStep={this.onBackStepHandler}
            onConfirmPresses={this.onNextStepHandler}
          />
        )
      } else if (this.state.currentStep === 2) {
        return (
          <DepositStep2
            {...formProps}
            extraProps={{ requestedTransaction: this.props.transactionRequest, onTimesup: this.onTimesupRequest }}
            onBackStep={this.onBackStepHandler}
            onCancelPresses={this.onCancelHandler}
          />
        )
      }
      return (<DepositStep1 {...formProps} />)
    }

    return (
      <Formik
        initialValues={this.state.initialFormValue}
        validationSchema={scheme}
        enableReinitialize
        onSubmit={this.onSubmitDeposit}
      >
        {DepositFormComponent}
      </Formik>
    )
  }

  render() {
    const DepositFormComponent = this.renderDepositForm
    return (
      <div className="deposit-container secondary-bg-navy" ref={this.pageContainerRef}>
        <div className="container">
          <DepositFormComponent />
        </div>
      </div>
    )
  }
}

export default DepositContainer