import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const getBetHistoryReducer = (
  state: ILottoHistoryListState = initialState,
  action: RootAction
): ILottoHistoryListState => {
  switch (action.type) {
    case getType(actions.getBetHistoryAction):
      return {
        ...state,
        isFetching: true,
      }
      case getType(actions.getBetHistorySuccessAction):
      const data = (action.payload.isFirst)
      ? action.payload.data.data.dataList
      : [...(state.data || []), ...(action.payload.data.data.dataList || [] || null)]
      return {
        isFetching: false,
        data,
        code: action.payload.status,
        pagination: action.payload.data.data,
      }

    case getType(actions.getBetHistoryFailureAction):
      return {
        isFetching: false,
        error: action.payload.response?.data.devMessage,
        code: action.payload.response?.data.code,
      }
    case getType(actions.clearLottoHistoryList):
      return initialState
    default:
      return state
  }
}

export default getBetHistoryReducer