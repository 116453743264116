import React, { Component, createRef, RefObject } from 'react'
import { ALink, ErrorModal } from 'components'
import { noop, map } from 'lodash'
import { RouteComponentProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import colors from 'constants/colors'
import { responseCode } from 'constants/response'
import './newsroom.style.scss'
import { date, interactive } from 'utils'

const constants = {
  back: 'ย้อนกลับ',
  newsroom: 'ข่าวสาร',
}

const defaultProps: INewsroomProps & INewsroomActionProps = {
  getNews() { noop() },
  loader() { noop() },
  getNewsIsFetching: false,
  getNewsCode: 0,
  getNewsError: '',
  news: [],
}

class NewsroomContainer extends Component<
  INewsroomProps
  & INewsroomActionProps
  & RouteComponentProps
  > {

  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    interactive.rootElement.scrollToTop()
    this.props.loader(true)
    this.props.getNews()
  }

  componentDidUpdate(prevProps: INewsroomProps) {
    if (prevProps.getNewsIsFetching !== this.props.getNewsIsFetching
      && !this.props.getNewsIsFetching) {
      this.props.loader(false)
      if (this.props.getNewsCode !== responseCode.OK
        && this.props.getNewsCode !== responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: () => ErrorModal.hide,
          description: this.props.getNewsError,
        })
      }
    }
  }

  onPressBack = () => {
    this.props.history.goBack()
  }

  renderNewsList = () => {
    const NewsComponent = map(this.props.news, (news, newsIndex) => {
      const updatedTime = date.calibratingTime(news.updatedAt).format('LLL')
      return (
        <div className="border-rounded tertiary-bg-navy p2 m3-t" key={`news-${newsIndex}`}>
          <h4 className="secondary-text-gold text-break">{news.title}</h4>
          <h6 className="subtitle-2 secondary-text m1-t">{updatedTime}</h6>
          <div className="separator-line m2-t" />
          <p className="body-1 text-break">{news.description}</p>
        </div>
      )
    })

    return <>{NewsComponent}</>
  }

  render() {
    const NewsComponent = this.renderNewsList

    return (
      <div className="newsroom-container secondary-bg-navy" ref={this.pageContainerRef}>
        <div className="container">
          <div className="row">
            <div className="col">
              <ALink id="backto-previus-page" color={colors.PRIMARY_RED} bold onClick={this.onPressBack}>
                <FontAwesomeIcon icon={faChevronLeft} className="m1-r" />
                {constants.back}
              </ALink>
            </div>
          </div>
          <div className="row m4-t">
            <div className="col">
              <h2>{constants.newsroom}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <NewsComponent />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewsroomContainer