import { Epic } from "redux-observable";
import { catchError, exhaustMap, takeUntil, filter, map } from "rxjs/operators";
import { RootAction } from "typings/reduxs/Actions";
import actions from "./actions";
import { isActionOf } from "typesafe-actions";
import { fetchGetNotificationUserCount } from "./services";
import { from, of } from "rxjs";
import { get } from "lodash";

const getNotificationUserCountEpic: Epic<
  RootAction,
  RootAction,
  RootReducers
> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.getNotificationUserCountAction)),
    exhaustMap((_) =>
      from(fetchGetNotificationUserCount()).pipe(
        map(actions.getNotificationUserCountSuccessAction),
        catchError((error) =>
          of(actions.getNotificationUserCountFailureAction(error))
        ),
        takeUntil(
          action$.pipe(
            filter(isActionOf(actions.getNotificationUserCountCancelAction))
          )
        )
      )
    )
  );

const updateNotificationUserCountEpic: Epic<
  RootAction,
  RootAction,
  RootReducers
> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.updateNotificationUserCountAction)),
    map((action) =>
      actions.updateNotificationUserCountSuccessAction(
        get(action, "payload", {
          unreadCount: 0
        })
      )
    )
  );
// eslint-disable-next-line import/no-anonymous-default-export
export default [getNotificationUserCountEpic, updateNotificationUserCountEpic];
