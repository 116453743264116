import { get } from 'lodash'
import project from 'constants/project'
import { initialBetRateState, initialBetNumberRateState } from './bet/rate/constants'
import { initialState as initialYeegeGameHistoryState } from './yeege/gameHistory/constants'
import { initialState as initialLottoListState } from './list/constants'
import { initialState as initialLottoHistoryState } from './me/list/constants'
import { initialState as initialBetLotteryPullListState } from './oldFavorite/list/constants'
import { initialState as initialBetLotteryPullDetailState } from './oldFavorite/detail/constants'
import { initialState as initialBetLotteryPullSaveState } from './oldFavorite/save/constants'

const betRates = (state: RootReducers): ReducerState<IBetRate[]> =>
    get(state, `${project.name}.lotto.bet.rate.rate`, initialBetRateState)
const betNumberRate = (state: RootReducers): ReducerState<(IBetNumberRateRequest & { rate: string })[]> =>
    get(state, `${project.name}.lotto.bet.rate.number`, initialBetNumberRateState)
const yeegeGameHistory = (state: RootReducers): ReducerState<IYeegeGameHistory[]> =>
    get(state, `${project.name}.lotto.yeege.gameHistory`, initialYeegeGameHistoryState)
const lottoResultList = (state: RootReducers): ReducerState<ILotto[]> =>
    get(state, `${project.name}.lotto.list`, initialLottoListState)
const lottoHistoryList = (state: RootReducers): ILottoHistoryListState =>
    get(state, `${project.name}.lotto.me.list`, initialLottoHistoryState)
    const betLottoPullList = (state: RootReducers): IOldFavoriteLottoState =>
    get(state, `${project.name}.lotto.oldFavorite.list`, initialBetLotteryPullListState)
const betLottoPullDetail = (state: RootReducers): IBetLotteryPullDetailState =>
    get(state, `${project.name}.lotto.oldFavorite.detail`, initialBetLotteryPullDetailState)
const betLottoPullSave = (state: RootReducers): IBetLotteryPullSaveState =>
    get(state, `${project.name}.lotto.oldFavorite.save`, initialBetLotteryPullSaveState)

// eslint-disable-next-line import/no-anonymous-default-export    
export default {
    betRates,
    betNumberRate,
    yeegeGameHistory,
    lottoResultList,
    lottoHistoryList,
    betLottoPullList,
    betLottoPullDetail,
    betLottoPullSave,
}