import React, { FC } from "react";
import { Badge } from "components";
import { includes, split, groupBy, map, isEmpty, get } from "lodash";
import colors from "constants/colors";
import {
  LOTTO_GAME_TYPE_NAME,
  LOTTO_TYPE,
  LOTTO_FLAG_ALPHA,
} from "constants/variables";
import LottoFlags from "assets/images/global/flags";
import "./lottoResultCard.style.scss";
import { date } from "utils";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ILottoResultCard = {
  lotto: {
    code: "GOVN",
    status: "OPEN",
    createdAt: "",
    lotto: [],
  },
};

const constants = {
  lotto: "หวย",
  noResult: "ไม่ออกผล",
};

const LottoResultCard: FC<ILottoResultCard & DefaultProps> = (props) => {
  const { lotto } = props;

  const { code, createdAt, status, lotto: lottos } = lotto;

  const normalizationCode: TLottoType = includes(code, "YEGEE")
    ? "YEGEE"
    : code;
  const dateDisplay = date
    .calibratingTime(createdAt)
    .add("years", 543)
    .format("Do MMM YY HH:mm");

  const groupLottoTypes: { [type in TLottoGameType]: ILottoResult[] } =
    groupBy<ILottoResult>(lottos, "type") as {
      [type in TLottoGameType]: ILottoResult[];
    };

  const NumberComponent = ({
    lottoNumber,
    type,
    gameType,
  }: {
    lottoNumber: ILottoResult[];
    type: TLottoType;
    gameType: TLottoGameType;
  }) => {
    if (isEmpty(lottoNumber) || typeof lottoNumber === "undefined") {
      return <></>;
    }
    return (
      <>
        <div className="row pt-1">
          <div className="col text-center">
            <h5
              className="secondary-text-gold"
              id={`lotto-name-${type}-${gameType}`}
            >
              {LOTTO_GAME_TYPE_NAME[gameType]}
            </h5>
          </div>
        </div>
        <div className="row m1-t">
          <div className="col text-center">
            {map(
              lottoNumber,
              (numberResult: ILottoResult, resultIndex: number) => (
                <h1
                  className="lotto primary-green-text"
                  key={`lotto-number-${type}-${gameType}-${resultIndex}`}
                  id={`lotto-number-${type}-${gameType}-${resultIndex}`}
                >
                  {numberResult.numbers}
                </h1>
              )
            )}
          </div>
        </div>
      </>
    );
  };

  const LottoNumbersFormat = () => {
    switch (normalizationCode) {
      case "GOVN":
      case "GSB":
      case "BAAC":
      case "TH_SHARE_MORNING":
      case "TH_SHARE_MIDDAY":
      case "TH_SHARE_AFTERNOON":
      case "TH_SHARE_EVENING":
      case "NAT_SHARE_DOWNJON":
      case "NAT_SHARE_EGYPT":
      case "NAT_SHARE_GERMANY":
      case "NAT_SHARE_NIKAII_MORNING":
      case "NAT_SHARE_NIKAII_AFTERNOON":
      case "NAT_SHARE_CHINA_MORNING":
      case "NAT_SHARE_CHINA_AFTERNOON":
      case "NAT_SHARE_TAIWAN":
      case "NAT_SHARE_KOREA":
      case "NAT_SHARE_SINGAPORE":
      case "NAT_SHARE_INDIA":
      case "NAT_SHARE_HANOI_SPECIAL":
      case "NAT_SHARE_MALAY":
      case "NAT_SHARE_VIETNAM_HANOI":
      case "NAT_SHARE_VIETNAM_HANOI_VIP":
      case "NAT_SHARE_HANOI_4D":
      case "NAT_SHARE_RUSSIA":
      case "NAT_SHARE_ENGLISH":
      case "NAT_SHARE_HUNGSENG_MORNING":
      case "NAT_SHARE_HUNGSENG_AFTERNOON":
      case "NAT_SHARE_LAO":
      case "YEGEE":
      case "LAO_HD":
      case "HANOI_TV":
      case "LAO_STAR":
      case "HANOI_RED_CROSS":
      case "HANOI_SAMAKKEE":
      case "HANOI_PHATTANA":
      case "LAO_SAMAKKEE":
      case "LAO_ASEAN":
      case "LAO_STAR_VIP":
      case "ENGLISH_VIP":
      case "GERMANY_VIP":
      case "LAO_RED_CROSS":
      case "RUSSIA_VIP":
      case "DOWNJON_VIP":
      case "HANOI_ASEAN":
      case "LAO_TV":
      case "LAO_VIP":
      case "HANOI_HD":
      case "HANOI_STAR":
      case "MALAY":
      case "HANOI_EXTRA":
      case "NAT_SHARE_HUNGSENG_MORNING_VIP":
      case "NAT_SHARE_HUNGSENG_AFTERNOON_VIP":
      case "NAT_SHARE_CHINA_MORNING_VIP":
      case "NAT_SHARE_CHINA_AFTERNOON_VIP":
      case "NAT_SHARE_SINGAPORE_VIP":
      case "NAT_SHARE_KOREA_VIP":
      case "NAT_SHARE_TAIWAN_VIP":
      case "NAT_SHARE_NIKAII_MORNING_VIP":
      case "NAT_SHARE_NIKAII_AFTERNOON_VIP":
      case "HANOI_CHINESE_NEW_YEAR":
      case "NAT_SHARE_DOWNJON_STAR":
        return (
          <div className="row">
            <div className="col">
              <NumberComponent
                type={normalizationCode}
                lottoNumber={groupLottoTypes.THREE_UP}
                gameType="THREE_UP"
              />
            </div>
            <div className="col">
              <NumberComponent
                type={normalizationCode}
                lottoNumber={groupLottoTypes.TWO_DOWN}
                gameType="TWO_DOWN"
              />
            </div>
          </div>
        );
      case "LAO_SUITE":
        return (
          <div className="row">
            <div className="col">
              <NumberComponent
                type={normalizationCode}
                lottoNumber={groupLottoTypes.FOUR_SUITE}
                gameType="FOUR_SUITE"
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const FlagIcon = get(
    LottoFlags,
    `${get(LOTTO_FLAG_ALPHA, normalizationCode, "")}.Icon`,
    ""
  );
  const gameName =
    normalizationCode === "YEGEE"
      ? `${LOTTO_TYPE[normalizationCode]}รอบ`
      : LOTTO_TYPE[normalizationCode];
  const gameRound =
    normalizationCode === "YEGEE" ? Number(split(code, "_")[1]) : "";

  const RenderBadge = () =>
    status === "OPEN" ? (
      <Badge
        text={`งวด ${dateDisplay}`}
        backgroundColor={colors.PRIMARY_NAVY}
        color={colors.SECONDARY_GOLD_TEXT}
        fontSize={"subtitle-2r"}
      />
    ) : (
      <Badge
        text={constants.noResult}
        backgroundColor={colors.PRIMARY_NAVY}
        color={colors.SECONDARY_GOLD_TEXT}
        fontSize={"subtitle-2r"}
      />
    );

  return (
    <div className="lotto-card-container secondary-blue-button">
      <div className="row">
        <div className="col text-left d-flex flex-row align-items-center">
          <div className="d-flex flex-row flex justify-content-between">
            <img alt="lotto-flag" src={FlagIcon} className="flag-icon" />
            <h4 className="body-bold-rs secondary-text-gold lotto-name">
              {gameName} {gameRound}
            </h4>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end m1-y">
        <RenderBadge />
      </div>
      <LottoNumbersFormat />
    </div>
  );
};

export default LottoResultCard;
