import { ALink, Button, Modal, PlaceholderLoading } from 'components'
import colors from 'constants/colors'
import { LOTTO_GAME_TYPE_NAME } from 'constants/variables'
import { get, groupBy, isEmpty, keys, map, noop, split, sumBy, values } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { fetchGetBetNumberRate } from 'reduxs/lotto/bet/rate/services'
import { number } from 'utils'
import './confirmation.style.scss'
import logo from 'assets/images/logo/logotlb.png'

declare interface IConfirmationProps {
    data: ILottoNumber[]
    betRates: IBetRate[]
    lottoSlug: TLottoSlug
    onConfirm(): void
    onCancel(): void
}

const constants = {
    bth: 'บาท',
    baht: 'บาทละ',
    title: 'ตรวจสอบรายการ',
    confirm: 'แทงหวย',
    cancel: 'แก้ไขรายการ',
    wraning1: '*กรุณาตรวจสอบรายการอีกครั้ง',
    wraning2: '*ไม่สามารถคืนโพยได้ในทุกกรณี',
    total:'ยอดรวม'
}

const defaultProps: IConfirmationProps = {
    data: [],
    betRates: [],
    lottoSlug: 'LOTTER_YEGEE',
    onConfirm: () => { noop() },
    onCancel: () => { noop() },
}

const Confirmation: FC<IConfirmationProps> = ({
    data,
    betRates,
    lottoSlug,
    onCancel,
    onConfirm,
}) => {

    const [activeTap,setActiveTap] = useState(0)
    const [loader,setLoader] = useState(false)
    const [numberListRecheck,setNumberListRecheck] = useState<ILottoNumber[]>([])

    useEffect(()=>{
        if (lottoSlug!=='LOTTER_YEGEE') {
            setLoader(true)
            const groupingLottoListObject: { [name in TLottoGameType]?: (ILottoNumber & { seq?: number })[] }
                = groupBy<(ILottoNumber & { seq?: number })>(data.map((bet, betIndex) => ({ ...bet, seq: betIndex })), 'type');
            const numbersQuery = map(values(groupingLottoListObject)[activeTap], (item) => ({
                code: lottoSlug,
                number: item.number,
                type: item.type,
            }))
            fetchGetBetNumberRate(numbersQuery).then((res)=>{
                let setData :ILottoNumber[]  = res.map((item)=>{
                    return { number: item.number, type: item.type, value: '', slug: '', rate: item.rate, }
                  })
                setLoader(false)
                setNumberListRecheck(setData)
            }).catch((error)=>{
                setLoader(false)
                console.log('error..',error)
            }).finally(()=>{setLoader(false)})
        }
    },[activeTap,lottoSlug,data]);

    const renderLoader = <PlaceholderLoading
        customCol={
            ['1','1','1'].map(()=>{
                return [
                    { col: 3, color: colors.PRIMARY_TEXT, show: true, size: 'S' },
                    { col: 1, show: false},
                    { col: 2, color: colors.PRIMARY_TEXT, show: true, size: 'S' },
                    { col: 2, color: colors.PRIMARY_TEXT, show: true, size: 'S' },
                    { col: 2, show: false},
                    { col: 2, color: colors.PRIMARY_TEXT, show: true, size: 'S' },
                ]
            })
        }
    />

    const TapLotto = ()=>{
        const groupingLottoListObject: { [name in TLottoGameType]?: (ILottoNumber & { seq?: number })[] }
            = groupBy<(ILottoNumber & { seq?: number })>(data.map((bet, betIndex) => ({ ...bet, seq: betIndex })), 'type')

        const renderTap = keys(groupingLottoListObject).map((lottos, lottosIndex) => {
            return <div className='col-auto p-0' key={lottos}>
                <div
                    key={lottos}
                    className={`tap ${activeTap===lottosIndex?'active':''}`}
                    onClick={()=>{
                        if (!loader) {
                            setNumberListRecheck([])
                            setActiveTap(lottosIndex)
                        }
                    }}
                >
                    <h4 className={`h4r text-tap ${activeTap===lottosIndex?'active':''}`}>{LOTTO_GAME_TYPE_NAME[lottos as TLottoGameType]}</h4>
                </div>
            </div>
        })
        const renderData = values(groupingLottoListObject)[activeTap]?.map((item,index)=>{
            const splitedType = split(item.slug!, '_')
            const lotterType = split(item.slug!, '_', (get(splitedType, 'length', 0) - 1)).reduce((prev, curr) => `${prev}_${curr}`)
            const betType = `${lotterType}_${item.type}`
            const betRateValue = (isEmpty(item.rate) || Number(item.rate) <= 0)
                ? get(betRates.filter((rate) => rate.type === betType), '0.rate', '0')
                : item.rate
            
            let check = 0
            let rateNumberCheck = 0
            if (!isEmpty(numberListRecheck)) {
                rateNumberCheck = Number(get(numberListRecheck.filter((check)=> item.number===check.number),"[0].rate",0))
                let rateNumber = Number(betRateValue)
                check = rateNumberCheck-rateNumber
            }

            return <div className='row' key={index+item.number}>
                <div className={`text-value col-2 col-md-4 ${check===0?'white-text':'secondary-text-gold'}`}>{item.number}</div>
                {/* text-center */}
                <div className='text-value col-5 col-md-4 white-text text-center d-flex flex-row justify-content-center'>
                    <div className='text-right' style={{width:"60px",marginRight:"10px"}}>{constants.baht}</div> 
                    <div className={`${check===0?'white-text':'primary-red-text'} text-right `} style={{width:"50%",maxWidth:'65px'}}>
                        {check===0?betRateValue:number.castToMoneyWithoutPrefix(rateNumberCheck)}
                    </div>
                </div>
                <div className='text-value col-5 col-md-4 white-text text-right'>{number.castToMoneyWithoutPrefix(Number(item.value))} {constants.bth}</div>
            </div>
        })
        let total = 0
        const resultTotal = keys(groupingLottoListObject).map((lottos, lottosIndex) => {
            let data = get(groupingLottoListObject,lottos)
            let sum = sumBy( data, (node:any)=>Number(node.value) )
            total += sum
            return <div className='col-12 col-lg-6 d-flex flex-row w-100' key={lottosIndex+lottos}>
                <div className='white-text flex-grow-1'>{LOTTO_GAME_TYPE_NAME[lottos as TLottoGameType]}</div>
                <div className='secondary-text-gold'>{number.addComma(sum)}</div>
                <div className='white-text pl-2'>{`${constants.bth}`}</div>
            </div>
        })

        const renderTotal = <div className='col-12 col-lg-6 d-flex flex-row w-100'>
            <div className='white-text flex-grow-1'>{constants.total}</div>
            <div className='secondary-text-gold'>{number.addComma(total)}</div>
            <div className='white-text pl-2'>{`${constants.bth}`}</div>
        </div>
        
        return (<div className=''>
            <div className='row px-3 mt-3'>{renderTap}</div>
            <div className='box-body mt-3 p-4' style={{backgroundImage: `url(${logo})`}}>{(loader)?renderLoader:renderData}</div>
            <div className='row mt-3'>{resultTotal}</div>
            <div className='row'>{renderTotal}</div>
        </div>)
    }

    return (
        <div className="confirmation-lotto-modal-container border-rounded">
            <div className="container">
                <div className="row m3-b">
                    <div className="col">
                        <h2 className='h2r white-text'>{constants.title}</h2>
                        <h6 className="p0-t body-bold-rs secondary-text-gold">{constants.wraning1}</h6>
                        <h6 className="p0-t body-bold-rs secondary-text-gold">{constants.wraning2}</h6>
                    </div>
                </div>
                <TapLotto />
                <div className="row m3-t">
                    <div className="col">
                        <Button
                            id="confirm-maked-lotto-button"
                            type="rectangle"
                            onClick={onConfirm}
                            backgroundColor={'#004AB9'}
                            text={constants.confirm}
                        />
                    </div>
                </div>
                <div className="row m2-t">
                    <div className="col text-center">
                        <ALink id="cancel-maked-lotto" color={colors.PRIMARY_TEXT} onClick={onCancel}>
                            {constants.cancel}
                        </ALink>
                    </div>
                </div>
            </div>
        </div>
    )
}

Confirmation.defaultProps = defaultProps


const ConfirmationModal = {
    show: (props: IConfirmationProps) => {
        return Modal.event('MODAL', {
            state: 'show',
            extraProps: { size: 'lg' },
            component: (<Confirmation {...props} />),
        })
    },
    hide: () => Modal.event('MODAL', { state: 'hide' }),
}

export default ConfirmationModal