import { createAction } from 'typesafe-actions'
import { REMEMBER_ME } from './constants'

const rememberMe = createAction(
  REMEMBER_ME,
  resolve => (data: ILogin) => resolve(data))

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  rememberMe,
}