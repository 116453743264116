const project: IProjectConstants = {
  name: 'ludens',
  environmentName: process.env.REACT_APP_LUDENS_ENV as IEnvironmentName || 'develop',
  environment: {
    production: {
      name: 'production',
      api: 'https://prodapi.tlbetmantra.com/api',
      socket: 'https://prodapi.tlbetmantra.com',
      notificationUserCountSocket: 'https://prodapi.tlbetmantra.com/api/v1/notification/user/count',
      secretKey: process.env.REACT_APP_JWT_SECRET_KEY as string || '',
      aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY as string || '',
    },
    staging: {
      name: 'staging',
      api: 'https://stapi.tlbetmantra.com/api',
      socket: 'https://stapi.tlbetmantra.com',
      notificationUserCountSocket: 'https://stapi.tlbetmantra.com/api/v1/notification/user/count',
      secretKey: process.env.REACT_APP_JWT_SECRET_KEY as string || '',
      aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY as string || '',
    },
    bifrost: {
      name: 'bifrost',
      api: 'https://bifrostapi.tlbetmantra.com/api',
      socket: 'https://bifrostapi.tlbetmantra.com',
      notificationUserCountSocket: 'https://bifrostapi.tlbetmantra.com/api/v1/notification/user/count',
      secretKey: process.env.REACT_APP_JWT_SECRET_KEY as string || '',
      aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY as string || '',
    },
    develop: {
      name: 'develop',
      api: 'https://devapi.tlbetmantra.com/api',
      socket: 'https://devapi.tlbetmantra.com',
      notificationUserCountSocket: 'https://devapi.tlbetmantra.com/api/v1/notification/user/count',
      secretKey: process.env.REACT_APP_JWT_SECRET_KEY as string || '',
      aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY as string || '',
    },
  },
}

export default project
