import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const clickRefReducer = (state: IClickRef = initialState, action: RootAction): IClickRef => {
  switch (action.type) {
    case getType(actions.clickRef):
      return {
        code: action.payload.code,
        company: action.payload.company,
        path: action.payload.path
      }
    case getType(actions.clearClickRef):
      return {
        ...state,
        code: undefined,
        company: undefined,
        path: undefined,
      }
    default:
      return state
  }
}

export default clickRefReducer