import project from 'constants/project'

export const GET_NUMBER_BAN_REQUEST = 'GET_NUMBER_BAN_REQUEST'
export const GET_NUMBER_BAN_SUCCESS = 'GET_NUMBER_BAN_SUCCESS'
export const GET_NUMBER_BAN_FAILURE = 'GET_NUMBER_BAN_FAILURE'
export const GET_NUMBER_BAN_CANCEL = 'GET_NUMBER_BAN_CANCEL'

export const initialState: ReducerState<Array<string>> = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const endpoint = {
  getBanNumber: (lottoSlug: TLottoSlug) => `${project.environment[project.environmentName].api}/lotter/ban-number?code=${lottoSlug}`,
}