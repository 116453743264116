import { get } from 'lodash'
import prject from 'constants/project'
import { initialState as summaryPmpgIntialState } from './summary/constants'
import { initialState as eraPmpgIntialState } from './pmpgEra/constants'
import { initialState as awardListPmpgIntialState } from './pmpgAwardList/constants'


const summaryPmpg = (state: RootReducers): ReducerState<IPmpgSummary> =>
    get(state, `${prject.name}.pmpg.summaryPmpg`, summaryPmpgIntialState)

const eraPmpg = (state: RootReducers): ReducerState<IPmpgEra> =>
    get(state, `${prject.name}.pmpg.pmpgEra`, eraPmpgIntialState)

const awardListPmpg = (state: RootReducers): ReducerState<IPmpgAwardList[]> =>
    get(state, `${prject.name}.pmpg.awardListPmpg`, awardListPmpgIntialState)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    summaryPmpg,
    eraPmpg,
    awardListPmpg,
}