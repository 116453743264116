import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
    catchError,
    exhaustMap,
    takeUntil,
    mergeMap,
    filter,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { AxiosResponse } from 'axios'
import actions from '../actions'
import { fetGetCount } from './services'

const getCountEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store, dependencies) =>
    action$.pipe(
        filter(isActionOf(actions.getCountAction)),
        exhaustMap(_ =>
            from(fetGetCount())
                .pipe(
                    mergeMap((response: AxiosResponse<APIResponse<IUserCount>>) => of(
                        actions.getCountSuccessAction(response)
                    )),
                    catchError(error => of(actions.getCountFailureAction(error))),
                    takeUntil(action$.pipe(filter(isActionOf(actions.getCountCancelAction))))
                ),
        )
    )
// eslint-disable-next-line import/no-anonymous-default-export
export default [
    getCountEpic,
]