
import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CreditCard, InputNumber } from 'components'
import { Form, FormikProps } from 'formik'
import React, { FC } from 'react'
import { number } from 'utils'

const constants = {
    amountLabel: 'จำนวนเงินถอน',
    placeholdeAmount: 'ระบุจำนวนเงินถอน',
    buttonFinish: 'ถอนรายได้',
    remark: '*กรุณาระบุจำนวนถอนเป็นจำนวนเต็ม ขั้นต่ำ 300 บาทขึ้นไป',
    availableWithdraw: 'สามารถถอนได้',
}

const AffiliateWithdrawForm:
    FC<FormikProps<{ money: string }> & IAffiliateWithdrawalFormProps> = ({
        originMoney,
        destinationMoney,
        handleBlur,
        handleChange,
        values,
        errors,
        touched,
        isValid,
    }) => {

        return (
            <div className="row m/-t">
                <div className="col-12 col-md-5 col-lg-7 mt-3 ">
                    <Form>
                        <div className="border-rounded secondary-bg p2">
                            <div className="row">
                                <div className="col">
                                    <div className="border-rounded secondary-bg-navy p2">
                                        <h6 className="secondary-text-gold">{constants.availableWithdraw}</h6>
                                        <h3 className="m0-t">{number.castToMoney(originMoney, true)}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row m2-t">
                                <div className="col">
                                    <h6 className="subtitle-2 secondary-text-gold m1-b">
                                        {constants.amountLabel}
                                        <span className="subtitle-2"> {constants.remark}</span>
                                    </h6>
                                    <InputNumber
                                        thousandSeparator
                                        decimalScale={0}
                                        name="money"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.money}
                                        allowLeadingZeros={false}
                                        allowNegative={false}
                                        errorMessage={errors.money}
                                        placeholder={constants.placeholdeAmount}
                                        error={!!errors.money && touched.money}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row m2-t">
                            <div className="col">
                                <Button
                                    id="withdraw-submit-button"
                                    buttonType="submit"
                                    disabled={!isValid}
                                    text={constants.buttonFinish}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="col-12 col-md-1 col-lg-1 mt-3">
                    <div className="d-none d-md-flex vertical-chevron-icon">
                        <FontAwesomeIcon icon={faChevronCircleRight} className="secondary-text-gold" />
                    </div>
                    <div className="d-flex d-md-none horizontal-chevron-icon">
                        <FontAwesomeIcon icon={faChevronCircleDown} className="secondary-text-gold" />
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mt-3">
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <CreditCard credit={destinationMoney} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

export default AffiliateWithdrawForm