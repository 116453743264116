import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import gameAction from 'reduxs/game/actions'
import loaderAction from 'reduxs/loader/actions'
import CasinoOnline from './CasinoOnline.container'
import gameSelector from 'reduxs/game/selectors'
import displayConfigSelector from 'reduxs/config/selectors'
import configAction from 'reduxs/config/actions'
import clickRefAction from 'reduxs/user/clickRef/actions'
import userSelector from 'reduxs/user/selector'

const mapStateToProps = (state: RootReducers): ICasinoOnlineProps => {
  const connectedGameSelected = gameSelector.gameConnect(state)
  const displayConfig = displayConfigSelector.displayConfig(state)
  const clickRef = userSelector.clickRef(state)
  return {
    connectedGameIsFetching: connectedGameSelected.isFetching!,
    connectedGameError: connectedGameSelected.error!,
    connectedGameCode: connectedGameSelected.code!,
    connectedGame: connectedGameSelected.data!,
    displayConfig: displayConfig.data!,
    clickRef,
  }
}

const mapDispatchToProos = (
  dispatch: Dispatch<RootAction>
): ICasinoOnlineActionProps =>
  bindActionCreators(
    {
      loader: loaderAction.loadingAction,
      connectGame: gameAction.connectGameAction,
      getDisplayConfig: configAction.getDisplayConfigAction,
      clearClickRef: clickRefAction.clearClickRef,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProos)(CasinoOnline)
