import axios from 'axios'
import { endpoint } from './constants'
import { get } from 'lodash'

export const fetchGetBetHistory= (data: IGetLottoHistoryParams) => {
    const queryParams = new URLSearchParams({
        page: String(get(data, 'page', '')),
        limit: String(get(data, 'limit', '')),
        date: String(get(data, 'date', '')),
        status: (get(data, 'status', '')),
        type: get(data, 'type', ''),
        subType: get(data, 'subType', ''),
    })
    return axios.get(`${endpoint.getBetHistory}`, {
        params: queryParams,
    })
}