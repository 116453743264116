import React, { Component, createRef, RefObject } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomButton, GameMenuList } from './components'
import { ErrorModal, InfoModal, TextRunning } from 'components'
import { responseCode } from 'constants/response'

import { interactive } from 'utils'
import { noop, map, get } from 'lodash'

import routes from 'constants/routes'
import LogoThailandBet from 'assets/images/logo/logothailandbet04.png'
import ASSETS_LIST from 'assets/images/landingContent/index'
import CASINOGAME_ASSETS_LIST from 'assets/images/vendorsIcon'
import LINE_COMMUNITY_ICON from 'assets/images/landingContent/line-community.png'
import { vendorLists as ECASINO_ASSETS_LIST } from 'assets/images/e-casino/index'
import { BANKS } from 'constants/variables'
import { ReactComponent as ConteactMe } from 'assets/images/landingContent/contact-me.svg'
import './landing.style.scss'
import '../../components/Navbar/navbar.style.scss'

import parse from 'html-react-parser'
import project from 'constants/project'

interface IGameMenuCallback {
  company: TGameCompany | undefined
  code: string | undefined
  path: string | undefined
}

const communityList = [
  {
    image: LINE_COMMUNITY_ICON,
    name: 'แนวทางมหาเฮง',
    url: 'WjbDlXg0EX',
  },
  {
    image: LINE_COMMUNITY_ICON,
    name: 'แนวทางรวยยี่กี',
    url: 'MOwrM3zz5o',
  },
  {
    image: LINE_COMMUNITY_ICON,
    name: 'แนวทางยี่กีพารวย',
    url: 'MOwrM3zz5o',
  },
  {
    image: LINE_COMMUNITY_ICON,
    name: 'แนวทางหวยไทย',
    url: 'Wu6X8RE4Yr',
  },
]

const constants = {
  ageHeader: 'มาแล้ว โบนัส !!',
  ageSubHeader: 'กองเงินกองทอง',
  ageDescription: 'การเปลี่ยนแปลงของยุค Thailandbet',
  ageFooter: 'จากการใช้บริการเว็บไซต์ในช่วงเวลาของยุค',
  login: 'เข้าสู่ระบบ',
  register: 'สมัคร',
  mode: 'dark-mode',
  introTLB:
    'ยินดีต้อนรับสู่ Thailandbet เว็บแทงหวยออนไลน์ ที่ดีที่สุด เดิมพันง่าย จ่ายเงินจริง เล่นได้ทุกที่ทุกเวลาหากต้องสร้างรายได้กับการเดิมพัน โปรดีที่สุด จ่ายชัว 100%',
  totalMemberLabel: 'สมาชิกประจำ',
  mobileAppDownloableLabel: 'สะดวกสบายกว่าด้วย แอพพลิเคชั่นมือถือ',
  financeTrans24hrsLabel: 'ฝาก ถอน อัตโนมัติ 24 ชม',
  communityLabelHead: 'ชุมชน',
  communityLabelBody: 'วางเลข แนวทาง วิเคราะห์ผล',
  seviceDeskLabelHead: 'บริการ',
  seviceDeskLabelBody: 'ช่วยเหลือ ตอบปัญหาตลอด 24 ชม',
  serviceLineID: '@thailandbet',
  comingSoon: 'พบกันเร็วๆนี้',
  TLBIos: 'Thailandbet IOS',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILandingProps & ILandingActionProps = {
  CountList: { userCount: 0 },
  getCount() {
    noop()
  },
  clearCount() {
    noop()
  },
  getCountCode: 0,
  getCountError: '',
  getCountIsFetching: false,
  loader() {
    noop()
  },
  clickRef() {
    noop()
  },
  clearClickRef() {
    noop()
  },
}
class LandingContainer extends Component<
  ILandingProps & ILandingActionProps & DefaultProps & RouteComponentProps,
  ILandingState
> {
  static defaultProps = defaultProps
  landingContainerRef: RefObject<HTMLDivElement> = createRef()

  state: ILandingState = {
    memberCounting: 0,
    selectedLandingMenu: 'สุดฮิต!',
    memberInterval: setInterval(() => this.onGetCount(), 5000),
  }

  componentDidMount() {
    this.onGetCount()
    this.handleScrollToTop()
    setTimeout(() => interactive.scrollToTop(this.landingContainerRef), 256)
  }

  componentDidUpdate(prevProps: ILandingProps) {
    if (
      prevProps.getCountIsFetching !== this.props.getCountIsFetching &&
      !this.props.getCountIsFetching
    ) {
      if (this.props.getCountCode === responseCode.OK) {
      } else {
        ErrorModal.show({
          description: this.props.getCountError,
          actionText: 'รับทราบ',
          action: ErrorModal.hide,
        })
        clearInterval(this.state.memberInterval)
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.memberInterval)
    this.props.clearCount()
  }

  downloadAppLTB = () => {
    window.open(
      project.environmentName !== 'production'
        ? 'https://genesis.tlbetmantra.com/api/v1/app/android/tinkywinky/beta'
        : 'https://genesis.tlbetmantra.com/api/v1/app/android/tinkywinky/production',
      '_blank'
    )
  }

  handleScrollToTop = () => {
    if (this.landingContainerRef.current) {
      this.landingContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      })
    }
  }

  onNavigateToRegister = () => {
    this.props.history.push(routes.register.path)
  }

  onNavigateToLogin = () => {
    this.props.history.push(routes.home.path)
  }

  handleOnClick = () => {
    this.props.history.replace(routes.main.path)
  }

  onGetCount = () => {
    this.props.getCount()
  }

  onRedirectLineGroup = (path: string) => {
    window.open(`http://line.me/R/ti/g/${path}`, '_blank')
  }

  onRedirectLineOfficial = () => {
    window.open(`https://line.me/R/ti/p/${constants.serviceLineID}`, '_blank')
  }

  onPopupModal = () => {
    InfoModal.show({
      title: constants.TLBIos,
      description: constants.comingSoon,
      action: () => InfoModal.hide(),
    })
  }

  onGetGameMenuCallback = (data: IGameMenuCallback) => {
    const createStoreClick: IClickRef = {
      code: get(data, 'code', undefined),
      company: get(data, 'company', undefined),
      path: data.path,
    }
    this.props.clickRef(createStoreClick)
    return this.onNavigateToLogin()
  }
  onCacheClickRef = (path: string) => {
    const createStoreClick: IClickRef = {
      code: undefined,
      company: undefined,
      path,
    }
    this.props.clickRef(createStoreClick)
    return this.onNavigateToLogin()
  }
  onRenderNavsBar = () => {
    return (
      <div
        className={`col-12 
      navbar-container position-fixed 
        ${constants.mode}`}
      >
        <div className="container">
          <div
            className="leading-navbar-container"
            onClick={this.handleOnClick}
          >
            <img
              src={LogoThailandBet}
              alt="thailand"
              className="logo-container-self"
            />
          </div>
          <div className="on-displayed-button trailing-navbar-container">
            <span
              className="p1-r pb-1 can-click"
              onClick={this.onNavigateToLogin}
            >
              {constants.login}
            </span>
            <div
              className="navbar-button-container"
              style={{ zIndex: 5, position: 'relative' }}
            >
              <CustomButton
                text={constants.register}
                id="go-to-register"
                size="small"
                buttonPaddingClass={'px-3'}
                onClick={this.onNavigateToRegister}
                backgroundIcon={LogoThailandBet}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  onRenderLandingMenu = () => {
    const mappingMenu = map(ASSETS_LIST.menuIcon, (dataList, i) => (
      <div
        className={`menu-box-wrapper p-2
         ${this.state.selectedLandingMenu === dataList.name && 'selected-menu'}
        `}
        key={`item-${i}`}
        id={`menu-${i}-${dataList.name}`}
        onClick={() => this.setState({ selectedLandingMenu: dataList.name })}
      >
        <img
          className={`icon-wrapper ${
            this.state.selectedLandingMenu === dataList.name && 'active-menu'
          }`}
          src={dataList.icon}
          alt={`img-${dataList.name}`}
        />
        <span className="subtitle-2">{dataList.name}</span>
      </div>
    ))

    return <div className="landing-menu-container">{mappingMenu}</div>
  }

  onRenderMenuContent = () => {
    return (
      <div className="menu-content-wrapper py-lg-3 py-md-2 py-sm-2 py-1">
        <GameMenuList
          onClickGame={this.onGetGameMenuCallback}
          selectedMenu={this.state.selectedLandingMenu as TLandingMenuComponent}
        />
      </div>
    )
  }

  onRenderMemberCouting = () => {
    const formArrayTotalMember = Array.from(
      get(this.props.CountList, 'userCount', 1681688).toString()
    )
    return (
      <>
        <div className="m1-b ">
          <span className="responsive-fonts font-weight-bold">
            {constants.totalMemberLabel}
          </span>
        </div>
        <div className="couting-container">
          {map(formArrayTotalMember, (dataList, i) => (
            <div
              className="number-wrapper"
              id={`number-id-${i}`}
              key={`number-item-${i}`}
            >
              <span className="couting-text font-weight-bold">{dataList}</span>
            </div>
          ))}
        </div>
      </>
    )
  }

  onRenderBonusAndAffilate = () => {
    const mapContent = map(ASSETS_LIST.bonusAndAffiliate, (dataList, i) => (
      <div
        id={`bonusAndAffilate-id-${i}`}
        key={`bonusAndAffilate-item-${i}`}
        className="can-click bonus-and-affiliate-container"
        tabIndex={0}
        onClick={() =>
          this.onGetGameMenuCallback({
            code: undefined,
            company: undefined,
            path: ['โบนัส'].includes(dataList.title)
              ? routes.jackpot.path
              : routes.affiliate.path,
          })
        }
      >
        <div className="row m3-b">
          <div className="col-6 text-right">
            <img
              src={dataList.icon}
              alt={dataList.title}
              className="image-wrapper"
            />
          </div>
          <div className="col-6">
            <div className="text-ba-wrap">
              <h5 className="font-weight-bold">{dataList.title}</h5>
              <div>{parse(dataList.content)}</div>
            </div>
          </div>
        </div>
      </div>
    ))
    return <>{mapContent}</>
  }

  onRenderCasinoGameGroup = () => {
    const mapIcon = map(
      CASINOGAME_ASSETS_LIST.casinoGameIconList,
      (dataList, i) => (
        <div key={`casino-game-item-${i}`} id={`casino-game-id-${i}`}>
          <img
            className="casino-game-icon-wrapper can-click"
            key={`icon-item-${i}`}
            id={`icon-id-${i}`}
            src={dataList.image}
            alt={`alt-${dataList.code}`}
            onClick={() =>
              dataList.code === 'KINGMAKER' || dataList.code === 'SLOTXO'
                ? this.onGetGameMenuCallback({
                    company: dataList.code === 'KINGMAKER' ? 'AESEXY' : 'JOKER',
                    code: undefined,
                    path: routes.casino.path,
                  })
                : this.onGetGameMenuCallback({
                    code: undefined,
                    company: ['JOKER', 'PG', 'DREAMTECH'].includes(
                      dataList.code
                    )
                      ? (dataList.code as TGameCompany)
                      : 'JOKER',
                    path: routes.games.path,
                  })
            }
          />
        </div>
      )
    )
    return <div className="casino-game-wrapper">{mapIcon}</div>
  }

  onRenderCasinoOnlineGroup = () => {
    const filtered = ['ASIAGAMING', 'PRETTYGAMING']
    const mapIcon = map(
      ECASINO_ASSETS_LIST.vendorListCircle,
      (dataList, i) =>
        !filtered.includes(dataList.code) && (
          <div
            className={``}
            key={`casino-online-item-${i}`}
            id={`casino-online-id-${i}`}
          >
            <img
              className="casino-onlinei-icon-wrapper can-click"
              key={`icon-item-${i}`}
              id={`icon-id-${i}`}
              src={dataList.image}
              alt={`alt-${dataList.company}`}
              onClick={() =>
                this.onGetGameMenuCallback({
                  company: ['PRETTYGAMING'].includes(dataList.code)
                    ? undefined
                    : (dataList.code as TGameCompany),
                  code: undefined,
                  path: routes.casino.path,
                })
              }
            />
          </div>
        )
    )
    return <div className="casino-online-wrapper">{mapIcon}</div>
  }

  onRenderExampleDownloadApplication = () => {
    const mapIcon = map(ASSETS_LIST.downloadAppImage, (dataList, i) => (
      <div
        className="d-flex flex-column"
        key={`exp-app-download-item-${i}`}
        id={`exp-app-download-id-${i}`}
      >
        <img
          className="icon-wrapper mx-3 my-2"
          src={dataList.image}
          alt={`alt-${dataList.image}`}
        />
        <img
          className="button-icon-wrapper can-click"
          onClick={
            dataList.type === 'ios' ? this.onPopupModal : this.downloadAppLTB
          }
          src={dataList.imageButton}
          alt={`alt-${dataList.imageButton}`}
        />
      </div>
    ))
    return (
      <>
        <span className="responsive-fonts font-weight-bold">
          {constants.mobileAppDownloableLabel}
        </span>
        <div className="download-app-container">{mapIcon}</div>
      </>
    )
  }

  onRenderFinanceWithdrawAndDeposit24Hrs = () => {
    const mapIcon = map(BANKS, (dataList, i) => (
      <div
        className={`bank-icom-item
        `}
        key={`bank-item-${i}-${dataList.name}`}
        id={`bank-id-${i}-${dataList.name}`}
      >
        <img
          onClick={() =>
            this.onGetGameMenuCallback({
              code: undefined,
              company: undefined,
              path: routes.deposit.path,
            })
          }
          src={dataList.Icon}
          className="bank-icon-wrapper"
          alt={`bank-${dataList.name}`}
        />
      </div>
    ))
    return (
      <>
        <div className="mb-4">
          <span className="responsive-fonts font-weight-bold">
            {constants.financeTrans24hrsLabel}
          </span>
        </div>
        <div className="mx-auto">
          <div className="finance-container">{mapIcon}</div>
        </div>
      </>
    )
  }
  onRenderLineCommunities = () => {
    const mapIconCommunity = map(communityList, (dataList, i) => (
      <div
        className="d-flex flex-column justify-content-center align-items-center my-2 mx-2"
        key={`community-item-${i}`}
        id={`community-id-${i}`}
      >
        <img
          src={dataList.image}
          alt={dataList.name}
          className="community-icon can-click"
          onClick={() => this.onRedirectLineGroup(dataList.url)}
        />
        <span className="responsive-fonts-3">{dataList.name}</span>
      </div>
    ))
    return (
      <div className="community-container">
        <span className="responsive-fonts font-weight-bold">
          {constants.communityLabelHead}
        </span>
        <span className="responsive-fonts-2">
          {constants.communityLabelBody}
        </span>
        <div className="community-wrapper mx-auto">{mapIconCommunity}</div>
        <div className="community-service-container">
          <span className="responsive-fonts responsive-fonts font-weight-bold">
            {constants.seviceDeskLabelHead}
          </span>
          <span className="responsive-fonts-2">
            {constants.seviceDeskLabelBody}
          </span>
          <div className="service-wrapper my-2">
            <img
              src={LINE_COMMUNITY_ICON}
              alt={`LINE-OFFICIAL-SERVICE`}
              className="service-icon can-click"
              onClick={this.onRedirectLineOfficial}
            />
            <span className="responsive-fonts-2">
              {constants.serviceLineID}
            </span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const RenderNavsBar = this.onRenderNavsBar
    const RenderLandingMenu = this.onRenderLandingMenu
    const RenderMenuContent = this.onRenderMenuContent
    const RenderMemberCouting = this.onRenderMemberCouting
    const RenderBonusAndAffilate = this.onRenderBonusAndAffilate
    const RenderCasinoOnlineGroup = this.onRenderCasinoOnlineGroup
    const RenderCasinoGameGroup = this.onRenderCasinoGameGroup
    const RenderLineCommunities = this.onRenderLineCommunities
    const RenderExampleDownloadApplication =
      this.onRenderExampleDownloadApplication
    const RenderFinanceWithdrawAndDeposit24Hrs =
      this.onRenderFinanceWithdrawAndDeposit24Hrs
    return (
      <div className="landing-container" ref={this.landingContainerRef}>
        <TextRunning text={'Welcome to Thailand Bet'} />
        <RenderNavsBar />
        <ConteactMe
          className="contact-us-stucky"
          onClick={this.onRedirectLineOfficial}
        />
        <div className="container-lg mt-5">
          <div className="row">
            <div className="col-12 mx-auto px-1 px-sm-2 px-md-2 px-lg-2">
              <RenderLandingMenu />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mx-auto">
            <RenderMenuContent />
          </div>
        </div>
        <div>
          <div className="row mt-5">
            <div className="col-12 mx-auto text-center">
              <RenderMemberCouting />
            </div>
          </div>
          <div className="container">
            <div className=" my-5">
              <RenderBonusAndAffilate />
            </div>
            <div className="row mt-5 mb-2">
              <div className="col-12 mx=auto">
                <RenderCasinoGameGroup />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 mx=auto">
                <RenderCasinoOnlineGroup />
              </div>
            </div>
            <div className="row my-5">
              <div className="col-12 mx-auto text-center">
                <RenderExampleDownloadApplication />
              </div>
            </div>
            <div className="row my-5">
              <div className="col-11 col-lg-11 col-md-12 col-sm-12 mx-auto text-center mx-auto">
                <RenderFinanceWithdrawAndDeposit24Hrs />
              </div>
            </div>
            <div className="row my-5">
              <div className="col-12 text-center mx-auto">
                <RenderLineCommunities />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default LandingContainer
