import React, { Component, ChangeEvent } from 'react'
import {
  // NumberPad,
  Button,
  InputNumberLotto,
} from 'components'
import { isEmpty, noop } from 'lodash'
import './makingGame.style.scss'

const constants = {
  addNumber: 'ยิงเลข',
  sampleGamePlaceHoder: 'โปรดใส่เลขที่ต้องการยิงเลข',
  nameList: 'รายการยิงเลข',
}

const defaultProps: IMakingGameComponentProps = {
  onClickAddNumber() { noop() },
}

class MakingGame extends Component<IMakingGameComponentProps, IMakingGameComponentState> {

  static defaultProps = defaultProps

  state: IMakingGameComponentState = {
    numberSet: '',
    collapseState: false,
  }

  onChangeNumberValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.setState({
      numberSet: value,
    })
  }

  handleOnClickNumberPad = (num: number) => {
    if (num === -1) {
      return this.setState({ numberSet: this.state.numberSet.slice(0, -1) })
    } else if (this.state.numberSet.length >= 5) { return }
    const newValue = this.state.numberSet.concat(String(num))
    return this.setState({ numberSet: newValue })
  }

  handleOnClickAddNumber = () => {
    if (this.state.numberSet.length < 5) { return }
    this.props.onClickAddNumber(this.state.numberSet)
    this.setState({
      numberSet: '',
    })
  }

  renderMakingGameNumber = () => {
    if (isEmpty(this.state.numberSet)) {
      return <h3 className="placeholder-making-game-number font-m25r">{constants.sampleGamePlaceHoder}</h3>
    }
    return <h2 className="making-game-number-text">{this.state.numberSet}</h2>
  }

  render() {
    // const MakingGameNumberComponent = this.renderMakingGameNumber

    return (
      <div className='making-game-container'>
        <div className="row">
          <div className="col">
            <div className="text-center">
              {/* <MakingGameNumberComponent /> */}
              <InputNumberLotto
                decimalScale={0}
                name="numberSet"
                inputProps={{maxLength:5}}
                textAlign={'center'}
                allowNegative={false}
                allowLeadingZeros
                onValueChange={({ value }) => {
                  if (this.state.numberSet.toString().length <= 5 && Number(value) >= 0 ) {
                    this.setState({ numberSet: value.replace(/\D/g, "") })
                  }
                }}
                isAllowed={(e)=>{return e.formattedValue.length <= 5}}
                value={this.state.numberSet}
                underLine={true}
                placeholder={constants.sampleGamePlaceHoder}
              />
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className='col'>
            <div className="add-number-action-wrapper">
              <Button
                size="small"
                id="add-number-game"
                disabled={this.state.numberSet.length < 5}
                text={constants.addNumber}
                onClick={this.handleOnClickAddNumber}
              />
            </div>
          </div>
          {/* <div className="col">
            <NumberPad onNumberPresses={this.handleOnClickNumberPad} />
          </div> */}
        </div>
      </div>
    )
  }
}

export default MakingGame