import React, { Component, createRef, RefObject } from "react";
import { chunk, get, isEmpty, lowerCase, map, noop } from "lodash";
import { BackButton, ResponsiveIcon } from "components";
import { RouteComponentProps } from "react-router-dom";
import { responseCode } from "constants/response";
import project from "constants/project";
import LogoTlb from "assets/images/logo/logothailandbet02.png";
import jiliGameBanner from "assets/images/vendorsIcon/jili_logo_banner.png";
import W88 from "assets/images/vendorsIcon/w88.svg";
import "./casinoGame.style.scss";
import { interactive } from "utils";
import routes from "constants/routes";
import partnerList from "assets/images/vendorsIcon";
import { Icon } from "@iconify/react";

const constants = {
  back: "ย้อนกลับ",
  slot: "สล็อต",
  fishing: "ยิงปลา",
  bingo: "บิงโก",
  ecasino: "E-Casino",
  pageTitle: "เกมส์แนะนำ",
  comingSoon: "พบกันเร็วๆนี้",
  closedGame: "ปิดปรับปรุง ชั่วคราว",
  defaultCompanyGame: "JOKER",
  qa: "วิธีเล่นผ่านแอปโทรศัพท์",
  playNow: "เล่นเลย",
  emptyGameList: "ไม่พบข้อมูลรายการเกม",
  moreGame: "เปลี่ยนค่ายเกม",
  partnerDescription: "(เปลี่ยนค่ายเกม)",
  commingSoon: "พบกันเร็วๆนี้",
  allprovider: "ค่ายเกมส์ทั้งหมด",
};

const defaultProps: ICasinoGameProps & ICasinoGameActionProps = {
  connectGame() {
    noop();
  },
  connectedGameIsFetching: false,
  connectedGameError: "",
  connectedGameCode: 0,
  connectedGame: "",
  getGameList() {
    noop();
  },
  getGameListIsFetching: false,
  getGameListError: "",
  getGameListCode: 0,
  gameList: [],
  loader() {
    noop();
  },
  clearGameList() {
    noop();
  },
  displayConfig: {},
  displayConfigCode: 0,
  displayConfigError: "",
  displayConfigIsFetching: false,
  getDisplayConfig() {
    noop();
  },
  clearClickRef() {
    noop();
  },
  clickRef: {
    code: undefined,
    company: undefined,
    path: undefined,
  },
};

type DefaultProps = Readonly<typeof defaultProps>;

class CasinoGameContainer extends Component<
  ICasinoGameProps &
    ICasinoGameActionProps &
    DefaultProps &
    RouteComponentProps<any, any, ICasinoGameRouteProps>,
  ICasinoGameContainerState
> {
  static defaultProps = defaultProps;
  gameListContainerRef: RefObject<HTMLDivElement> = createRef();
  pageContainerRef: RefObject<HTMLDivElement> = createRef();

  state: ICasinoGameContainerState = {
    currentGameType: "SLOT",
    currentTextMenu: "เกมทั้งหมด",
    currentPartnerMenu: {
      alt: "SLOT",
      icon: LogoTlb,
    },
    gameQuery: {},
    portalIsReady: false,
    displaySorting: [],
  };

  componentDidMount() {
    interactive.rootElement.scrollToTop();
    this.props.loader(true);

    if (this.props.location.state?.isNotiSlot) {
      this.onClickTextMenu("เกมสล็อต", "SLOT", false);
    }
    if (this.props.location.state?.onClickPartnerData) {
      const { onClickPartnerData } = this.props.location.state;
      const isMaintenance = !get(
        this.props.displayConfig,
        `${project.environmentName}.${lowerCase(onClickPartnerData.alt)}`,
        false
      );
      this.onClickPartnerMenu(
        isMaintenance ? { alt: "SLOT", icon: LogoTlb } : onClickPartnerData
      );
    }

    if (
      this.props.clickRef.company !== undefined ||
      this.props.clickRef.code !== undefined
    ) {
      this.props.loader(true);
      setTimeout(() => {
        this.onNavigateByClickRef();
      }, 512);
    } else if (!isEmpty(get(this.props.location, "state.company", {}))) {
      this.refFromCasinoOnline();
    } else {
      // TODO: Query get new or hot only 6 game
      this.onGetGameList(this.state.gameQuery);
      // TODO: Handle this if having problem
      this.onGetDisplayConfig();
    }
  }

  componentDidUpdate(prevProps: ICasinoGameProps) {
    if (
      prevProps.getGameListIsFetching !== this.props.getGameListIsFetching &&
      !this.props.getGameListIsFetching
    ) {
      if (this.props.getGameListCode === responseCode.OK) {
        this.props.loader(false);
        if (this.state.portalIsReady) {
          const strSplit = this.props.clickRef!.code!.split("_");
          if (![""].includes(strSplit[1])) {
            for (let i = 0; i < this.props.gameList.length; i++) {
              if (this.props.gameList[i].code === strSplit[1]) {
                window.open(
                  interactive.openLink<IGame & { gameLocation: TGameLocation }>(
                    { ...this.props.gameList[i], gameLocation: "EXTERNAL" }
                  ),
                  "_blank"
                );
                this.setState(
                  {
                    portalIsReady: false,
                  },
                  () => this.props.clearClickRef()
                );
                break;
              }
            }
          }
        }
      } else {
        this.props.loader(false);
      }
    }

    if (
      prevProps.connectedGameIsFetching !==
        this.props.connectedGameIsFetching &&
      !this.props.connectedGameIsFetching
    ) {
      this.props.loader(false);
      if (this.props.connectedGameCode === responseCode.OK) {
        const newWindow = window.open();
        if (newWindow) {
          newWindow.opener = null;
          newWindow.location.href = this.props.connectedGame;
        }
      }
    }
    if (
      prevProps.displayConfigIsFetching !==
        this.props.displayConfigIsFetching &&
      !this.props.displayConfigIsFetching
    ) {
      this.props.loader(true);
      if (this.props.displayConfigCode === responseCode.OK) {
        this.props.loader(false);
        this.onHandleSortingDisplayConfig();
      } else {
        this.props.loader(false);
        this.onHandleSortingDisplayConfig();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearGameList();
  }

  onGetDisplayConfig = () => {
    this.props.loader(true);
    this.props.getDisplayConfig();
  };

  onGetGameList = (val: IGameQuery, ref?: string) => {
    if (["PG", "DREAMTECH"].includes(val.company!)) {
      this.props.loader(false);
      if (["PG"].includes(ref!)) {
        window.open(
          interactive.openLink<any & { gameLocation: TGameLocation }>({
            company: "PGGAME",
            code: "PGGAME",
            gameLocation: "EXTERNAL",
          }),
          "_blank"
        );
      } else if (["DREAMTECH"].includes(ref!)) {
        window.open(
          interactive.openLink<any & { gameLocation: TGameLocation }>({
            company: "DREAMTECH",
            code: "DREAMTECH",
            gameLocation: "EXTERNAL",
          }),
          "_blank"
        );
      }
    } else {
      this.props.getGameList("EXTERNAL", val);
    }
  };

  handleConnectGame = (casinoGame: IGame) => {
    this.props.loader(true);
    const query: IGameConnectQuery = {
      code: casinoGame.code,
      isMobile: false,
      company: constants.defaultCompanyGame as TGameCompany,
    };
    this.props.connectGame("EXTERNAL", query);
  };

  handleOnChangeCategory = (
    category: TGameType | "" | "ALL",
    isHot: boolean
  ) => {
    this.props.loader(true);
    this.setState(
      (ps) => ({
        currentGameType: category as TGameType,
        gameQuery:
          isHot === true
            ? {
                ...ps.gameQuery,
                isHot,
                type: ["ALL"].includes(category) ? "" : category,
              }
            : category !== ""
            ? {
                ...ps.gameQuery,
                isHot,
                type: ["ALL"].includes(category) ? "" : category,
              }
            : {},
      }),
      () =>
        this.setState(
          (ps) => ({
            currentPartnerMenu: isEmpty(ps.gameQuery)
              ? { alt: "", icon: LogoTlb }
              : { ...ps.currentPartnerMenu },
          }),
          () => this.onGetGameList(this.state.gameQuery)
        )
    );
  };

  handleScrollToList = () => {
    if (this.gameListContainerRef.current) {
      this.gameListContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  onClickBack = () => this.props.history.goBack();

  renderCasinoGame = () => {
    const CasinoGameComponent = ["JILIGAME"].includes(
      this.state.currentPartnerMenu.alt
    ) ? (
      <a
        href={interactive.openLink<any & { gameLocation: TGameLocation }>({
          company: "JILIGAME",
          code: "JILIGAME",
          gameLocation: "EXTERNAL",
        })}
        className={`col-12 col-xl-6 col-md-6 col-sm-6 col-xs-12 casino-game-wrapper`}
        target="_blank"
        aria-disabled
        rel="noreferrer noopener"
      >
        <div
          className="casino-game-button-wrapper text-center"
          id={`casino-game-pg`}
        >
          <ResponsiveIcon
            icon={jiliGameBanner}
            alt={`casino-game-pg-alt`}
            className="casino-game-pg"
          />
          <div className="content-heightlight-hover-pg d-flex align-items-center justify-content-center">
            <h2>{constants.playNow}</h2>
          </div>
        </div>
      </a>
    ) : (
      // TODO: Remove chunk after get api limit game
      map(get(chunk(this.props.gameList, 6), 0, []), (casinoGame, _) => {
        const isMaintenance = get(
          this.props.displayConfig,
          `${project.environmentName}.${lowerCase(casinoGame.company)}`,
          true
        );
        return (
          <a
            href={
              isMaintenance
                ? interactive.openLink<IGame & { gameLocation: TGameLocation }>(
                    { ...casinoGame, gameLocation: "EXTERNAL" }
                  )
                : "/"
            }
            className={`col-4 col-md-4 col-xl-2 m1-b p-1 casino-game-wrapper ${
              !isMaintenance && "disabled"
            }`}
            key={`casino-game-${_}`}
            target="_blank"
            aria-disabled
            rel="noreferrer noopener"
          >
            <div
              className="casino-game-button-wrapper text-center"
              id={`casino-game-${casinoGame.id}`}
            >
              <ResponsiveIcon
                icon={casinoGame.image}
                alt={`casino-game-${casinoGame.id}-alt`}
                className="casino-game-image"
              />
              <div className="content-heightlight-hover d-flex align-items-center justify-content-center">
                <h2 className="secondary-text-navy">{constants.playNow}</h2>
              </div>
            </div>
            {isMaintenance ? (
              <></>
            ) : (
              <div className="closed-game-play text-center">
                <h3>{constants.closedGame}</h3>
              </div>
            )}
          </a>
        );
      })
    );
    return <>{CasinoGameComponent}</>;
  };

  refFromCasinoOnline = () => {
    this.setState(
      (prevState) => ({
        currentPartnerMenu: { alt: "W88", icon: W88 },
        currentTextMenu: "เกมสล็อต",
        gameQuery: {
          ...prevState.gameQuery,
          company: "W88" as TGameCompany,
          type: "SLOT",
        },
      }),
      () => this.onGetGameList(this.state.gameQuery, "W88")
    );
  };

  onNavigateByClickRef = () => {
    const { company, code } = this.props.clickRef;
    if (company !== undefined) {
      if (
        this.props.displayConfig![project.environmentName]![
          company.toLowerCase() as TGameCompany
        ]
      ) {
        this.setState(
          (prevState) => ({
            currentTextMenu: "เกมทั้งหมด",
            gameQuery: {
              ...prevState.gameQuery,
              company: company! as TGameCompany,
              type: "",
            },
            currentPartnerMenu: {
              alt: company!,
              icon: partnerList.partnerList[`${company!.toLowerCase()}`],
            },
          }),
          () => this.onGetGameList(this.state.gameQuery, company)
        );
      }
      this.onGetGameList(this.state.gameQuery);
      this.props.clearClickRef();
    } else if (code !== undefined) {
      const strSplit = code.split("_");
      if (
        this.props.displayConfig![project.environmentName]![
          "joker" as TGameCompany
        ]
      ) {
        this.setState(
          (prevState) => ({
            currentTextMenu: "เกมยิงปลา",
            gameQuery: {
              ...prevState.gameQuery,
              company: "JOKER",
              type: strSplit[0] as TGameType,
            },
            currentPartnerMenu: {
              alt: "JOKER",
              icon: partnerList.partnerList[`joker`],
            },
            portalIsReady: true,
          }),
          () => this.props.getGameList("EXTERNAL", this.state.gameQuery)
        );
      } else {
        this.props.clearClickRef();
      }
      this.onGetGameList(this.state.gameQuery);
    }
    return;
  };

  onHandleSortingDisplayConfig = () => {
    const initGameList = {
      aesexy: false,
      allbet: false,
      joker: false,
      pg: false,
      sagame: false,
      wmgame: false,
      w88: false,
      dreamtech: false,
      thbgame: false,
      slotxo: false,
      dreamgaming: false,
      asiagaming: false,
    };
    const extractGameList: any = {
      ...initGameList,
      ...get(this.props.displayConfig, [project.environmentName], ""),
    };
    const gameSorted = Object.keys(extractGameList).sort(
      (a, b) => extractGameList[b] - extractGameList[a]
    );
    this.setState({ displaySorting: gameSorted });
    return;
  };

  onClickPartnerMenu = (data: ICasinoGameIcon) => {
    if (["PG", "DREAMTECH", "JILIGAME"].includes(data.alt)) {
      switch (data.alt) {
        case "PG":
          window.open(
            interactive.openLink<any & { gameLocation: TGameLocation }>({
              company: "PGGAME",
              code: "PGGAME",
              gameLocation: "EXTERNAL",
            }),
            "_blank"
          );
          break;
        case "DREAMTECH":
          window.open(
            interactive.openLink<any & { gameLocation: TGameLocation }>({
              company: "DREAMTECH",
              code: "DREAMTECH",
              gameLocation: "EXTERNAL",
            }),
            "_blank"
          );
          break;
        case "JILIGAME":
          window.open(
            interactive.openLink<any & { gameLocation: TGameLocation }>({
              company: "JILIGAME",
              code: "JILIGAME",
              gameLocation: "EXTERNAL",
            }),
            "_blank"
          );
          break;
        default:
          this.props.history.push(routes.slotProvider.exactPath(data.alt));
          break;
      }
    } else {
      this.props.history.push(routes.slotProvider.exactPath(data.alt));
    }

    // TODO: Remove this
    // document.body.style.removeProperty("overflow-y");
    // this.props.loader(true);
    // return this.setState(
    //   (ps) => ({
    //     currentPartnerMenu: {
    //       alt: ![data.alt].includes(ps.currentPartnerMenu.alt)
    //         ? data.alt
    //         : ps.currentPartnerMenu.alt,
    //       icon: data.icon,
    //     },
    //     gameQuery: {
    //       ...ps.gameQuery,
    //       company: data.alt as TGameCompany,
    //       type: "",
    //       isHot: false,
    //     },
    //     currentTextMenu: "เกมทั้งหมด",
    //     modalToggler: this.props.location.state?.onClickPartnerData
    //       ? false
    //       : !ps.modalToggler,
    //   }),
    //   () => {
    //     if (this.props.location.state?.onClickPartnerData) {
    //       this.onClickTextMenu("เกมสล็อต", "SLOT", false);
    //       this.props.location.state.onClickPartnerData = undefined;
    //     }
    //     this.onGetGameList(this.state.gameQuery);
    //   }
    // );
  };

  _RenderPartnerComponent = () => {
    const data = this.state.displaySorting || [];
    const gameCollection = [
      "joker",
      "pg",
      "slotxo",
      "dreamtech",
      "asiagaming",
      "w88Slot",
      "ppgame",
      "jiligame",
    ];
    const isMaintenance = (code: string): boolean =>
      get(
        this.props.displayConfig,
        `${project.environmentName}.${code}`,
        false
      );
    const createComponent = map(
      data.filter((o) => gameCollection.includes(o)),
      (dataList, index) => {
        let newDataList = dataList === "w88Slot" ? "w88" : dataList;
        const onMaintenanced =
          !isMaintenance(dataList) ||
          ["dreamgaming", "slotxo", "asiagaming"].includes(dataList);
        return (
          <div
            className="col-6 col-xl-4 col-md-6 col-sm-6 col-xs-6 p-2 text-center"
            key={`icon-items-${index}`}
          >
            <div
              className="partner-icon-wrapper"
              onClick={() =>
                !onMaintenanced &&
                this.onClickPartnerMenu({
                  alt: newDataList.toUpperCase(),
                  icon: partnerList.partnerList[newDataList],
                })
              }
            >
              <img
                className={`
      partner-icon${
        dataList === "jiligame" ? "-blink" : ""
      } align-self-center
      ${this.state.currentPartnerMenu.alt === dataList && "active"}
      `}
                src={partnerList.partnerList[newDataList]}
                alt={`${dataList}`}
                id={`${dataList}-${index}`}
              />
              {onMaintenanced ? (
                <div className="closed-partner-play p-2">ปิด</div>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      }
    );
    return <div className="row">{createComponent}</div>;
  };

  renderTextMenu = () => {
    const menu: { name: TMenuText; type: TGameType | "" | "ALL" }[] = [
      { name: "เกมทั้งหมด", type: "ALL" },
      { name: "เกมยอดนิยม", type: "" },
      { name: "เกมสล็อต", type: "SLOT" },
      { name: "เกมยิงปลา", type: "FISHING" },
      { name: "เกมบิงโก", type: "BINGO" },
      { name: "E-CASINO", type: "ECASINO" },
    ];
    const menuHandler = map(menu, (data, index) => (
      <div
        className={`col-6 col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-6 m3-t text-center`}
        key={`menu-items-${index}`}
      >
        <span
          className={`menu-text ${
            this.state.currentTextMenu === data.name && "menu-text-selected"
          }`}
          onClick={() =>
            this.onClickTextMenu(
              data.name,
              data.type,
              data.name === "เกมยอดนิยม" ? true : false
            )
          }
        >
          {data.name}
        </span>
      </div>
    ));
    return <>{menuHandler}</>;
  };

  renderFAQ = () => {
    return (
      <div
        id="goto-qa-page"
        className="d-flex flex-row qna-container"
        onClick={this.handleOnQA}
      >
        <span className="qna-button-text" style={{ fontSize: "8px" }}>
          {constants.qa}
        </span>
        <Icon icon="jam:info" width="12px" height="12px" />
      </div>
    );
  };

  onClickTextMenu = (
    data: TMenuText,
    type: TGameType | "" | "ALL",
    isHot: boolean
  ) => {
    !["PG", "JILIGAME", "DREAMTECH", "WMGAME"].includes(
      this.state.currentPartnerMenu.alt
    )
      ? this.setState(
          (ps) => ({
            currentTextMenu: data,
            currentPartnerMenu: { ...ps.currentPartnerMenu, alt: "" },
          }),
          () => this.handleOnChangeCategory(type, isHot)
        )
      : this.setState({ currentTextMenu: data });
  };

  handleOnQA = () => {
    this.props.history.push(routes.qaType.exactPath("game"));
  };

  render() {
    const FAQ = this.renderFAQ;
    const CasinoGameComponent = this.renderCasinoGame;
    // const RenderTextMenu = this.renderTextMenu;
    // const RenderPartnerMenu = this.renderPartnerMenu;
    const RenderPartnerMenu = this._RenderPartnerComponent;
    return (
      <>
        <div
          className="col-12 position-fixed position-fixed"
          style={{ zIndex: 99, marginTop: 44 }}
        >
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <BackButton id="backto-previus-page" onClick={this.onClickBack} />
            <FAQ />
          </div>
        </div>
        <div
          className="casino-games-container secondary-bg-navy"
          ref={this.pageContainerRef}
          id={`casino-games-container`}
        >
          <div className="container">
            <div className="row" style={{ paddingTop: "100px" }}>
              <div className="col">
                <div className="d-flex justify-content-center">
                  <h2 className="secondary-text-gold">{constants.pageTitle}</h2>
                </div>
              </div>
            </div>
            <div
              // className="row m4-t p4-t mx-2 gx-5"
              className="row p4-t gx-1"
              ref={this.gameListContainerRef}
            >
              <CasinoGameComponent />
            </div>
            <div className="row" style={{ paddingTop: "42px" }}>
              <div className="col">
                <div className="d-flex justify-content-center">
                  <h2 className="secondary-text-gold">
                    {constants.allprovider}
                  </h2>
                </div>
              </div>
            </div>
            <RenderPartnerMenu />
            <div className="row">
              <div className="col"></div>
              <div className="col"></div>
            </div>
          </div>
          {!isEmpty(project.environmentName) ? (
            <></>
          ) : (
            <div className="casino-game-block-wrapper">
              <div className="background-water-line d-flex flex-column align-items-center justify-content-center">
                <img src={LogoTlb} alt="logo" className="logo-icon" />
                <h1 className="m4-t">{constants.comingSoon}</h1>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default CasinoGameContainer;
