import { createAction } from 'typesafe-actions'
import {
  GET_NUMBER_BAN_REQUEST,
  GET_NUMBER_BAN_SUCCESS,
  GET_NUMBER_BAN_FAILURE,
  GET_NUMBER_BAN_CANCEL
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getNumberBanAction = createAction(
  GET_NUMBER_BAN_REQUEST,
  resolve => (slugname: TLottoSlug) => resolve({ slugname }))

const getNumberBanSuccessAction = createAction(
  GET_NUMBER_BAN_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<Array<string>>>) => resolve(data))

const getNumberBanFailureAction = createAction(
  GET_NUMBER_BAN_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getNumberBanCancelAction = createAction(GET_NUMBER_BAN_CANCEL)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getNumberBanAction,
  getNumberBanSuccessAction,
  getNumberBanFailureAction,
  getNumberBanCancelAction,
}