import React, { Component, createRef, RefObject } from 'react'
import { ALink, Button, LottoBetItem, ErrorModal } from 'components'
import moment from 'moment-timezone'
import colors from 'constants/colors'
import routers from 'constants/routes'
import { noop, map } from 'lodash'
import { interactive } from 'utils'
import { RouteComponentProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { responseCode } from 'constants/response'
import './lottoOldFavorite.style.scss'

const constants = {
  backText: 'ย้อนกลับ',
  favoriteList: 'รายการชุดตัวเลข',
  favoriteManagement: 'จัดการเลขชุด',
  select: 'เลือก',
  historyOld: 'ดึงโพยเก่า',
  note: 'ระบบสะสมเพียง 10 โพยล่าสุดเท่านั้น',
  historyBet: 'ประวัติการแทง',
  notFound: 'ไม่พบข้อมูล',
  lotter: 'แทงหวย',
}

const defaultProps: IOldFavoriteLottoProps & IOldFavoriteLottoActionProps = {
  getOldFavoriteLottoList() { noop() },
  loader() { noop() },
  clearOldFavoriteLottoList() {noop()},
  lottoOldFavoriteList:[],
  getOldFavoriteLottoListIsFetching: false,
  getOldFavoriteLottoListCode: 0,
  getOldFavoriteLottoListError: '',
}

class LottoFavoriteContainer extends Component<
IOldFavoriteLottoProps & IOldFavoriteLottoActionProps
  & RouteComponentProps<any, any, IMakingLottoRouteProps | IBetLotteryPullListRouteProps1>
> {

  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    const { lottoSlug } =this.props.location.state as IBetLotteryPullListRouteProps
    interactive.rootElement.scrollToTop()
    this.props.loader(true)
    const queryData: APIPaginationQuery & IBetLotteryPullListRequest = {
      limit: 10,
      page: 1,
      lottery_type:lottoSlug,
    }
    this.props.getOldFavoriteLottoList(queryData,true)

  }

  componentDidUpdate(prevProps: IOldFavoriteLottoProps) {
    if (prevProps.getOldFavoriteLottoListIsFetching !== this.props.getOldFavoriteLottoListIsFetching
      && !this.props.getOldFavoriteLottoListIsFetching) {
      this.props.loader(false)
      if (this.props.getOldFavoriteLottoListCode !== responseCode.OK
        && this.props.getOldFavoriteLottoListCode !== responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: () => ErrorModal.hide,
          description: this.props.getOldFavoriteLottoListError,
        })
      }
    }
  }

  componentWillUnmount(){
    this.props.clearOldFavoriteLottoList()
  }
  onPressBack = () => {
    const { lottoSlug, selectedLottoGame, betList } = this.props.location.state as IBetLotteryPullListRouteProps
    const makeLottoRouteProps: IMakingLottoRouteProps = {
      selectedLottoGame,
      betList,
    }
    this.props.history.replace(routers.lottoMaking.exactPath(lottoSlug), makeLottoRouteProps)
  }

  renderHistoryLotterList = () => {
    const historyBetItem = map(this.props.lottoOldFavoriteList, (transaction, index) => {
      return (
        <LottoBetItem
          containerClassName="mt-2 mx-0"
          key={`${transaction.lotteryType}-${transaction.createdAt}-${index}`}
          totalNumbers={transaction.totalNumber}
          totalValue={transaction.totalValue}
          time={moment(transaction.createdAt,'YYYYMMDDHHmmss').format('ll')}
          onClick={() => this.handleOnGotoCustomBet(transaction)}
        />
      )
    })
    return historyBetItem
  }
  handleOnGotoCustomBet = (transaction: IOldFavoriteSet) => {
    const locationState: ILottoFavoriteRouteProps = this.props.location.state as ILottoFavoriteRouteProps
    const favoriteOldRouteProps: IBetLotteryPullListRouteProps = {
      betList: locationState.betList,
      lottoSlug: locationState.lottoSlug,
      selectedLottoGame: locationState.selectedLottoGame,
      keyLocation: this.props.history.location.key!,
    }
    this.props.history.push(routers.lottoOldFavoriteDetail.path, {betLotteryPullList:transaction,
      ...favoriteOldRouteProps})
  }

  render() {
    const historyBetItem = this.renderHistoryLotterList()
    return (
      <div className="lotto-old-favorite-container secondary-bg-navy" ref={this.pageContainerRef}>
        <div className="container">
          <div className="row">
            <div className="col">
              <ALink id="backto-previus-page" color={colors.PRIMARY_RED} bold onClick={this.onPressBack}>
                <FontAwesomeIcon icon={faChevronLeft} className="m1-r" />
                {constants.backText}
              </ALink>
            </div>
          </div>
          <div className="row p3-t">
            <div className="col">
              <div className="d-flex flex-row align-items-center ">
                <h2 className="flex title-responsive">{constants.historyOld}</h2>
                <div className="note-responsive secondary-text">{constants.note}</div>
              </div>
            </div>
          </div>
          <div className="row m2-t">
            <div className="col">
              <div className="d-flex flex-row align-items-center m1-b">
                <h4 className="flex history-bet-responsive">{constants.historyBet}</h4>
                <div style={{width:100}}>
                  <Button
                    size="small"
                    id="history-old"
                    type="rectangle"
                    text={constants.lotter}
                    textResponsive={true}
                    backgroundColor={colors.PRIMARY_BLUE}
                    backgroundHoverColor={colors.SECONDARY_BLUE}
                    onClick={this.onPressBack}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="border-rounded">
                { this.props.lottoOldFavoriteList.length === 0
                ? <h5 className="flex history-bet-responsive secondary-text m2-l m1-t">{constants.notFound}</h5>
                : historyBetItem}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LottoFavoriteContainer