import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  takeUntil,
  filter,
  map,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { fetchAffilateWithdrawal } from './services'
import actions from './actions'

const affilateWithdrawalEpic: Epic<RootAction, RootAction, RootReducers> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(actions.affilateWithdrawalAction)),
    exhaustMap(action =>
      from(fetchAffilateWithdrawal(action.payload))
        .pipe(
          map(actions.affilateWithdrawalSuccessAction),
          catchError(error => of(actions.affilateWithdrawalFailureAction(error))),
          takeUntil(action$.pipe(filter(isActionOf(actions.affilateWithdrawalCancelAction))))
        ),
    )
  )
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  affilateWithdrawalEpic,
]
