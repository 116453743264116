import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import actions from './actions'
import { initialState } from './constants'

const userCountReducer = (state: IUserCountState = initialState, action: RootAction):IUserCountState => {
   switch (action.type) {
       case getType(actions.getCountAction):
           return {
               ...state,
               isFetching: true,
           }
        case getType(actions.getCountSuccessAction):
            return {
                isFetching: false,
                data: action.payload.data.data,
                code: action.payload.data.code,
            }
        case getType(actions.getCountFailureAction):
            return {
                isFetching: false,
                devMessage: action.payload.response?.data.devMessage,
                code: action.payload.response?.data.code,

            }
        default:
            return state
   }
}

export default userCountReducer