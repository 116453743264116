import { getType } from 'typesafe-actions'
import { RootAction } from 'typings/reduxs/Actions'
import { initialState } from './constants'
import actions from './actions'

const codeLotteryReducer = (state: ICodeLotterGameState = initialState, action: RootAction): ICodeLotterGameState => {
  switch (action.type) {
    case getType(actions.persistedCodeLotteryAction):
      return {
        ...state,
        code: action.payload.code,
        oldCode: action.payload.oldCode,
      }
    default:
      return state
  }
}

export default codeLotteryReducer