
import React, { FC } from 'react'
import { number } from 'utils';
import './progressbar.style.scss'

const ProgressBar: FC<any> = ({min, max, era}) => {
    const [style, setStyle] = React.useState({});
	setTimeout(() => {
        const mapPercent = (100*min)/max
		const newStyle = {
			opacity: 1,
			width: `${mapPercent}%`,
            maxWidth: `100%`,
		}
		setStyle(newStyle);
	}, 200);

    return (
        <div className="progress">
               <span className="progress-text text-center">{number.castToMoney(min)} / {number.castToMoney(max)}</span>
            <div className={`progress-done-${era}`} style={style} />
        </div>
    )
}

export default ProgressBar
