import React, { Component, createRef, RefObject } from 'react'
import { interactive, number } from 'utils'
import moment from 'moment-timezone'
import colors from 'constants/colors'
import routers from 'constants/routes'
import './lottoOldFavoriteDetail.style.scss'
import { responseCode } from 'constants/response'
import { RouteComponentProps } from 'react-router-dom'
import { noop, groupBy, Dictionary, map, keys, pickBy, filter, find, isEmpty, forIn } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { ConfirmModal } from './components'
import { ALink, ErrorModal, LottoBetItem, CheckBox, BetLotteryDetailItem, InfoModal } from 'components'

import { LOTTO_GAME_TYPE_NAME, LOTTO_BET_TYPES } from 'constants/variables'
const constants = {
  backText: 'ย้อนกลับ',
  detail: 'รายละเอียด',
  selectAll: 'เลือกทั้งหมด',
  select: 'เลือก',
  historyOld: 'รายละเอียดและปรับแต่งโพย',
  note: 'ระบบสะสมเพียง 10 โพย ล่าสุดเท่านั้น',
  historyBet: 'ประวัติการแทง',
  lotter: 'แทงหวย',
  confirm: 'ยืนยันดึงโพย',
  announce: 'โพยดังกล่าวไม่มีข้อมูล',
  accept: 'เข้าใจ',
  description: 'กรุณาเลือกเลขจากโพยดังกล่าวเพื่อใช้งานระบบดึงโพยจากประวัติ'
}

const defaultProps: IBetLotteryPullDetailProps & IBetLotteryPullDetailActionProps = {
  getBetLotteryPullDetail() { noop() },
  loader() { noop() },
  getBetLotteryPullDetailFetching: false,
  getBetLotteryPullDetailCode: 0,
  getBetLotteryPullDetailError: '',
  betLotteryPullDetail: [],
  betLotteryPullDetailPagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
}

class LottoFavoriteContainer extends Component<
  IBetLotteryPullDetailProps & IBetLotteryPullDetailActionProps
  & RouteComponentProps<any, any, IMakingLottoRouteProps | IBetLotteryPullListRouteProps1 | ILottoFavoriteRouteProps>
> {
  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  state: IBetLotteryPullDetailContainerState = {
    hasMore: true,
    betLotteryPullDetail: [],
    selectAll: true,
    keys: {
      TWO_UP: true,
      TWO_DOWN: true,
      THREE_UP: true,
      THREE_TOAST: true,
      FOUR_SUITE: true,
      RUN_UP: true,
      RUN_DOWN: true,
    },
    totalValue: 0,
    totalNumbers: 0,
  };

  componentDidMount() {
    interactive.rootElement.scrollToTop()
    this.props.loader(true)
    this.onGetBetLotteryPullDetail(true)
  }

  componentDidUpdate(prevProps: IBetLotteryPullDetailProps) {
    if (prevProps.getBetLotteryPullDetailFetching !== this.props.getBetLotteryPullDetailFetching
      && !this.props.getBetLotteryPullDetailFetching) {
      this.props.loader(false)
      if (this.props.getBetLotteryPullDetailCode === responseCode.OK) {
        // store bet ischeck
        const isCheckCurentBet: IResBetLotteryPullDetail[] = this.state.betLotteryPullDetail.filter((betDeatil) => {
          return betDeatil.isCheck === true
        })


        this.setState({
          keys: isEmpty(isCheckCurentBet) ? this.handleIsCheckSelect(true) : this.state.keys,
          betLotteryPullDetail: isEmpty(isCheckCurentBet)
            ? this.handleIsSelectAll(true)
            : this.unionByBetDetail(isCheckCurentBet),
        }, () => this.sumNumberBet())
        if (this.props.betLotteryPullDetailPagination.page >= this.props.betLotteryPullDetailPagination.total) {
          this.setState({ hasMore: false })
        }
      }
      else if (this.props.getBetLotteryPullDetailCode !== responseCode.OK
        && this.props.getBetLotteryPullDetailCode !== responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: () => ErrorModal.hide,
          description: this.props.getBetLotteryPullDetailError,
        })
      }
    }
  }

  handleCheckSelectAll = () => {
    const isSelectTypeList = keys(pickBy(this.state.keys)).length
    const numberAllType = Object.keys(this.state.keys).length
    const betLotteryPullDetail: IResBetLotteryPullDetail[] = this.state.betLotteryPullDetail
    const notNumberCheck = find(betLotteryPullDetail, ['isCheck', false])
    this.setState({ selectAll: (isSelectTypeList === numberAllType) && !notNumberCheck ? true : false })
  }

  handleIsCheckSelect = (isCheck: boolean) => {
    const isCheckSelect: any = {}
    Array.from(new Set(this.props.betLotteryPullDetail.map(
      (data: IResBetLotteryPullDetail) => data.type))).map((lottoType: TLottoGameType) => {
        isCheckSelect[lottoType] = isCheck
        return true
      })
    return isCheckSelect
  }

  handleSelectTypeWithCheckBox = (type: TLottoGameType) => {
    const isCheckSelect: IBetType = this.state.keys
    const notNumberCheck = filter(this.state.betLotteryPullDetail, { type: type, isCheck: false })
    if (isEmpty(notNumberCheck)) {
      isCheckSelect[type] = true
    }
    else {
      isCheckSelect[type] = false
      this.setState({ keys: isCheckSelect })
    }
  }


  unionByBetDetail = (dataList: IResBetLotteryPullDetail[]) => {
    const defaultBetList: any = this.handleIsSelectAll(true)
    map(defaultBetList, ((data, index) => {
      dataList.forEach(dataIsCheck => {
        if (data.id === dataIsCheck.id) {
          defaultBetList[index] = dataIsCheck
        }
      })
    }))

    // Check is select key then new page
    const keysSelect: IBetType = this.state.keys
    forIn(this.state.keys, (value, key) => {
      if (value) {
        const newBetList = defaultBetList.filter((betDeatil: any) =>
          betDeatil.type === key && betDeatil.isCheck === false)
        if (!isEmpty(newBetList)) {
          keysSelect[key as TLottoGameType] = false
        }
      }
    });
    this.setState({ keys: keysSelect }, () => this.handleCheckSelectAll())
    return defaultBetList
  }

  onGetBetLotteryPullDetail = (isFirst = false) => {
    const { betLotteryPullList } = this.props.location.state as IBetLotteryPullListRouteProps1
    const queryData: APIPaginationQuery & IBetLotteryPullDetailRequest = {
      id: betLotteryPullList.id,
      limit: betLotteryPullList.totalNumber,
      page: isFirst ? 1 : this.props.betLotteryPullDetailPagination.page + 1,
    }
    this.props.getBetLotteryPullDetail(queryData, isFirst)
  }

  onPressBack = () => {
    this.props.history.goBack()
  }

  onClickFavoriteManagement = () => {
    this.props.history.push(routers.lottoFavoriteManagement.path)
  }


  onClickBetListActive = (favoriteItems: IResBetLotteryPullDetail[]) => {
    const { betLotteryPullList } = this.props.location.state as IBetLotteryPullListRouteProps1
    if(isEmpty( favoriteItems)) {
      InfoModal.show({
        action: InfoModal.hide,
        title: constants.announce,
        actionText: constants.accept,
        description: constants.description,
      },'md')
    }
    else{
      ConfirmModal.show({
        onCancel: ConfirmModal.hide,
        onConfirm: () => {
          this.props.loader(true)
          this.onConfirmBet(favoriteItems)
          ConfirmModal.hide()
        },
        betDetail: betLotteryPullList,
        totalNumbers: this.state.totalNumbers,
        totalValue: this.state.totalValue,
        title: constants.confirm,
      })
    }

  }

  onConfirmBet = (favoriteItems: IResBetLotteryPullDetail[]) =>{
    const locationState: ILottoFavoriteRouteProps = this.props.location.state as ILottoFavoriteRouteProps
    const finding = (numb: ILottoNumber) => isEmpty(find(favoriteItems, { number: numb.number, type: numb.type }))
    const newNumberList: ILottoNumber[] = locationState.betList.filter(finding)

    const currentTime = moment().local().format('DDMMYYYYHHmm')
    const addNumberList: ILottoNumber[] = favoriteItems.map(numb => ({
      number: numb.number,
      type: numb.type,
      value: numb.value,
      slug: `${locationState.lottoSlug}_${currentTime}${number.padNumber(locationState.selectedLottoGame.round, 3)}`,
    }))

    const appendedList: ILottoNumber[] = [
      ...newNumberList,
      ...addNumberList,
    ]

    const { lottoSlug, keyLocation } = this.props.location.state as ILottoFavoriteRouteProps
    const makeLottoRouteProps: IMakingLottoRouteProps = {
      betList: appendedList,
      selectedLottoGame: locationState.selectedLottoGame,
      keyLocation: keyLocation,
    }
  this.props.history.replace(routers.lottoMaking.exactPath(lottoSlug), makeLottoRouteProps)
  }

  onGetbetLotteryPullDetail = (isFirst = false) => {
    const { betLotteryPullList } = this.props.location.state as IBetLotteryPullListRouteProps1
    const queryData: APIPaginationQuery & IBetLotteryPullDetailRequest = {
      id: betLotteryPullList.id,
      limit: 10,
      page: isFirst ? 1 : this.props.betLotteryPullDetailPagination.page + 1,
    }
    this.props.getBetLotteryPullDetail(queryData, isFirst)
  }

  renderHistoryLotterList = () => {
    const { betLotteryPullList } = this.props.location.state as IBetLotteryPullListRouteProps1
    return (
      <LottoBetItem
        containerClassName="mt-2"
        key={`${betLotteryPullList.lotteryType}-${betLotteryPullList.createdAt}-${betLotteryPullList.id}`}
        totalNumbers={this.state.totalNumbers}
        totalValue={this.state.totalValue}
        time={moment(betLotteryPullList.createdAt, 'YYYYMMDDHHmmss').format('ll')}
        onClick={() => this.onClickBetListActive(filter(this.state.betLotteryPullDetail, { isCheck: true }))}
      />
    )
  }

  handleIsSelectAll = (isCheck: boolean) => {
    return this.props.betLotteryPullDetail.map(v => ({ ...v, isCheck: v.isCheck ? true : isCheck }))
  }
  onSelectAll = () => {
    this.setState({ selectAll: !this.state.selectAll }, () => {
      if (!this.state.selectAll) {
        this.setState({
          betLotteryPullDetail: this.handleIsSelectAll(false),
          keys: this.handleIsCheckSelect(false),
        }, () => this.sumNumberBet())
      }
      else {
        this.setState({
          betLotteryPullDetail: this.handleIsSelectAll(true),
          keys: this.handleIsCheckSelect(true),
        }, () => this.sumNumberBet())
      }
    })
  }

  onCheckBoxSelect = (listBetType: IResBetLotteryPullDetail[]) => {
    const isCheckSelect: IBetType = this.state.keys
    let isAllSelect: IResBetLotteryPullDetail[]
    isCheckSelect[listBetType[0].type] = !isCheckSelect[listBetType[0].type]
    this.setState({ keys: isCheckSelect }, () => {
      this.handleCheckSelectAll()
    })

    if (isCheckSelect[listBetType[0].type]) {
      isAllSelect = listBetType.map(data => ({
        ...data, isCheck: true,
      }))
    }
    else {
      isAllSelect = listBetType.map(data => ({
        ...data, isCheck: false,
      }))
    }

    const betLotteryPullDetail: IResBetLotteryPullDetail[] = this.state.betLotteryPullDetail
    map(this.state.betLotteryPullDetail, ((dataList, i) => {
      isAllSelect.forEach(dataIsCheck => {
        if (dataList.id === dataIsCheck.id) {
          betLotteryPullDetail[i] = dataIsCheck
        }
      })
    }))
    this.setState({ betLotteryPullDetail: betLotteryPullDetail }, () => this.sumNumberBet())
  }

  onCheckBox = (numberBet: IResBetLotteryPullDetail) => {
    const betLotteryPullDetail: IResBetLotteryPullDetail[] = this.state.betLotteryPullDetail
    const index = betLotteryPullDetail.map(l => l.id).indexOf(numberBet.id)
    if (index > -1) {
      numberBet.isCheck = !numberBet.isCheck
      betLotteryPullDetail[index] = numberBet
      this.setState({
        betLotteryPullDetail: betLotteryPullDetail,
      })
      this.sumNumberBet()
      this.handleSelectTypeWithCheckBox(numberBet.type)
      this.handleCheckSelectAll()
    }
  }

  sumNumberBet = () => {
    const numberIsSelect = filter(this.state.betLotteryPullDetail, 'isCheck');
    if (!isEmpty(numberIsSelect)) {
      const sum = (numberList: Number[]) => numberList.reduce((acc: Number, curr: Number) => Number(acc) + Number(curr));
      this.setState({
        totalNumbers: numberIsSelect.length,
        totalValue: sum(numberIsSelect.map(item => Number(item.value)))
      })
    }
    else {
      this.setState({
        totalNumbers: 0,
        totalValue: 0,
      })
    }
  }
  renderOldFavoriteLottoList = () => {
    const betLotteryPullDetailGroupList: Dictionary<IResBetLotteryPullDetail[]> =
      groupBy<IResBetLotteryPullDetail>(this.state.betLotteryPullDetail, 'type')
    return (
          map(betLotteryPullDetailGroupList, (_, key, index) => {
            const betLotteryItem = betLotteryPullDetailGroupList[key]
              .map((betLotteryPullDetail, betLotteryPullDetailIndex) => {
                return (
                  <BetLotteryDetailItem
                    betLotteryPullDetail={betLotteryPullDetail}
                    key={`credit-info-items-${betLotteryPullDetailIndex}`}
                    selectAll={
                      this.state.selectAll && this.state.keys[LOTTO_BET_TYPES[key as TLottoGameType]] ? false : false
                    }
                    onClick={() => this.onCheckBox(betLotteryPullDetail)}
                  />
                )
              })
            return (
              <div className="row mt-3" key={`${key}-${index}`}>
                <div className="col">
                  <div className="row">
                    <div className="col d-flex flex-row py-2">
                      <div className="col-3">
                        <div className="lotto-type-responsive">
                          {LOTTO_GAME_TYPE_NAME[key as TLottoGameType]}
                        </div>
                      </div>
                      <div className="col-4">
                        { }
                      </div>
                      <div className="col-4">
                        <CheckBox
                          selectAll={
                            this.state.selectAll && this.state.keys[LOTTO_BET_TYPES[key as TLottoGameType]]
                              ? false : false
                          }
                          isSelect={this.state.keys[LOTTO_BET_TYPES[key as TLottoGameType]]}
                          id={`Select${key}-${index}`}
                          onClick={() => this.onCheckBoxSelect(betLotteryPullDetailGroupList[key])}
                        />
                      </div>
                    </div>
                  </div>
                  {betLotteryItem}
                </div>
              </div>
            )
          })
    )
  }

  render() {
    const historyBetItem = this.renderHistoryLotterList()
    const historyBetItemList = this.renderOldFavoriteLottoList()

    return (
      <div className="lotto-old-favorite-container secondary-bg-navy" ref={this.pageContainerRef}>
        <div className="container">
          <div className="row">
            <div className="col">
              <ALink id="backto-previus-page" color={colors.PRIMARY_RED} bold onClick={this.onPressBack}>
                <FontAwesomeIcon icon={faChevronLeft} className="m1-r" />
                {constants.backText}
              </ALink>
            </div>
          </div>
          <div className="row p3-t">
            <div className="col">
              <div className="d-flex flex-row align-items-center ">
                <h2 className="flex title-responsive">{constants.historyOld}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="border-rounded">
                {historyBetItem}
                <div className="container-padding">
                  <div className="row m2-t">
                    <div className="col">
                      <div className="d-flex flex-row align-items-center m1-b">
                        <h4 className="flex history-bet-responsive">{constants.detail}</h4>
                        <div className="d-flex flex-row align-items-center ">
                          <div>
                            <h4 className="m1-r note-responsive">{constants.selectAll}</h4>
                          </div>
                          <div>
                            <CheckBox
                              id="selectAll"
                              selectAll={this.state.selectAll}
                              onClick={() => this.onSelectAll()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col" style={{ paddingRight: '0px' }}>
                      {historyBetItemList}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LottoFavoriteContainer