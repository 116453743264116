import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, exhaustMap, takeUntil, filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { fetchPostNotificationClick } from "./services";
import { RootAction } from "typings/reduxs/Actions";
import actions from "./actions";

const notificationClickEpic: Epic<RootAction, RootAction, RootReducers> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(actions.postNotificationClickAction)),
    exhaustMap((action) =>
      from(fetchPostNotificationClick(action.payload)).pipe(
        map(actions.postNotificationClickSuccessAction),
        catchError((error) =>
          of(actions.postNotificationClickFailureAction(error))
        ),
        takeUntil(
          action$.pipe(
            filter(isActionOf(actions.postNotificationClickCancelAction))
          )
        )
      )
    )
  );
// eslint-disable-next-line import/no-anonymous-default-export
export default [notificationClickEpic];
