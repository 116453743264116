import React, { Component, createRef, RefObject } from 'react'
import LogoThailandBet from 'assets/images/logo/logothailandbet03.png'
// import YakImage from 'assets/images/logo/yak.png'
import './casino.style.scss'
import { RouteComponentProps } from 'react-router-dom'
import routes from 'constants/routes'
import { interactive } from 'utils'

const constants = {
  back: 'ย้อนกลับ',
  eCasino: 'คาสิโนออนไลน์',
  eCasinoDescription: 'บาคาร่า, ผู้เล่นคนเดียว, ผู้เล่นหลายคน',
  casinoGame: 'คาสิโนเกม',
  casinoGameDescription: 'สล็อต, ยิงปลา, บิงโก',
}

class CasinoContainer extends Component<RouteComponentProps> {
  pageContainerRef: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    interactive.rootElement.scrollToTop()
  }

  onPressBack = () => {
    this.props.history.goBack()
  }


  render() {
    return (
      <div className="casino-container secondary-bg-navy" ref={this.pageContainerRef}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div
                className="casino-group-wrapper mt-3"
                onClick={() => this.props.history.push(routes.casino.path)}
              >
                <h1 className="casino-group-text">{constants.eCasino}</h1>
                <h6 className="body-2 casino-group-text">{constants.eCasinoDescription}</h6>
                <div className="casino-group-image-wrapper">
                  <img src={LogoThailandBet} alt="logo" className="casino-group-image" />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div
                className="casino-group-wrapper mt-3"
                onClick={() => this.props.history.push(routes.slot.path)}
              >
                <h1 className="casino-group-text">{constants.casinoGame}</h1>
                <h6 className="body-2 casino-group-text">{constants.casinoGameDescription}</h6>
                <div className="casino-group-image-wrapper">
                  <img src={LogoThailandBet} alt="logo" className="casino-group-image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CasinoContainer