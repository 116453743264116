import React, { FC } from 'react'
import LogoThailandBet from 'assets/images/logo/logothailandbet04.png'
import { noop } from 'lodash'
import { number } from 'utils'
import routers from 'constants/routes'
import { useHistory /*useLocation*/ } from 'react-router-dom'
import { Icon } from '@iconify/react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCreditCard/*faBars*/ } from '@fortawesome/free-solid-svg-icons'
import './navbar.style.scss'
import Coin from 'assets/images/pmpg/chinese-coin.svg'
import BronzeIcon from 'assets/images/pmpg/bronze.svg'
import SilverIcon from 'assets/images/pmpg/diamond.svg'
import GoldIcon from 'assets/images/pmpg/chest.svg'
import TinIcon from 'assets/images/pmpg/tin.svg'
import LineIcon from 'assets/images/icon/line.png'
// import colors from 'constants/colors'
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
// import { ALink } from 'components'
type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: INavbarProps = {
  mode: 'darkMode',
  isDisplayWallet: true,
  isAuthorized: false,
  wallet: {},
  jackpot: 0,
  jackpotTitle: '',
  onPressesMenu() {
    noop()
  },
  webConfig: {
    id: 0,
    textRunner: '',
    contactUrl: '',
    contactLine: '',
    contactPhoneNumber: '',
    updatedAt: '',
  },
}
const constants = {
  contactus: 'ติดต่อแอดมิน',
}

const Navbar: FC<INavbarProps & DefaultProps> = ({
  webConfig,
  mode,
  wallet,
  jackpot,
  isAuthorized,
  isDisplayWallet,
  jackpotTitle,
  jackpotIcon,
  onPressesMenu,
}) => {
  const history = useHistory()

  // const location = useLocation();
  // const onPressBack = () => {
  //   history.goBack()
  // }

  const handleOnClick = () => {
    history.push(routers.main.path)
  }

  const onPressLine = () => {
    window.open(`https://line.me/R/ti/p/${webConfig.contactLine}`, '_blank')
  }

  const mapIconCurrectBonus: { [key in IEraType]: string } = {
    GOLD: GoldIcon,
    SILVER: SilverIcon,
    BRONZE: BronzeIcon,
    TIN: TinIcon,
    '': Coin,
  }

  const AgeProgress = () => {
    if (isAuthorized) {
      return (
        <div
          id="badge-jackpot"
          className={`age-badge-wrapper ${mode} d-flex flex-row align-items-center`}
        >
          <img
            className="icon-bonus pr-1"
            src={mapIconCurrectBonus[jackpotIcon!]}
            alt={jackpotIcon}
          />
          <span className="jackpot-title pr-1">
            &nbsp;
            {jackpotTitle}
          </span>
          <span className="body-1 secondary-text-gold text-jackpot">{`${number.addComma(jackpot || 0)} บ`}</span>
        </div>
      )
    }
    return <></>
  }

  const CreditBadgeComponent = () => {
    if (isAuthorized) {
      if (!isDisplayWallet) {
        return <></>
      }
      return (
        <div className={`credit-badge-wrapper ${mode}`}>
          <h6 className="total-credit secondary-text-gold">
            {/* <FontAwesomeIcon icon={faCreditCard} className="m0-r" /> */}
            {`${number.addComma(wallet?.money || 0)} บ`}
          </h6>
        </div>
      )
    }
    return <></>
  }

  const BurgerComponent = () => {
    if (isAuthorized) {
      return (
        <Icon
          icon="solar:hamburger-menu-broken"
          className="burger-container"
          onClick={onPressesMenu}
        />
      )
    }
    return <></>
  }

  const authorizedClass = () => {
    return isAuthorized ? '' : 'non-authorized'
  }
  return ( 
    <div id="navbar-container" className={`col-12 navbar-container position-fixed ${mode} ${authorizedClass()}`}>
      <div className="container">
        <div className="wrapper-navbar w-100">
          <div className="d-flex">
            <div className="leading-navbar-container" onClick={handleOnClick}>
              <img
                src={LogoThailandBet}
                alt="thailand"
                className="logo-container"
              />
            </div>
            {/* <div className="jackpot-navbar-container d-none d-sm-block d-md-block align-self-end">
                <AgeProgress />
                </div> */
            }
            <div className="trailing-navbar-container">
              <CreditBadgeComponent />
              <div className="row">
                <div className="col">
                  <div className="contact-container" onClick={onPressLine}>
                    <img src={LineIcon} alt={'line'} className="line-icon" />
                    <div className="line-wrapper">
                      <div id="goto-line-contact" className="line-button">
                        <h6 className="line-text">{constants.contactus}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BurgerComponent />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <AgeProgress />
            </div>
          </div>
        </div>

      </div>
      {/* <div className="mt-2 d-lg-none d-md-none d-sm-none d-xs-block"> */}
      <div className="mt-1">
        {/* <AgeProgress /> */}
      </div>
    </div>
  )
}

Navbar.defaultProps = defaultProps

export default Navbar
