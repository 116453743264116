import project from 'constants/project'
import { transformer } from 'utils'

export const GET_OLD_FAVORITE_LOTTO_LIST_REQUEST = 'GET_OLD_FAVORITE_LOTTO_LIST_REQUEST'
export const GET_OLD_FAVORITE_LOTTO_LIST_SUCCESS = 'GET_OLD_FAVORITE_LOTTO_LIST_SUCCESS'
export const GET_OLD_FAVORITE_LOTTO_LIST_FAILURE = 'GET_OLD_FAVORITE_LOTTO_LIST_FAILURE'
export const GET_OLD_FAVORITE_LOTTO_LIST_CANCEL = 'GET_OLD_FAVORITE_LOTTO_LIST_CANCEL'
export const CLEAR_OLD_FAVORITE_LOTTO_LIST = 'CLEAR_OLD_FAVORITE_LOTTO_LIST'

export const initialState: IOldFavoriteLottoState = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
  pagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
}


export const endpoint = {
  getOldFavoriteLottoList: (query?: APIPaginationQuery) =>`${project.environment[project.environmentName].api}/bet/lottery/pull/list${transformer.urlSearchParams(query)}`,
}