import project from 'constants/project'
import { transformer } from 'utils'

export const GET_CREDIT_INFO_BET_LOTTERY_REQUEST = 'GET_CREDIT_INFO_BET_LOTTERY_REQUEST'
export const GET_CREDIT_INFO_BET_LOTTERY_SUCCESS = 'GET_CREDIT_INFO_BET_LOTTERY_SUCCESS'
export const GET_CREDIT_INFO_BET_LOTTERY_FAILURE = 'GET_CREDIT_INFO_BET_LOTTERY_FAILURE'
export const GET_CREDIT_INFO_BET_LOTTERY_CANCEL = 'GET_CREDIT_INFO_BET_LOTTERY_CANCEL'
export const CLEAR_CREDIT_INFO_BET_LOTTERY = 'CLEAR_CREDIT_INFO_BET_LOTTERY'

export const initialState: ICreditInfoBetLotteryState = {
  isFetching: false,
  code: 0,
  data: [],
  pagination: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  error: '',
}

export const endpoint = {
  getCreditInfoBetLottery: (query?: APIPaginationQuery) => `${project.environment[project.environmentName].api}/bet/lottery${transformer.urlSearchParams(query)}`,
}