import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { date, transformer } from 'utils'

import './playedUsers.style.scss'
import { get } from 'lodash'
import Scrollbar from 'react-scrollbars-custom'

const windowHeight = window.innerHeight
const constants = {
  nameList: 'รายการยิงเลข',
}

const defaultProps: IPlayedUsers = {
  playedYeegeList: [],
  isLottoClose:false
}

const PlayedUser: FC<IPlayedUsers> = ({ playedYeegeList,isLottoClose }) => {

  var heightNavbar = get(document.getElementById('navbar-container'),"clientHeight",0);
    var heightTabbar = get(document.getElementById('tabbar-container'),"clientHeight",0);
    var heightJackpot = get(document.getElementById('badge-jackpot'),"clientHeight",0);
    var heightTop = heightNavbar+heightJackpot
    var tabbarNavbar = heightTabbar+heightTop
    var heightHeader = get(document.getElementById('header-lotto-make'),"clientHeight",0);
    var heightBack = get(document.getElementById('btn-back'),"clientHeight",0);
    var heightHeaderGameResult = get(document.getElementById('header-yeege-game-result'),"clientHeight",0);
    var heightYeegeGameResult = get(document.getElementById('yeege-game-result'),"clientHeight",0);
    
    var heighFullMain = windowHeight - (tabbarNavbar+10)

  const [collapseState, setCollapseState] = useState(false)

  const Header = () => (
    <div id="header-yeege-game-result" className="d-flex flex-row align-items-center justify-content-between font-m25r w-100 p-1 Header header-yeege-game-result tertiary-bg-blue-new"
      onClick={()=>{setCollapseState(!collapseState)}}
    >
      <h3 className="flex font-m25r">{constants.nameList}</h3>
      <FontAwesomeIcon icon={faChevronRight} className={`chevron-right-icon ${collapseState ? 'expanded' : ''} secondary-text-gold`}/>
    </div>
  )

  const renderPlayedUsers = () => {
    return (
      
      <div
        style={isLottoClose?{maxHeight:heighFullMain-(heightHeader+heightBack+heightHeaderGameResult+heightYeegeGameResult+16)}:{}}
        className={`player-game-wrapper p-2 ${collapseState&&'open'}`}
      >
       <Scrollbar
        id="player-game-wrapper"
        removeTracksWhenNotUsed 
        disableTracksWidthCompensation 
      > 
        {
          playedYeegeList.slice(0, collapseState?playedYeegeList.length:3).map((played, playedIndex) => {
            const time = date.calibratingTime(played.createdAt).clone().format('HH:mm:ss')
            return (
              <div className="d-flex flex-row p-2 pr-3 mb-1 w-100 Header tertiary-bg-blue-new" key={`played-game-user-${playedIndex}`}>
                <div className="seq-player-number"><h4 className='font-m15r white-text'>{playedIndex + 1}</h4></div>
                <h4 className="flex font-m15r white-text">{transformer.hiddenString(played.userId?.username || '')}</h4>
                <div className="text-right">
                  <h4 className='font-m15r white-text'>{played.number}</h4>
                  <h4 className="font-m15r">{time}</h4>
                </div>
              </div>
            )
          })
        }
      </Scrollbar>
      </div>
    )
  }

  return (
    <div
      className={`played-game-container tertiary-bg-blue my-1 p-1 ${isLottoClose&&'CLOSE'}`}
    >
      {Header()}   
      {renderPlayedUsers()}
    </div>
  )
}

PlayedUser.defaultProps = defaultProps

export default PlayedUser