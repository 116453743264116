import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootAction } from 'typings/reduxs/Actions'
import lottoAction from 'reduxs/lotto/actions'
import loaderAction from 'reduxs/loader/actions'
import lottoSelector from 'reduxs/lotto/selectors'
import LottoResultContainer from './LottoResult.container'

const mapStateToProps = (state: RootReducers): ILottoResultProps => {
    const lottoResultList = lottoSelector.lottoResultList(state)
    return {
        getLottoIsFetching: lottoResultList.isFetching!,
        getLottoError: lottoResultList.error!,
        getLottoCode: lottoResultList.code!,
        lottos: lottoResultList.data!,
    }
}

const mapDispatchToProos = (dispatch: Dispatch<RootAction>): ILottoResultActionProps => bindActionCreators({
    loader: loaderAction.loadingAction,
    getLottoResult: lottoAction.getLottoListAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProos)(LottoResultContainer)