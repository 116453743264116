import { createAction } from 'typesafe-actions'
import {
  GET_GAME_LIST_REQUEST,
  GET_GAME_LIST_SUCCESS,
  GET_GAME_LIST_FAILURE,
  GET_GAME_LIST_CANCEL,
  CLEAR_GAME_LIST,
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const getGameListAction = createAction(
  GET_GAME_LIST_REQUEST,
  resolve => (gameLocation: TGameLocation, query: IGameQuery) => resolve({ query, gameLocation }))

const getGameListSuccessAction = createAction(
  GET_GAME_LIST_SUCCESS,
  resolve => (data: AxiosResponse<APIResponse<IGame[]>>) => resolve(data))

const getGameListFailureAction = createAction(
  GET_GAME_LIST_FAILURE,
  resolve => (error: AxiosError<APIResponse>) => resolve(error))

const getGameListCancelAction = createAction(GET_GAME_LIST_CANCEL)

const clearGameListAction = createAction(CLEAR_GAME_LIST)
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getGameListAction,
  getGameListSuccessAction,
  getGameListFailureAction,
  getGameListCancelAction,
  clearGameListAction,
}