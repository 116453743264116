import React, { ChangeEvent, Component } from 'react'
import { isEmpty, isEqual, map, noop, } from 'lodash'
import './WinNumber.style.scss'
import { Button, ButtonRadio, InputNumber } from 'components'

import {
  LOTTO_GAME_TYPES, LOTTO_GAME_TYPE_LENGTH, TEXT_GAME_TYPE_NAME
  // LOTTO_GAME_TYPE_NAME,
  // LOTTO_GAME_TYPE_LENGTH
} from 'constants/variables'
import { get } from 'lodash'
import colors from 'constants/colors'
import close from 'assets/images/icon/closeBlue.png'
import expandIcon from 'assets/images/icon/expand.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Scrollbar from 'react-scrollbars-custom'
import transformer from 'utils/transformer'

const windowHeight = window.innerHeight

// const constants = {
//   switchNumberMode: 'กลับเลข',
//   numberpadMode: 'โหมดแป้นตัวเลข',
//   numbersetMode: 'โหมดชุดตัวเลข',
//   betRate: 'สูงสุดบาทละ',
//   suitRate: 'ชุดละ',
//   gameType: 'ประเภทการแทง',
//   placeholderGameType: 'เลือกประเภทการแทง',
//   placeholderNumber: (numberSet: number) => `เลข ${numberSet} ตัว`,
//   historyOld: 'ดึงโพยเก่า',
//   cancel:'ยกเลิก',
//   finish:'เสร็จสิ้น'
// }

const defaultProps: IWinNumberComponentProps = {
  lottos: [],
  betRates: [],
  gameSlug: 'LOTTER_YEGEE',
  onAddedNumber() { noop() },
  onNavigateToOldFavorite() { noop() },
  numberBan: '',
  betLimit: {betLimit: 0},
  mode:'EASY'
}

// const TEXT_NAME: { [type in TLottoGameType]: string } = {
//   THREE_UP:"สามตัว",
//   THREE_TOAST:"สามตัว",

//   TWO_UP:"สองตัว",
//   TWO_DOWN:"สองตัว",

//   RUN_UP:"วิ่งเลข",
//   RUN_DOWN:"วิ่งเลข",

//   FOUR_SUITE:"สี่ตัว"
// }

// const listTwoType: TModeTwoType[] = ['DOOR', 'FRONT', 'BACK']
//   const listNameTwoType:{[type in TModeTwoType]:string} ={
//     FRONT:'รูดหน้า',
//     BACK:'รูดหลัง',
//     DOOR:'19 ประตู',
//   }


class WinNumber extends Component<IWinNumberComponentProps, IWinNumberComponentState> {
  static defaultProps = defaultProps

  state: IWinNumberComponentState = {
    animated: false,
    numberSet: '',
    gameType: [get(LOTTO_GAME_TYPES, `${this.props.gameSlug}.0`, 'THREE_UP')],
    mainGameType : 'TWO',
    inputMode: 'NUMBERPAD',
    isSwitchedNumber: false,
    valueBet: '',
    modeTwoType:'DOOR',

    openModal: false,

    inputWinNumber: '',
    winTwoNumber: true,
    winDouble: false,
    listNumber: [],
  }

  renderWinNumber=()=>{
    const number = ['0','1','2','3','4','5','6','7','8','9']
    return number.map((item,index)=>{
      let indexNumber = this.state.inputWinNumber.lastIndexOf(item)
      return <div className='btn-win-number' key={'btn-win-number'+item}>
      <Button
        id={'btn-win-number'+item}
        text={item}
        styleContainer={indexNumber!==-1?'active':''}
        onClick={()=>{
          if (indexNumber===-1 && this.state.inputWinNumber.length<7) {
            this.setState({inputWinNumber:this.state.inputWinNumber+item})
          }else{
            this.setState({inputWinNumber:this.state.inputWinNumber.replace(item,'')})
          }
        }}
        // color={indexNumber===-1?colors.PRIMARY_TEXT:colors.PRIMARY_BG}
        backgroundColor={indexNumber===-1?colors.SECONDARY_BLUE:colors.SECONDARY_GOLD_TEXT}
        backgroundHoverColor={indexNumber===-1?colors.PRIMARY_BLUE:colors.PRIMARY_YELLOW}
      >
        {indexNumber!==-1 && <div className='position-index font-m12r'>{indexNumber+1}</div>}
      </Button>
      </div>
      
    })
  }

  checkTongNumber = (num1:string,num2:string,num3:string) => (Number(num1)+Number(num2)+Number(num3))/3 === Number(num1)

  calculateWinNumber=(input:string)=>{
    let twoWin = input.split("").reduce((result, item,index)=>{
      if (this.state.winDouble) {
        result.push(`${item}${item}`)
      }
      for (let j = index + 1; j < input.length; j++) {
        result.push(`${item}${input[j]}`)
      }
      return result.sort();
    }, [] as string[]);
    let threeWin = input.split("").reduce((result, item,index)=>{
      if (this.state.winDouble) {
        result.push(`${item}${item}${item}`)
      }

      for (let j = index + 1; j < input.length; j++) {
        result.push(`${item}${item}${input[j]}`)

        for (let k = j ; k < input.length; k++) {
          if ( this.checkTongNumber(input[k],item,input[j]) ) {
            if (this.state.winDouble) {
              result.push(`${item}${input[j]}${input[k]}`)
            }
          }else{
            result.push(`${item}${input[j]}${input[k]}`)
          }
        }
      }
      return result.sort();
    }, [] as string[]);
    
    let list:string[] = this.state.listNumber.concat(this.state.winTwoNumber?twoWin:threeWin)
    list = list.filter(function(item, pos) {
      return list.indexOf(item) === pos;
    })
    return this.setState({listNumber:list},()=>{})
  }

  renderLottoGameTypes = () => {
    const gameList:TLottoGameType[] = ['TWO_UP', 'THREE_UP']
    const TEXT_NAME_LOTTO_WIN: { [type in TLottoGameType]: string } = {
      THREE_UP: "จับวินเลข 3 ตัว",
      THREE_TOAST: "จับวินเลข 3 ตัว",
    
      TWO_UP: "จับวินเลข 2 ตัว",
      TWO_DOWN: "จับวินเลข 2 ตัว",
    
      RUN_UP: "วิ่งเลข",
      RUN_DOWN: "วิ่งเลข",
    
      FOUR_SUITE: "สี่ตัว"
    }

    const LottoGamTypesComponent = map(gameList, (gameType, gameTypeIndex) => {
    const isCurrentActive = (gameType==='TWO_UP'&&this.state.winTwoNumber) || (gameType==='THREE_UP'&& !this.state.winTwoNumber)
      return (
        <div
          className={`col-6 px-1 pb-1`}
          key={`lotto-game-type-${gameTypeIndex}-${gameType}`}
        >
          <div className="d-flex">
            <ButtonRadio
              id={`lotto-game-type-${gameType}`}
              text={(<h4 className={`font-m20r ${isCurrentActive ? 'secondary-text-navy' : 'secondary-text-gold'} `}>{TEXT_NAME_LOTTO_WIN[gameType]}</h4>)}
              forceState={isCurrentActive}
              defaultState={isCurrentActive}
              onChangeState={state => {
                this.setState({winTwoNumber:gameType==='TWO_UP',listNumber:[],gameType:[gameType]})
              }}
              backgroundColor={colors.SECONDARY_GOLD_TEXT}
              defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
            />
          </div>
        </div>
      )
    })

    return (<div className="d-flex w-100">{LottoGamTypesComponent}</div>)
  }

  renderLottoSubGameTypes = () => {
    // const gameList = LOTTO_GAME_TYPES[this.props.gameSlug]
    const gameList:TLottoGameType[] = this.state.winTwoNumber?['TWO_UP', 'TWO_DOWN']:['THREE_UP', 'THREE_TOAST']

    const LottoGamTypesComponent = map(gameList, (gameType, gameTypeIndex) => {
      const isCurrentActive = this.state.gameType.includes(gameType)
      return (
        <div
          className={`col-6 px-1 pb-1`}
          key={`lotto-sub-game-type-${gameTypeIndex}-${gameType}`}
        >
          <div className="d-flex">
            <ButtonRadio
              id={`lotto-game-type-${gameType}`}
              text={(
                <>
                  <h4 className={`font-m20r ${isCurrentActive ? 'secondary-text-navy' : 'secondary-text-gold'} `}>{TEXT_GAME_TYPE_NAME[gameType]}</h4>
                  {/* <h6 className="subtitle-2 secondary-text">{betRateText}</h6> */}
                </>
              )}
              forceState={isCurrentActive}
              defaultState={isCurrentActive}
              onChangeState={state => {
                if (state) {
                  let gameTypes: TLottoGameType[] = []
                  if (this.state.gameType.length <= 0) {
                    gameTypes = [gameType]
                  } else if (this.state.gameType.length > 1) {
                    if (this.state.gameType.includes(gameType)) {
                      gameTypes = this.state.gameType.filter(type => (!isEqual(type, gameType)))
                    } else {
                      gameTypes = [gameType]
                    }
                  } else if (this.state.gameType.length < 2) {
                    if (this.state.gameType.includes(gameType)) {
                      gameTypes = this.state.gameType
                    } else {
                      const gameTypeState: TLottoGameType = get(this.state.gameType, '0', '')
                      const lengthOriginal = LOTTO_GAME_TYPE_LENGTH[gameTypeState] || 0
                      const lengthTarget = LOTTO_GAME_TYPE_LENGTH[gameType]
                      if (lengthOriginal === lengthTarget) {
                        gameTypes = [...this.state.gameType, gameType]
                      } else {
                        gameTypes = [gameType]
                      }
                    }
                  } else {
                    gameTypes = []
                  }

                  return this.setState({
                    gameType: gameTypes,
                    numberSet: '',
                    inputMode: (gameType === 'RUN_DOWN' || gameType === 'RUN_UP' || gameType === 'FOUR_SUITE') ? 'NUMBERPAD' : this.state.inputMode,
                  })
                } else {
                  if (this.state.gameType.length > 1) {
                    if (this.state.gameType.includes(gameType)) {
                      const gameTypes = this.state.gameType.filter(type => (!isEqual(type, gameType)))
                      return this.setState({
                        gameType: gameTypes,
                        numberSet: '',
                        inputMode: (gameType === 'RUN_DOWN' || gameType === 'RUN_UP' || gameType === 'FOUR_SUITE') ? 'NUMBERPAD' : this.state.inputMode,
                      })
                    }
                  }
                }
              }}
              backgroundColor={colors.SECONDARY_GOLD_TEXT}
              defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
            />
          </div>
        </div>
      )
    })

    return (<div className="d-flex w-100">{LottoGamTypesComponent}</div>)
  }

  renderWinDouble = () => {
    const list = [true,false]
    const LottoGamTypesComponent = map(list, (gameType, gameTypeIndex) => {
      const isCurrentActive = (gameTypeIndex===0&&this.state.winDouble) || (gameTypeIndex===1&& !this.state.winDouble)
      return (
        <div
          className={`col-6 px-1 pb-1`}
          key={`lotto-game-type-${gameTypeIndex}-${gameType}`}
        >
          <div className="d-flex">
            <ButtonRadio
              id={`lotto-game-type-${gameType}`}
              text={(<h4 className={`font-m20r ${isCurrentActive ? 'secondary-text-navy' : 'secondary-text-gold'} `}>{gameType?(this.state.winTwoNumber?'จับวินรวมเลขเบิ้ล':'จับวินรวมเลขตอง'):(this.state.winTwoNumber?'จับวินไม่รวมเลขเบิ้ล':'จับวินไม่รวมเลขตอง')}</h4>)}
              forceState={isCurrentActive}
              defaultState={isCurrentActive}
              onChangeState={state => {
                this.setState({winDouble:gameType})
              }}
              backgroundColor={colors.SECONDARY_GOLD_TEXT}
              defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
            />
          </div>
        </div>
      )
    })

    return (<div className="d-flex w-100">{LottoGamTypesComponent}</div>)
  }

  renderListNumber = () => {
    return this.state.listNumber.map((item,index)=>{
      return <div key={item+index} className='col-auto px-1'>
        <div className='list-num-item'>
          {item}
          <img
            src={close}
            alt="close"
            className='close'
            onClick={()=>{
              this.state.listNumber.splice(index, 1)
              this.setState({listNumber:this.state.listNumber})
            }}
          />
        </div>
      </div>
    })
  }

  render() {
    const LottoGameTypes = this.renderLottoGameTypes
    const RenderWinDouble = this.renderWinDouble
    const SubLottoGameTypes = this.renderLottoSubGameTypes

    var heightNavbar = get(document.getElementById('navbar-container'),"clientHeight",0);
    var heightTabbar = get(document.getElementById('tabbar-container'),"clientHeight",0);
    var heightJackpot = get(document.getElementById('badge-jackpot'),"clientHeight",0);
    var heightTop = heightNavbar+heightJackpot
    var tabbarNavbar = heightTabbar+heightTop
    var heighFullMain = windowHeight - (tabbarNavbar+10)
    var heightSetion1 = get(document.getElementById('setion-1'),"clientHeight",0);

    return (<>
      <div className={`win-number ${this.props.gameSlug} ${transformer.checkSizeDevice(windowHeight)}`}>
        <div className='d-flex align-items-center w-100'>
          <div className='d-flex font-m20r pl-1 pr-2'>เดินพันราคา</div>
          <div className="col px-1">
            <InputNumber
              textAlign="center"
              placeholder="โปรดใส่ราคาที่ต้องการ"
              hiddenErrorBlock
              thousandSeparator
              allowNegative={false}
              decimalScale={0}
              isAllowed={(values)=>(
                (values.formattedValue === '' ||
                values.floatValue! <= this.props.betLimit.betLimit) && values.value[0]!=='0')
              }
              name={`input-values-bet`}
              onBlur={(event: ChangeEvent<HTMLInputElement>) =>{}}
              onValueChange={({ value }) => {this.setState({valueBet:value})}}
              value={this.state.valueBet}
              borderStyle={"input-bet"}
              autoComplete="off"
            />
          </div>
        </div>

        <LottoGameTypes />
        <RenderWinDouble />
        <SubLottoGameTypes />
        
        <div className='d-flex w-100 text-selete-num'>
          <div className='font-m20r pl-1'>{`กรุณาเลือกตัวเลขที่ต้องการจับ 2 - 7 ตัวเลข`}</div>
        </div>

        <div className='d-flex w-100 group-btn-winNumber'>
          {this.renderWinNumber()}
        </div>

        <div className='row px-1 m-0 align-items-center'>
            <div className='font-m15r mr-1'>{`รายการที่คำนวณทั้งหมด ${this.state.listNumber.length} รายการ`}</div>
            <ButtonRadio
              id={`lotto-mode-numberset`}
              text={(<div className='btn-expand'>
                <span className='mr-1'>{"ดูตัวเลขทั้งหมด"}</span>
                <img src={expandIcon} className="expand-icon" alt='expandIcon' />
              </div>
              )}
              // paddingY={3}
              forceState={false}
              defaultState={false}
              onChangeState={state => {this.setState({openModal:!this.state.openModal})}}
              backgroundColor={colors.SECONDARY_GOLD_TEXT}
              defaultBackgroundColor={colors.TERTIARY_BUTTON_BLUE}
            />
        </div>

        <div className="d-flex w-100">
          <div className="col px-1 btn-calc">
            <Button
              size="medium"
              id="calculate"
              type="rectangle"
              text="คำนวณ"
              backgroundColor={colors.PRIMARY_BLUE}
              backgroundHoverColor={colors.SECONDARY_BLUE}
              onClick={()=>{
                this.setState({listNumber:[]},()=>{
                  this.calculateWinNumber(this.state.inputWinNumber)
                })
              }}
              // icon={leftArrow}
              styleContainer='btn-back'
            />
          </div>
          <div className="col px-1 btn-add-num">
            <Button
              size="medium"
              id="add-number"
              type="rectangle"
              text="เพิ่มรายการที่เลือก"
              backgroundColor={colors.PRIMARY_GREEN}
              backgroundHoverColor={colors.PRIMARY_GREEN_HOVER}
              onClick={()=>{
                let numSet = this.state.listNumber.reduce((result, num,index)=>{
                    if (this.state.gameType.length>1) {
                      result.push({number:num,type:get(this.state.gameType,"[1]"),value:isEmpty(this.state.valueBet)?'1':this.state.valueBet})
                    }
                    result.push({number:num,type:get(this.state.gameType,"[0]"),value:isEmpty(this.state.valueBet)?'1':this.state.valueBet})
                    return result
                  }, [] as ILottoNumber[])
                this.props.onAddedNumber(numSet,"ADD",false,undefined,this.state.valueBet)
                this.setState({listNumber:[]})
              }}
              // icon={leftArrow}
              styleContainer='btn-back'
            />
          </div>
        </div>

        <div className='d-flex w-100 flex-wrap'>
            {this.renderListNumber()}
        </div>
        <div className={`open-modal ${this.state.openModal&&'open'}`} style={{
          top:(heightSetion1),
          height:`${heighFullMain-heightSetion1}px`
        }}>
          <div className='d-flex flex-row align-items-center'>
            <div className='font-m20r secondary-text-gold'>{`รายการที่คำนวณทั้งหมด ${this.state.listNumber.length} รายการ`}</div>
            {/* <div className="close-modal ml-auto"
               onClick={()=>{
                this.setState({openModal:false})
              }}
            >
              <ResponsiveIcon
                icon={{ x1: closeIcon, x2: closeIcon, x3: closeIcon }}
                className="delete-lotto-button-icon-header"
                alt="delete-lotto-icon"
              />
            </div> */}
            <FontAwesomeIcon icon={faTimes}
              onClick={()=>{
                this.setState({openModal:false})
              }}
              className="font-m25r delete-lotto-button-icon-expand ml-auto"
            />
          </div>
          <Scrollbar
            id="ListNumber"
            // ref={this.lottoOldFavoriteBetItemsWrapperRef as any}
            removeTracksWhenNotUsed 
            disableTracksWidthCompensation 
          >
          <div className='d-flex flex-wrap w-100'>
            {this.renderListNumber()}
          </div>
          </Scrollbar>
        </div>
      </div>
      </>
    )
  }

}

export default WinNumber