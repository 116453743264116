import React, { SFC } from 'react'
import { LOTTO_GAME_TYPE_NAME } from 'constants/variables'
import { find, split, get } from 'lodash'
import './betResult.style.scss'

const BetResultComponent: SFC<IBetResultComponentProps> = ({
  results,
}) => {

  const threeUp = find(results, ['valueType', 'THREE_UP'])
  const twoUp = find(results, ['valueType', 'TWO_UP'])
  const twoDown = find(results, ['valueType', 'TWO_DOWN'])
  const [, slugType] = split(get(results[0], 'slug', '') || '', '_')
  return (
    <div className="bet-result-container tertiary-bg-navy p4">
      {slugType === 'YEGEE'
        ?
        <div className="row">
          <div className="col text-center">
            <h4 className='h2r'>{LOTTO_GAME_TYPE_NAME[threeUp?.valueType!]}</h4>
            <h1 className="h1r secondary-text-gold">{threeUp?.value || ''}</h1>
          </div>
        </div>
        :
        <div className="row">
          <div className="col text-center">
            <h4 className='h2r'>{LOTTO_GAME_TYPE_NAME[threeUp?.valueType!]}</h4>
            <h1 className="h1r secondary-text-gold">{threeUp?.value || ''}</h1>
          </div>
          <div className="col text-center">
            <h4 className='h2r'>{LOTTO_GAME_TYPE_NAME[twoDown?.valueType!]}</h4>
            <h1 className="h1r secondary-text-gold">{twoDown?.value || ''}</h1>
          </div>
        </div>
      }
      {slugType === 'YEGEE'
        ?
        <div className="row m3-t">
          <div className="col text-center">
            <h4 className='h2r'>{LOTTO_GAME_TYPE_NAME[twoUp?.valueType!]}</h4>
            <h1 className="h1r secondary-text-gold">{twoUp?.value || ''}</h1>
          </div>
          <div className="col text-center">
            <h4 className='h2r'>{LOTTO_GAME_TYPE_NAME[twoDown?.valueType!]}</h4>
            <h1 className="h1r secondary-text-gold">{twoDown?.value || ''}</h1>
          </div>
        </div>
        :
        <></>
      }

    </ div>
  )
}

export default BetResultComponent