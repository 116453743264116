import React, { FC } from 'react'
import { noop } from 'lodash'
import moment from 'moment-timezone'
import colors from 'constants/colors'
import { ResponsiveIcon, Button, Modal, LottoBetItem } from 'components'
import InfoIcon from 'assets/images/modal/info.png'
import './confirm.style.scss'


declare interface IConfirmProps {
  title?: string
  description?: string
  onConfirm(): void
  onCancel(): void
  betDetail: IOldFavoriteSet
  totalNumbers: number
  totalValue: number
}


const constants = {
  title: 'ข่าวด่วน',
  description: 'กรุณารอการตรวจสอบสักครู่',
  cancel: 'ยกเลิก',
  confirm: 'ยืนยัน',
}

type DefaultProps = Readonly<typeof defaultProps>


const defaultProps: IConfirmProps = {
  title: constants.title,
  description: constants.description,
  onCancel() { noop() },
  onConfirm() { noop() },
  betDetail: {
    id: 0,
    lotteryType: '',
    totalValue: 0,
    totalNumber: 0,
    createdAt: '',
  },
  totalNumbers: 0,
  totalValue: 0,
}

const ConfirmModal: FC<IConfirmProps & DefaultProps> = (props) => {

  return (
    <div className="confirm-modal-container border-rounded">
      <ResponsiveIcon
        alt="confirm-icon"
        className="confirm-checked-icon"
        icon={InfoIcon}
      />
      <p className="body-1">{props.title}</p>
      <LottoBetItem
        containerClassName="mt-2"
        key={`${props.betDetail.lotteryType}-${props.betDetail.createdAt}-${props.betDetail.id}`}
        totalNumbers={props.totalNumbers}
        totalValue={props.totalValue}
        time={moment(props.betDetail.createdAt, 'YYYYMMDDHHmmss').format('ll')}
      />
      <div className="row m3-t">
        <div className="col">
          <Button
            id="confirm-ok-button"
            backgroundColor={colors.TERTIARY_RED}
            backgroundHoverColor={colors.TERTIARY_RED_HOVER}
            type="rectangle"
            onClick={props.onCancel}
            text={constants.cancel!}
          />
        </div>
        <div className="col">
          <Button
            id="confirm-ok-button"
            backgroundColor={colors.TERTIARY_GREEN}
            backgroundHoverColor={colors.TERTIARY_GREEN_HOVER}
            type="rectangle"
            onClick={props.onConfirm}
            text={constants.confirm!}
          />
        </div>
      </div>
    </div>
  )
}

ConfirmModal.defaultProps = defaultProps

const confirmModal = {
  show: (props: IConfirmProps) => {
    return Modal.event('MODAL_OVER', {
      state: 'show',
      extraProps: { size: 's' },
      component: (<ConfirmModal {...props} />),
    })
  },
  hide: () => Modal.event('MODAL_OVER', { state: 'hide' }),
}

export default confirmModal