import { getType } from "typesafe-actions";
import { RootAction } from "typings/reduxs/Actions";
import { initialState } from "./constants";
import actions from "./actions";
import { get } from "lodash";

const getNotificationUserCountReducer = (
  state: ReducerState<IV1GetNotificationUserCount> = initialState,
  action: RootAction
): ReducerState<IV1GetNotificationUserCount> => {
  switch (action.type) {
    case getType(actions.getNotificationUserCountAction):
      return {
        ...state,
        isFetching: true,
      };
    case getType(actions.getNotificationUserCountSuccessAction):
      return {
        isFetching: false,
        data: action.payload.data.data,
        code: action.payload.status,
      };

    case getType(actions.getNotificationUserCountFailureAction):
      return {
        isFetching: false,
        error: action.payload.response?.data.devMessage,
        code: action.payload.response?.status,
      };
    case getType(actions.updateNotificationUserCountAction):
      return {
        ...state,
      }
    case getType(actions.updateNotificationUserCountSuccessAction):
      return {
        ...state,
        isFetching: false,
        data: get(action, "payload", {
          unreadCount: 0
        })
      }
    default:
      return state;
  }
};

export default getNotificationUserCountReducer;
